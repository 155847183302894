import { Module } from "vuex";
import { appStore } from "../app.store";
import { IRootState } from "../app.store";

import { IUserMessage, USER_MESSAGE_LEVEL } from "./user-message-models";

export enum USER_MESSAGE_STORE_CONST {
  USER_MESSAGE_CONST_MODULE_NAME = "USER_MESSAGE_CONST_MODULE_NAME",

  // <MUTATIONS>
  USER_MESSAGE_MUTATIONS_ADD_MESSAGE = "USER_MESSAGE_MUTATIONS_ADD_MESSAGE",
  // </MUTATIONS>
}

export interface IUserMessageStoreState {
  userMessages: IUserMessage[];
  currentMessage: IUserMessage;
}

const mutations = {
  [USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE](
    state: IUserMessageStoreState,
    userMessage: IUserMessage
  ) {
    //  create an id that is a 36 char random string.
    const id =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    userMessage = {
      ...userMessage,
      id,
    };
    state.userMessages.push(userMessage);
    state.currentMessage = userMessage;
  },
};

export const userMessageStore: Module<IUserMessageStoreState, IRootState> = {
  namespaced: true,
  state: {
    userMessages: [],
    currentMessage: {} as IUserMessage,
  },
  mutations,
};

export const messageDispatchHelper = (
  message: string,
  level?: string | USER_MESSAGE_LEVEL
) => {
  if (!level || level.toString().length === 0) {
    level = USER_MESSAGE_LEVEL.INFO;
  }

  const id =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  const userMessage: IUserMessage = {
    id,
    level: level
      ? USER_MESSAGE_LEVEL.INFO
      : (level as any as USER_MESSAGE_LEVEL),
    message,
  };

  appStore.commit(
    USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME +
      "/" +
      USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE,
    userMessage
  );

  (window as any).M.toast({
    html: userMessage.message, // "<div>Drag left or right to close.</div>"
    classes: level === "WARN" ? "toast-warning" : "toast-info",
    displayLength: 7000,
    inDuration: 500,
    outDuration: 500,
  });
};

export const messageDispatchHelperAdmin = (
  message: string,
  level?: string | USER_MESSAGE_LEVEL
) => {
  if (!level || level.toString().length === 0) {
    level = USER_MESSAGE_LEVEL.INFO;
  }

  const isAdmin =
    appStore.getters["CONFIG_CONST_MODULE_NAME/CONFIG_GETTERS_IS_ADMIN"];
  if (isAdmin) {
    messageDispatchHelper(message, level);
  }
};
