











































import { defineComponent, SetupContext } from "@vue/composition-api";

export default defineComponent({
  name: "form-generic-input-template-v2",
  components: {},
  inheritAttrs: false,
  props: {
    formLabel: {
      type: String,
      default: () => {
        return "";
      },
    },
    formLabelClass: {
      type: String,
      default: () => {
        return "e4s-input--label";
      },
    },
    errorMessage: {
      type: String,
      default: () => {
        return "";
      },
    },
    showHelp: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    showLabel: {
      type: Boolean,
      default: () => {
        //   If false hides the whole label section, which doesn't
        //  really make sense...you'd just dump an input or whatever
        //  on ui...see "hideLabel"
        return true;
      },
    },
    hideLabel: {
      type: Boolean,
      default: () => {
        //  This sets visibility to hidden, so the label + field
        //  still takes up same space...which is most likely
        //  what we are after.
        return false;
      },
    },
  },
  setup(
    props: {
      value: string;
      formLabel: string;
      placeHolder: string;
      errorMessage: string;
      showHelp: boolean;
      showLabel: boolean;
      hideLabel: boolean;
    },
    context: SetupContext
  ) {
    return {
      helpClicked: () => {
        context.emit("helpClicked");
      },
    };
  },
});
