import {ISecondaryCustDef} from "./secondary-cust-models"
import {SecondaryService} from "../secondary-service"
import {ISecondaryDef, ISecondaryVariant} from "../secondary-models"
import {IValidationProp} from "../../validation/validation-models"
import {ValidationService} from "../../validation/validation-service"
import * as R from "ramda"
import {ISecondaryCustStoreState} from "./secondary-cust-store"

const secondaryService: SecondaryService = new SecondaryService();

export class SecondaryCustService {

    public factorySecondaryCustStoreState(): ISecondaryCustStoreState {
        return {
            secondaryCustDefs: []
        }
    }

    public factorySecondaryCustDef(): ISecondaryCustDef {
        return {
            id: 0,
            prod: secondaryService.factorySecondaryProduct(),
            maxAllowed: 0,
            refObj: secondaryService.factorySecondaryRefObj(),
            perAcc: ""
        };
    }

    public validateVariation(secondaryVariant: ISecondaryVariant, secondaryDef: ISecondaryDef): Record<string, IValidationProp> {
        const validationService: ValidationService = new ValidationService();
        let validationState: Record<string, IValidationProp>  = {};

        const attrToIgnore = secondaryService.getAttributeNameToIgnore();

        const attributeValuesSelected: number = secondaryVariant.attributeValues.reduce( (accum, attrValue) => {
            if ((attrValue.name !== attrToIgnore) && attrValue.value.length === 0) {
                accum++;
            }
            return accum;
        }, 0)

        if (attributeValuesSelected > 0) {
            validationState = validationService.addMessage("attributes", "Please select all attributes.", validationState);
        }

        const secondaryVariantMatch: ISecondaryVariant | null = this.getMatchingVariation(secondaryVariant, secondaryDef);
        if (secondaryVariantMatch) {
            validationState = validationService.addMessage(
                "attributes",
                "Please select the item set up for this combination of attributes.",
                validationState);
        }

        return validationState;
    }

    public getMatchingVariation(secondaryVariant: ISecondaryVariant, secondaryDef: ISecondaryDef): ISecondaryVariant | null {
        return secondaryDef.prod.variations.reduce( (accum: ISecondaryVariant | null, variation) => {
            if (variation.id !== secondaryVariant.id) {
                if (R.equals(variation.attributeValues, secondaryVariant.attributeValues)) {
                    accum = variation;
                }
            }
            return accum;
        }, null)
    }

    public getAthleteItems(secondaryCustDefs: ISecondaryCustDef[]): ISecondaryCustDef[] {
        return secondaryCustDefs.filter( (secDef) => {
            return secDef.perAcc === "A";
        });
    }

    public anyAthleteItems(secondaryCustDefs: ISecondaryCustDef[]): boolean {
        return this.getAthleteItems(secondaryCustDefs).length > 0;
    }
}
