import {DirectiveOptions} from "vue";
import {FormController} from "./form-controller";

// use like: <input v-form-controller-field="{formController, propPath: 'min'}"/>

const directive: DirectiveOptions = {
    bind(value: any) {
        // do preparation work
        // e.g. add event listeners or expensive stuff
        // that needs to be run only once
        // `value` is the initial value
        // console.log("formControllerFieldDirective bind", {value});
        // @ts-ignore
        // this.el.style.color = "#fff";
    },
    inserted(el, node) {
        // do something based on the updated value
        // this will also be called for the initial value
        // console.log("formControllerFieldDirective inserted");
    },
    update(el, node) {
        // do something based on the updated value
        // this will also be called for the initial value
        // console.log("formControllerFieldDirective update");
        if (node.value.formController) {
            const formController: FormController = node.value.formController;
            // formController.processChanges();  infinite loop
            const propPath: string = node.value.propPath;
            const className = formController.isFieldDirty(propPath) ? "" :  "";
            el.className = className;
        }
    },
    unbind() {
        // do clean up work
        // e.g. remove event listeners added in bind()
        // console.log("formControllerFieldDirective unbind");
    }
};

export default directive;
