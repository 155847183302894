import {useStore} from "../app.store";
import {AUTH_STORE_CONST, IAuthStoreState} from "./auth-store";
import {computed} from "@vue/composition-api"
import {useConfigStore} from "../config/useConfigStore"



export function useAuthStore(): IAuthStoreState {
  const store = useStore();
  return store.state[AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME];
}

export function useAuthStoreController() {
  // const store = useAuthStore();
  const configStore = useConfigStore();

  const isLoggedIn = computed( () => {
    // return !!(store.tokenReponse && store.tokenReponse.token && store.tokenReponse.token.length > 0);
    return configStore.configApp.userInfo.user.id > 0;
  })

  return {isLoggedIn}
}
