import {IServerPagingResponseList} from "../../common/common-models";
import https from "../../common/https";
import {ResourceData} from "../../common/resource/resource-service";
import {IEventTeamHeader} from "./event-teams-models";

export class CompEventTeamHeaderData  extends ResourceData<IEventTeamHeader> {
    constructor() {
        super("/v5/eventteams");
    }

    public getEventHeaders(compId: number, entityLevel: number, entityId: number): Promise<IServerPagingResponseList<IEventTeamHeader>> {
        return https.get( "/v5/eventteams/" + compId + "?entityid=" + entityId + "&entitylevel=" + entityLevel) as any as Promise<IServerPagingResponseList<IEventTeamHeader>>;
    }
}
