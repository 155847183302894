export class InputRestrictController {
    private maxLength: number = 100;
    private inValue: string;

    constructor(maxLength?: number) {
        this.inValue = "";
        this.maxLength = maxLength ? maxLength : 100;
    }

    public setInputValue(inputValue: string) {
        this.inValue = inputValue.slice(0, this.maxLength);
    }

    public getInputValue(): string {
        return this.inValue;
    }

    public setMaxLength(maxLength: number) {
        this.maxLength = maxLength;
    }

    public getIsMaxLength() {
        return this.inValue.length >= this.maxLength;
    }

    public onKeyDown(evt: any) {
        if (this.getIsMaxLength()) {
            if (evt.keyCode >= 48 && evt.keyCode <= 90) {
                evt.preventDefault();
                return;
            }
        }
    }

}
