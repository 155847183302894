import {ValidationController} from "./validation-controller";
import {computed, reactive} from "@vue/composition-api";

export function useValidationControllerV2(validationController: ValidationController) {

  const errors = computed( () => {
    return validationController.errs;
  })

  const re = reactive( validationController.errs);

  return {
    errors,
    re
  }
}
