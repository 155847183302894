import {Module} from "vuex";
import {ISimpleDateModel} from "../../common/common-models";
import {IRootState} from "../../app.store";


export enum RESULTS_STORE_CONST {
  RESULTS_CONST_MODULE_NAME = "RESULTS_CONST_MODULE_NAME",

  // <MUTATIONS>
  RESULTS_MUTATIONS_SET_DATE = "RESULTS_MUTATIONS_SET_DATE"
  // </MUTATIONS>

}

export interface IResultsStoreState {
  dateSelected: ISimpleDateModel;
}

const mutations = {
  [ RESULTS_STORE_CONST.RESULTS_MUTATIONS_SET_DATE ](state: IResultsStoreState, dateSelected: ISimpleDateModel) {
    state.dateSelected = dateSelected;
  }
};

const getters = {
};

const actions = {
};


export const resultsStore: Module<IResultsStoreState, IRootState> = {
  namespaced: true,
  state: {
    dateSelected: {
      iso: "",
      display: ""
    }
  },
  mutations,
  getters,
  actions
};

