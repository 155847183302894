import { IBaseConcrete, IServerResponse } from "../common/common-models";
import https from "../common/https";
import { IConfigApp } from "./config-app-models";
import { isLocalDevServer } from "../common/config";
// import {configMock} from "./config-data-mock"

export interface IConfigMeta {
  events: IBaseConcrete[];
  locations: IBaseConcrete[];
  organisers: IBaseConcrete[];
}

export class ConfigData {
  public getAppConfig(): Promise<IServerResponse<IConfigApp, IConfigMeta>> {
    return https
      .get(
        "/v5/public/config" + "?x=" + Math.random().toString(36).substring(2)
      )
      .then((resp) => {
        console.warn("xxxxxxxxxxxxxxxxxxxxxxxxx", resp);

        if (isLocalDevServer) {
          const data: IConfigApp = resp.data as any as IConfigApp;
          data.theme = "irl";
        }

        return resp;
      }) as any as Promise<IServerResponse<IConfigApp, IConfigMeta>>;
  }

  public getAdminConfig(): Promise<IServerResponse<IConfigApp>> {
    return https.get(
      "/v5/admin/config" + "?x=" + Math.random().toString(36).substring(2)
    ) as any as Promise<IServerResponse<IConfigApp>>;
  }

  public selfService(
    ssrId: number,
    entityId: number
  ): Promise<IServerResponse<null>> {
    return https.post(
      "/v5/selfservice?ssid=" + ssrId + "&entityid=" + entityId
    ) as any as Promise<IServerResponse<null>>;
  }
}
