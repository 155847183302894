import { IAthleticsOrganisation } from "../common/ui/athletic-org/athletic-org-models";
import { IBase, IBaseConcrete, IsoDateTime } from "../common/common-models";
import { IOrg } from "../org/org-models";
import { ILocation } from "../location/location-models";
import { IHelpData } from "../common/ui/field/field-help/field-help-store";
import {
  IE4sEmailMessageApp,
  IE4sEmailMessageComp,
} from "../email-message/email-message-models";
import { ICompFilterParams } from "../competition/v2/competition-data-v2";
import { IUserVersion } from "../admin/user/user-models";

export enum USER_ROLES {
  ANON = "ANON",
  USER = "USER",
  E4SUSER = "E4SUSER",
  APPADMIN = "APPADMIN",
}

export const SECURITY_ROLES = {
  BUILDER: "BUILDER",
  CHECKIN: "CHECKIN",
  REPORT: "REPORT",
  CHECK: "CHECK", //  ?
  ACTIVATE: "ACTIVATE", //  ?
  DEACTIVATE: "DEACTIVATE", //  ?
  FINANCE: "FINANCE",
  UKFINANCE: "UKFINANCE", //  ?
  E4S: "E4S", //  ?
  ADMIN: "ADMIN",
  APPADMIN: "APPADMIN",
  TICKET: "TICKET",
  SCOREBOARD: "SCOREBOARD",
  RESULTS: "RESULTS",
  CREATE_ATHLETE: "CREATE_ATHLETE",
};

//  Disability
export interface IClassification extends IBase {
  class: number; //  E.g. 11
  description: string; //  E.g. "These athletes have a very low visual acuity and/or no light perception"
  title: string; //  E.g. "Visual impairment"
}

export interface IOrgConfig extends IOrg {
  locations: ILocation[];
}

export interface IReport {
  title: string;
  link: number;
  description: string;
}

export const E4sThemes = ["def", "irl", "gbr"] as const;
export type E4sTheme = typeof E4sThemes[number];

export type E4sThemeId = "" | "ia";

export const E4sCssThemeIds: Record<E4sTheme, E4sThemeId> = {
  def: "",
  irl: "ia",
  gbr: "",
};

export const E4sEnvs = ["dev", "uat", "prod"] as const;
export type E4sEnv = typeof E4sEnvs[number];

export interface IConfigApp {
  systemName: string;
  theme: E4sTheme; //  Country 2 character identifier, e.g. GB, IE, FR, etc.
  env: E4sEnv;
  logo: string; //  Url to logo
  currency: string; //  Currency symbol
  userguide: string; //  Url to user guide.
  defaultao: IAthleticsOrganisation;
  aos: IAthleticsOrganisation[];
  userId: number; //  Current logged in user id
  role: USER_ROLES;
  menus: {
    admin: {
      cheques: boolean;
      builder: boolean;
      userSearch: boolean;
    };
    reports: IReport[];
  };
  classifications: IClassification[];
  orgs: IOrgConfig[];
  userInfo: IUserInfo;
  options: IConfigOptions;
  logout: string;
  checkIn: IConfigCheckIn;
  help: IHelpData[];
  messages: (IE4sEmailMessageApp | IE4sEmailMessageComp)[];
}

export interface IConfigCheckIn {
  shortUrl: string; //  E.g. e4scheck.in/{compId}
  defaultText: string;
}

export interface IConfigHealth {
  entryCheck: IConfigHealthStatus;
  healthMonitor: IConfigHealthStatus;
  orderCheck: IConfigHealthStatus;
  paymentStatus: IConfigHealthStatus;
  waitingList: IConfigHealthStatus;
}
export interface IConfigHealthStatus {
  every: number; //  Runs every x secs
  lastRun: IsoDateTime;
}

export interface IConfigOptionsPrice {
  addCost: number;
  minFee: number;
  minCost: number;
  percCharged: number;
  nationaladdCost: number;
  nationalminCost: number;
}

export interface IConfigOptions extends IConfigOptionsPrice {
  message: string;
  homeMessage: string;
  cartTimeLimit: number; //  Minutes.  Entries older than x mins get "time" reset.  E.g. subscriptions.
  health: IConfigHealth;
  homePage: {
    message: string;
    defaultFilters: ICompFilterParams;
  };
  pwd: {
    helpText: string;
    regex: string;
  };
  defaultAllowExpiredRegistration: boolean;
  useStripeConnect: boolean;
}

export type BUILDER_PERM_LEVEL_NAMES =
  | "ADMIN"
  | "FRONT"
  | "OPTIONS"
  | "COMP_EVENTS"
  | "SCHEDULE"
  | "SCHEDULE_ACTIONS";

export interface IConfigPermLevel {
  level: BUILDER_PERM_LEVEL_NAMES;
  levelid: number;
}

export interface IConfigPermission extends IBase {
  compid: number;
  orgid: number;
  orgname: string;
  role: string;
  roleid: number;
  userid: number;
}

export interface IConfigPermissionUser extends IBase {
  id: number;
  comp: IBaseConcrete;
  org: IBaseConcrete;
  role: IBaseConcrete;
  userid: number;
}

export interface IUserApplication extends IBase {
  display_name: string;
  impersonating: boolean;
  role: "" | "ANONYMOUS" | "USER" | "E4SUSER";
  user_email: string;
  google_email: string;
  permissions: IConfigPermissionUser[];
  version: IUserVersion;
}

export type ENTITY_TYPE =
  | ""
  | "Club"
  | "School"
  | "County"
  | "Region"
  | "Country";
export type ENTITY_LEVEL_NUMBER = 0 | 1 | 2 | 3 | 4;

export interface IEntityLevel {
  entityLevel: number;
  entityName: ENTITY_TYPE;
}

export const ENTITY_TABLE: IEntityLevel[] = [
  {
    entityLevel: 0,
    entityName: "",
  },
  {
    entityLevel: 1,
    entityName: "Club",
  },
  {
    entityLevel: 2,
    entityName: "County",
  },
  {
    entityLevel: 3,
    entityName: "Region",
  },
  {
    entityLevel: 4,
    entityName: "Country",
  },
];

export interface IUserInfoEntity extends IEntityLevel {
  areaid: number;
}

export interface IAreaUserInfo extends IUserInfoEntity {
  areaname: string;
  areashortname: string;
  areaparentid: number;
}

export type CLUB_TYPE = "C" | "S";
export interface IClubUserInfo extends IUserInfoEntity {
  id: number;
  Clubname: string;
  clubtype: CLUB_TYPE;
  active: number;
}

export interface IUserInfo {
  areas: IAreaUserInfo[];
  clubs: IClubUserInfo[];
  orgs: IOrg[];
  security: {
    permLevels: {
      builder: IConfigPermLevel[];
    };
    permissions: IConfigPermission[];
  };
  user: IUserApplication;
  e4s_credit: number;
}

/**
 * For Region, County, Club: e.g. Teams, what "level" is user using the app, as Club secretary
 * e.g. { name: "Nuneaton", level: 1 }, { name: "Devon", level: 2 }
 */
export interface IEntity {
  name: string;
  entityLevel: number | ENTITY_LEVEL_NUMBER;
  id: number;
  entityName: ENTITY_TYPE; //  Confusing, really this is entityType
  clubType: CLUB_TYPE | "";
}

export type UiVersion = "v1" | "v2";
