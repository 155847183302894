








































import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import { AUTH_STORE_CONST, IAuthStoreState } from "../auth/auth-store";
import Toaster from "../user-message/toaster.vue";
import LaunchFooter from "./launch-footer.vue";
import LaunchHeader from "./launch-header.vue";
import LaunchNavigator from "./launch-navigator.vue";
import {
  e4sLoginJwtHelper,
  getContentWidthClassV1,
  getWrapperClassV1,
  LAUNCH_ROUTES_PATHS,
} from "./launch-routes";
import { IConfigStoreState, CONFIG_STORE_CONST } from "../config/config-store";
import LoginForm from "../auth/login/login-form.vue";
import FieldHelpModal from "../common/ui/field/field-help/field-help-modal.vue";
import FieldHelpDisplay from "../common/ui/field/field-help/field-help-display.vue";
import { IUserApplication, UiVersion } from "../config/config-app-models";
import LoadingSpinnerV2 from "../common/ui/loading-spinner-v2.vue";
import { UserProfileData } from "../admin/user/user-data";

@Component({
  name: "launch",
  components: {
    LoadingSpinnerV2,
    FieldHelpDisplay,
    FieldHelpModal,
    navigator: LaunchNavigator,
    header: LaunchHeader,
    footer: LaunchFooter,
    "launch-header": LaunchHeader,
    "launch-footer": LaunchFooter,
    Toaster,
    LoginForm,
  },
  computed: {
    ...mapState(AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME, {
      isLoggedIn: (state: IAuthStoreState) => state.isLoggedIn,
    }),
    ...mapState(CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME, {
      userApplication: (state: IConfigStoreState) =>
        state.configApp.userInfo.user,
      uiVersion: (state: IConfigStoreState) => state.ui.version,
    }),
  },
})
export default class Launch extends Vue {
  public readonly uiVersion: UiVersion;
  public readonly userApplication: IUserApplication;

  public mounted() {
    if (this.$route.name === LAUNCH_ROUTES_PATHS.HOME) {
      new UserProfileData().setVersion("v1");
    }

    e4sLoginJwtHelper();
  }

  @Watch("isLoggedIn")
  public onIsLoggedInChanged(newValue: boolean, oldValue: boolean) {
    // console.log("LaunchLogin @Watch isLoggedIn:", newValue);
    // if (newValue) {
    //   this.$router.push(LAUNCH_ROUTES_PATHS.ENTRY);
    // } else {
    //   this.$router.push(LAUNCH_ROUTES_PATHS.SHOW_ENTRIES);
    // }
  }

  public get isAppReady() {
    return this.userApplication.role !== "";
  }

  @Watch("isAppReady")
  public onAsAppReadyChanged(newValue: boolean, oldValue: boolean) {
    const isUserOnV1HomeRoute = this.$route.name === LAUNCH_ROUTES_PATHS.HOME;
    const doesUSerHaveV2SetAsPreference =
      this.userApplication.role !== "" &&
      this.userApplication.version.current === "v2";

    if (isUserOnV1HomeRoute && doesUSerHaveV2SetAsPreference) {
      // const location: RawLocation = {
      //   path: "/" + LAUNCH_ROUTES_PATHS_V2_DIR,
      // };
      // this.$router.push(location);
      console.log("onAsAppReadyChanged switch to v2 deprecated for now.");
    }
  }

  public get getWrapperClass() {
    const classes = [getWrapperClassV1(this.$route.name)];

    if (this.userApplication.impersonating) {
      classes.push("impersonating-user");
    }
    return classes;
  }

  public get getContentWidth() {
    return getContentWidthClassV1(this.$route.name);
  }
}
