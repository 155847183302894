






























































import {
  computed,
  defineComponent,
  PropType,
  ref,
  SetupContext,
} from "@vue/composition-api";
import { ICompetitionSummaryPublic } from "../../../competition/competition-models";
import { CompetitionService } from "../../../competition/competiton-service";
import { format, parse } from "date-fns";
import { CompetitionData } from "../../../competition/competition-data";
import { handleResponseMessages } from "../../../common/handle-http-reponse";
import PublicCompCardWrapperV2 from "../public-list/v2/public-comp-card-wrapper-v2.vue";
import FormGenericSectionTitleV2 from "../../../common/ui/layoutV2/form/form-generic-section-title-v2.vue";
import FieldTextV2 from "../../../common/ui/layoutV2/fields/field-text-v2.vue";
import FormGenericInputTemplateV2 from "../../../common/ui/layoutV2/form/form-generic-input-template-v2.vue";
import ButtonGotoContactOrganiserV2 from "../../../common/ui/layoutV2/buttons/button-goto-contact-organiser-v2.vue";

const competitionService = new CompetitionService();

export default defineComponent({
  name: "priority-v2",
  components: {
    ButtonGotoContactOrganiserV2,
    FormGenericInputTemplateV2,
    FieldTextV2,
    FormGenericSectionTitleV2,
    PublicCompCardWrapperV2,
  },
  props: {
    competitionSummaryPublic: {
      type: Object as PropType<ICompetitionSummaryPublic>,
      default: () => {
        return competitionService.factorySummaryPublic();
      },
    },
  },
  setup(
    props: { competitionSummaryPublic: ICompetitionSummaryPublic },
    context: SetupContext
  ) {
    const priorityCode = ref("");
    const isLoading = ref(false);
    const message = ref("");

    const getCompName = computed(() => {
      return competitionService.getCompetitionTitle(
        props.competitionSummaryPublic
      );
    });

    const getHasPriorityDate = computed(() => {
      return (
        props.competitionSummaryPublic.options.priority.dateTime.length > 0
      );
    });

    const getOpenDate = computed(() => {
      return format(
        parse(props.competitionSummaryPublic.options.priority.dateTime),
        "Do MMM h:mma"
      );
    });

    function processAnswer() {
      isLoading.value = true;
      const prom = new CompetitionData().validatePriorityCode(
        props.competitionSummaryPublic.compId,
        priorityCode.value
      );
      handleResponseMessages(prom);

      return prom
        .then((resp) => {
          return resp.errNo === 0;
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    function submit() {
      processAnswer().then((isOk: boolean) => {
        if (!isOk) {
          message.value = "Code entered does not match.";
        }

        if (isOk) {
          context.emit("submit", isOk);
        }
      });
    }

    function cancel() {
      context.emit("cancel");
    }

    function test(xxx: any) {
      console.log(
        "test: " + xxx.target.value + ", typeof: " + typeof xxx.target.value
      );
      if (typeof xxx.target.value === "string") {
        priorityCode.value = new Date().toISOString();
      }
      priorityCode.value = xxx.target.value;
    }

    function priorityCodeChanged(code: string) {
      priorityCode.value = code;
    }

    return {
      priorityCode,
      getCompName,
      getHasPriorityDate,
      getOpenDate,
      message,
      submit,
      isLoading,
      cancel,
      test,
      priorityCodeChanged,
    };
  },
});
