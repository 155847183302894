











import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import { useConfigController } from "../../../../config/useConfigStore";

export interface PrimaryLinkOptions {
  widthFitContent: boolean; //  If true, the width of the link will be fit to the content.
  // If false, the width will be 100%, e.g. in a Tab or something where makes sense to have the width 100%.
}

/*
 * This component is used to navigate back to the previous page.
 * If you want a click, bind it to this component from parent component.
 */
export default defineComponent({
  name: "PrimaryLink",
  components: {},
  props: {
    linkText: {
      type: String,
      default: "SET_LINK_TEXT",
    },
    link: {
      type: String,
      default: "#",
    },
    target: {
      type: String,
      default: "_self",
    },
    linkOptions: {
      type: Object as PropType<PrimaryLinkOptions>,
      default: () => {
        return {
          widthFitContent: true,
        };
      },
    },
  },
  setup(
    props: {
      linkText: string;
      link: string;
      target: string;
      linkOptions: PrimaryLinkOptions;
    },
    context: SetupContext
  ) {
    const configController = useConfigController();

    const withInputCss = computed(() => {
      const cssEnv = configController.getCssEnvSuffix.value; //  E.g. AAI = "ai-", UK = ""  e4s-hyperlink--ia-primary
      return ["e4s-hyperlink--" + cssEnv + "primary"];
    });

    function onClicked() {
      console.log("PrimaryLink.onClicked()");

      context.emit("onClick");

      if (props.link === "#") {
        return;
      }

      window.open(props.link, "_blank");
      return;
    }

    const getLinkOptionStyles = computed(() => {
      const styles: string[] = [];
      if (props.linkOptions.widthFitContent) {
        styles.push("width: fit-content");
      }
      return styles.join(";");
    });

    return { withInputCss, onClicked, getLinkOptionStyles };
  },
});
