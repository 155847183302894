






import { ref, defineComponent, SetupContext } from "@vue/composition-api";
import LoginFormV2 from "./login-form-v2.vue";
import { useRoute } from "../../router/migrateRouterVue3";

export default defineComponent({
  name: "login-container-v2",
  components: { LoginFormV2 },
  props: {},
  setup(props: any, context: SetupContext) {
    const redirectedFrom = ref("");

    const route = useRoute();

    if (route.query && route.query.redirectFrom) {
      redirectedFrom.value = route.query.redirectFrom as any as string;
    }

    function onCancel() {
      window.location.href = window.location.href.split("#")[0] + "#/";
    }

    return { redirectedFrom, onCancel };
  },
});
