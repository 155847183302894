
















import {
  defineComponent,
  PropType,
  ref,
  SetupContext,
  watch,
} from "@vue/composition-api";
import { useFieldController } from "./useFieldController";

export type FieldTextType = "text" | "password" | "email";

export default defineComponent({
  name: "field-text-v2",
  components: {},
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: () => {
        return "";
      },
    },
    // sourceValue: {
    //   type: [String, null] as PropType<string | null>,
    //   default: () => {
    //     return null;
    //   },
    // },
    fieldType: {
      type: String as PropType<FieldTextType>,
      default: () => {
        return "text";
      },
    },
    placeHolder: {
      type: String,
      default: () => {
        return "";
      },
    },
    errorMessage: {
      type: String,
      default: () => {
        return "";
      },
    },
    isDisabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    tabIndex: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    useIsDirty: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  setup(
    props: {
      value: string;
      fieldType: FieldTextType;
      placeHolder: string;
      errorMessage: string;
      isDisabled: boolean;
      tabIndex: number;
      useIsDirty: boolean;
    },
    context: SetupContext
  ) {
    const valueInternal = ref(props.value);

    const fieldController = useFieldController();
    fieldController.init(props.value);

    // const sourceValueInternal = ref<string | null>(props.value);
    // const isDirty = ref(false);
    const cssClasses = ref<string[]>([]);

    // const attrs = useAttrs();

    watch(
      () => props.value,
      (newValue: string) => {
        if (newValue !== valueInternal.value) {
          valueInternal.value = newValue;
          // sourceValueInternal.value = newValue;
          fieldController.init(newValue);
          cssClasses.value = getCssClasses();
        }
      },
      {
        immediate: true,
      }
    );

    function clickHandler() {
      console.log("clickHandler");
      context.emit("onClick", valueInternal.value);
    }

    function submit() {
      console.log("field-text-v2.submit() value: " + valueInternal.value);
      context.emit("input", valueInternal.value);
      context.emit("change", valueInternal.value);

      // console.log(
      //   ">>>>>>>>>>valueInternal.value: " +
      //     valueInternal.value +
      //     ", props.sourceValue: " +
      //     sourceValueInternal.value
      // );

      fieldController.updateCurrent(valueInternal.value);

      // if (props.sourceValue !== null) {
      //   isDirty.value = valueInternal.value !== sourceValueInternal.value;
      // } else {
      //   isDirty.value = true;
      // }
      cssClasses.value = getCssClasses();
    }

    function keyUpHandler(args: KeyboardEvent) {
      console.log("keyUpHandler", args);

      if (args.key === "Enter") {
        // context.emit("keyup.enter", valueInternal.value);

        //   TODO I know this is wrong!  I just need to get this working.
        //   TODO At some point sort this as per the Vue.js docs.
        // https://stackoverflow.com/questions/56224091/what-are-inheritattrs-false-and-attrs-used-for-in-vue
        context.emit("keyUpEnter", valueInternal.value);
      }

      context.emit("keyUp", valueInternal.value);
    }

    // function keypressEnter() {
    //   console.log(
    //     "form-generic-input-text-v2.keypressEnter() value: " +
    //       valueInternal.value
    //   );
    //   context.emit("keypressEnter", valueInternal.value);
    // }
    //
    // function everything(x: unknown) {
    //   console.log("everything");
    // }

    function getCssClasses() {
      const cssClasses: string[] = [];

      if (props.errorMessage.length > 0) {
        cssClasses.push("e4s-input-field--error");
        return cssClasses;
      }
      if (props.isDisabled) {
        cssClasses.push("e4s-input-field--disabled");
        return cssClasses;
      }

      if (props.useIsDirty && fieldController.state.isDirty) {
        //if (props.useIsDirty && isDirty.value) {
        cssClasses.push("e4s-input-field--dirty");
      }

      return cssClasses;
    }

    return {
      valueInternal,
      submit,
      keyUpHandler,
      clickHandler,
      fieldController,
      cssClasses,
    };
  },
});
