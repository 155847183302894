export function debounce(func: any, wait: any, immediate?: any) {
    let timeout: any;
    return function() {
        // @ts-ignore
        const context: any = (this) as any;
        const args: any = arguments;
        const later = function() {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
}


