import {IAgeGroup} from "./agegroup-models"
import {ResourceData} from "../common/resource/resource-service";
import {IServerPagingResponseList} from "../common/common-models";
import https from "../common/https";

export class AgeGroupData extends ResourceData<IAgeGroup> {
    constructor() {
        super("/v5/ag");
    }

    public getAgeGroupsForComp_REAL(compId: number): Promise<IServerPagingResponseList<IAgeGroup>> {
        return https.get( this.getEndPoint() + "comp/" + compId) as any as Promise<IServerPagingResponseList<IAgeGroup>>;
    }

    public getAgeGroupsForComp(aocode: string, compId: number): Promise<IServerPagingResponseList<IAgeGroup>> {
        return https.get( this.getEndPoint() + "/def/" + aocode + "?compid=" + compId) as any as Promise<IServerPagingResponseList<IAgeGroup>>;
    }
}
