












import Vue from "vue";
import Component from "vue-class-component";
import CompAdmin from "./comp-admin.vue"
import { CompetitionData } from "../competition-data";
import {handleResponseMessages} from "../../common/handle-http-reponse"
import { CompetitionService } from "../competiton-service";
import {ICompetitionSummaryPublic} from "../competition-models"

@Component({
    name: "comp-admin-route",
    components: {CompAdmin}
})
export default class CompAdminRoute extends Vue {
    public competitionData: CompetitionData = new CompetitionData();
    public competitionService: CompetitionService = new CompetitionService();
    public id: number = 0;
    public isLoading = false;
    public competitionSummaryPublic: ICompetitionSummaryPublic = this.competitionService.factorySummaryPublic();


    public created() {
        this.id = isNaN(Number(this.$route.params.id)) ? 0 : parseInt(this.$route.params.id, 0);
        this.getData();
    }

    public getData() {
        if (this.id === 0 ) {
            return;
        }
        this.isLoading = true;
        const prom = this.competitionData.getCompById(this.id);
        handleResponseMessages(prom);
        prom
        .then((response)=> {
            if (response.errNo === 0) {
                this.competitionSummaryPublic = response.data;
            }
        })
        .finally(() => {
            this.isLoading = false;
        })
    }
}
