import { EventTypeNameGeneric } from "../../../../athleteCompSched/athletecompsched-models";
import { simpleClone } from "../../../../common/common-service-utils";
import { Module } from "vuex";
import { IRootState } from "../../../../app.store";

export interface IFilterTrackField {
  id: EventTypeNameGeneric | "ALL";
  name: string;
}

export const filtersTrackFieldOptions: IFilterTrackField[] = [
  {
    id: "ALL",
    name: "All",
  },
  {
    id: "Track",
    name: "Track",
  },
  {
    id: "Field",
    name: "Field",
  },
];

export enum SCHEDULE_COMP_PUBLIC_STORE_CONST {
  SCHEDULE_COMP_PUBLIC_MODULE_NAME = "SCHEDULE_COMP_PUBLIC_MODULE_NAME",

  // <MUTATIONS>
  SCHEDULE_COMP_PUBLIC__MUTATIONS__QUICK_FILTER = "SCHEDULE_COMP_PUBLIC__MUTATIONS__QUICK_FILTER",
  SCHEDULE_COMP_PUBLIC__MUTATIONS__FILTER_TRACK_FIELD = "SCHEDULE_COMP_PUBLIC__MUTATIONS__FILTER_TRACK_FIELD",
  // </MUTATIONS>
}

export interface IScheduleCompPublicStoreState {
  defaultFilterValue: string;
  filterTrackField: IFilterTrackField;
}

const mutations = {
  [SCHEDULE_COMP_PUBLIC_STORE_CONST.SCHEDULE_COMP_PUBLIC__MUTATIONS__QUICK_FILTER](
    state: IScheduleCompPublicStoreState,
    quickFilter: string
  ) {
    state.defaultFilterValue = quickFilter;
  },
  [SCHEDULE_COMP_PUBLIC_STORE_CONST.SCHEDULE_COMP_PUBLIC__MUTATIONS__FILTER_TRACK_FIELD](
    state: IScheduleCompPublicStoreState,
    filterTrackField: IFilterTrackField
  ) {
    state.filterTrackField = simpleClone(filterTrackField);
  },
};

const actions = {};

export const scheduleCompPublicStore: Module<
  IScheduleCompPublicStoreState,
  IRootState
> = {
  namespaced: true,
  state: {
    defaultFilterValue: "",
    filterTrackField: {
      id: "ALL",
      name: "All",
    },
  },
  mutations,
  actions,
};
