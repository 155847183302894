import https from "../common/https"
import {IServerGenericResponse} from "../common/common-models"
import {AxiosRequestConfig} from "axios"
import {CONFIG} from "../common/config"

export class CartData {


    public getMiniCart(): Promise<IServerGenericResponse> {

        const url = (process.env.NODE_ENV === "development" ? "https://dev.entry4sports.com" : CONFIG.E4S_HOST) + "/wp-json/e4s/v5/minicart";
        const config: AxiosRequestConfig = {
            responseType: "document"
        }
        return https.get(url, config) as Promise<IServerGenericResponse>;
    }


    // public getMiniCart(): Promise<IServerGenericResponse> {
    //     return Promise.resolve({
    //         "errNo": 0,
    //         "error": "Success",
    //         "data": [],
    //         "meta": {
    //             "wcCart": {
    //                 "url": "https://dev.entry4sports.co.uk/basket/",
    //                 "currency": "&euro;",
    //                 "items": []
    //             }
    //         }
    //     })
    // }

}
