import {ActionContext, Module} from "vuex";
import {IRootState} from "../app.store";
import {ICompetitionInfo} from "../competition/competition-models";
import {ENTRY_STORE_CONST} from "../entry/entry-store";
import { IUserMessage, USER_MESSAGE_LEVEL } from "../user-message/user-message-models";
import { USER_MESSAGE_STORE_CONST } from "../user-message/user-message-store";
import {TeamData} from "./team-data";
import {IAreaTeamSummary, ITeamComp, ITeamServerResponse} from "./team-models";
import {TeamService} from "./team-service";

const teamService: TeamService = new TeamService();

export enum TEAM_STORE_CONST {
    TEAM_STORE_CONST_MODULE_NAME = "TEAM_STORE_CONST_MODULE_NAME",

    // <MUTATIONS>
    TEAM_STORE_MUTATIONS_SET_TEAMS = "TEAM_STORE_MUTATIONS_SET_TEAMS",
    TEAM_STORE_MUTATIONS_TEAMS_LOADING = "TEAM_STORE_MUTATIONS_TEAMS_LOADING",
    TEAM_STORE_MUTATIONS_SET_SELECTED_TEAM = "TEAM_STORE_MUTATIONS_SET_SELECTED_TEAM",
    TEAM_STORE_MUTATIONS_CREATE_TEAM_SUCCESS = "TEAM_STORE_MUTATIONS_CREATE_TEAM_SUCCESS",
    TEAM_STORE_MUTATIONS_CREATE_TEAM_MESSAGE = "TEAM_STORE_MUTATIONS_CREATE_TEAM_MESSAGE",
    TEAM_STORE_MUTATIONS_CREATE_TEAM_SHOW_MODAL = "TEAM_STORE_MUTATIONS_CREATE_TEAM_SHOW_MODAL",
    // </MUTATIONS>

    // <GETTERS>
    TEAM_STORE_GETTERS_AREA_TEAMS = "TEAM_STORE_GETTERS_AREA_TEAMS",
    // </GETTERS>

    // <ACTIONS>
    TEAM_STORE_ACTIONS_GET_TEAMS = "TEAM_STORE_ACTIONS_GET_TEAMS",
    TEAM_STORE_ACTIONS_CREATE_TEAM = "TEAM_STORE_ACTIONS_CREATE_TEAM",
    TEAM_STORE_ACTIONS_DELETE_TEAM = "TEAM_STORE_ACTIONS_DELETE_TEAM"
    // </ACTIONS>
}

export interface ITeamStoreState {
    teamsServerResponse: ITeamServerResponse;
    teams: IAreaTeamSummary[];
    isLoading: boolean;
    selectedTeam: IAreaTeamSummary;
    createTeamSuccess: boolean;
    createTeamMessage: string;
    createTeamShowModal: boolean;
}

const mutations = {
    [ TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_SET_TEAMS ](state: ITeamStoreState, teamServerResponse: ITeamServerResponse) {
        if (teamServerResponse) {
            state.teamsServerResponse = teamServerResponse;
            if (teamServerResponse.data) {
                state.teams = teamService.flattenTeams([...teamServerResponse.data]);
            }
        }
    },
    [ TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_TEAMS_LOADING ](state: ITeamStoreState, isLoading: boolean) {
        state.isLoading = isLoading;
    },
    [ TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_SET_SELECTED_TEAM ](state: ITeamStoreState, team: IAreaTeamSummary) {
        state.selectedTeam = {...team};
    },
    [ TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_CREATE_TEAM_MESSAGE ](state: ITeamStoreState, message: string) {
        state.createTeamMessage = message;
    },
    [ TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_CREATE_TEAM_SHOW_MODAL ](state: ITeamStoreState, showModal: boolean) {
        state.createTeamShowModal = showModal;
    },
    [ TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_CREATE_TEAM_SUCCESS ](state: ITeamStoreState, isSuccess: boolean) {
        state.createTeamSuccess = isSuccess;
    }
};

const getters = {
    [ TEAM_STORE_CONST.TEAM_STORE_GETTERS_AREA_TEAMS ](state: ITeamStoreState) {
        return state.teamsServerResponse.data;
    }
};

const actions = {
    [ TEAM_STORE_CONST.TEAM_STORE_ACTIONS_GET_TEAMS ](context: ActionContext<ITeamStoreState, IRootState>, compId: number) {
        const teamData: TeamData = new TeamData();
        context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_TEAMS_LOADING, true);
        return teamData.getTeams(compId)
            .then((response: ITeamServerResponse) => {
                context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_SET_TEAMS, response);
                context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_TEAMS_LOADING, false);
                return;
            });
    },

    [ TEAM_STORE_CONST.TEAM_STORE_ACTIONS_CREATE_TEAM ](context: ActionContext<ITeamStoreState, IRootState>, team: ITeamComp) {
        const teamData: TeamData = new TeamData();
        context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_TEAMS_LOADING, true);
        context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_CREATE_TEAM_SUCCESS, false);
        const selectedCompetition: ICompetitionInfo = context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME].entryForm.selectedCompetition;
        team.compId = selectedCompetition.id;
        context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_CREATE_TEAM_MESSAGE, "");
        return teamData.create(team)
            .then((response: any) => {
                context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_SET_SELECTED_TEAM, response.data);
                context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_TEAMS_LOADING, false);
                context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_CREATE_TEAM_SUCCESS, true);

                //  Reload grid
                context.dispatch(TEAM_STORE_CONST.TEAM_STORE_ACTIONS_GET_TEAMS, selectedCompetition.id);
                context.commit(
                    USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME + "/" + USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE,
                    {
                        level: USER_MESSAGE_LEVEL.DEBUG,
                        message: "Team: " + team.teamName + " saved."
                    } as IUserMessage,
                    { root: true }
                );
                return;
            })
            .catch((error: any) => {
                context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_TEAMS_LOADING, false);
                context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_CREATE_TEAM_MESSAGE, error.response.data.error);
                return;
            });
    },

    [ TEAM_STORE_CONST.TEAM_STORE_ACTIONS_DELETE_TEAM ](context: ActionContext<ITeamStoreState, IRootState>, teamId: number) {
        const teamData: TeamData = new TeamData();
        context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_TEAMS_LOADING, true);
        const selectedCompetition: ICompetitionInfo = context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME].entryForm.selectedCompetition;
        return teamData.delete(teamId)
            .then((response: ITeamServerResponse) => {
                context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_TEAMS_LOADING, false);
                context.dispatch(TEAM_STORE_CONST.TEAM_STORE_ACTIONS_GET_TEAMS, selectedCompetition.id);
                context.commit(
                    USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME + "/" + USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE,
                    {
                        level: USER_MESSAGE_LEVEL.DEBUG,
                        message: "Team deleted."
                    } as IUserMessage,
                    { root: true }
                );
                return;
            })
            .catch((error: any) => {
                context.commit(TEAM_STORE_CONST.TEAM_STORE_MUTATIONS_TEAMS_LOADING, false);
                context.commit(
                    USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME + "/" + USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE, {
                        level: USER_MESSAGE_LEVEL.DEBUG,
                        message: error.response.data.error
                    } as IUserMessage,
                    { root: true }
                );
                return;
            });
    }
};

export {mutations, actions};

export const teamStore: Module<ITeamStoreState, IRootState> = {
    namespaced: true,
    state: {
        teamsServerResponse: {} as ITeamServerResponse,
        teams: [] as IAreaTeamSummary[],
        selectedTeam: {} as IAreaTeamSummary,
        isLoading: false,
        createTeamSuccess: false,
        createTeamMessage: "",
        createTeamShowModal: false
    },
    mutations,
    getters,
    actions
};
