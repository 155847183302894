import {IServerGenericResponse, IServerResponse} from "../common/common-models";
import https from "../common/https";
import {IClubLookup} from "./club-models";

export class ClubData {

    public getCompOrgs(orgId: number): Promise<IServerGenericResponse> {
        return https.get( "/v5/comporg/" + orgId) as any as Promise<IServerGenericResponse>;
    }
    public getCompOrgsPublic(): Promise<IServerGenericResponse> {
        return https.get( "/v5/public/comporg") as any as Promise<IServerGenericResponse>;
    }

    public searchClubs(areaId: number, key: string, isSchool: boolean, pageSize: number = 20): Promise<IServerResponse<IClubLookup[]>> {
        return https.get( "/v5/clubs?areaid=" + areaId + "&pagesize=" + pageSize + "&startswith=" + key + (isSchool ? "&type=S" : "")) as any as Promise<IServerGenericResponse>;
    }
}
