import https from "../common/https";

import {
  IServerBaseResponse,
  IServerResponse,
  IServerResponseList,
} from "../common/common-models";
import {
  ICompetitionSummaryPublic,
  IServerCompetitionLookupResponse,
  IWorkFlow,
} from "./competition-models";
import { IAgeGroupCompCoverageModel } from "../agegroup/agegroup-models";
import { IEntity } from "../config/config-app-models";
import { IClubCompInfo } from "../entry/v2/schools/clubCompInfo-models";
import { IClubComp } from "../entry/v2/schools/club-comp-picker/club-comp-picker-models";

export interface IServerResponseListCompPublic
  extends IServerResponseList<ICompetitionSummaryPublic> {
  meta: {
    workflow: IWorkFlow[];
  };
}

export class CompetitionData {
  public getCompetitionsByid(
    id: number,
    isPublic?: boolean,
    compId?: number
  ): Promise<IServerCompetitionLookupResponse> {
    return https.get(
      "/v5/" +
        (isPublic ? "public/" : "") +
        "compdates/" +
        id +
        (compId ? "/" + compId : "")
    ) as any as Promise<IServerCompetitionLookupResponse>;
  }

  public getAllComps(orgId?: number): Promise<IServerResponseListCompPublic> {
    return https.get(
      "/v5/public/get-all-comps" + (orgId ? "?orgid=" + orgId : "")
    ) as any as Promise<IServerResponseListCompPublic>;
  }

  public getCompById(
    compId?: number
  ): Promise<IServerResponse<ICompetitionSummaryPublic>> {
    return https.get("/v5/competition/" + compId) as any as Promise<
      IServerResponse<ICompetitionSummaryPublic>
    >;
  }

  public validatePriorityCode(
    compId: number,
    code: string
  ): Promise<IServerBaseResponse> {
    return https.post("/v5/competition/priority/" + compId, {
      code,
    }) as any as Promise<IServerBaseResponse>;
  }

  public getCompetitionAgeGroupCoverage(
    compId: number
  ): Promise<IServerResponseList<IAgeGroupCompCoverageModel>> {
    return https.get("/v5/competition/agegroups/" + compId);
  }

  //  https://dev.entry4sports.co.uk/wp-json/e4s/v5/competition/entries/354?entityId=0&entityLevel=0
  public getEntriesForEntity(
    compId: number,
    entity: IEntity
  ): Promise<IServerResponseList<unknown>> {
    return https.get("/v5/competition/entries/" + compId, {
      params: {
        entityId: entity.id,
        entityLevel: entity.entityLevel,
      },
    });
  }

  public getClubCompInfo(
    compId: number
  ): Promise<IServerResponse<undefined, { clubCompInfo: IClubCompInfo }>> {
    return https.get("/v5/competition/info/" + compId);
  }

  public getClubComps(): Promise<IServerResponseList<IClubComp>> {
    return https.get("/v5/competition/clubcomps");
  }
}
