export enum BUILDER_STORE_CONST {
    BUILDER_CONST_MODULE_NAME =  "BUILDER_CONST_MODULE_NAME",

    // <MUTATIONS>
    BUILDER_MUTATIONS_STORE_BUILDER_RESET =  "BUILDER_MUTATIONS_STORE_BUILDER_RESET",

    BUILDER_MUTATIONS_SET_BUILDER_FROM_ID =  "BUILDER_MUTATIONS_SET_BUILDER_FROM_ID",
    BUILDER_MUTATIONS_SET_BUILDER =  "BUILDER_MUTATIONS_SET_BUILDER",

    BUILDER_MUTATIONS_SET_COMP_ORGS =  "BUILDER_MUTATIONS_SET_COMP_ORGS",
    BUILDER_MUTATIONS_SET_COMP_ORGS_LOADING = "BUILDER_MUTATIONS_SET_COMP_ORGS_LOADING",

    BUILDER_MUTATIONS_SET_LOCATIONS = "BUILDER_MUTATIONS_SET_LOCATIONS",
    BUILDER_MUTATIONS_SET_LOCATIONS_LOADING = "BUILDER_MUTATIONS_SET_LOCATIONS_LOADING",

    BUILDER_MUTATIONS_UPDATE_LOCATION = "BUILDER_MUTATIONS_UPDATE_LOCATION",

    BUILDER_MUTATIONS_SET_AREAS = "BUILDER_MUTATIONS_SET_AREAS",
    BUILDER_MUTATIONS_SET_AREAS_LOADING = "BUILDER_MUTATIONS_SET_AREAS_LOADING",

    BUILDER_MUTATIONS_BUILDER_LOADING = "BUILDER_MUTATIONS_SUBMIT_BUILDER_LOADING",
    BUILDER_MUTATIONS_SUBMIT_BUILDER_LOADING_RESULT = "BUILDER_MUTATIONS_SUBMIT_BUILDER_LOADING_RESULT",

    BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP = "BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP",
    BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP_LOADING = "BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP_LOADING",
    BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP_ADD = "BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP_ADD",
    BUILDER_MUTATIONS_AGE_GROUPS_ALL = "BUILDER_MUTATIONS_AGE_GROUPS_ALL",
    BUILDER_MUTATIONS_AGE_GROUPS_ALL_LOADING = "BUILDER_MUTATIONS_AGE_GROUPS_ALL_LOADING",
    BUILDER_MUTATIONS_AGE_GROUPS_DEFAULT_LOADING = "BUILDER_MUTATIONS_AGE_GROUPS_DEFAULT_LOADING",
    BUILDER_MUTATIONS_AGE_GROUPS_DEFAULT_SET = "BUILDER_MUTATIONS_AGE_GROUPS_DEFAULT_SET",
    BUILDER_MUTATIONS_AGE_GROUPS_COVERAGE_COMP = "BUILDER_MUTATIONS_AGE_GROUPS_COVERAGE_COMP",
    BUILDER_MUTATIONS_CREATE_GROUP_LOADING = "BUILDER_MUTATIONS_CREATE_GROUP_LOADING",
    BUILDER_MUTATIONS_SET_EVENTS = "BUILDER_MUTATIONS_SET_EVENTS",
    BUILDER_MUTATIONS_SET_EVENTS_LOADING = "BUILDER_MUTATIONS_SET_EVENTS_LOADING",

    BUILDER_MUTATIONS_SET_UOMS = "BUILDER_MUTATIONS_SET_UOMS",
    BUILDER_MUTATIONS_SET_UOMS_LOADING = "BUILDER_MUTATIONS_SET_UOMS_LOADING",

    BUILDER_MUTATIONS_SET_COMP_EVENT_LOADING = "BUILDER_MUTATIONS_SET_COMP_EVENT_LOADING",

    BUILDER_MUTATIONS_PRICE_ADD = "BUILDER_MUTATIONS_PRICE_ADD",
    BUILDER_MUTATIONS_PRICES_SET = "BUILDER_MUTATIONS_PRICES_SET",
    BUILDER_MUTATIONS_PRICES_LOADING = "BUILDER_MUTATIONS_PRICES_LOADING",

    // BUILDER_MUTATIONS_COMPEVENT_EDIT_SET = "BUILDER_MUTATIONS_COMPEVENT_EDIT_SET",
    BUILDER_MUTATIONS_BUILDERCOMPEVENT_EDIT_SET = "BUILDER_MUTATIONS_COMPEVENT_EDIT_SET",
    BUILDER_MUTATIONS_COMPEVENTS_SET = "BUILDER_MUTATIONS_COMPEVENTS_SET",

    BUILDER_MUTATIONS_COMP_EVENT_ADD = "BUILDER_MUTATIONS_COMP_EVENT_ADD",

    BUILDER_MUTATIONS_DISCOUNT_SET = "BUILDER_MUTATIONS_DISCOUNT_SET",
    BUILDER_MUTATIONS_DISCOUNT_ADD = "BUILDER_MUTATIONS_DISCOUNT_ADD",

    BUILDER_MUTATIONS_COMP_RULE_SET = "BUILDER_MUTATIONS_COMP_RULE_SET",
    BUILDER_MUTATIONS_COMP_RULE_ADD = "BUILDER_MUTATIONS_COMP_RULE_ADD",
    // BUILDER_MUTATIONS_SECONDARIES_SET = "BUILDER_MUTATIONS_SECONDARIES_SET",
    // BUILDER_MUTATIONS_SECONDARIES_LOADING = "BUILDER_MUTATIONS_SECONDARIES_LOADING",

    BUILDER_MUTATIONS__UI_SHOW_LOCATION_PICKER =  "BUILDER_MUTATIONS__UI_SHOW_LOCATION_PICKER",

    BUILDER_MUTATIONS__STICKY_DATE_SET = "BUILDER_MUTATIONS__STICKY_DATE_SET",

    // <MUTATIONS>

    // <ACTIONS>
    BUILDER_ACTIONS_CREATE_ORG = "BUILDER_ACTIONS_CREATE_ORG",
    // BUILDER_ACTIONS_CREATE_LOCATION = "BUILDER_ACTIONS_CREATE_LOCATION",

    BUILDER_ACTIONS_GET_COMP_ORGS = "BUILDER_ACTIONS_GET_COMP_ORGS",
    BUILDER_ACTIONS_GET_LOCATIONS = "BUILDER_ACTIONS_GET_LOCATIONS",
    BUILDER_ACTIONS_GET_AREAS = "BUILDER_ACTIONS_GET_AREAS",
    BUILDER_ACTIONS_GET_BUILDER_BY_ID = "BUILDER_ACTIONS_GET_BUILDER_BY_ID",
    BUILDER_ACTIONS_SUBMIT_BUILDER = "BUILDER_ACTIONS_SUBMIT_BUILDER",
    // BUILDER_ACTIONS_GET_AGE_GROUPS_FOR_COMP = "BUILDER_ACTIONS_GET_AGE_GROUPS_FOR_COMP",
    BUILDER_ACTIONS_GET_AGE_GROUPS_ALL = "BUILDER_ACTIONS_GET_AGE_GROUPS_ALL",
    BUILDER_ACTIONS_GET_AGE_GROUPS_DEFAULT = "BUILDER_ACTIONS_GET_AGE_GROUPS_DEFAULT",
    BUILDER_ACTIONS_CREATE_AGE_GROUP = "BUILDER_ACTIONS_CREATE_AGE_GROUP",
    BUILDER_ACTIONS_LIST_EVENTS = "BUILDER_ACTIONS_LIST_EVENTS",
    BUILDER_ACTIONS_LIST_GENERIC = "BUILDER_ACTIONS_LIST_GENERIC",
    BUILDER_ACTIONS_UOMS_LIST = "BUILDER_ACTIONS_UOMS_LIST",
    BUILDER_ACTIONS_UOMS_CREATE = "BUILDER_ACTIONS_UOMS_CREATE",
    BUILDER_ACTIONS_EVENT_CREATE = "BUILDER_ACTIONS_EVENT_CREATE",
    BUILDER_ACTIONS_COMP_EVENT_CREATE = "BUILDER_ACTIONS_COMP_EVENT_CREATE",

    BUILDER_ACTIONS_DISCOUNT_LOAD = "BUILDER_ACTIONS_DISCOUNT_LOAD",
    BUILDER_ACTIONS_COMP_RULE_LOAD = "BUILDER_ACTIONS_COMP_RULE_LOAD",
    BUILDER_ACTIONS_COMPEVENTS_LOAD = "BUILDER_ACTIONS_COMPEVENTS_LOAD",
    BUILDER_ACTIONS_COMPEVENT_DELETE = "BUILDER_ACTIONS_COMPEVENT_DELETE",
    BUILDER_ACTIONS_COMPEVENT_DELETE_MULTIPLE = "BUILDER_ACTIONS_COMPEVENT_DELETE_MULTIPLE",

    BUILDER_ACTIONS_PRICES_GET_FOR_COMP = "BUILDER_ACTIONS_PRICES_GET_FOR_COMP",

    // BUILDER_MUTATIONS_SECONDARIES_GET = "BUILDER_MUTATIONS_SECONDARIES_GET",
    // <ACTIONS>
}
