









import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {ICompetitionSummaryPublic} from "../competition-models"
import PublicCompCardBody from "../../public/entry-public/public-list/public-list-comp-body.vue"
import {CompetitionService} from "../competiton-service"
import CloseBack from "../../common/ui/close-back.vue"
import {LAUNCH_ROUTES_PATHS} from "../../launch/launch-routes"

const competitionService: CompetitionService = new CompetitionService();

@Component({
    name: "comp-admin",
    components: {CloseBack, PublicCompCardBody}
})
export default class CompAdmin extends Vue {
    @Prop({
        default: () => {
            return competitionService.factorySummaryPublic();
        }
    })
    public readonly comp: ICompetitionSummaryPublic;

    public onClose() {
        this.$router.push({
            path: "/" + LAUNCH_ROUTES_PATHS.HOME
        });
    }
}
