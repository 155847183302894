import https from "../common/https";
import {ITeamComp, ITeamServerResponse} from "./team-models";

export class TeamData {

    public getTeams(compId: number): Promise<ITeamServerResponse> {
        return https.get( "/v5/teams/" + compId)
            .then((data: any) => {
                return data;
            }).
            catch((error: any) => {
                return error;
            });
    }

    public create(team: ITeamComp): Promise<any> {
        return https.post( "/v5/competition/team/add", team);
    }

    public delete(teamId: number): Promise<any> {
        return https.delete( "/v5/competition/team/" + teamId);
    }
}
