import { IConfig } from "./common-models";

const E4S_HOST =
  process.env.NODE_ENV === "production"
    ? window.location.origin
    : process.env.E4S_HOST;
// const E4S_HOST = "";
const API_URL = E4S_HOST + "/entry/php";
const REST_URL = E4S_HOST + "/wp-json/e4s";
const WP_BASKET = E4S_HOST + "/my-account/basket";
const ORGANISER_AND_PUBLIC_CARD = "/{COMP_ID}/card";

// const ORGANISER_REPORT = "/wp-json/e4s/v5/public/reports/cards/";
const ORGANISER_REPORT = "/{COMP_ID}/report";

export const POWER_OF_TEN_ATHLETE_LINK =
  "https://www.thepowerof10.info/athletes/profile.aspx?ukaurn=";

export const CONFIG = {
  E4S_HOST,
  API_URL,
  REST_URL,
  WP_BASKET,
  ORGANISER_AND_PUBLIC_CARD,
  ORGANISER_REPORT,
} as IConfig;

export enum E4S_WARN_CODES {
  GENERIC_WARN = -999,
}

export const STADIUM_URL_MANCHESTER =
  CONFIG.E4S_HOST + "/resources/EnglishSchoolsStadiumMap.png";

export const isLocalDevServer = process.env.NODE_ENV === "development";
