




























import { computed, defineComponent, SetupContext } from "@vue/composition-api";

export default defineComponent({
  name: "launch-sublink-simple-v2",
  components: {},
  props: {
    linkDisplayName: {
      type: String,
      required: true,
    },
    isLink: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: "",
    },
  },
  setup(
    props: { linkDisplayName: string; isLink: boolean; url: string },
    context: SetupContext
  ) {
    function linkClicked() {
      context.emit("linkClicked");
    }

    const hasUrl = computed(() => {
      return props.url.length > 0;
    });

    return { linkClicked, hasUrl };
  },
});
