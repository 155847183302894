




import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import ButtonGenericV2 from "./button-generic-v2.vue";
import { CONFIG } from "../../../config";

export type ButtonGotoGenericType = "SCHEDULE" | "ENTRIES" | "RESULTS";

export default defineComponent({
  name: "ButtonGotoGenericV2",
  components: { ButtonGenericV2 },
  props: {
    compId: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    gotoType: {
      type: String as PropType<ButtonGotoGenericType>,
      required: true,
    },
    buttonText: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  setup(
    props: {
      compId: number;
      gotoType: ButtonGotoGenericType;
      buttonText: string;
    },
    context: SetupContext
  ) {
    const getButtonMap = computed(() => {
      const buttonMap = {
        SCHEDULE: {
          text: "Schedule",
          link: CONFIG.E4S_HOST + "/" + props.compId + "/schedule",
        },
        ENTRIES: {
          text: "Entries",
          link: CONFIG.E4S_HOST + "/" + props.compId + "/entries",
        },
        RESULTS: {
          text: "Results",
          link: CONFIG.E4S_HOST + "/" + props.compId + "/results",
        },
      };

      return buttonMap[props.gotoType]
        ? buttonMap[props.gotoType]
        : buttonMap["SCHEDULE"];
    });

    function goToResults() {
      const buttonMap = getButtonMap.value;

      window.open(buttonMap.link, buttonMap.text);
    }

    return { goToResults, getButtonMap };
  },
});
