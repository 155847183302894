





















































import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters, mapState } from "vuex";
import { AUTH_STORE_CONST, IAuthStoreState } from "../auth/auth-store";
import { LAUNCH_ROUTES_PATHS } from "./launch-routes";
import { CONFIG_STORE_CONST, IConfigStoreState } from "../config/config-store";
import { Prop } from "vue-property-decorator";
import { IConfigApp } from "../config/config-app-models";
import { CONFIG } from "../common/config";

@Component({
  name: "launch-menu-admin",
  computed: {
    ...mapState(AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME, {
      isLoggedIn: (state: IAuthStoreState) => state.isLoggedIn,
    }),
    ...mapState(CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME, {
      configApp: (state: IConfigStoreState) => state.configApp,
    }),
    ...mapGetters({
      isAdmin:
        CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_GETTERS_IS_ADMIN,
      hasBuilderPermissionForAnyOrg:
        CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_GETTERS_HAS_ANY_ORG_BUILDER_PERM,
      hasChequesAccess:
        CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_GETTERS_HAS_CHEQUES_ACCESS,
      hasFinanceAccess:
        CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_GETTERS_HAS_FINANCE,
      hasAAIFinanceAccess:
        CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_GETTERS_HAS_AAI_FINANCE,
    }),
  },
})
export default class LaunchMenuAdmin extends Vue {
  public readonly isAdmin: boolean;
  public readonly hasFinanceAccess: boolean;
  public readonly hasAAIFinanceAccess: boolean;
  public readonly hasChequesAccess: boolean;
  public readonly hasBuilderPermissionForAnyOrg: boolean;
  public readonly configApp: IConfigApp;

  @Prop({ default: false }) public readonly sideNavClose: boolean;
  public readonly launchRoutesPaths = LAUNCH_ROUTES_PATHS;

  public CONFIG = CONFIG;

  public get hasChequesMenuAccess() {
    return this.isAdmin || this.hasChequesAccess;
  }
}
