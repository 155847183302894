import { useStore } from "../app.store";
import { CONFIG_STORE_CONST, IConfigStoreState } from "./config-store";
import { E4sCssThemeIds, E4sThemeId, USER_ROLES } from "./config-app-models";
import { computed, ComputedRef } from "@vue/composition-api";
import { IBaseConcrete } from "../common/common-models";
import { ConfigService } from "./config-service";

const configService = new ConfigService();

export function useConfigStore(): IConfigStoreState {
  const store = useStore();
  return store.state[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME];
}

/**
 *
 */
export function useConfigController() {
  const store = useConfigStore();

  const isAdmin = computed(() => {
    return store.configApp.role === USER_ROLES.E4SUSER;
  });

  const getVersion = computed(() => {
    return store.ui.version;
  });

  const isAppReady = computed(() => {
    return store.configApp.userInfo.user.role !== "";
  });

  const userOrgs = computed<IBaseConcrete[]>(() => {
    if (!store.configApp.userInfo.user.permissions) {
      return [];
    }
    return store.configApp.userInfo.user.permissions.map((userPerm) => {
      return userPerm.org;
    });
  });

  function hasBuilderPermissionForComp(compOrgId: number, compId: number) {
    return configService.hasBuilderPermissionForComp(
      store.configApp.userInfo,
      compOrgId,
      compId
    );
  }

  function hasBuilderPermissionForAnyOrg() {
    return configService.hasBuilderPermissionForAnyOrg(
      store.configApp.userInfo
    );
  }

  const getStore = computed(() => {
    return store;
  });

  const getUserEntities = computed(() => {
    return configService.getEntitiesFromUserInfo(store.configApp.userInfo);
  });

  const hasUserEntities = computed(() => {
    return getUserEntities.value.length > 0;
  });

  const getCssEnvIdentifier: ComputedRef<E4sThemeId> = computed(() => {
    return E4sCssThemeIds[getStore.value.configApp.theme];
  });

  const getCssEnvSuffix: ComputedRef<string> = computed(() => {
    const doesValueExistForEnv = E4sCssThemeIds[getStore.value.configApp.theme];

    return doesValueExistForEnv === "" ? "" : doesValueExistForEnv + "-";
  });

  const getRegistrationSystemAoCode = computed(() => {
    return store.configApp.defaultao.code;
  });

  return {
    isAdmin,
    isAppReady,
    userOrgs,
    hasBuilderPermissionForComp,
    hasBuilderPermissionForAnyOrg,
    getVersion,
    getStore,
    getUserEntities,
    hasUserEntities,
    getCssEnvIdentifier,
    getCssEnvSuffix,
    getRegistrationSystemAoCode,
  };
}
