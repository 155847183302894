export default {
    hello: "Hello UK",
    entry: {
        athletesSectionHeader: "Athletes UK",
        scheduleHeader: "Schedule"
    },
    menu: {
        home: "Home",
        showEntries: "Home",
        entryForm: "Enter",
        support: "Support",
        userGuide: "User Guide",
        login: "Login",
        myAccount: "My Account",
        builder: "Builder",
        athletes: "Athletes",
        admin: {
            label: "Admin",
            menu: {
                builder: "Create Comp",
                athletes: "Athletes",
                cheques: "Cheques",
                userSearch: "Users",
                reports: "Reports"
            }
        }
    },
    public: {
        reportLink: "Report",
        checkLink: "Organiser Link"
    },
    buttons: {
        save: "Save",
        cancel: "Cancel",
        close: "Close",
        add: "Add",
        remove: "Remove",
        confirm: "Confirm",
        select: "Select",
        create: "Create",
        edit: "Edit",
        bulkEdit: "Bulk",
        deleteShort: "Del",
        x: "X",
        reset: "Reset",
        go: "Go",
        search: "Search"
    }
};
