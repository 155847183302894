import {ISecondaryDef, SECONDARY_OBJECT_TYPES} from "../secondary-models"
import {IListParams, ResourceData} from "../../common/resource/resource-service"
import {IServerPagingResponseList, IServerResponse} from "../../common/common-models"
import https from "../../common/https"
import {ISecondaryPurchaseRead, ISecondaryPurchaseSubmit} from "./secondary-cust-models"

export class SecondaryCustData extends ResourceData<ISecondaryDef> {
    constructor() {
        super("/v5/secondary");
    }

    public getSecondaries(objType: SECONDARY_OBJECT_TYPES, objId: number, compId: number = 0, listParams?: IListParams): Promise<IServerPagingResponseList<ISecondaryDef>> {

        let payload = {
            objtype: objType,
            objid: objId,
            compid: compId
        }
        if (listParams) {
            payload = {...payload, ...listParams};
        }

        return https.get("/v5/secondary/cust/list",{
            params: payload
        });
    }

    public submitSecondaryPurchase(secondaryPurchase: ISecondaryPurchaseSubmit): Promise<IServerResponse<ISecondaryPurchaseSubmit>> {
        return https.post("/v5/secondary/cust", secondaryPurchase);
    }

    public getPurchases(compId: number = 0): Promise<IServerPagingResponseList<ISecondaryPurchaseRead>> {
        return https.get("/v5/secondary/cust?compid=" + compId) as any as Promise<IServerPagingResponseList<ISecondaryPurchaseRead>> ;
    }

    public getPurchasesAthlete(compId: number, athleteId: number): Promise<IServerPagingResponseList<ISecondaryPurchaseRead>> {
        return https.get("/v5/secondary/athlete?compid=" + compId + "&athleteid=" + athleteId) as any as Promise<IServerPagingResponseList<ISecondaryPurchaseRead>> ;
    }

    public removeFromCart(compId: number): Promise<IServerPagingResponseList<ISecondaryPurchaseRead>> {
        return https.get("/v5/secondary/athlete?compid=" + compId) as any as Promise<IServerPagingResponseList<ISecondaryPurchaseRead>> ;
    }
}
