

















import { defineComponent, PropType, SetupContext } from "@vue/composition-api";
import ButtonGenericBackV2 from "../buttons/button-generic-back-v2.vue";

export default defineComponent({
  name: "form-generic-section-title-v2",
  components: { ButtonGenericBackV2 },
  props: {
    sectionTitle: {
      type: String,
      default: () => {
        return "";
      },
    },
    sectionOverview: {
      type: String,
      default: () => {
        return "";
      },
    },
    titleSize: {
      type: String as PropType<"100" | "200" | "300" | "400" | "500">,
      default: () => {
        return "500";
      },
    },
    overviewSize: {
      type: String as PropType<"100" | "200" | "300" | "400" | "500">,
      default: () => {
        return "500";
      },
    },
    showCancelButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, context: SetupContext) {
    function cancel() {
      context.emit("cancel");
    }

    return {
      cancel,
    };
  },
  // setup(props: {
  //   sectionTitle: string;
  //   sectionOverview: string;
  //   titleSize: "1" | "1.25" | "1.5" | "1.75" | "2";
  //   overviewSize: "0.625" | "0.75" | "1" | "1.25";
  // }) {
  //   const getTitleCssTag = computed(() => {
  //     //  See css, 500 is standard for form, 100 is biggest.
  //     const cssMap = {
  //       "2": "100",
  //       "1.75": "200",
  //       "1.5": "300",
  //       "1.25": "400",
  //       "1": "500",
  //     };
  //     return cssMap[props.titleSize] ? cssMap[props.titleSize] : "500";
  //   });
  //
  //   const getOverviewCssTag = computed(() => {
  //     //  See css, 500 is standard for form, 100 is biggest.
  //     const cssMap = {
  //       "1.25": "100",
  //       "1": "200",
  //       "0.75": "300",
  //       "0.625": "400",
  //     };
  //     return cssMap[props.overviewSize] ? cssMap[props.overviewSize] : "500";
  //   });
  //
  //   return {
  //     getTitleCssTag,
  //     getOverviewCssTag
  //   };
  // },
});
