













import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import { ButtonGenericType } from "./button-generic-models";
import { useConfigController } from "../../../../config/useConfigStore";

export type ButtonPosition = "" | "left" | "right" | "both";

export default defineComponent({
  name: "button-generic-v2",
  components: {},
  props: {
    text: {
      type: String,
      default: () => {
        return "OK";
      },
    },
    buttonType: {
      type: String as PropType<ButtonGenericType>,
      default: () => {
        return "primary";
      },
      validator: function (value: ButtonGenericType) {
        return (
          ["primary", "secondary", "tertiary", "destructive"].indexOf(value) !==
          -1
        );
      },
    },
    withInput: {
      type: String as PropType<ButtonPosition>,
      default: "",
    },
  },
  setup(
    props: {
      text: string;
      buttonType: "primary" | "secondary" | "tertiary" | "destructive";
      withInput: ButtonPosition;
    },
    context: SetupContext
  ) {
    const configController = useConfigController();

    function buttonClicked() {
      console.log("button-generic-v2.buttonClicked()");
      context.emit("click");
    }

    const withInputCss = computed(() => {
      // const cssEnv =
      //   configController.getCssEnvIdentifier.value.length > 0
      //     ? configController.getCssEnvIdentifier.value + "-"
      //     : "";
      const cssEnv = configController.getCssEnvSuffix.value; //  E.g. AAI = "ai-", UK = ""
      const css: string[] = ["e4s-button--" + cssEnv + props.buttonType];

      //  With button to the right
      if (props.withInput === "right" || props.withInput === "both") {
        css.push("e4s-square--left");
      }

      //  With button to the left
      if (props.withInput === "left" || props.withInput === "both") {
        css.push("e4s-square--right");
      }
      return css;
    });

    return { buttonClicked, withInputCss };
  },
});
