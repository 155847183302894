import * as R from "ramda";
import { IEventE4S, IUniqueEventDisplay } from "./event-models";
import { GENDER, IValidationResult } from "../common/common-models";
import { IEventUom, IEventUomType } from "../uom/uom-models";
import { CommonService } from "../common/common-service";
import { IEoptions } from "../athletecompsched/athletecompsched-models";
import { AthleteCompSchedService } from "../athletecompsched/athletecompsched-service";

const commonService: CommonService = new CommonService();
const athleteCompSchedService: AthleteCompSchedService =
  new AthleteCompSchedService();

export class EventService {
  public factoryGetEvent(): IEventE4S {
    return {
      id: 0,
      name: "",
      gender: "",
      tf: "",
      options: {
        helpText: "",
        min: 0,
        max: 0,
        excludeFromCntRule: false,
        unique: [] as IUniqueEventDisplay[],
        isTeamEvent: false,
        eventTeam: athleteCompSchedService.factoryEventTeam(),
        rowOptions: {
          autoExpandHelpText: false,
          showPB: true,
          showPrice: true,
          showEntryCount: false,
        },
        maxInHeat: 0,
      } as IEoptions,
      uom: {} as IEventUomType,
    } as IEventE4S;
  }

  public filterEventsByStartsWith(startsWith: string, events: IEventE4S[]) {
    return events.filter((evt: IEventE4S) => {
      const eventName = evt.name;
      const firstChars = eventName.substring(0, startsWith.length);
      return eventName && firstChars === startsWith;
    });
  }

  public filterEventsByGender(gender: GENDER, events: IEventE4S[]) {
    return events.filter((evt: IEventE4S) => {
      return evt.gender === gender;
    });
  }

  public convertEventTypeEnumToArray(eventTypes: any): any[] {
    const arraytypes = [];
    for (const key in eventTypes as any) {
      if (eventTypes.hasOwnProperty(key)) {
        arraytypes.push({
          id: key,
          name: eventTypes[key as any],
        });
      }
    }
    return arraytypes;
  }

  public validate(event: IEventE4S): IValidationResult[] {
    const validationResults: IValidationResult[] = [];
    if (event.name === "") {
      validationResults.push({
        name: "name",
        message: "Name",
      });
    }
    if (event.gender === "") {
      validationResults.push({
        name: "gender",
        message: "Gender",
      });
    }
    if (event.tf === "") {
      validationResults.push({
        name: "tf",
        message: "Event Type",
      });
    }
    if (event.uom.id === 0) {
      validationResults.push({
        name: "uom",
        message: "Uom",
      });
    }
    //  TODO
    // console.log("efsdccfsdfsdfsdfsf :: " + Number(event.options.max) + " = " + isNaN(Number(event.options.max)));
    // if (isNaN(Number(event.options.max))) {
    const max: string = event.options.max.toString();
    if (max.length === 0 && !commonService.onlyContainsNumber(max)) {
      validationResults.push({
        name: "options-max",
        message: "Max should be number",
      });
    }
    return validationResults;
  }

  public getUomLabel(uomType: IEventUomType): string {
    if (uomType && uomType.options) {
      const label: string = uomType.options.reduce(
        (accum: string, eventUom: IEventUom) => {
          accum +=
            "[" +
            eventUom.pattern.toString() +
            " - " +
            eventUom.short +
            " - " +
            eventUom.text +
            "]";
          return accum;
        },
        ""
      );
      return "(" + uomType.id + ") " + uomType.type + " - " + label;
    }
    return "";
  }

  public getLabelForEvent(event: IEventE4S) {
    return event.name + " (" + event.gender + ")";
  }

  public removeUnwantedDataForSubmission(event: IEventE4S): IEventE4S {
    event = R.clone(event);
    event.options.unique = event.options.unique.map(
      (unique: IUniqueEventDisplay) => {
        return {
          e: unique.e || 0,
          ce: unique.ce || 0,
        } as IUniqueEventDisplay;
      }
    );
    event.uom = {
      id: event.uom.id,
    } as IEventUomType;
    return event;
  }
}
