import * as R from "ramda";
import {
  GENDER,
  IBase,
  IObjectKeyType,
  IsoDateTime,
  IValidationResult,
} from "../common/common-models";
import { IListParams } from "../common/resource/resource-service";
import { LocationService } from "../location/location-service";
import {
  IAutoEntries,
  IBuilderCompetition,
  IBuilderOptions,
  IBuilderOptionsCheckIn,
  IBuilderSectionUi,
  IBuilderSubscription,
  IBuilderTeamConfig,
  IBuilderValidationOptions,
  ICancelEvent,
  IContact,
  INDOOR_OUTDOOR,
  IPhotoFinishFile,
  ISelfService,
  VALIDATION_NAMES,
} from "./builder-models";
import { ICompEvent } from "../compevent/compevent-models";
import { IAgeGroup } from "../agegroup/agegroup-models";
import {
  IAgeCeidGender,
  IAgeCeidLink,
  IAgeCeidLinkGender,
  IBuilderCompEvent,
} from "./buildercompevent/builder-comp-event-models";
import { CommonService } from "../common/common-service";
import { IEventE4S } from "../event/event-models";
import { CompEventService } from "../compevent/compevent-service";
import { HEAT_ORDER } from "../competition/competition-models";
import { IEntity } from "../config/config-app-models";
import { IValidationProp } from "../validation/validation-models";
import { ValidationService } from "../validation/validation-service";
import { isAfter, isBefore, parse } from "date-fns";
import { OrgService } from "../org/org-service";
import { convertDateToIsoWithOffset } from "../common/common-service-utils";

const commonService: CommonService = new CommonService();
const compEventService: CompEventService = new CompEventService();
const validationService: ValidationService = new ValidationService();

export class BuilderService {
  public locationService: LocationService = new LocationService();

  public factoryGetBuilder(
    options: Partial<IBuilderOptions>
  ): IBuilderCompetition {
    const currentYear = new Date().getFullYear();
    return {
      id: 0,
      active: false,
      compOrg: {
        id: 0,
        name: "",
        logo: "",
        stripeUser: new OrgService().factory(),
        options: {
          tAndCUsers: [],
        },
      },
      location: this.locationService.factoryGetLocation(),
      date: "",
      indoorOutdoor: INDOOR_OUTDOOR.NO_SELECTION.value,
      name: "",
      entriesOpenDateTime: convertDateToIsoWithOffset(new Date()),
      entriesCloseDateTime: "",
      link: "", //  Link to flyer.
      tandclink: "",
      tandcdesc: "",
      termsConditions: "",
      newsFlash: "",
      emailText: "",
      yearFactor: currentYear,
      area: {
        id: 0,
        name: "",
      },
      teamConfigId: 0,
      teamConfig: {} as IBuilderTeamConfig,
      reportLink: "",
      options: {
        ...this.factoryOptions(),
        ...options,
      },
      organisers: [],
      information: "",
      e4sNotes: "",
      singleAge: false,
      audits: [],
      meta: {
        compEvents: [],
        ageGroups: [],
        prices: [],
        rules: [],
        discounts: [],
        multiEvents: [],
        access: [],
      },
      pfInfo: {
        key: "",
      },
    };
  }

  public factoryBuildCompEvent(): IBuilderCompEvent {
    return {
      ...compEventService.factoryGetCompEvent(),
      ageGroups: [],
      ceids: [],
      ageCeidLink: [],
      // ageCeidLinkGender: [],
      ageCeidGenders: [],
      events: [],
      compEventsFromSchedule: [],
      entryCount: {
        total: 0,
        waiting: 0,
      },
      createOptions: {
        addAge: false,
        addGender: false,
      },
    };
  }

  public factoryOptions(): IBuilderOptions {
    return {
      homeInfo: "",
      allowAdd: {
        unregistered: false,
        registered: true,
      },
      // live: false,
      disabled: false,
      disabledReason: "",
      school: false,
      athleteQrData: false,
      compDates: [],
      bibNos: "1",
      bibSort1: "",
      bibSort2: "",
      bibSort3: "",
      paymentCode: "",
      laneCount: 8,
      heatOrder: HEAT_ORDER.SLOWEST.value,
      cardInfo: {
        enabled: true,
        availableFrom: "",
      },
      cheques: {
        allow: false,
        ends: "",
      },
      helpText: {
        schedule: "",
        teams: "",
        cart: "",
      },
      showTeamAthletes: true,
      singleAge: false,
      contact: this.factoryContact(),
      compLimits: {
        athletes: 0,
        entries: 0,
        teams: 0,
      },
      selfService: [],
      athleteSecurity: {
        areas: [],
        clubs: [],
        onlyClubsUpTo: "",
      },
      stopReport: false,
      checkIn: this.factoryBuilderOptionsCheckIn(),
      priority: {
        required: false,
        code: "",
        dateTime: "",
        message: "",
      },
      subscription: this.factoryBuilderSubscription(),
      cancelEvent: this.factoryCancelEvent(),
      resultsAvailable: false,
      ui: this.factoryBuilderSectionUi(),
      stadium: "",
      autoEntries: this.factoryAutoEntries(),
      level: "",
      shortCode: "",
      pfTargetDirectory: "",
      allowExpiredRegistration: true,
      dates: [],
      timetable: "Provisional",
      clubComp: false,
      isClubComp: false,
      pbMandatory: false,
      cloneInfo: {
        fromId: 0,
        saved: false,
      },
      saleEndDate: "",
    };
  }

  public factoryBuilderOptionsCheckIn(): IBuilderOptionsCheckIn {
    return {
      enabled: false,
      checkInDateTimeOpens: "",
      defaultFrom: 180,
      defaultTo: 60,
      qrCode: true,
      text: "",
      useTerms: false,
      terms: "",
      seedOnEntries: false,
    };
  }

  public factoryAutoEntries(): IAutoEntries {
    return {
      selectedTargetComp: {
        id: 0,
        name: "",
        timeTronicsUrl: "",
      },
      targetable: {
        allowedSources: [],
        enabled: false,
      },
    };
  }

  public factoryCancelEvent(): ICancelEvent {
    return {
      hrsBeforeClose: 48,
      refund: {
        allow: true,
        type: "E4S_FEES",
      },
      credit: {
        allow: true,
      },
    };
  }

  public factoryBuilderSubscription(): IBuilderSubscription {
    return {
      enabled: false,
      timeCloses: "",
      organiserMessage: "",
      e4sMessage: "",
      process: true,
      refunded: "",
      processRefundTime: "",
    };
  }

  public factoryContact(): IContact {
    return {
      id: 0,
      userName: "",
      email: "",
      visible: true,
      tel: "",
      socials: [],
    };
  }

  public factorySelfService(): ISelfService {
    return {
      id: 0,
      entityName: "",
      entityLevel: 0,
      clubType: "C",
      approvalUsers: [],
    };
  }

  public factoryBuilderSectionUi(): IBuilderSectionUi {
    return {
      enterButtonText: "Enter",
      entryDefaultPanel: "SCHEDULE",
      ticketComp: 0,
      ticketCompBase: {
        id: 0,
        name: "",
      },
      ticketCompButtonText: "Buy Tickets",
      sectionsToHide: {
        ATHLETES: false,
        TEAMS: false,
        SCHEDULE: false,
        SHOP: false,
      },
    };
  }

  public factoryBuilderValidationOptions(): IBuilderValidationOptions {
    return {
      theme: "def",
      stripe: {
        useStripeConnect: false,
      },
    };
  }

  public validate(
    builderCompetition: IBuilderCompetition,
    isoNow: string,
    validationOptions: IBuilderValidationOptions
  ): IObjectKeyType<IValidationProp> {
    let validationState: IObjectKeyType<IValidationProp> = {};

    if (this.stripChars(builderCompetition.compOrg.name).length === 0) {
      validationState = validationService.addMessage(
        "builderCompetition.compOrg.name",
        "Enter an organisation name.",
        validationState
      );
    }

    if (
      builderCompetition.options.checkIn.useTerms &&
      builderCompetition.options.checkIn.terms.length === 0
    ) {
      validationState = validationService.addMessage(
        "builderCompetition.options.checkIn.terms",
        "Enter 'terms and conditions'.",
        validationState
      );
    }

    if (builderCompetition.options.priority.required) {
      if (builderCompetition.options.priority.code.length === 0) {
        validationState = validationService.addMessage(
          "builderCompetition.options.priority.code",
          "A 'Priority' code is Required.",
          validationState
        );
      }

      if (builderCompetition.options.priority.code.length > 0) {
        if (builderCompetition.options.priority.dateTime.length === 0) {
          validationState = validationService.addMessage(
            "builderCompetition.options.priority.dateTime",
            "A 'Priority' finish time Required.",
            validationState
          );
        } else {
          if (
            isAfter(
              parse(builderCompetition.options.priority.dateTime),
              parse(builderCompetition.entriesCloseDateTime)
            )
          ) {
            validationState = validationService.addMessage(
              "builderCompetition.options.priority.dateTime",
              "'Priority' date entered after entries closing date.",
              validationState
            );
          }
          if (
            isBefore(
              parse(builderCompetition.options.priority.dateTime),
              parse(builderCompetition.entriesOpenDateTime)
            )
          ) {
            validationState = validationService.addMessage(
              "builderCompetition.options.priority.dateTime",
              "'Priority' date entered before entries open date.",
              validationState
            );
          }
        }
      }
    }

    if (validationOptions.stripe.useStripeConnect) {
      if (
        !builderCompetition.compOrg.stripeUser ||
        builderCompetition.compOrg.stripeUser.id === 0
      ) {
        validationState = validationService.addMessage(
          "builderCompetition.compOrg.stripeUser.id",
          "Stripe user account required.",
          validationState
        );
      }
    }

    return validationState;
  }

  public validateBuilderStep1(
    builderCompetition: IBuilderCompetition,
    isoNow: string,
    validationOptions: IBuilderValidationOptions,
    compEvents: ICompEvent[]
  ): IValidationResult[] {
    const validationResults = [] as IValidationResult[];

    if (this.stripChars(builderCompetition.name).length === 0) {
      validationResults.push({
        name: VALIDATION_NAMES.COMP_NAME_EMPTY,
        message: "Enter a competition name.",
      });
    }

    if (this.stripChars(builderCompetition.compOrg.name).length === 0) {
      validationResults.push({
        name: VALIDATION_NAMES.COMP_ORG_EMPTY,
        message: "Enter an organisation.",
      });
    }

    if (this.stripChars(builderCompetition.location.name).length === 0) {
      validationResults.push({
        name: VALIDATION_NAMES.LOCATION_EMPTY,
        message: "Enter a Location.",
      });
    }

    //  N.B. not checking for a bunch of "", VeeValidate already doing.
    let compDate = builderCompetition.date;
    const entriesOpenDateTime = builderCompetition.entriesOpenDateTime;
    const entriesCloseDateTime = builderCompetition.entriesCloseDateTime;
    if (
      compDate.length > 0 &&
      entriesOpenDateTime.length > 0 &&
      entriesCloseDateTime.length > 0
    ) {
      const isoNowDate = isoNow.split("T")[0];

      //  This needs to be the "last date", so actually
      //  builderCompetition.options.compDates...compDates does not exist ?!?!?
      let isoCompDate = builderCompetition.date.split("T")[0];

      //  Don't check against compDates if this is a clone and it's never been saved...as the
      //  compDates won't have been adjusted.
      const checkAgainstCompDates =
        builderCompetition.options.cloneInfo.fromId === 0 ||
        (builderCompetition.options.cloneInfo.fromId > 0 &&
          builderCompetition.options.cloneInfo.saved);

      if (checkAgainstCompDates) {
        const compDates = this.getEventTimes(compEvents);
        if (compDates.length > 0) {
          const lastCompDate = compDates[compDates.length - 1];
          isoCompDate = lastCompDate.split("T")[0];
          compDate = lastCompDate;
        }
      }

      // if (isoNow > builderCompetition.date) {
      if (isoNowDate > isoCompDate) {
        validationResults.push({
          name: VALIDATION_NAMES.COMP_DATE_BEFORE_NOW,
          message: "Competition date cannot be before current time.",
        });
      }
      if (
        entriesOpenDateTime > compDate ||
        entriesOpenDateTime > entriesCloseDateTime
      ) {
        validationResults.push({
          name: VALIDATION_NAMES.OPEN_DATE_NOT_IN_RANGE,
          message: "Open date must be in range.",
        });
      }

      const dateOfClose = entriesCloseDateTime.split("T")[0];
      if (
        dateOfClose > compDate ||
        entriesCloseDateTime < entriesOpenDateTime
      ) {
        validationResults.push({
          name: VALIDATION_NAMES.CLOSE_DATE_NOT_IN_RANGE,
          message: "Close date must be in range.",
        });
      }
    } else {
      validationResults.push({
        name: VALIDATION_NAMES.COMP_DATE_BEFORE_NOW,
        message: "Competition, open and closed dates required.",
      });
    }

    if (builderCompetition.options.contact.userName.length === 0) {
      validationResults.push({
        name: VALIDATION_NAMES.CONTACT_NAME_EMPTY,
        message: "Contact name required.",
      });
    }
    if (builderCompetition.options.contact.email.length === 0) {
      validationResults.push({
        name: VALIDATION_NAMES.CONTACT_EMAIL_EMPTY,
        message: "Contact email required.",
      });
    }

    // only do this when making the competition active...else
    // you can't set the comp up!!!
    if (builderCompetition.active) {
      if (validationOptions.stripe.useStripeConnect) {
        if (
          !builderCompetition.compOrg.stripeUser ||
          builderCompetition.compOrg.stripeUser.id === 0
        ) {
          validationResults.push({
            name: VALIDATION_NAMES.STRIPE_USER,
            message: "Stripe user required.",
          });
        }
      }
    }

    return validationResults;
  }

  public validateBuilderStep2(
    builderCompetition: IBuilderCompetition
  ): IValidationResult[] {
    return [];
  }

  public validateBuilder(
    builderCompetition: IBuilderCompetition,
    isoNow: string,
    validationOptions: IBuilderValidationOptions,
    compEvents: ICompEvent[]
  ): IValidationResult[] {
    let validationResults = [] as IValidationResult[];

    validationResults = validationResults.concat(
      this.validateBuilderStep1(
        builderCompetition,
        isoNow,
        validationOptions,
        compEvents
      )
    );
    validationResults = validationResults.concat(
      this.validateBuilderStep2(builderCompetition)
    );
    return validationResults;
  }

  public stripChars(str: string) {
    // return str.replace(/\s/g, "");
    return str.replace(/[\n\r\s\t]+/g, "");
  }

  public getListParamsDefault(searchKey: string): IListParams {
    return {
      startswith: searchKey,
      pagenumber: 1,
      pagesize: 5,
      sortkey: "name",
    } as IListParams;
  }

  public mergeAgeGroupsIntoCeidLink(
    ageGroups: IAgeGroup[],
    ageCeidLinks: IAgeCeidLink[],
    SourceAgeCeidLinks: IAgeCeidLink[]
  ): IAgeCeidLink[] {
    ageCeidLinks = R.clone(ageCeidLinks);
    ageCeidLinks = ageCeidLinks.map((ageCeidLink) => {
      ageCeidLink.crud = "D";
      return ageCeidLink;
    });

    // const ageGroupsObj: IObjectKeyType<IAgeGroup> = commonService.convertArrayToObject("id", ageGroups);
    const ageCeidLinksObj: IObjectKeyType<IAgeCeidLink> =
      commonService.convertArrayToObject("agid", ageCeidLinks);

    // console.log("ageGroupsObj", ageGroupsObj);
    // console.log("ageCeidLinksObj", ageCeidLinksObj);

    const result = ageGroups.reduce(
      (
        accum: IObjectKeyType<IAgeCeidLink>,
        ageGroup: IAgeGroup,
        index: number
      ) => {
        const ageCeidLink = accum[ageGroup.id + ""];
        if (ageCeidLink) {
          ageCeidLink.crud = ageCeidLink.agid === 0 ? "C" : "U";
        } else {
          accum[ageGroup.id + ""] = {
            crud: "C",
            ceid: 0,
            agid: ageGroup.id,
          };
        }
        return accum;
      },
      ageCeidLinksObj
    );

    // console.log("result", result);

    return commonService.convertObjectToArray(result);
  }

  /**
   *
   * @param ageGroups
   * @param genderEvents
   * @param compEvents            These have to be the same compEvents.event.id as genderEvents
   */
  public mergeGenderAgeGroupsIntoCeidLink(
    ageGroups: IAgeGroup[],
    genderEvents: IEventE4S[],
    compEvents: ICompEvent[]
  ): IAgeCeidLinkGender[] {
    //  Events have tobe all the same "type",right now,that means   .name
    const resultCompEvents: IObjectKeyType<IAgeCeidGender> =
      this.createAgeCeidLinkGenderCompEvents(compEvents);
    const resultSelected: IObjectKeyType<IAgeCeidGender> =
      this.createAgeCeidLinkGender(ageGroups, genderEvents);

    Object.keys(resultCompEvents).forEach((key) => {
      if (!resultSelected[key]) {
        resultCompEvents[key].crud = "D";
      } else {
        resultCompEvents[key].crud = "U";
      }
      resultSelected[key] = R.clone(resultCompEvents[key]);
    });

    const result: IObjectKeyType<IAgeCeidLinkGender> = Object.keys(
      resultSelected
    ).reduce((accum, key) => {
      const ageCeidGender: IAgeCeidGender = resultSelected[key];
      let ageCeidLinkGender: IAgeCeidLinkGender;
      const ageIdKey = ageCeidGender.ageId + "";
      // @ts-ignore
      if (!accum[ageIdKey]) {
        accum[ageIdKey] = {
          agid: ageCeidGender.ageId,
        } as IAgeCeidLinkGender;
      }
      ageCeidLinkGender = accum[ageIdKey];
      // @ts-ignore
      ageCeidLinkGender[ageCeidGender.gender] = R.clone(ageCeidGender);
      return accum;
    }, {} as IObjectKeyType<IAgeCeidLinkGender>);
    return commonService.convertObjectToArray(result);
  }

  public createAgeCeidLinkGender(
    ageGrps: IAgeGroup[],
    evts: IEventE4S[]
  ): IObjectKeyType<IAgeCeidGender> {
    return ageGrps.reduce(
      (
        accum: IObjectKeyType<IAgeCeidGender>,
        ageGrp: IAgeGroup
      ): IObjectKeyType<IAgeCeidGender> => {
        evts.forEach((genderEvt) => {
          const ageGenderAgeKey = genderEvt.gender + "-" + ageGrp.id;
          accum[ageGenderAgeKey] = {
            ceid: 0,
            crud: "C",
            eventId: genderEvt.id,
            ageId: ageGrp.id,
            gender: genderEvt.gender,
          };
        });
        return accum;
      },
      {} as IObjectKeyType<IAgeCeidGender>
    );
  }

  public createAgeCeidLinkGenderCompEvents(
    compEvents: ICompEvent[]
  ): IObjectKeyType<IAgeCeidGender> {
    return compEvents.reduce(
      (
        accum: IObjectKeyType<IAgeCeidGender>,
        compEvent: ICompEvent
      ): IObjectKeyType<IAgeCeidGender> => {
        const ageGenderKey =
          compEvent.event.gender + "-" + compEvent.ageGroup.id;
        accum[ageGenderKey] = {
          ceid: compEvent.id,
          crud: "U",
          eventId: compEvent.event.id,
          ageId: compEvent.ageGroup.id,
          gender: compEvent.event.gender,
        };
        return accum;
      },
      {} as IObjectKeyType<IAgeCeidGender>
    );
  }

  public flattenGenderAgeGroups(
    ageCeidLinkGenders: IAgeCeidLinkGender[]
  ): IAgeCeidGender[] {
    return ageCeidLinkGenders.reduce((accum, ageCeidLinkGender) => {
      [GENDER.FEMALE, GENDER.MALE, GENDER.OPEN].forEach((gender) => {
        // @ts-ignore
        if (ageCeidLinkGender[gender]) {
          // @ts-ignore
          accum.push(ageCeidLinkGender[gender]);
        }
      });
      return accum;
    }, [] as IAgeCeidGender[]);
  }

  public getAgeGroupsFromCompEvents(compEvents: ICompEvent[]): IAgeGroup[] {
    const ageGroupsInUse: IAgeGroup[] = compEvents.map(
      (compEvent) => compEvent.ageGroup
    );
    return commonService.uniqueArrayById(ageGroupsInUse, "id");
  }

  public getEntityLevelNameXX(entityLevel: number): string {
    return entityLevel === 1 ? "Club" : entityLevel === 2 ? "County" : "";
  }

  public getSelfServices(builderOptions: IBuilderOptions): ISelfService[] {
    if (builderOptions && builderOptions.selfService) {
      return builderOptions.selfService;
    }
    return [];
  }

  public hasSelfServiceCounty(builderOptions: IBuilderOptions): boolean {
    return (
      this.getSelfServices(builderOptions).filter((selfServ) => {
        return selfServ.entityLevel === 2;
      }).length === 1
    );
  }

  public hasSelfServiceClub(
    builderOptions: IBuilderOptions,
    isSchool: boolean
  ): boolean {
    return (
      this.getSelfServices(builderOptions).filter((selfServ) => {
        return (
          selfServ.entityLevel === 1 &&
          selfServ.clubType === (isSchool ? "S" : "C")
        );
      }).length === 1
    );
  }

  public hasSelfServiceEntity(
    builderOptions: IBuilderOptions,
    entity: IEntity
  ): boolean {
    return (
      this.getSelfServices(builderOptions).filter((selfServ) => {
        return selfServ.entityLevel === entity.entityLevel;
      }).length === 1
    );
  }

  public hasSelfService(builderCompetition: IBuilderCompetition): boolean {
    return this.getSelfServices(builderCompetition.options).length > 0;
  }

  public getUniqueAgeGroups(compEvents: ICompEvent[]): IAgeGroup[] {
    const initState: IAgeGroup[] = [];
    const ageGroups = compEvents.reduce((accum, compEvent) => {
      const ageGroup: IAgeGroup = compEvent.ageGroup;
      accum.push(ageGroup);
      if (compEvent.options.ageGroups) {
        const agGrps: IAgeGroup[] = compEvent.options.ageGroups as IAgeGroup[];
        accum.concat(agGrps);
      }
      // accum.concat(...compEvent.options.ageGroups);
      return accum;
    }, initState);

    return commonService.uniqueArrayById(ageGroups);
  }

  public getEventGroupUniqueNames(compEvents: ICompEvent[]): IBase[] {
    const sort = (a: string, b: string) => {
      a = a ? a : "";
      b = b ? b : "";
      // console.log("getEventGroupUniqueNames.sort", {a, b});
      return a.localeCompare(b);
    };
    const makeIBases = (eventGroups: string[]): IBase[] => {
      return eventGroups.map((eventGroup: string, index: number) => {
        return {
          id: index + 1,
          name: eventGroup,
        };
      });
    };
    return R.compose(
      makeIBases,
      R.sort(sort),
      R.uniq,
      this.getEventNames
    )(compEvents);
  }

  public getEventNames(cmpEvents: ICompEvent[]): string[] {
    return cmpEvents.map((compEvent: ICompEvent) => {
      return compEvent.eventGroup;
    });
  }

  public getEventTimes(compEvents: ICompEvent[]): IsoDateTime[] {
    const dateMap = compEvents.reduce<Record<IsoDateTime, unknown>>(
      (accum, compEvent) => {
        if (!accum[compEvent.startDateTime]) {
          accum[compEvent.startDateTime] = "";
        }
        return accum;
      },
      {}
    );
    return Object.keys(dateMap).sort();
  }

  public getTimingResultsEndPoint(
    key: string,
    compId: number,
    host: string
  ): string {
    return host + "/wp-json/e4s/v5/otd/secure/track/" + compId + "/" + key;
  }

  public createPhotoFinishFile(
    targetPath: string,
    key: string,
    compId: number,
    host: string
  ): IPhotoFinishFile {
    return {
      App: {
        FolderToWatch: targetPath.replace(/\\/g, "/"),
        PostUrl: this.getTimingResultsEndPoint(key, compId, host),
      },
      Logging: {
        LogLevel: {
          Default: "Information",
          Microsoft: "Warning",
          "Microsoft.Hosting.Lifetime": "Information",
        },
      },
    };
  }
}
