














import {computed, defineComponent, PropType, SetupContext} from "@vue/composition-api"
import {ILinkV2} from "./launch-link-models"

export default defineComponent({
  name: "launch-sublink-v2",
  components: {},
  props: {
    linkV2: {
      type: Object as PropType<ILinkV2>,
      required: true,
    },
    // I guess the following props could apply at some point.
    // showBadge: {
    //   type: Boolean,
    //   default: false
    // },
    // badgeCount: {
    //   type: String,
    //   default: 0
    // },
    // children: {
    //   type: Array as PropType<unknown[]>,
    //   default: () => {
    //     return [];
    //   }
    // }
  },
  setup(props: {linkV2: ILinkV2}, context: SetupContext) {

    function linkClicked() {
      context.emit("linkClicked", props.linkV2)
    }

    const getPath = computed(()=>{
      return props.linkV2.path.length === 0 ? "#" : props.linkV2.path
    })

    return {linkClicked, getPath};
  },
});
