import { IAthlete } from "../../../athlete/athlete-models";
import {
  ATHLETE_PERF_TEXT_PREFIX,
  EditPbV3InputProp,
  EditPbV3Options,
  IPbCompSchedMini,
  IPbEditBaseV3State,
  IPerfInfo,
  PerfInfoInput,
} from "./edit-pb-v3-models";
import { factoryAthletePb } from "../../../athlete/v2/athlete-service-v2";
import { IAthleteEvent } from "../../../athlete/athlete-event/athlete-event-models";

export type PBErrorMessageTimeType =
  | "RANGE_HRS"
  | "RANGE_MINS"
  | "RANGE_SECS"
  | "RANGE_HUNDS";

export type PBErrorMessageType =
  | PBErrorMessageTimeType
  | "ZERO"
  | "NEGATIVE"
  | "MIN_MAX"
  | "INVALID"
  | "SPLIT_UNDER"
  | "SPLIT_OVER";

export function factoryIPbBaseV3State(): IPbEditBaseV3State {
  return {
    editTimePbV3Props: {
      athleteCompSchedMini: {
        split: 0,
        entryId: 0,
      },
      performance: factoryPerformance(),
    },
    athletePb: factoryAthletePb(),
    outputText: "",
    errors: {},
    isValid: true,
  };
}

export function factoryPerformance(): IPerfInfo {
  return factoryPerfInfo();
}

export function factoryPerfInfo(): IPerfInfo {
  return {
    athleteId: 0,
    eventId: 0,
    eventType: "D",
    info: "",
    perf: 0,
    perfText: "",
    pb: 0,
    pbText: "",
    pbAchieved: "",
    sb: 0,
    sbText: "",
    sbAchieved: "",
    ageGroup: "",
    curAgeGroup: "",
    eventName: "",
    limits: {
      min: 0,
      max: 0,
    },
    uom: [],
  };
}

//   athletePb: IAthletePb,
export function genericValidationEstimatedPerformance(
  pb: number,
  athleteCompSchedMini: IPbCompSchedMini,
  perfInfoInput: PerfInfoInput
): Partial<Record<PBErrorMessageType, string>> {
  const errors: Partial<Record<PBErrorMessageType, string>> = {};

  if (pb < 0) {
    errors.ZERO = "PB must be greater than 0.";
  }

  if (perfInfoInput.limits.min > 0 && perfInfoInput.limits.max > 0) {
    if (pb < perfInfoInput.limits.min || pb > perfInfoInput.limits.max) {
      errors.MIN_MAX = `PB must be between ${perfInfoInput.limits.min} and ${perfInfoInput.limits.max}.`;
    }
  }

  const shouldBeLessThanSplit = athleteCompSchedMini.split < 0;
  const split = Math.abs(athleteCompSchedMini.split);

  if (split !== 0) {
    if (shouldBeLessThanSplit) {
      if (pb > split) {
        errors.SPLIT_OVER =
          "EP " + pb + " cannot be greater than: " + split + ".";
      }
    } else {
      if (pb < split) {
        errors.SPLIT_UNDER =
          "EP " + pb + " cannot be less than: " + split + ".";
      }
    }
  }

  return errors;
}

export function genericValidationEstimatedPerformanceNew(
  pb: number,
  athleteCompSchedMini: IPbCompSchedMini,
  perfInfoInput: PerfInfoInput
): any {
  const errors: Partial<Record<PBErrorMessageType, string>> = {};

  if (pb < 0) {
    errors.ZERO = "PB must be greater than 0.";
  }

  if (perfInfoInput.limits.min > 0 && perfInfoInput.limits.max > 0) {
    if (pb < perfInfoInput.limits.min || pb > perfInfoInput.limits.max) {
      errors.MIN_MAX = `PB must be between ${perfInfoInput.limits.min} and ${perfInfoInput.limits.max}.`;
    }
  }

  const shouldBeLessThanSplit = athleteCompSchedMini.split < 0;
  const split = Math.abs(athleteCompSchedMini.split);

  if (athleteCompSchedMini.split !== 0) {
    if (shouldBeLessThanSplit) {
      if (pb > split) {
        errors.SPLIT_OVER =
          "PB " + pb + " cannot be greater than split: " + split + ".";
      }
    } else {
      if (pb < athleteCompSchedMini.split) {
        errors.SPLIT_UNDER =
          "PB " +
          pb +
          " cannot be less than split: " +
          athleteCompSchedMini.split +
          ".";
      }
    }
  }

  return {
    split: athleteCompSchedMini.split,
    splitAbs: split,
    shouldBeLessThanSplit,
    errors,
  };
}

export function isTypeTime(perfInfoInput: PerfInfoInput): boolean {
  if (perfInfoInput.uom.length > 0) {
    const eventUom = perfInfoInput.uom[0];
    if (eventUom.uomType) {
      return eventUom.uomType === "T";
    }
    //  Really clunky way, check if pattern is a string, e.g. "m:ss.SS", then a track event.
    //  Field events have a pattern of e.g. 0.99, a number.
    return typeof eventUom.pattern === "string";
  }
  return true;
}

export function getDefaultValue(perfInfoInput: PerfInfoInput): number {
  if (perfInfoInput.perf > 0) {
    return perfInfoInput.perf;
  }
  if (perfInfoInput.sb > 0) {
    return perfInfoInput.sb;
  }
  if (perfInfoInput.pb > 0) {
    return perfInfoInput.pb;
  }
  return 0;
}

export function factoryEditPbV3InputProp(): EditPbV3InputProp {
  return {
    athleteMini: {
      id: 0,
      firstName: "",
      surName: "",
      URN: "",
      aocode: "",
    },
    athleteCompSchedMini: {
      split: 0,
      entryId: 0,
    },
    performance: factoryPerformance(),
  };
}

export function factoryEditPbV3Options(): EditPbV3Options {
  return {
    doSubmit: false,
  };
}

export function createEditPbV3InputProp(
  athlete: IAthlete,
  entryId: number
): EditPbV3InputProp {
  const athleteEvents: IAthleteEvent[] = athlete.events!.filter(
    (athleteEvent) => {
      return athleteEvent.entryid === entryId;
    }
  );

  const athleteEventEditingPb: IAthleteEvent = athleteEvents[0];

  const performance: IPerfInfo = athleteEventEditingPb.perfInfo;

  return {
    athleteMini: {
      id: athlete.id,
      firstName: athlete.firstName,
      surName: athlete.surName,
      URN: athlete.URN,
      aocode: athlete.aocode,
    },
    athleteCompSchedMini: {
      split: 0,
      entryId: athleteEventEditingPb.entryid,
    },
    performance,
  };
}

export function getPerfForDisplay(perfInfo: IPerfInfo): string {
  if (perfInfo.perf === 0) {
    return "";
  }
  // return perfInfo.perfText.replace(ATHLETE_PERF_TEXT_PREFIX, "");
  return getPerfTextForDisplay("perfText", perfInfo);
}

export function getPerfTextForDisplay(
  propName: keyof Pick<IPerfInfo, "sbText" | "pbText" | "perfText">,
  perfInfo: IPerfInfo
): string {
  const perfText = perfInfo[propName];
  //  Server sometime sending up "number" instead of string.
  if (typeof perfText === "number") {
    return (perfText as number).toString();
  }
  return perfText.replace(ATHLETE_PERF_TEXT_PREFIX, "");
}
