












import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import * as R from "ramda";
import { ICompetitionSummaryPublic } from "../competition-models";
import CounterV2 from "../../common/ui/layoutV2/counter/counter-v2.vue";

export default defineComponent({
  name: "CompetitionEntryCounts",
  components: { CounterV2 },
  props: {
    competitionSummaryPublic: {
      type: Object as PropType<ICompetitionSummaryPublic>,
      required: true,
    },
  },
  setup(
    props: { competitionSummaryPublic: ICompetitionSummaryPublic },
    context: SetupContext
  ) {
    /*
    watch(
      () => props.athlete,
      (newValue: IAthlete) => {
        athleteForm.init(newValue);
      },
      {
        immediate: true,
      }
    );
     */

    const getAthleteCount = computed(() => {
      return R.isNil(props.competitionSummaryPublic.entries.athletes)
        ? 0
        : props.competitionSummaryPublic.entries.athletes;
    });

    const getEntryCount = computed(() => {
      return R.isNil(props.competitionSummaryPublic.entries.indiv)
        ? 0
        : props.competitionSummaryPublic.entries.indiv;
    });

    const getTeamCount = computed(() => {
      return R.isNil(props.competitionSummaryPublic.entries.team)
        ? 0
        : props.competitionSummaryPublic.entries.team;
    });

    return { getAthleteCount, getEntryCount, getTeamCount };
  },
});
