import {IPermLevel, IRole} from "./permission-models";
import * as R from "ramda";
import {Module} from "vuex";
import {IRootState} from "../../app.store";
import {PermissionsService} from "./permissions-service";
import {IObjectKeyType} from "../../common/common-models";

const permissionsService: PermissionsService = new PermissionsService();

export interface IPermissionStoreState {
    rolesLoading: boolean;
    roles: IObjectKeyType<IRole>;
    permLevelsLoading: boolean;
    permLevels: IPermLevel[];
}

export enum PERMISSION_STORE_CONST {

    PERMISSION_CONST_MODULE_NAME =  "PERMISSION_CONST_MODULE_NAME",

    // <MUTATIONS>
    PERMISSION_MUTATIONS_STORE_ROLES_LOADING =  "PERMISSION_MUTATIONS_STORE_ROLES_LOADING",
    PERMISSION_MUTATIONS_STORE_SET_ROLES =  "PERMISSION_MUTATIONS_STORE_SET_ROLES",
    PERMISSION_MUTATIONS_STORE_PERM_LEVELS_LOADING =  "PERMISSION_MUTATIONS_STORE_PERM_LEVELS_LOADING",
    PERMISSION_MUTATIONS_STORE_SET_PERM_LEVELS =  "PERMISSION_MUTATIONS_STORE_SET_PERM_LEVELS",
    // </MUTATIONS>

    // <ACTIONS>
    PERMISSION_ACTIONS_STORE_GET_ROLES =  "PERMISSION_ACTIONS_STORE_GET_ROLES",
    PERMISSION_ACTIONS_STORE_GET_PERM_LEVELS =  "PERMISSION_ACTIONS_STORE_GET_PERM_LEVELS"
    // </ACTIONS>
}

const mutations = {
    [PERMISSION_STORE_CONST.PERMISSION_MUTATIONS_STORE_ROLES_LOADING](state: IPermissionStoreState, isLoading: boolean) {
        state.rolesLoading = isLoading;
    },
    [PERMISSION_STORE_CONST.PERMISSION_MUTATIONS_STORE_SET_ROLES](state: IPermissionStoreState, rolesObj: IObjectKeyType<IRole>) {
        if (rolesObj) {
            state.roles = R.clone(rolesObj);
        }
    },
    [PERMISSION_STORE_CONST.PERMISSION_MUTATIONS_STORE_PERM_LEVELS_LOADING](state: IPermissionStoreState, isLoading: boolean) {
        state.permLevelsLoading = isLoading;
    },
    [PERMISSION_STORE_CONST.PERMISSION_MUTATIONS_STORE_SET_PERM_LEVELS](state: IPermissionStoreState, permLevels: IPermLevel[]) {
        state.permLevels = R.clone(permLevels);
    }
};

const getters = {
};

const actions = {
    // [PERMISSION_STORE_CONST.PERMISSION_ACTIONS_STORE_GET_ROLES](
    //     context: ActionContext<IPermissionStoreState, IRootState>
    // ) {
    //
    //     context.commit(PERMISSION_STORE_CONST.PERMISSION_MUTATIONS_STORE_ROLES_LOADING, true);
    //     permissionData.getRoles()
    //         .then( (response: IServerResponse<IRole[]>) => {
    //             if (response.errNo > 0) {
    //                 messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
    //                 return;
    //             }
    //
    //             context.commit(PERMISSION_STORE_CONST.PERMISSION_MUTATIONS_STORE_SET_ROLES, response.data);
    //             return;
    //         })
    //         .catch((error) => {
    //             messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
    //             return;
    //         })
    //         .finally(() => {
    //             context.commit(PERMISSION_STORE_CONST.PERMISSION_MUTATIONS_STORE_ROLES_LOADING, false);
    //         });
    // },
    //
    //
    // [PERMISSION_STORE_CONST.PERMISSION_ACTIONS_STORE_GET_PERM_LEVELS](
    //     context: ActionContext<IPermissionStoreState, IRootState>
    // ) {
    //
    //     context.commit(PERMISSION_STORE_CONST.PERMISSION_MUTATIONS_STORE_PERM_LEVELS_LOADING, true);
    //     permissionData.getPermLevels()
    //         .then( (response: IServerResponse<IPermLevel[]>) => {
    //             if (response.errNo > 0) {
    //                 messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
    //                 return;
    //             }
    //
    //             context.commit(PERMISSION_STORE_CONST.PERMISSION_MUTATIONS_STORE_SET_PERM_LEVELS, response.data);
    //             return;
    //         })
    //         .catch((error) => {
    //             messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
    //             return;
    //         })
    //         .finally(() => {
    //             context.commit(PERMISSION_STORE_CONST.PERMISSION_MUTATIONS_STORE_PERM_LEVELS_LOADING, false);
    //         });
    // }
};


export const permissionStore: Module<IPermissionStoreState, IRootState> = {
    namespaced: true,
    state: permissionsService.factoryStoreState(),
    mutations,
    getters,
    actions
};
