import {ILocation} from "./location-models";

export class LocationService {
    public factoryGetLocation(): ILocation {
        return {
            id: 0,
            name: "",
            postcode: "",
            website: "",
            address1: "",
            address2: "",
            town: "",
            county: "",
            map: "",
            contact: "",
            directions: "",
        } as ILocation;
    }
}
