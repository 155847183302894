export function nickPurple(el: HTMLElement) {
  el.style.color = "purple";
}

export const clickOutside = {
  bind(element: HTMLElement, binding: any, vnode: any) {
    //  @ts-ignore
    element.clickOutsideEvent = function (event: any) {
      //  check that click was outside the el and his children
      if (!(element === event.target || element.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
        // binding.value(); run the arg
      }
    };
    //  @ts-ignore
    document.body.addEventListener("click", element.clickOutsideEvent);
  },
  unbind(element: HTMLElement) {
    //  @ts-ignore
    document.body.removeEventListener("click", element.clickOutsideEvent);
  },
};

/*
let handleOutsideClick: any;
export const clickOutside = {
  bind(el: any, binding: any, vnode: any) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e: any) => {
      e.stopPropagation();
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value;
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false;
      exclude.forEach((refName: string) => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          //  @ts-ignore
          const excludedEl = vnode.context.$refs[refName];
          // See if this excluded element
          // is the same element the user just clicked on
          //  @ts-ignore
          clickedOnExcludedEl = excludedEl.contains(e.target);
        }
      });
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        //  @ts-ignore
        vnode.context[handler]();
      }
    };
    // Register click/touchstart event listeners on the whole page
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  },
  unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
  },
}
*/
