import { IPbMultiItem } from "../athletecompsched/athletecompsched-models";
import {
  IServerGenericResponse,
  IServerPagingResponseList,
  IServerResponse,
} from "../common/common-models";
import https from "../common/https";
import {
  IAthlete,
  IAthleteSearch,
  IAthleteSummary,
  ICompEventGroupAthletes,
} from "./athlete-models";
import { ResourceData } from "../common/resource/resource-service";
import { IAgeGroup } from "../agegroup/agegroup-models";
import { IEaAthleteModel } from "./ea/ea-models";
import { IPerfInfo } from "../athleteCompSched/pb/v3/edit-pb-v3-models";

export interface IPbSubmitState {
  aid: number;
  eid: number;
  pb: number;
  pbText: string;
  trackSb: boolean;
}

export interface IAthletePagingResponseList
  extends IServerPagingResponseList<IAthleteSummary> {
  ageGroupInfo: Record<string, IAgeGroup>;
}

export class AthleteData extends ResourceData<IAthleteSummary> {
  constructor() {
    super("/v5/athlete");
  }

  /**
   *
   * @param competitionId
   * @param teamId
   * @param showAllAthletes       0 = only eligible athletes.
   * @param pageNumber
   * @param pageSize
   * @param sortKey
   * @param athleteSearch
   */
  public findAthletes(
    competitionId: number,
    teamId: number,
    pageNumber: number,
    pageSize: number,
    sortKey: string = "surname",
    athleteSearch: IAthleteSearch
  ): Promise<IAthletePagingResponseList> {
    const paramsPayload = {
      ...athleteSearch,
      compid: competitionId,
      teamid: teamId,
      pagenumber: pageNumber,
      pagesize: pageSize,
      sort: sortKey,
    };

    return https
      .get("/v5/athletes", {
        params: paramsPayload,
      })
      .then((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return error.response.data;
      });
  }

  /**
   *
   * @param competitionId
   * @param teamId
   * @param showAllAthletes       0 = only eligible athletes.
   * @param pageNumber
   * @param pageSize
   * @param sortKey
   * @param athleteSearch
   */
  public findAthletesV2(
    competitionId: number,
    teamId: number,
    pageNumber: number,
    pageSize: number,
    sortKey: string = "surname",
    athleteSearch: IAthleteSearch
  ): Promise<IAthletePagingResponseList> {
    const paramsPayload = {
      ...athleteSearch,
      compid: competitionId,
      teamid: teamId,
      pagenumber: pageNumber,
      pagesize: pageSize,
      sort: sortKey,
    };

    return https.get("/v5/athletes", {
      params: paramsPayload,
    });
  }

  public submitAthletePbV2(
    entryId: number,
    performance: IPerfInfo
  ): Promise<IServerGenericResponse> {
    const payload = {
      entryId,
      performance,
    };
    return https
      .post("/v5/athlete/pbupdatev2", payload)
      .then((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return error.response.data;
      });
  }

  public submitAthletePb(
    aid: number,
    eid: number,
    pb: number,
    pbText: string,
    trackSb: boolean,
    ceid: number
  ): Promise<IServerGenericResponse> {
    //  Endpoint requires decimal placesparseFloat(pb.toFixed(2));
    const pbSubmit: number = Number(pb.toFixed(2));
    // pbSubmit = typeof pbSubmit === "string" ? Number(pbSubmit) : pbSubmit;

    const payload = {
      aid,
      eid,
      pb: pbSubmit,
      pbText,
      trackSb,
    };
    return https
      .post("/v5/athlete/pbupdate", payload)
      .then((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return error.response.data;
      });
  }

  public getAthleteMultiPb(athleteId: number, eventId: number): Promise<any> {
    return https.get("/v5/events/multi/" + athleteId + "/" + eventId);
  }

  public submitAthleteMultiPb = (
    athleteId: number,
    athleteMultiPb: IPbMultiItem[]
  ): Promise<any> => {
    return https.post("/v5/athlete/multi/" + athleteId, athleteMultiPb);
  };

  public createAthlete = (
    athlete: IAthlete
  ): Promise<IServerGenericResponse> => {
    return https
      .post("/v5/athlete", athlete)
      .then((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return error.response.data;
      });
  };

  public getAthlete(id: number): Promise<IAthlete> {
    return https
      .get("/v5/athlete/" + id)
      .then((data: any) => {
        return data.data;
      })
      .catch((error: any) => {
        return error.response.data;
      });
  }

  public getAthleteByUrn(
    aoCode: string,
    urn: string,
    dob: string
  ): Promise<IServerGenericResponse> {
    const payload = {
      aocode: aoCode,
      regid: urn,
      dob,
    };
    return https.get("/v5/athlete/getid", {
      params: payload,
    }) as any as Promise<IServerGenericResponse>;
  }

  public confirmAthleteByUrn(
    aoCode: string,
    urn: string,
    dob: string,
    clubId: number,
    classification: number
  ): Promise<IServerGenericResponse> {
    const payload = {
      aocode: aoCode,
      regid: urn,
      dob,
      clubid: clubId,
      classification,
    };
    return https.get("/v5/athlete/confirm", {
      params: payload,
    }) as any as Promise<IServerGenericResponse>;
  }

  public updateAthlete(athlete: IAthlete): Promise<IServerGenericResponse> {
    return https
      .put("/v5/athlete/" + athlete.id, athlete)
      .then((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return error.response.data;
      });
  }

  public checkAthleteEventEligible(
    athleteId: number,
    ceid: number
  ): Promise<IServerGenericResponse> {
    return https.get(
      "/v5/athlete/check/" + athleteId + "/" + ceid
    ) as any as Promise<IServerGenericResponse>;
  }

  public deleteAthleteForUser(
    athleteId: number,
    userId: number
  ): Promise<IServerGenericResponse> {
    return https.delete(
      "/v5/athlete/" + athleteId + "/" + userId
    ) as any as Promise<IServerGenericResponse>;
  }

  public getPof10Pb(urn: string): Promise<IServerResponse<IAthleteSummary>> {
    return https.get(
      "/v5/athlete/pof10pbs/" + urn
    ) as any as Promise<IServerGenericResponse>;
  }

  public getAthletesInEventGroup(
    eventGroupId: number
  ): Promise<IServerResponse<ICompEventGroupAthletes>> {
    return https.get("/v5/athletes/" + eventGroupId);
  }

  public assignAthleteToUser(
    athleteId: number,
    userId: number
  ): Promise<IServerResponse<any>> {
    return https.post("/v5/athlete/" + athleteId + "/" + userId);
  }

  public linkAthlete(athleteId: number): Promise<IServerResponse<any>> {
    return https.post("/v5/linkathlete/" + athleteId);
  }

  public getEaAthlete(
    athleteId: string
  ): Promise<IServerResponse<IEaAthleteModel>> {
    return https.post(
      "https://entry4sports.co.uk/wp-json/e4s/v5/test?action=ealive&urn=" +
        athleteId
    );
  }
}
