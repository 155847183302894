import {IObjectKeyType} from "../common/common-models";
import {IValidationProp, IValidationState} from "./validation-models";

import {ValidationService} from "./validation-service";

export class ValidationController {
    public valid: boolean = true;   //  See below
    public errs: IObjectKeyType<IValidationProp> = {};
    public validationService = new ValidationService();

    /**
     * TODO not reactive. get composition api loaded.
     */
    public get isValid(): boolean {
        return this.valid;
    }

    public get errors() {
        return this.errs;
    }

    public addError(propPath: string, messages: string[]) {
        this.valid = false;
        this.errs[propPath] = {
            propPath,
            messages
        };
    }

    public reset() {
        this.valid = true;
        this.errs = {};
    }

    public setErrors(validationProps: IObjectKeyType<IValidationProp>): void {
        this.valid = Object.keys(validationProps).length === 0;
        this.errs = validationProps;
    }

    public getValidationState(): IValidationState {
        return{
            isValid: this.isValid,
            errors: this.errs
        };
    }

    public hasError(propPath: string): boolean {
        return this.errs[propPath] ? true : false;
    }

    public getErrors(propPath: string): string {
        return this.errs[propPath] ? this.errs[propPath].messages.join(" ") : "";
    }

    public getValidationSummary(): string {
        return Object.keys(this.errs).map((key) => {
            return key + ": " + this.errs[key].messages.join(" ");
        }).join(" ");
    }

    public getValidationSummaryOutput(): string {
        return Object.keys(this.errs).map((key) => {
            const validationProp = this.errs[key];
            const title = validationProp.title && validationProp.title.length > 0 ? validationProp.title : "";
            return ( title.length > 0 ? title + ": ":  "") + validationProp.messages.join(" ");
        }).join(" ");
    }

    public getValidationSummaryWithTitle(): string[] {
        return this.validationService.getValidationSummary(this.errs);
    }
}
