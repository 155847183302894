







































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import LoadingSpinner from "./loading-spinner.vue";

@Component({
  name: "e4s-modal",
  components: {
    "loading-spinner": LoadingSpinner,
  },
})
export default class E4sModal extends Vue {
  @Prop({ default: "" }) public headerMessage: string;
  @Prop({ default: "" }) public bodyMessage: string;
  @Prop({ default: "Cancel" }) public buttonSecondaryText: string;
  @Prop({ default: "OK" }) public buttonPrimaryText: string;
  @Prop({ default: "" }) public cssClass: string;
  @Prop({ default: false }) public isLoading: boolean;
  @Prop({ default: false }) public isFullSizeForm: boolean;
  @Prop({ default: false }) public noPadding: boolean;

  public get getCssClass() {
    if (this.noPadding) {
      return "e4s-modal-container--no-padding";
    }
    if (this.isFullSizeForm) {
      return "e4s-modal-container--full-size";
    }
    return this.cssClass.length === 0
      ? "e4s-modal-container-size"
      : this.cssClass;
  }

  public closeSecondary() {
    this.$emit("closeSecondary");
  }

  public closePrimary() {
    this.$emit("closePrimary");
  }
}
