import {IScoreboardEvent, IScoreboardCompEventGroup, IScoreboardEventGroup, IScoreboardRank} from "./scoreboard-models"
import {IScheduleTableRow} from "./rs4/rs4-scoreboard-models";

export class ScoreboardService {
    public factoryScoreboardCompEventGroup(): IScoreboardCompEventGroup {
        return {
            id: 0,
            name: "",
            compId: 0,
            eventNo: 0,
            startDateIso: ""
        };
    }

    public factoryScoreboardEventGroup(): IScoreboardEventGroup {
        return {
            id: 0,
            name: "",
            startDateIso: ""
        };
    }

    public factoryScoreboardEvent(): IScoreboardEvent {
        return {
            comp: {
                id: 0,
                name: "",
                logo: ""
            },
            eventGroup: this.factoryScoreboardEventGroup(),
            athlete: {
                id: 0,
                name: "",
                bibNo: 0,
                pb: 0
            },
            score: {
                score: 0,
                scoreUom: "",
                modified: ""
            },
            config: {
                polling: 10000,
                showScore: 5000,
                showVideo: 5000,
                showPicture: 5000,
                ranksPerPage: 10,
                ranksPageTime: 10000
            },
            text: "",
            picture: "",
            ranking: []
        };
    }

    public factoryScoreboardRank(): IScoreboardRank {
        return {
            bibno: 0,
            athleteId: 0,
            athleteName: "",
            position: "",
            score: 0
        };
    }

    public factoryScheduleTableRow(): IScheduleTableRow {
        return {
            scheduledEvent: null,
            athleteEntries: [],
            entries: 0,
            eventGroupId: 0,
            eventKey: "",
            eventName: "",
            hasResults: false,
            heats: 0,
            startTime: "",
            waiting: false,
            status: "unchecked",
            counts: {
                entries: 0,
                maxAthletes: 0,
                maxInHeat: 0
            },
            resultsPossible: true
        };
    }
}
