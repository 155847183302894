










import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "info-minor-svg",
  props: {
    height: {
      type: String,
      default: () => {
        return "20px";
      }
    }
  }
});
