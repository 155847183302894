

























import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import LaunchHeaderV2 from "./launch-header-v2.vue";
import LaunchFooterV2 from "./launch-footer-v2.vue";
import { useRoute } from "../../router/migrateRouterVue3";
import {LaunchRouteValueV2, widerRoutesClass} from "./launch-routes-v2"

export default defineComponent({
  name: "launch-v2",
  components: { LaunchFooterV2, LaunchHeaderV2 },
  props: {
    entries: {
      type: Array as PropType<unknown[]>,
      default: () => {
        return [];
      },
    },
  },
  setup(props: { entries: unknown[] }, context: SetupContext) {
    const routeX = useRoute();
    // const configStore = useConfigStore();
    // const configVersionSwitch = useConfigVersionSwitch(
    //   "v2",
    //   configStore.configApp.userInfo.user.version
    // );

    // if (routeX.name === LAUNCH_ROUTES_PATHS_V2.HOME_V2) {
    //   configVersionSwitch.setUserVersion("v2");
    // }

    const getContentWidth = computed(() => {
      const routeName: LaunchRouteValueV2 = routeX.name ? routeX.name : "" as any as LaunchRouteValueV2;
      if (widerRoutesClass[routeName]) {
        return widerRoutesClass[routeName];
      }
      return "e4s-width-controller";
    });


    return {
      getContentWidth,
    };
  },
});
