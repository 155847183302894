






import Vue from "vue";
import Component from "vue-class-component";
import {mapState} from "vuex";
import {AUTH_STORE_CONST, IAuthStoreState} from "./auth-store";

@Component({
  name: "logout",
  computed: {
     ...mapState(AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME, {
        isLoggedIn: (state: IAuthStoreState) => state.isLoggedIn,
        tokenReponse: (state: IAuthStoreState) => state.tokenReponse
    })
  }
})
export default class Logout extends Vue {

  public doLogout() {
    sessionStorage.setItem("e4s_token", "");
    this.$store.dispatch(
        AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME + "/" + AUTH_STORE_CONST.AUTH_ACTIONS_LOGOUT);
  }
}
