











import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import { PillV2StatusType } from "./pills-models-v2";

export default defineComponent({
  name: "pill-v2",
  components: {},
  props: {
    text: {
      type: String,
      default: () => {
        return "OK";
      },
    },
    pillType: {
      type: String as PropType<PillV2StatusType>,
      default: () => {
        return "open";
      },
    },
  },
  setup(
    props: { text: string; pillType: PillV2StatusType },
    context: SetupContext
  ) {
    function buttonClicked() {
      console.log("button-generic-v2.buttonClicked()");
      context.emit("click");
    }

    const getPillClass = computed<string>(() => {
      //  Leaves text black
      let cssClass: string = "e4s-status-pill--" + props.pillType;

      //  ...changes text if...
      const changeTextColorForStatus: PillV2StatusType[] = ["open", "closed"];

      if (changeTextColorForStatus.indexOf(props.pillType) > -1) {
        cssClass = cssClass + "-competition";
      }
      return cssClass;
    });

    return { buttonClicked, getPillClass };
  },
});
