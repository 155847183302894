

































import Vue from "vue";
import Component from "vue-class-component";
import Toaster from "../../user-message/toaster.vue";
import CommonHeader from "../../common/ui/menus/common-header.vue"
import {mapState} from "vuex"
import {CONFIG_STORE_CONST, IConfigStoreState} from "../../config/config-store"
import {IConfigApp} from "../../config/config-app-models"
import {IScoreboardStoreState, SCOREBOARD_STORE_CONST} from "./scorboard-store"
import {IScoreboardEvent} from "./scoreboard-models"
import { parse, format } from "date-fns";
import {CONFIG} from "../../common/config";

@Component({
    name: "scoreboard-section",
    components: {
        CommonHeader,
        Toaster
    },
    computed: {
        ...mapState(CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME, {
            configApp: (state: IConfigStoreState) => state.configApp
        }),
        ...mapState(SCOREBOARD_STORE_CONST.SCOREBOARD_CONST_MODULE_NAME, {
            scoreboardEventStoreState: (state: IScoreboardStoreState) => state.scoreboardEvent
        })
    }
})
export default class ScoreboardSection extends Vue {
    public readonly configApp: IConfigApp;
    public scoreboardEventStoreState: IScoreboardEvent;

    // public get getFooterText() {
    //     return "Powered by Entry4Sports uk.entry4sports.com"; //  window.location.host;
    // }

    public mounted() {
        //  WP is setting page heights and stuff
        document.body.style.overflow = "hidden";
    }

    public get getStartTime() {
        const startDateIso = this.scoreboardEventStoreState && this.scoreboardEventStoreState.eventGroup ? this.scoreboardEventStoreState.eventGroup.startDateIso : "";

        return startDateIso.length > 0 ? format(parse(startDateIso), "Do MMM YYYY") :  "";
        //
        // if (this.scoreboardEventStoreState && this.scoreboardEventStoreState.eventGroup) {
        //     return format(parse(this.scoreboardEventStoreState.eventGroup.startDateIso), "Do MMM YYYY");
        // }
        // return "";
    }

    public get getResultsUrl() {
        return CONFIG.E4S_HOST + "/" + this.scoreboardEventStoreState.comp.id + "/results";
    }
}

