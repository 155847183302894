






















import { defineComponent, ref } from "@vue/composition-api";
import { CompetitionService } from "../../competition/competiton-service";
import { handleResponseMessages } from "../../common/handle-http-reponse";
import { CompetitionData } from "../../competition/competition-data";
import { useRoute, useRouter } from "../../router/migrateRouterVue3";
import EntryConditionsV2 from "./entry-conditions-v2.vue";
import { LAUNCH_ROUTES_PATHS } from "../../launch/launch-routes";
import LoadingSpinnerV2 from "../../common/ui/loading-spinner-v2.vue";

export default defineComponent({
  name: "entry-conditions-route",
  components: { LoadingSpinnerV2, EntryConditionsV2 },
  setup() {
    const isLoading = ref(false);
    const route = useRoute();
    const router = useRouter();

    const competitionService: CompetitionService = new CompetitionService();
    const competitionSummaryPublic = ref(
      competitionService.factorySummaryPublic()
    );

    const compId = isNaN(Number(route.params.id))
      ? 0
      : parseInt(route.params.id, 0);
    if (compId > 0) {
      isLoading.value = true;
      const prom = new CompetitionData().getCompById(compId);
      handleResponseMessages(prom);
      prom
        .then((resp) => {
          if (resp.errNo === 0) {
            competitionSummaryPublic.value = resp.data;
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    function proceedToComp() {
      router.push({
        path: "/" + LAUNCH_ROUTES_PATHS.ENTRY,
        query: {
          comporgid: competitionSummaryPublic.value.compOrgId.toString(),
          compid: competitionSummaryPublic.value.compId.toString(),
        },
      });
    }

    function cancel() {
      router.push({
        path:
          "/" +
          LAUNCH_ROUTES_PATHS.SHOW_ENTRY +
          "/" +
          competitionSummaryPublic.value.compId.toString(),
      });
    }

    return {
      competitionSummaryPublic,
      proceedToComp,
      cancel,
    };
  },
});
