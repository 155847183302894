import * as R from "ramda";
import {
  IAgeGroup,
  IAgeGroupBase,
  IAgeGroupCompCoverageModel,
  IAgeGroupOption,
} from "./agegroup-models";
import { IObjectKeyType } from "../common/common-models";
import { CommonService } from "../common/common-service";
import { IValidationProp } from "../validation/validation-models";
import { ValidationService } from "../validation/validation-service";
import { IBuilderCompetition } from "../builder/builder-models";
import { format, parse } from "date-fns";
import { E4sTheme } from "../config/config-app-models";
import { AO_CODE } from "../common/ui/athletic-org/athletic-org-models";

const validationService: ValidationService = new ValidationService();

const commonService: CommonService = new CommonService();

export interface IAgeGroupForAoCodeTypeCount {
  default: number;
  nonDefault: number;
}

export class AgeGroupService {
  public factoryGetAgeGroup(): IAgeGroup {
    return {
      id: 0,
      name: "",
      minAge: 0,
      minAtDay: 0,
      minAtMonth: 0,
      minAtYear: 0,

      maxAge: 0,
      maxAtDay: 0,
      maxAtMonth: 0,
      maxAtYear: 0,

      keyName: "",
      shortName: "",
      options: [],
    };
  }

  public addAgeGroup(ageGroup: IAgeGroup, ageGroups: IAgeGroup[]): IAgeGroup[] {
    const ageGroupsForCompObj: IObjectKeyType<IAgeGroup> =
      commonService.convertArrayToObject("id", ageGroups);
    if (!ageGroupsForCompObj[ageGroup.id]) {
      ageGroups = R.clone(ageGroups);
      ageGroups.push(ageGroup);
      ageGroups = this.sortAgeGroups(ageGroups);
    }
    return ageGroups;
  }

  public sortAgeGroups(ageGroups: IAgeGroup[]): IAgeGroup[] {
    return R.compose(
      R.sort((a: IAgeGroup, b: IAgeGroup) => {
        return a.minAge - b.minAge;
      }),
      R.clone
    )(ageGroups);
  }

  public getDropDownLabel(ageGroup: IAgeGroup): string {
    return ageGroup.name + " (" + ageGroup.minAge + "-" + ageGroup.maxAge + ")";
  }

  public getCheckBoxLabel(
    ageGroup: IAgeGroup | IAgeGroupCompCoverageModel,
    builderCompetition: IBuilderCompetition,
    isAdmin: boolean
  ): string {
    if ((ageGroup as IAgeGroupCompCoverageModel).fromDate) {
      const ageGroupCompCoverageModel: IAgeGroupCompCoverageModel =
        ageGroup as IAgeGroupCompCoverageModel;
      return (
        ageGroupCompCoverageModel.name +
        " [" +
        ageGroupCompCoverageModel.minAge +
        "-" +
        ageGroupCompCoverageModel.maxAge +
        ", " +
        format(parse(ageGroupCompCoverageModel.fromDate), "Do MMM YYYY") +
        " to " +
        format(parse(ageGroupCompCoverageModel.toDate), "Do MMM YYYY") +
        "]" +
        (isAdmin ? " (" + ageGroupCompCoverageModel.id + ")" : "")
      );
    }

    return this.getDropDownLabel(ageGroup) + " " + ageGroup.id;
  }

  public validate(ageGroup: IAgeGroup): IObjectKeyType<IValidationProp> {
    let validationState: IObjectKeyType<IValidationProp> = {};

    if (ageGroup.name === "") {
      validationState = validationService.addMessage(
        "name",
        "Required.",
        validationState
      );
    }

    if (!this.isAgeOk(ageGroup.minAge.toString())) {
      validationState = validationService.addMessage(
        "minAge",
        "Number between 1 and 100.",
        validationState
      );
    }

    if (!this.isDayInputOk(ageGroup.minAtDay.toString())) {
      validationState = validationService.addMessage(
        "minAtDay",
        "Number between 1 and 31.",
        validationState
      );
    }

    if (!this.isMonthInputOk(ageGroup.minAtMonth.toString())) {
      validationState = validationService.addMessage(
        "minAtMonth",
        "Number between 1 and 12.",
        validationState
      );
    }

    if (!this.isAgeOk(ageGroup.maxAge.toString())) {
      validationState = validationService.addMessage(
        "maxAge",
        "Number between 1 and 100.",
        validationState
      );
    }

    if (!this.isDayInputOk(ageGroup.maxAtDay.toString())) {
      validationState = validationService.addMessage(
        "maxAtDay",
        "Number between 1 and 31.",
        validationState
      );
    }

    if (!this.isMonthInputOk(ageGroup.maxAtMonth.toString())) {
      validationState = validationService.addMessage(
        "maxAtMonth",
        "Number between 1 and 12.",
        validationState
      );
    }

    if (this.isYearRange(ageGroup)) {
      if (
        this.isAgeOk(ageGroup.minAge.toString()) &&
        this.isAgeOk(ageGroup.maxAge.toString())
      ) {
        if (ageGroup.minAge > ageGroup.maxAge) {
          validationState = validationService.addMessage(
            "minAge",
            "Must be less than max age.",
            validationState
          );
        }
      }
    }

    return validationState;
  }

  public preProcessForSubmit(ageGroup: IAgeGroup): IAgeGroup {
    const ageGroupInternal = R.clone(ageGroup);
    if (!this.isYearRange(ageGroupInternal)) {
      ageGroupInternal.minAge = ageGroupInternal.maxAge;
      ageGroupInternal.minAtDay = ageGroupInternal.maxAtDay;
      ageGroupInternal.minAtMonth = ageGroupInternal.maxAtMonth;
      ageGroupInternal.minAtYear = ageGroupInternal.maxAtYear;
    }
    return ageGroupInternal;
  }

  public isDayInputOk(value: string): boolean {
    const reg = new RegExp(/^(?:[0-9]|[12][0-9]|3[01])$/);
    return reg.test(value);
  }

  public isMonthInputOk(value: string): boolean {
    const reg = new RegExp(/^([0-9]|1[012])$/);
    return reg.test(value);
  }

  public isAgeOk(value: string): boolean {
    const reg = new RegExp(/^[1-9][0-9]?$|^100$/);
    return reg.test(value);
  }

  /**
   * @deprecated  use option.base @See isDefaultAgeGroup()
   * @param ageGroup
   * @param aoCode
   */
  public isAgeGroupDefaultForAoCode(ageGroup: IAgeGroup, aoCode: string) {
    if (!ageGroup.options) {
      return false;
    }
    return ageGroup.options.reduce((accum, option: IAgeGroupOption) => {
      if (option.aocode === aoCode && option.default) {
        accum = true;
      }
      return accum;
    }, false);
  }

  public calculateKeyName(ageGroup: IAgeGroup): string {
    const sep = "~";
    return (
      ageGroup.name +
      sep +
      ageGroup.minAge +
      sep +
      ageGroup.minAtDay +
      sep +
      ageGroup.minAtMonth +
      sep +
      ageGroup.minAtYear +
      sep +
      ageGroup.maxAge +
      sep +
      ageGroup.maxAtDay +
      sep +
      ageGroup.maxAtMonth +
      sep +
      ageGroup.maxAtYear
    );
  }

  public isYearRange(ageGroup: IAgeGroup): boolean {
    if (ageGroup.maxAge === 0 || ageGroup.minAge === 0) {
      return false;
    }
    return ageGroup.maxAge !== ageGroup.minAge;
  }

  /**
   * badly named...
   * @param ageGroupsSource
   * @param ageGroups
   */
  public getAgeGroupsFromDefaults(
    ageGroupsSource: IAgeGroupBase[],
    ageGroups: IAgeGroupBase[]
  ): IAgeGroupBase[] {
    const ageGroupIdsObj: IObjectKeyType<IAgeGroupBase> =
      commonService.convertArrayToObject("id", ageGroupsSource);
    return ageGroups.reduce((accum, ageGroup) => {
      if (ageGroupIdsObj[ageGroup.id]) {
        accum.push(ageGroupIdsObj[ageGroup.id]);
      }
      return accum;
    }, [] as IAgeGroupBase[]);
  }

  public getAoCodeFromTheme(theme: E4sTheme): AO_CODE {
    return theme === "gbr" ? "EA" : "IRL";
  }

  public filterDefaultAgeGroups(
    ageGroups: IAgeGroup[],
    theme: E4sTheme
  ): IAgeGroup[] {
    const aoCode: AO_CODE = this.getAoCodeFromTheme(theme);

    return ageGroups.filter((ageGroup) => {
      return this.isDefaultAgeGroup(ageGroup, aoCode);
    });
  }

  public isDefaultAgeGroup(ageGroup: IAgeGroup, aoCode: AO_CODE): boolean {
    const options = ageGroup.options;

    return options.reduce<boolean>((accum, option) => {
      if (option.base && option.base > 0 && aoCode === option.aocode) {
        accum = true;
      }
      return accum;
    }, false);
  }

  public getAgeGroupCounts(
    ageGroups: IAgeGroup[],
    theme: E4sTheme
  ): IAgeGroupForAoCodeTypeCount {
    const aoCode: AO_CODE = this.getAoCodeFromTheme(theme);
    return ageGroups.reduce<IAgeGroupForAoCodeTypeCount>(
      (accum, ageGroup) => {
        const isDefault = this.isDefaultAgeGroup(ageGroup, aoCode);
        isDefault ? accum.default++ : accum.nonDefault++;
        return accum;
      },
      {
        default: 0,
        nonDefault: 0,
      }
    );
  }
}
