














import Vue from "vue";
import Component from "vue-class-component";

@Component({
    name: "launch-footer"
})
export default class LaunchFooter extends Vue {
}
