
import {GENDER} from "../common/common-models";
import {IAreaTeam, IAreaTeamSummary, ITeamComp} from "./team-models";


export class TeamService {

    public factoryGetTeam(): ITeamComp {
        return {
            compId: 0,
            teamName: "",
            areaId: 0,
            gender: GENDER.UNKNOWN
        } as ITeamComp;

    }


    public flattenTeams(areaTeams: IAreaTeam[]): IAreaTeamSummary[] {

        const res: IAreaTeamSummary[] = areaTeams.reduce((accum: IAreaTeamSummary[], areaTeam: IAreaTeam) => {
            const teamSummaries: IAreaTeamSummary[] = areaTeam.teams.map( (team) => {
                const teamSummary: IAreaTeamSummary = {
                    ...team,
                    areaName: areaTeam.areaName
                };
                return teamSummary;
            });
            return accum.concat(teamSummaries);
        }, []);
        return res;
    }

}
