import * as R from "ramda";
import { ActionContext, Module } from "vuex";
import { IRootState, useStore } from "../app.store";

import { AthleteData } from "../athlete/athlete-data";
import { IAthlete, IAthleteSearch } from "../athlete/athlete-models";
import { AthleteService } from "../athlete/athlete-service";
import {
  IAthleteCompSchedRuleEvent,
  IPbMultiItem,
} from "../athletecompsched/athletecompsched-models";
import { IClub } from "../club/club-models";
import { IObjectKeyType } from "../common/common-models";
import {
  ICompetitionDropDown,
  ICompetitionInfo,
  ICompetitionSummaryPublic,
  IServerCompetitionLookupResponse,
} from "../competition/competition-models";
import { CompetitionService } from "../competition/competiton-service";
import { CompetitionData } from "../competition/competition-data";
import { format, parse } from "date-fns";
import { messageDispatchHelper } from "../user-message/user-message-store";
import { USER_MESSAGE_LEVEL } from "../user-message/user-message-models";
import { ConfigService } from "../config/config-service";
import { CONFIG_STORE_CONST } from "../config/config-store";
import { IUserInfo } from "../config/config-app-models";
import { handleResponseMessages } from "../common/handle-http-reponse";
import { IE4sRouteControllerError } from "../router/useE4sRouteController";
import { ATH_COMP_SCHED_STORE_CONST } from "../athleteCompSched/store/athleteCompSched-store";
import { IClubCompInfo } from "./v2/schools/clubCompInfo-models";
import { factoryClubCompInfo } from "../competition/v2/competiton-service-v2";
import {hasClubCompInfoCompetition} from "./v2/schools/clubCompInfoService"
import {simpleClone} from "../common/common-service-utils"
// import {clubCompInfoMock} from "./v2/schools/clubCompInfo-mock"

const competitionService = new CompetitionService();
const athleteService: AthleteService = new AthleteService();
const configService: ConfigService = new ConfigService();

export enum ENTRY_STORE_CONST {
  ENTRY_STORE_CONST_MODULE_NAME = "ENTRY_STORE_CONST_MODULE_NAME",

  // <MUTATIONS>
  ENTRY_STORE_MUTATIONS_ENTRYFORM_SET_CLUB = "ENTRY_STORE_MUTATIONS_ENTRYFORM_SET_CLUB",
  ENTRY_STORE_MUTATIONS_ENTRYFORM_SET_COMPETITION = "ENTRY_STORE_MUTATIONS_ENTRYFORM_SET_COMPETITION",
  ENTRY_STORE_MUTATIONS_SET_SELECTED_ATHLETE = "ENTRY_STORE_MUTATIONS_SET_SELECTED_ATHLETE",
  ENTRY_STORE_MUTATIONS_UPDATE_SELECTED_ATHLETE_PB = "ENTRY_STORE_MUTATIONS_UPDATE_SELECTED_ATHLETE_PB",
  ENTRY_STORE_MUTATIONS_TOGGLE_SECONDARY_CLAIM = "ENTRY_STORE_MUTATIONS_TOGGLE_SECONDARY_CLAIM",
  ENTRY_STORE_MUTATIONS_RESET_SECONDARY_CLAIM = "ENTRY_STORE_MUTATIONS_RESET_SECONDARY_CLAIM",
  ENTRY_STORE_MUTATIONS_SET_SELECTED_ATHLETE_LOADING = "ENTRY_STORE_MUTATIONS_SET_SELECTED_ATHLETE_LOADING",
  ENTRY_STORE_MUTATIONS_SHOW_ADD_ATHLETE = "ENTRY_STORE_MUTATIONS_SHOW_ADD_ATHLETE",
  ENTRY_STORE_MUTATIONS_SET_ATHLETE_FILTER = "ENTRY_STORE_MUTATIONS_SET_ATHLETE_FILTER",
  ENTRY_STORE_MUTATIONS_SET_ATHLETE_MULTI_PB = "ENTRY_STORE_MUTATIONS_SET_ATHLETE_MULTI_PB",
  ENTRY_STORE_MUTATIONS_SET_ATHLETE_MULTI_PB_LOADING = "ENTRY_STORE_MUTATIONS_SET_ATHLETE_MULTI_PB_LOADING",
  ENTRY_STORE_MUTATIONS_ATHLETE_MULTI_PB_MODAL_SHOW = "ENTRY_STORE_MUTATIONS_ATHLETE_MULTI_PB_MODAL_SHOW",
  ENTRY_STORE_MUTATIONS_ATHLETE_TRIGGER_REFRESH = "ENTRY_STORE_MUTATIONS_ATHLETE_TRIGGER_REFRESH",
  ENTRY_STORE_MUTATIONS_AUTO_SELECT = "ENTRY_STORE_MUTATIONS_AUTO_SELECT",
  ENTRY_STORE_MUTATIONS_AUTO_SELECT_COMP_ORG = "ENTRY_STORE_MUTATIONS_AUTO_SELECT_COMP_ORG",
  ENTRY_STORE_MUTATIONS_AUTO_SELECT_COMP = "ENTRY_STORE_MUTATIONS_AUTO_SELECT_COMP",
  ENTRY_STORE_MUTATIONS_SET_COMPETITIONS = "ENTRY_STORE_MUTATIONS_SET_COMPETITIONS",
  ENTRY_STORE_MUTATIONS_SET_COMPETITIONS_LOADING = "ENTRY_STORE_MUTATIONS_SET_COMPETITIONS_LOADING",
  ENTRY_STORE_MUTATIONS_SET_COMP_PUBLIC = "ENTRY_STORE_MUTATIONS_SET_COMP_PUBLIC",
  ENTRY_STORE_MUTATIONS_SET_SEARCHED_ATHLETES = "ENTRY_STORE_MUTATIONS_SET_SEARCHED_ATHLETES",
  ENTRY_STORE_MUTATIONS_SET_ROUTE_ERROR = "ENTRY_STORE_MUTATIONS_SET_ROUTE_ERROR",
  ENTRY_STORE_MUTATIONS_SET_CLUB_COMP_INFO = "ENTRY_STORE_MUTATIONS_SET_CLUB_COMP_INFO",

  // </MUTATIONS>

  // <GETTERS>
  ENTRY_STORE_GETTERS_IS_ATHLETE_SEARCH_FILTER_ON = "ENTRY_STORE_ACTIONS_IS_ATHLETE_SEARCH_FILTER_ON",
  ENTRY_STORE_GETTERS_SELECTED_ATHLETE = "ENTRY_STORE_GETTERS_SELECTED_ATHLETE",
  ENTRY_STORE_GETTERS_IS_ATHLETE_SELECTED = "ENTRY_STORE_GETTERS_IS_ATHLETE_SELECTED",
  ENTRY_STORE_GETTERS_SELECTED_COMP_IS_TEAM = "ENTRY_STORE_GETTERS_SELECTED_COMP_IS_TEAM",
  ENTRY_STORE_GETTERS_SHOW_ADD_ATHLETE = "ENTRY_STORE_GETTERS_SHOW_ADD_ATHLETE",
  ENTRY_STORE_GETTERS_GET_ATHLETE_CLUB_NAME = "ENTRY_STORE_GETTERS_GET_ATHLETE_CLUB_NAME",
  ENTRY_STORE_GETTERS_HAS_BUILDER_PERM_FOR_COMP = "ENTRY_STORE_GETTERS_HAS_BUILDER_PERM_FOR_COMP",
  // </GETTERS>

  // <ACTIONS>
  ENTRY_STORE_ACTIONS_GET_SELECTED_ATHLETE = "ENTRY_STORE_ACTIONS_GET_SELECTED_ATHLETE",
  ENTRY_STORE_ACTIONS_GET_ATHLETE_MULTI_PB = "ENTRY_STORE_ACTIONS_GET_ATHLETE_MULTI_PB",
  ENTRY_STORE_ACTIONS_GET_COMPETITIONS_BY_ID = "ENTRY_STORE_ACTIONS_GET_COMPETITIONS_BY_ID",
  ENTRY_STORE_ACTIONS_GET_CLUB_COMP_INFO = "ENTRY_STORE_ACTIONS_GET_CLUB_COMP_INFO",
  // </ACTIONS>
}

export interface IEntryStoreState {
  entryForm: {
    club: IClub;
    selectedCompetition: ICompetitionInfo;
    clubCompInfo: IClubCompInfo;
    competitionSummaryPublic: ICompetitionSummaryPublic;
    selectedAthlete: IAthlete;
    searchedAthletes: IAthlete[];
    selectedAthleteShowSecondaryClaim: boolean;
    selectedAthleteLoading: boolean;
    showAddAthlete: boolean;
    athleteSearch: IAthleteSearch;
    // athleteShowModal: boolean,
    athleteMultiPb: IPbMultiItem[];
    athleteMultiPbLoading: boolean;
    athleteMultiPbModalShow: boolean;
    triggerRefreshAthlete: string;
    autoSelect: {
      compOrgId: number;
      compId: number;
    };
    sectionsOpen: {
      teamEvents: boolean;
      athletes: boolean;
      schedule: boolean;
      orderSummary: boolean;
    };
    competitions: ICompetitionDropDown[];
    competitionsLoading: boolean;
  };
  routeError: IE4sRouteControllerError;
}

const mutations = {
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_ENTRYFORM_SET_CLUB](
    state: IEntryStoreState,
    club: IClub
  ) {
    state.entryForm.club = club;
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_COMP_PUBLIC](
    state: IEntryStoreState,
    competitionSummaryPublic: ICompetitionSummaryPublic
  ) {
    state.entryForm.competitionSummaryPublic = R.clone(
      competitionSummaryPublic
    );
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_ENTRYFORM_SET_COMPETITION](
    state: IEntryStoreState,
    selectedCompetition: ICompetitionInfo
  ) {
    // selectedCompetition.eventTeamHeaders = eventTeamHeader;

    //  TODO this is for test only!!!!
    const comp = R.clone(selectedCompetition);
    // comp.clubCompInfo = clubCompInfoMock;
    state.entryForm.selectedCompetition = comp;
    let clubCompInfo = factoryClubCompInfo();
    if (hasClubCompInfoCompetition(comp.clubCompInfo as IClubCompInfo)) {
      clubCompInfo = comp.clubCompInfo as IClubCompInfo;
    }
    state.entryForm.clubCompInfo = simpleClone(clubCompInfo);
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_SELECTED_ATHLETE_LOADING](
    state: IEntryStoreState,
    isLoading: boolean
  ) {
    state.entryForm.selectedAthleteLoading = isLoading;
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_SELECTED_ATHLETE](
    state: IEntryStoreState,
    athlete: IAthlete
  ) {
    state.entryForm.selectedAthlete = R.clone(athlete);
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_SEARCHED_ATHLETES](
    state: IEntryStoreState,
    athletes: IAthlete[]
  ) {
    state.entryForm.searchedAthletes = R.clone(athletes);
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_UPDATE_SELECTED_ATHLETE_PB](
    state: IEntryStoreState,
    athlete: IAthlete
  ) {
    state.entryForm.selectedAthlete.pbInfo = R.clone(athlete.pbInfo);
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_TOGGLE_SECONDARY_CLAIM](
    state: IEntryStoreState,
    force?: boolean
  ) {
    state.entryForm.selectedAthleteShowSecondaryClaim =
      !state.entryForm.selectedAthleteShowSecondaryClaim;
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_RESET_SECONDARY_CLAIM](
    state: IEntryStoreState
  ) {
    state.entryForm.selectedAthleteShowSecondaryClaim = false;
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SHOW_ADD_ATHLETE](
    state: IEntryStoreState,
    showIt: boolean
  ) {
    state.entryForm.showAddAthlete = showIt;
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_ATHLETE_FILTER](
    state: IEntryStoreState,
    athleteSearch: IAthleteSearch
  ) {
    state.entryForm.athleteSearch = { ...athleteSearch };
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_ATHLETE_MULTI_PB](
    state: IEntryStoreState,
    athleteMultiPb: IPbMultiItem[]
  ) {
    state.entryForm.athleteMultiPb = [...athleteMultiPb];
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_ATHLETE_MULTI_PB_LOADING](
    state: IEntryStoreState,
    isLoading: boolean
  ) {
    state.entryForm.athleteMultiPbLoading = isLoading;
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_ATHLETE_MULTI_PB_MODAL_SHOW](
    state: IEntryStoreState,
    showModal: boolean
  ) {
    state.entryForm.athleteMultiPbModalShow = showModal;
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_ATHLETE_TRIGGER_REFRESH](
    state: IEntryStoreState
  ) {
    state.entryForm.triggerRefreshAthlete = new Date().toString(); // new Date().toISOString();
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_AUTO_SELECT](
    state: IEntryStoreState,
    routeQuery: IObjectKeyType<string>
  ) {
    if (routeQuery.comporgid) {
      state.entryForm.autoSelect.compOrgId = Number(routeQuery.comporgid);
    }
    if (routeQuery.compid) {
      state.entryForm.autoSelect.compId = Number(routeQuery.compid);
    }
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_AUTO_SELECT_COMP_ORG](
    state: IEntryStoreState,
    id: number
  ) {
    if (R.isNil(id)) {
      return;
    }
    state.entryForm.autoSelect.compOrgId = id;
  },
  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_AUTO_SELECT_COMP](
    state: IEntryStoreState,
    id: number
  ) {
    if (R.isNil(id) || id === 0) {
      return;
    }
    state.entryForm.autoSelect.compId = id;
  },

  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_COMPETITIONS](
    state: IEntryStoreState,
    comps: ICompetitionDropDown[]
  ) {
    state.entryForm.competitions = R.clone(comps);
  },

  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_COMPETITIONS_LOADING](
    state: IEntryStoreState,
    isLoading: boolean
  ) {
    state.entryForm.competitionsLoading = isLoading;
  },

  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_ROUTE_ERROR](
    state: IEntryStoreState,
    routeError: IE4sRouteControllerError
  ) {
    state.routeError = R.clone(routeError);
  },

  [ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_CLUB_COMP_INFO](
    state: IEntryStoreState,
    clubCompInfo: IClubCompInfo
  ) {
    state.entryForm.clubCompInfo = R.clone(clubCompInfo);
  },
};

const getters = {
  [ENTRY_STORE_CONST.ENTRY_STORE_GETTERS_IS_ATHLETE_SEARCH_FILTER_ON](
    state: IEntryStoreState
  ) {
    return athleteService.isAthleteSearchFilterEnabled(
      state.entryForm.athleteSearch
    );
  },

  [ENTRY_STORE_CONST.ENTRY_STORE_GETTERS_SELECTED_ATHLETE](
    state: IEntryStoreState
  ) {
    return state.entryForm.selectedAthlete;
  },

  [ENTRY_STORE_CONST.ENTRY_STORE_GETTERS_IS_ATHLETE_SELECTED](
    state: IEntryStoreState
  ) {
    return (
      state.entryForm.selectedAthlete && state.entryForm.selectedAthlete.id > 0
    );
  },

  [ENTRY_STORE_CONST.ENTRY_STORE_GETTERS_GET_ATHLETE_CLUB_NAME](
    state: IEntryStoreState
  ) {
    if (!state.entryForm.selectedAthlete) {
      return "";
    }
    return state.entryForm.selectedAthleteShowSecondaryClaim
      ? state.entryForm.selectedAthlete.club2
      : state.entryForm.selectedAthlete.club;
  },

  [ENTRY_STORE_CONST.ENTRY_STORE_GETTERS_SELECTED_COMP_IS_TEAM](
    state: IEntryStoreState
  ) {
    return competitionService.isTeamCompetition(
      state.entryForm.selectedCompetition
    );
  },

  [ENTRY_STORE_CONST.ENTRY_STORE_GETTERS_SHOW_ADD_ATHLETE](
    state: IEntryStoreState
  ) {
    const comp = state.entryForm.selectedCompetition;
    if (comp) {
      return competitionService.displayAddAthleteButton(
        state.entryForm.selectedCompetition
      );
    }
    return false;
  },

  [ENTRY_STORE_CONST.ENTRY_STORE_GETTERS_HAS_BUILDER_PERM_FOR_COMP](
    state: IEntryStoreState,
    gettersX: any,
    rootState: IRootState
  ) {
    const userInfo: IUserInfo =
      rootState[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME].configApp.userInfo;
    const selectedCompetition = state.entryForm.selectedCompetition;
    return configService.hasBuilderPermissionForComp(
      userInfo,
      selectedCompetition.compOrgId,
      selectedCompetition.id
    );
  },
};

const actions = {
  [ENTRY_STORE_CONST.ENTRY_STORE_ACTIONS_GET_SELECTED_ATHLETE](
    context: ActionContext<IEntryStoreState, IRootState>,
    payload: {
      id: number;
      forceReload: boolean;
    }
  ) {
    const athleteData: AthleteData = new AthleteData();
    context.commit(
      ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_SELECTED_ATHLETE_LOADING,
      true
    );

    const currentSelectedAthlete = context.state.entryForm.selectedAthlete;
    if (
      currentSelectedAthlete !== undefined &&
      (currentSelectedAthlete.id !== payload.id || payload.forceReload)
    ) {
      // context.commit(ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME + "/" +
      //     ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_PROCESS_EVENTS, [], {
      //     root: true
      // })

      //  Switching athlete, so reset any selected events
      context.commit(
        ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME +
          "/" +
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SELECTED,
        [],
        {
          root: true,
        }
      );

      return athleteData.getAthlete(payload.id).then((response: IAthlete) => {
        context.commit(
          ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_SELECTED_ATHLETE,
          response
        );
        context.commit(
          ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_SELECTED_ATHLETE_LOADING,
          false
        );
        return;
      });
    } else {
      context.commit(
        ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_SELECTED_ATHLETE_LOADING,
        false
      );
    }
    return;
  },

  [ENTRY_STORE_CONST.ENTRY_STORE_ACTIONS_GET_ATHLETE_MULTI_PB](
    context: ActionContext<IEntryStoreState, IRootState>,
    payload: {
      event: IAthleteCompSchedRuleEvent;
    }
  ) {
    const athleteData: AthleteData = new AthleteData();
    // context.commit(ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_SELECTED_ATHLETE_LOADING, true);
    context.commit(
      ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_ATHLETE_MULTI_PB_LOADING,
      true
    );
    context.commit(
      ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_ATHLETE_MULTI_PB,
      []
    );
    const currentSelectedAthlete = context.state.entryForm.selectedAthlete;
    if (currentSelectedAthlete !== undefined && payload.event.multiid > 0) {
      context.commit(
        ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_ATHLETE_MULTI_PB_LOADING,
        true
      );
      return athleteData
        .getAthleteMultiPb(currentSelectedAthlete.id, payload.event.ceid)
        .then((response: IPbMultiItem[]) => {
          context.commit(
            ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_ATHLETE_MULTI_PB,
            response
          );
          // context.commit(ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_SELECTED_ATHLETE_LOADING, false);
          context.commit(
            ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_ATHLETE_MULTI_PB_LOADING,
            false
          );
          return;
        });
    }
    return;
  },

  [ENTRY_STORE_CONST.ENTRY_STORE_ACTIONS_GET_COMPETITIONS_BY_ID](
    context: ActionContext<IEntryStoreState, IRootState>,
    payload: {
      orgId: number;
      compId: number;
    }
  ) {
    const competitionData = new CompetitionData();
    const isPublic = true;
    context.commit(
      ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_COMPETITIONS_LOADING,
      true
    );
    const prom = competitionData.getCompetitionsByid(
      payload.orgId,
      isPublic,
      payload.compId
    );
    handleResponseMessages(prom);
    prom
      .then((response: IServerCompetitionLookupResponse) => {
        if (response.errNo === 0) {
          const competitionDropDowns: ICompetitionDropDown[] =
            response.data.compDates.map((summary: ICompetitionInfo) => {
              const competitionDropDown: ICompetitionDropDown =
                summary as ICompetitionDropDown;
              competitionDropDown.humanReadableDate = format(
                parse(competitionDropDown.date),
                "Do MMM YY"
              );
              return competitionDropDown;
            });
          const compPlease: ICompetitionDropDown = {
            id: 0,
            name: "Select Competition...",
          } as ICompetitionDropDown;
          const competitionLookup = [compPlease, ...competitionDropDowns];
          context.commit(
            ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_COMPETITIONS,
            competitionLookup
          );
        }
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        return;
      })
      .finally(() => {
        context.commit(
          ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_COMPETITIONS_LOADING,
          false
        );
      });
  },

  [ENTRY_STORE_CONST.ENTRY_STORE_ACTIONS_GET_CLUB_COMP_INFO](
    context: ActionContext<IEntryStoreState, IRootState>,
    payload: {
      compId: number;
    }
  ) {
    const promGetClubCompInfo = new CompetitionData().getClubCompInfo(
      payload.compId
    );
    handleResponseMessages(promGetClubCompInfo);
    return promGetClubCompInfo.then((resp) => {
      context.commit(ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_CLUB_COMP_INFO, resp.meta!.clubCompInfo);
    });
  }
};

export const entryStore: Module<IEntryStoreState, IRootState> = {
  namespaced: true,
  state: {
    entryForm: {
      club: {
        id: 0,
        clubid: 0,
      } as IClub,
      selectedCompetition: {} as ICompetitionInfo,
      clubCompInfo: factoryClubCompInfo(),
      competitionSummaryPublic: competitionService.factorySummaryPublic(),
      selectedAthlete: {} as IAthlete,
      searchedAthletes: [],
      selectedAthleteLoading: false,
      selectedAthleteShowSecondaryClaim: false,
      showAddAthlete: false,
      athleteSearch: {
        region: "",
        county: "",
        club: "",
        firstname: "",
        surname: "",
        gender: "",
      } as IAthleteSearch,
      athleteMultiPb: [],
      athleteMultiPbLoading: false,
      athleteMultiPbModalShow: false,
      triggerRefreshAthlete: "",
      autoSelect: {
        compOrgId: 0,
        compId: 0,
      },
      sectionsOpen: {
        teamEvents: false,
        athletes: true,
        schedule: false,
        orderSummary: false,
      },
      competitions: [],
      competitionsLoading: false,
    },
    routeError: {
      id: 0,
      name: "",
      message: "",
    },
  },
  mutations,
  getters,
  actions,
};

export function useEntryStore(): IEntryStoreState {
  const store = useStore();
  return store.state[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME];
}
