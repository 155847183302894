




import { defineComponent, PropType, SetupContext } from "@vue/composition-api";
import { ICompetitionSummaryPublic } from "../../../../competition/competition-models";
import ButtonGenericV2 from "./button-generic-v2.vue";
import { CONFIG } from "../../../config";

export default defineComponent({
  name: "button-goto-results-v2",
  components: { ButtonGenericV2 },
  props: {
    competitionSummaryPublic: {
      type: Object as PropType<ICompetitionSummaryPublic>,
      default: () => {
        return [];
      },
    },
    compId: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    buttonText: {
      type: String,
      default: () => {
        return "View Results";
      },
    },
  },
  setup(
    props: {
      competitionSummaryPublic: ICompetitionSummaryPublic;
      buttonText: string;
    },
    context: SetupContext
  ) {
    function goToResults() {
      const link: string =
        CONFIG.E4S_HOST +
        "/" +
        props.competitionSummaryPublic.compId +
        "/schedule";

      window.open(link, "Results");
    }

    return { goToResults };
  },
});
