import { ResourceData } from "../common/resource/resource-service";
import {
  ICompEvent,
  IEventGroupSummary,
  IRenameGroup,
} from "./compevent-models";
import https from "../common/https";
import {
  IObjectKey,
  IServerGenericResponse,
  IServerResponse,
  IServerResponseListMeta,
} from "../common/common-models";
import { IAgeGroupCompCoverageModel } from "../agegroup/agegroup-models";

export class CompEventData extends ResourceData<ICompEvent> {
  constructor() {
    super("/v5/compevent");
  }

  public getScheduleListWithMeta(
    compId: number
  ): Promise<
    IServerResponseListMeta<
      ICompEvent,
      { ageGroups: IAgeGroupCompCoverageModel[] }
    >
  > {
    return https.get(this.getEndPoint() + "?compId=" + compId);
  }

  public deleteMultiple(
    payload: IObjectKey
  ): Promise<IServerResponse<unknown>> {
    return https.post(
      this.getEndPoint() + "/delete",
      payload
    ) as any as Promise<IServerResponse<unknown>>;
  }

  public renameGroup(
    renameGroup: IRenameGroup
  ): Promise<IServerGenericResponse> {
    return https.post(
      this.getEndPoint() + "/renamegroup",
      renameGroup
    ) as any as Promise<IServerGenericResponse>;
  }

  public changeGroupName(
    eventGroupSummary: IEventGroupSummary
  ): Promise<IServerResponse<unknown>> {
    return https.post("/v5/eventgroup", eventGroupSummary) as any as Promise<
      IServerResponse<unknown>
    >;
  }

  public updateEventGroupSummaries(
    eventGroupSummaries: IEventGroupSummary[]
  ): Promise<IServerResponse<unknown>> {
    return https.post("/v5/eventgroup", eventGroupSummaries) as any as Promise<
      IServerResponse<unknown>
    >;
  }

  public setNoResultsPossible(
    eventGroupId: number,
    isPossible: boolean
  ): Promise<IServerResponse<unknown>> {
    return https.post("/v5/eventgroup/resultspossible", {
      egId: eventGroupId,
      possible: isPossible,
    }) as any as Promise<IServerResponse<unknown>>;
  }

  public deleteEventGroup(
    eventGroupId: number
  ): Promise<IServerResponse<unknown>> {
    return https.delete("/v5/eventgroup/" + eventGroupId) as any as Promise<
      IServerResponse<unknown>
    >;
  }
}
