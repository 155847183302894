






























import { defineComponent, PropType, SetupContext } from "@vue/composition-api";
import { ICompetitionSummaryPublic } from "../../../competition/competition-models";
import PublicCompCardWrapperV2 from "../public-list/v2/public-comp-card-wrapper-v2.vue";
import { CompetitionService } from "../../../competition/competiton-service";
import PublicCompCardV2 from "../public-list/v2/public-list-comp-card-v2.vue";
import ButtonGotoCompV2 from "../../../common/ui/layoutV2/buttons/button-goto-comp-v2.vue";
import ButtonGenericV2 from "../../../common/ui/layoutV2/buttons/button-generic-v2.vue";

const competitionService = new CompetitionService();

export default defineComponent({
  name: "terms-conditions-v2",
  components: {
    ButtonGenericV2,
    ButtonGotoCompV2,
    PublicCompCardV2,
    PublicCompCardWrapperV2,
  },
  props: {
    competitionSummaryPublic: {
      type: Object as PropType<ICompetitionSummaryPublic>,
      default: () => {
        return competitionService.factorySummaryPublic();
      },
    },
  },
  setup(
    props: { competitionSummaryPublic: ICompetitionSummaryPublic },
    context: SetupContext
  ) {
    function submit() {
      context.emit("submit", props.competitionSummaryPublic);
    }

    function cancel() {
      context.emit("cancel", props.competitionSummaryPublic);
    }

    return {
      submit,
      cancel,
    };
  },
});
