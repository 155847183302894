

























































import Vue from "vue";
import Component from "vue-class-component";
import {LoginData} from "../login-data";
import {messageDispatchHelper} from "../../user-message/user-message-store";
import {USER_MESSAGE_LEVEL} from "../../user-message/user-message-models";
import {AUTH_STORE_CONST} from "../auth-store";
import { Prop } from "vue-property-decorator";
import {ValidationService} from "../../validation/validation-service";
import {ValidationController} from "../../validation/validation-controller";
import {IObjectKeyType} from "../../common/common-models";
import {IValidationProp} from "../../validation/validation-models";
import FieldValidationLabel from "../../validation/validation-field-lable.vue";
import {CONFIG_STORE_CONST} from "../../config/config-store";

const validationService: ValidationService = new ValidationService();

@Component({
    name: "login-form",
    components: {
        FieldValidationLabel
    }
})
export default class LoginForm extends Vue {

    @Prop({default: ""}) public readonly redirectedFrom: string;

    public userName: string = "";
    public password: string = "";
    public message: string = "";
    public isLoading: boolean = false;

    public validationController: ValidationController = new ValidationController();

    public created() {
        // @ts-ignore
        // this.redirectedFrom = this.$route.query && this.$route.query.redirectFrom ? this.$route.query.redirectFrom  : "";

    }

    public doLogin() {
        this.validate();
        if (!this.validationController.isValid) {
            return;
        }

        const loginData: LoginData = new LoginData();
        this.message = "";
        this.isLoading = true;
        loginData.login(this.userName, this.password)
            .then( (response: string) => {

                const isSuccess = response.indexOf("You have logged in successfully") > -1;

                this.$store.commit(AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME + "/" + AUTH_STORE_CONST.MUTATIONS_LOGGED_IN, isSuccess);

                if (isSuccess) {

                    this.$store.dispatch(CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME + "/" + CONFIG_STORE_CONST.CONFIG_ACTIONS_GET_APP_CONFIG)
                        .then( () => {
                            // this.$router.push({ path: this.redirectedFrom });
                            const redirect = window.location.href.split("#")[0];
                            if (this.redirectedFrom.length > 0) {
                                window.location.href = redirect + "#" + this.redirectedFrom;
                            } else {
                                window.location.href = redirect + "#" + "/";
                            }
                        });

                } else {
                    this.message = "Username or password was not recognised.  Please try again or reset password.";
                }

            })
            .catch((error) => {
                messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
                this.$store.commit(AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME + "/" + AUTH_STORE_CONST.MUTATIONS_LOGGED_IN, false);
                this.message = "Error: Username or password was not recognised.  Please try again or reset password.";
                return;
            })
            .finally( () => {
                this.isLoading = false;
            });
    }

    public doCancel() {
        // this.$store.commit(AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME + "/" + AUTH_STORE_CONST.MUTATIONS_SHOW_LOGIN, false);
        this.$emit("onCancel");
    }

    public validate() {
        let errors: IObjectKeyType<IValidationProp> = {};
        if (this.userName.length === 0) {
            errors = validationService.addMessage("userName", "Required", errors);
        }

        if (this.password.length === 0) {
            errors = validationService.addMessage("password", "Required", errors);
        }

        this.validationController.setErrors(errors);
    }

}

