import {
  IE4sEmailMessageApp,
  IE4sEmailMessageComp,
  IE4sEmailMessageCompUser,
  IE4sEmailMessageCreate,
  IEmailMessagesParams,
} from "./email-message-models";
import { CommonService } from "../common/common-service";

const commonService: CommonService = new CommonService();

export class EmailMessageService {
  public factoryEmailMessagesParams(): IEmailMessagesParams {
    return {
      messages: true,
      emails: true,
      read: null,
      deleted: false,
      compId: null,
    };
  }

  public factoryE4sEmailMessageApp(): IE4sEmailMessageApp {
    return {
      id: 0,
      type: "E",
      sendFrom: "",
      to: [],
      cc: [],
      bcc: [],
      subject: "",
      body: "",
      priority: 2,
      error: "",
      dateSentISO: "",
      dateCreatedISO: "",
      user: {
        id: 0,
        name: "",
        email: "",
      },
      // dateReadISO: "",
      // dateDeletedISO: ""
      forUsers: [],
    };
  }

  public factoryE4sEmailMessageCreate(): IE4sEmailMessageCreate {
    return {
      to: ["_ALL_"],
      subject: "",
      type: "M",
      body: "",
      easyFilter: "P",
      eventGroups: [],
    };
  }

  public factoryE4sEmailMessageCreateForCompAllAthletes(
    compId: number
  ): IE4sEmailMessageCreate {
    return {
      ...this.factoryE4sEmailMessageCreate(),
      to: ["_COMP" + compId + "_"],
    };
  }

  public factoryE4sEmailMessageCompUser(): IE4sEmailMessageCompUser {
    return {
      email: "",
      id: 0,
      muId: 0,
      name: "",
      status: {
        dateDeletedISO: "",
        dateReadISO: "",
      },
    };
  }

  public getUnreadMessages(
    e4sEmailMessages: (IE4sEmailMessageApp | IE4sEmailMessageComp)[]
  ): (IE4sEmailMessageApp | IE4sEmailMessageComp)[] {
    return e4sEmailMessages.filter(
      (message: IE4sEmailMessageApp | IE4sEmailMessageComp) => {
        return this.isUnread(message);
      }
    );
  }

  public getFrom(
    e4sEmailMessage: IE4sEmailMessageApp | IE4sEmailMessageComp
  ): string {
    return e4sEmailMessage.sendFrom.length > 0
      ? e4sEmailMessage.sendFrom
      : e4sEmailMessage.user.name;
  }

  public sortMessages(
    e4sEmailMessages: IE4sEmailMessageApp[]
  ): IE4sEmailMessageApp[] {
    const messages = e4sEmailMessages.map((message) => {
      if (message.dateSentISO.length === 0) {
        message.dateSentISO = message.dateCreatedISO;
      }
      return message;
    });
    return commonService.sortArray("dateCreatedISO", messages, "DESC");
  }

  public isUnread(
    e4sEmailMessage: IE4sEmailMessageApp | IE4sEmailMessageComp
  ): boolean {
    // if ((e4sEmailMessage as IE4sEmailMessageApp).dateReadISO) {
    //     return (e4sEmailMessage as IE4sEmailMessageApp).dateReadISO.length === 0;
    // }
    // const forUsers = (e4sEmailMessage as IE4sEmailMessageComp).forUsers;
    const forUsers = e4sEmailMessage.forUsers;
    if (forUsers) {
      if (forUsers.length === 1) {
        return forUsers[0].status.dateReadISO.length === 0;
      }
    }
    return false;
  }

  public isSendingToAllUsers(sendTos: string[]): boolean {
    return sendTos.reduce<boolean>((accum, sendTo) => {
      if (sendTo.indexOf("_ALL_") > -1) {
        accum = true;
      }
      return accum;
    }, false);
  }

  public getHumanReadableSendTos(sendTos: string[]): string[] {
    return sendTos.map((sendTo) => {
      if (sendTo.indexOf("_COMP") > -1) {
        return (
          "All athletes of comp " + sendTo.split("_COMP")[1].replace("_", "")
        );
      }
      if (sendTo.indexOf("_ALL_") > -1) {
        return "All Users";
      }
      return sendTo;
    });
  }
}
