







export default {
    props: ["close", "link-text", "show-icon"]
}
