import {IListParams, ResourceData} from "../common/resource/resource-service"
import { ISecondaryDef, SECONDARY_OBJECT_TYPES} from "./secondary-models"
import https from "../common/https"
import {IServerPagingResponseList} from "../common/common-models";
// import {SecondaryService} from "./secondary-service"

// const secondaryService: SecondaryService = new SecondaryService();

export class SecondaryData extends ResourceData<ISecondaryDef> {
    constructor() {
        super("/v5/secondary");
    }

    public getSecondaries(objType: SECONDARY_OBJECT_TYPES,
                          objId: number,
                          compId: number = 0,
                          listParams?: IListParams): Promise<IServerPagingResponseList<ISecondaryDef>> {

        let payload = {
            objtype: objType,
            objid: objId,
            compid: compId
        }
        if (listParams) {
            payload = {...payload, ...listParams};
        }

        return https.get("/v5/secondary/list",{
            params: payload
        });
    }
}
