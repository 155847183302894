





























































import Vue from "vue";
import Component from "vue-class-component";
import {FIELD_HELP_STORE_CONST, IFieldHelpStoreState, IHelpData} from "./field-help-store"
import {mapState} from "vuex";
import {CONFIG_STORE_CONST, IConfigStoreState} from "../../../../config/config-store"
import {IConfigApp} from "../../../../config/config-app-models"
import { VUE_MQ_SIZES } from "../../../..";
import { Watch } from "vue-property-decorator";
import {debounce} from "../../../debounce";

@Component({
    name: "field-help-display",
    computed: {
        ...mapState(FIELD_HELP_STORE_CONST.CONST_MODULE_NAME, {
            fieldHelpStoreState: (state: IFieldHelpStoreState) => state
        }),
        ...mapState(CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME, {
            configApp: (state: IConfigStoreState) => state.configApp
        })
    },
    components: {
    }
})
export default class FieldHelpDisplay extends Vue {
    public readonly fieldHelpStoreState: IFieldHelpStoreState;
    public readonly configApp: IConfigApp;

    public isFullScreen: boolean = false;
    public $mq: any;
    public VUE_MQ_SIZES = VUE_MQ_SIZES;
    public PREFIX = Math.random().toString(36).substring(2);

    public debounceResize: any;

    public mounted() {

        this.debounceResize = debounce((  ) => {
            // console.log("FieldHelpDisplay window.onresize() h: " + window.innerHeight + " " + window.innerWidth);
            this.setBodyStyle();
        }, 100);

        window.onresize = () => {
          this.debounceResize();
        };
    }

    @Watch("isFullScreen")
    public onIsFullScreenChanged(newValue: boolean, oldValue: boolean) {
        if (newValue !== oldValue) {
            window.setTimeout( () => {
                this.setBodyStyle();
            }, 250);
        }
    }

    @Watch("fieldHelpStoreState.showHelp")
    public onShowHelpChanged(newValue: boolean, oldValue: boolean) {
        if (newValue && !oldValue) {
            window.setTimeout( () => {
               this.setBodyStyle();
            }, 250);
        }
    }

    @Watch("fieldHelpStoreState.helpData")
    public onHelpDataChanged(newValue: IHelpData, oldValue: IHelpData) {
        this.isFullScreen = !this.isFullScreen && newValue.type === "U";
    }

    public setBodyStyle() {
        const container = document.getElementById(this.PREFIX + "-container");
        const header = document.getElementById(this.PREFIX + "-header");
        const body = document.getElementById(this.PREFIX + "-body");
        const bodyContent = document.getElementById(this.PREFIX + "-body-content");

        let sizes = {
            c: container ? container.clientHeight : 0,
            h: header ? header.clientHeight : 0,
            b: body ? body.clientHeight : 0,
            bc: bodyContent ? bodyContent.clientHeight : 0,
            nh: 0
        };
        if (container && body && sizes.c > 0) {
            const newHeight = (sizes.c - sizes.h) - 10;
            body.style.height = newHeight + "px";

            sizes = {...sizes, nh: newHeight};
        }
        // console.log("FieldHelpDisplay.setBodyStyle()", sizes);
    }


    public get getContainerCss(): string {
        const fullScreenCss = this.isFullScreen ? "field-help--container-full" : "";
        let widthCss = "";
        if (!this.isFullScreen) {
            widthCss = this.$mq === this.VUE_MQ_SIZES.MOBILE.name ? '' : 'field-help--container-tablet-large';
        }

        return [fullScreenCss, widthCss].reduce((accum, css)=> {
            if (css.length > 0) {
                accum += (accum.length > 0 ? " " : "") + css;
            }
            return accum;
        }, "");
    }

    public get getMessage() {
        return this.fieldHelpStoreState.helpData.data;
    }

    public hideHelp() {
        this.$store.commit(
            FIELD_HELP_STORE_CONST.CONST_MODULE_NAME + "/" +
            FIELD_HELP_STORE_CONST.MUTATIONS_SET_SHOW_HELP,
            false
        );
    }
}
