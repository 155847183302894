


























import Vue from "vue";
import Component from "vue-class-component";
import E4SLayout from "../../common/ui/e4s-layout.vue";
import Register from "./register.vue";
import { AUTH_STORE_CONST, IAuthStoreState } from "../auth-store";
import { mapState } from "vuex";
import {
  CONFIG_STORE_CONST,
  IConfigStoreState,
} from "../../config/config-store";
import { IConfigApp } from "../../config/config-app-models";

@Component({
  name: "register-container",
  components: {
    Register,
    E4SLayout,
  },
  computed: {
    ...mapState(AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME, {
      isLoggedIn: (state: IAuthStoreState) => state.isLoggedIn,
    }),
    ...mapState(CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME, {
      configApp: (state: IConfigStoreState) => state.configApp,
    }),
  },
})
export default class RegisterContainer extends Vue {
  public isLoggedIn: boolean;
  public configApp: IConfigApp;

  public created() {
    if (this.isLoggedIn) {
      this.$store.dispatch(
        AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME +
          "/" +
          AUTH_STORE_CONST.AUTH_ACTIONS_LOGOUT,
        { redirectToHome: true, logoutUrl: this.configApp.logout }
      );
    }
  }

  public onCancel() {
    window.location.href = window.location.href.split("#")[0];
  }

  public onRegistered() {
    // window.location.href = window.location.href.split("#")[0] + "#/login";
  }
}
