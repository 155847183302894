import {ActionContext, Module, MutationTree, } from "vuex";
import {IRootState} from "../../app.store";
import {ICheckinAthlete, ICheckinCompSummary, ICheckinStats, ICheckInStoreState} from "./checkin-models"
import {CheckinService} from "./checkin-service";
import * as R from "ramda";
import {messageDispatchHelper} from "../../user-message/user-message-store";
import {USER_MESSAGE_LEVEL} from "../../user-message/user-message-models";
import {CheckinData, IGetCheckinSearchParams} from "./checkin-data";

export interface IPayloadCheckInSubmit {
    compId: number;
    athleteCheckIns: ICheckinAthlete[];
}

export interface IClearDownPayload {
    collected: boolean;
    checkedIn: boolean;
    all: boolean;
}

export enum CHECKIN_STORE_CONST {

    CHECKIN_CONST_MODULE_NAME =  "CHECKIN_CONST_MODULE_NAME",

    // <MUTATIONS>
    CHECKIN_MUTATIONS_LOADING =  "CHECKIN_MUTATIONS_LOADING",
    CHECKIN_MUTATIONS_SET_MESSAGE =  "CHECKIN_MUTATIONS_SET_MESSAGE",
    CHECKIN_MUTATIONS_SET_STATS =  "CHECKIN_MUTATIONS_SET_STATS",
    CHECKIN_MUTATIONS_SET_SUMMARY =  "CHECKIN_MUTATIONS_SET_SUMMARY",
    CHECKIN_MUTATIONS_LOADING_SUMMARY =  "CHECKIN_MUTATIONS_LOADING_SUMMARY",
    CHECKIN_MUTATIONS_SET_CHECKIN_ATHLETES =  "CHECKIN_MUTATIONS_SET_CHECKIN_ATHLETES",
    CHECKIN_MUTATIONS_RESET_CHECKIN_ATHLETES =  "CHECKIN_MUTATIONS_RESET_CHECKIN_ATHLETES",
    CHECKIN_MUTATIONS_UPDATE_CHECKIN_ATHLETE =  "CHECKIN_MUTATIONS_UPDATE_CHECKIN_ATHLETE",

    CHECKIN_MUTATIONS_SET_EMAIL_ID =  "CHECKIN_MUTATIONS_SET_EMAIL_ID",
    CHECKIN_MUTATIONS_SET_COMP_CODE =  "CHECKIN_MUTATIONS_SET_COMP_CODE",
    CHECKIN_MUTATIONS_SET_NONCE =  "CHECKIN_MUTATIONS_SET_NONCE",

    CHECKIN_MUTATIONS_LOADING_CLEAR_DOWN =  "CHECKIN_MUTATIONS_LOADING_CLEAR_DOWN",
    // </MUTATIONS>

    // <GETTERS>
    CHECKIN_GETTERS_TITLE_HEADER =  "CHECKIN_GETTERS_TITLE_HEADER",
    // </GETTERS>

    // <ACTIONS>
    CHECKIN_ACTIONS_GET_SUMMARY =  "CHECKIN_ACTIONS_GET_SUMMARY",
    CHECKIN_ACTIONS_SEARCH =  "CHECKIN_ACTIONS_SEARCH",
    CHECKIN_ACTIONS_SUBMIT =  "CHECKIN_ACTIONS_SUBMIT",
    CHECKIN_ACTIONS_CLEAR_DOWN =  "CHECKIN_ACTIONS_CLEAR_DOWN"
    // </ACTIONS>
}

const mutations: MutationTree<ICheckInStoreState> = {
    [CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_LOADING](state: ICheckInStoreState, isLoading: boolean) {
        state.isLoading = isLoading;
    },

    [CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_MESSAGE](state: ICheckInStoreState, message: string) {
        state.message = message;
    },

    [CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_STATS](state: ICheckInStoreState, checkinStats: ICheckinStats) {
        state.checkinSummary.checkIn = R.clone(checkinStats);
    },

    [CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_SUMMARY](state: ICheckInStoreState, checkinCompSummary: ICheckinCompSummary) {
        state.checkinSummary = R.clone(checkinCompSummary);
    },

    [CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_LOADING_SUMMARY](state: ICheckInStoreState, isLoading: boolean) {
        state.isLoadingSummary = isLoading;
    },

    [CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_CHECKIN_ATHLETES](state: ICheckInStoreState, checkinAthletes: ICheckinAthlete[]) {
        state.athleteCheckin = R.clone(checkinAthletes);
    },

    // [CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_UPDATE_CHECKIN_ATHLETE](state: ICheckInStoreState, checkinAthlete: ICheckinAthlete) {
    //     state.athleteCheckin = checkinAthletes;
    // },

    [CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_RESET_CHECKIN_ATHLETES](state: ICheckInStoreState) {
        state.athleteCheckin = [];
        state.message = "";
    },

    [CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_EMAIL_ID](state, id: string) {
        state.emailId = id;
    },

    [CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_COMP_CODE](state: ICheckInStoreState, id: string) {
        state.compCode = id;
    },

    [CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_NONCE](state: ICheckInStoreState, id: string) {
        state.nonce = id;
    },

    [CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_LOADING_CLEAR_DOWN](state: ICheckInStoreState, isLoading: boolean) {
        state.clearDownIsLoading = isLoading;
    }
};

const getters = {
    [CHECKIN_STORE_CONST.CHECKIN_GETTERS_TITLE_HEADER](state: ICheckInStoreState) {
        const isOrgInCompName = state.checkinSummary.name.toLowerCase().indexOf(state.checkinSummary.organiser.name.toLowerCase()) > -1;
        return (isOrgInCompName  ? "" : state.checkinSummary.organiser.name + " - ") + state.checkinSummary.name + " (Expected: " +
            state.checkinSummary.checkIn.expected +
            " / Actual: " + state.checkinSummary.checkIn.collected + ")";
    }
};

const checkinData: CheckinData = new CheckinData();

const actions = {
    [CHECKIN_STORE_CONST.CHECKIN_ACTIONS_GET_SUMMARY](context: ActionContext<ICheckInStoreState, IRootState>, compId: number) {
        context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_LOADING_SUMMARY, true);
        return checkinData.getCheckinSummary(compId)
            .then((response) => {
                if (response.errNo > 0) {
                    messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
                    return;
                }
                context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_SUMMARY, response.data);
            })
            .catch((error) => {
                messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
            })
            .finally(() => {
                context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_LOADING_SUMMARY, false);
            });

    },

    [CHECKIN_STORE_CONST.CHECKIN_ACTIONS_SEARCH](
        context: ActionContext<ICheckInStoreState, IRootState>,
        getCheckinSearchParams: IGetCheckinSearchParams
        ) {

        context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_LOADING, true);
        context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_CHECKIN_ATHLETES, []);
        context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_MESSAGE, "");

        let promSearch;
        if (getCheckinSearchParams.isOrganiser) {
            promSearch = checkinData.getCheckin(getCheckinSearchParams);
        } else {
            promSearch = checkinData.getAthleteCheckin(getCheckinSearchParams);
        }

        promSearch
            .then((response) => {
                if (response.errNo > 0) {
                    messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
                    return;
                }

                const checkinAthletes: ICheckinAthlete[] = response.data.map( (checkinAthlete, index) => {
                    if (!checkinAthlete.id) {
                        checkinAthlete.id = index;
                    }

                    return checkinAthlete;
                })

                context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_CHECKIN_ATHLETES, checkinAthletes);
                context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_STATS, response.meta?.checkIn ? response.meta.checkIn : {});

                if (checkinAthletes.length === 0) {
                    context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_MESSAGE, "No results found.");
                } else {
                    context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_SET_MESSAGE, checkinAthletes.length +
                        " result" + (checkinAthletes.length > 1 ? "s" :  "") + " found.");
                }

            })
            .catch((error) => {
                messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
            })
            .finally(() => {
                context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_LOADING, false);
            });

    },

    [CHECKIN_STORE_CONST.CHECKIN_ACTIONS_SUBMIT](
        context: ActionContext<ICheckInStoreState, IRootState>,
        payload: {
            compId: number,
            athleteCheckIns: ICheckinAthlete[],
            isAthlete: boolean
        }
    ) {

        context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_LOADING, true);
        let prom;
        if (payload.isAthlete) {
            prom = checkinData.submitCheckinAthlete(payload.compId, payload.athleteCheckIns);
        } else {
            prom = checkinData.submitCheckinOrganiser( payload.compId, payload.athleteCheckIns);
        }
        return prom
            .then((response) => {
                if (response.errNo > 0) {
                    messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
                    return;
                }
            })
            .catch((error) => {
                messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
                return {};
            })
            .finally(() => {
                context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_LOADING, false);
            });
    },

    [CHECKIN_STORE_CONST.CHECKIN_ACTIONS_CLEAR_DOWN](
        context: ActionContext<ICheckInStoreState, IRootState>,
        payload: {
            compId: number,
            data: IClearDownPayload
        }
    ) {

        context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_LOADING_CLEAR_DOWN, true);
        return checkinData.clearDownCheck(payload.compId, payload.data)
            .then((response) => {
                if (response.errNo > 0) {
                    messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
                    return;
                }
            })
            .catch((error) => {
                messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
                return {};
            })
            .finally(() => {
                context.commit(CHECKIN_STORE_CONST.CHECKIN_MUTATIONS_LOADING_CLEAR_DOWN, false);
            });
    }
};

export const checkInStore: Module<ICheckInStoreState, IRootState> = {
    namespaced: true,
    state: new CheckinService().factoryStoreState(),
    mutations,
    getters,
    actions
};

