import { addMilliseconds, addSeconds, format, parse } from "date-fns";
import { IValidationResult } from "../common/common-models";
import {
  ATHLETE_PB_VALIDATION_MESSAGES,
  EVENT_TYPE,
  EVENT_UOM_TYPE,
  IAthleteCompSched,
  IAthleteCompSchedRuleEvent,
  IEoptions,
  IEventUom,
} from "./athletecompsched-models";
import { IAthletePb, IAthleteSummary } from "../athlete/athlete-models";
import { IPerfInfo } from "./pb/v3/edit-pb-v3-models";

export interface IProcessPbPatternResult {
  pattern: string;
  pb: number;
}

export interface IProcessPb {
  userText: string;
  isValid: boolean;
  eventUomUsed: IEventUom;
  pb: number;
  validationResults: IValidationResult[];
  patternResults: IProcessPbPatternResult[];
}

interface IConvertUserFormatErrors {
  [key: number]: {
    NAME: string;
    MESSAGE: string;
  };
}

const convertUserFormatErrors: IConvertUserFormatErrors = {
  [-22]: ATHLETE_PB_VALIDATION_MESSAGES.INVALID_SEPARATOR,
  [-23]: ATHLETE_PB_VALIDATION_MESSAGES.TOO_MANY_SEPARATORS,
};

export class PBService {
  public factoryProcessPb(): IProcessPb {
    return {
      userText: "",
      isValid: true,
      eventUomUsed: {
        pattern: "",
        text: "",
        short: "",
        uomType: EVENT_UOM_TYPE.DISTANCE,
      },
      pb: 0,
      validationResults: [],
      patternResults: [],
    };
  }

  public convertSecondsToUserFormat(sec: number, pbTimeFormat: string): string {
    // console.log("======================= PBService.convertSecondsToUserFormat() sec: " + sec + ", pbTimeFormat: " + pbTimeFormat);

    //  In  moment(), you can do this: moment.duration(123, "minutes").format("h:mm");
    //  but, we not using moment(), so...
    const secAsNum: number = Math.floor(sec);
    // console.log("PBService.convertSecondsToUserFormat() secAsNum: " + secAsNum);

    // const fnsStart = parse("0");
    const fnsStart = parse("1970-01-01T00:00:00");
    let fnsFuture = addSeconds(fnsStart, secAsNum);
    // console.log("PBService.convertSecondsToUserFormat() fnsStart: " + format(fnsStart, ""));
    // console.log("PBService.convertSecondsToUserFormat() fnsFuture: " + format(fnsFuture, ""));

    let secSep = this.getSeperator(sec + "");
    secSep = secSep === "" ? "." : secSep;
    // console.log("PBService.convertSecondsToUserFormat() secSep: " + secSep);
    const radixPos = (sec + "").indexOf(secSep);
    // console.log("PBService.convertSecondsToUserFormat() radixPos: " + radixPos);
    //  N.B.  If sec = 615.00, redix is -1
    if (radixPos > -1) {
      let decimalPortion: string = String(sec).slice(radixPos + 1);
      if (decimalPortion.length === 1) {
        decimalPortion = decimalPortion + "0"; //  E.g. user has entered 100m, 9.8, really means 9.80
      }
      // console.log("PBService.convertSecondsToUserFormat() decimalPortion: ", {decimalPortion, radixPos});
      const hundredthsOfSec = Number(decimalPortion) * 10;
      fnsFuture = addMilliseconds(fnsFuture, hundredthsOfSec);
    }
    const result = format(fnsFuture, pbTimeFormat);
    // console.log("PBService.convertSecondsToUserFormat() result: " + result);
    return result;
  }

  //  @Deprecated
  public processPbFromUserInput(
    userFormatPb: string,
    athleteCompSchedRuleEvent: IAthleteCompSched
  ): IProcessPb {
    let processPb: IProcessPb = {
      userText: userFormatPb,
      isValid: false,
      pb: 0,
      eventUomUsed: {} as IEventUom,
      validationResults: [],
      patternResults: [],
    };

    const validationResults: IValidationResult[] = [];
    const regOnlyNumbers = new RegExp(/^\d+$/);
    //  my regex skills aren't great, so stripping out non number chars allowed...
    const userFormatPbWithNoSeps = userFormatPb.replace(/[:.]/gi, "");
    const isOnlyNumbers = regOnlyNumbers.test(userFormatPbWithNoSeps);
    if (!isOnlyNumbers) {
      processPb.validationResults.push({
        name: ATHLETE_PB_VALIDATION_MESSAGES.ONLY_NUMBERS.NAME,
        message: ATHLETE_PB_VALIDATION_MESSAGES.ONLY_NUMBERS.MESSAGE,
      });
      return processPb;
    }

    //  now loop across any formats.
    processPb = athleteCompSchedRuleEvent.perfInfo.uom.reduce(
      (accum: IProcessPb, eventUom: IEventUom) => {
        if (!accum.isValid) {
          const pb = this.convertUserFormatToSeconds(
            userFormatPb,
            eventUom.pattern
          );
          accum.pb = pb;
          const isPbValid = this.isPbValid(athleteCompSchedRuleEvent, pb);
          accum.validationResults = [...accum.validationResults, ...isPbValid];
          //  accum.validationResults = isPbValid;
          accum.isValid = isPbValid.length === 0;
          accum.eventUomUsed = { ...eventUom };
          accum.patternResults.push({
            pattern: eventUom.pattern,
            pb,
          });
        }
        return accum;
      },
      processPb
    );

    //  Check some stuff
    if (convertUserFormatErrors[processPb.pb]) {
      validationResults.push({
        name: convertUserFormatErrors[processPb.pb].NAME,
        message: convertUserFormatErrors[processPb.pb].MESSAGE,
      });
    }
    processPb.validationResults = [
      ...processPb.validationResults,
      ...validationResults,
    ];
    return processPb;
  }

  public processAthletePbFromUserInput(
    userFormatPb: string,
    perfInfo: IPerfInfo
  ): IProcessPb {
    let processPb: IProcessPb = {
      userText: userFormatPb,
      isValid: false,
      pb: 0,
      eventUomUsed: {} as IEventUom,
      validationResults: [],
      patternResults: [],
    };

    const validationResults: IValidationResult[] = [];
    const regOnlyNumbers = new RegExp(/^\d+$/);
    //  my regex skills aren't great, so stripping out non number chars allowed...
    const userFormatPbWithNoSeps = userFormatPb.replace(/[:.]/gi, "");
    const isOnlyNumbers = regOnlyNumbers.test(userFormatPbWithNoSeps);
    if (!isOnlyNumbers) {
      processPb.validationResults.push({
        name: ATHLETE_PB_VALIDATION_MESSAGES.ONLY_NUMBERS.NAME,
        message: ATHLETE_PB_VALIDATION_MESSAGES.ONLY_NUMBERS.MESSAGE,
      });
      return processPb;
    }

    //  now loop across any formats.
    processPb = perfInfo.uom.reduce(
      (accum: IProcessPb, eventUom: IEventUom) => {
        if (!accum.isValid) {
          const pb = this.convertUserFormatToSeconds(
            userFormatPb,
            eventUom.pattern
          );
          accum.pb = pb;
          const min = perfInfo.limits.min;
          const max = perfInfo.limits.max;
          const isPbValid = this.isAthletePbValid(pb, min, max, 0);
          accum.validationResults = [...accum.validationResults, ...isPbValid];
          accum.isValid = isPbValid.length === 0;
          accum.eventUomUsed = { ...eventUom };
          accum.patternResults.push({
            pattern: eventUom.pattern,
            pb,
          });
        }
        return accum;
      },
      processPb
    );

    //  Check some stuff
    if (convertUserFormatErrors[processPb.pb]) {
      validationResults.push({
        name: convertUserFormatErrors[processPb.pb].NAME,
        message: convertUserFormatErrors[processPb.pb].MESSAGE,
      });
    }
    processPb.validationResults = [
      ...processPb.validationResults,
      ...validationResults,
    ];
    return processPb;
  }

  public convertUserFormatToSeconds(
    userFormat: string,
    pbTimeFormat: string
  ): number {
    const DEBUG_THIS: boolean = false;
    if (DEBUG_THIS) {
      console.log(
        "======================= PBService.convertUserFormatToSeconds() userFormat: " +
          userFormat +
          ", pbTimeFormat: " +
          pbTimeFormat
      );
    }

    //  TODO remove text, only separator and numeric

    let userSep = this.getSeperator(userFormat);
    userSep = userSep === "" ? "." : userSep;
    if (DEBUG_THIS) {
      console.log("PBService.convertUserFormatToSeconds() userSep: ", userSep);
    }
    const isUserSepValid = this.isSeparatorValid(userSep);
    if (DEBUG_THIS) {
      console.log(
        "PBService.convertUserFormatToSeconds() isUserSepValid: ",
        isUserSepValid
      );
    }
    if (!isUserSepValid) {
      return -22;
    }
    const userFormatTypes = userFormat.split(userSep);
    if (DEBUG_THIS) {
      console.log(
        "PBService.convertUserFormatToSeconds() userFormatTypes: ",
        userFormatTypes
      );
    }
    const lenUserTypes: number = userFormatTypes.length;
    if (DEBUG_THIS) {
      console.log(
        "PBService.convertUserFormatToSeconds() lenUserTypes: " + lenUserTypes
      );
    }

    const pbTimeFormatSep = this.getSeperator(pbTimeFormat);
    const pbFormatTypes = pbTimeFormat.split(pbTimeFormatSep);
    if (DEBUG_THIS) {
      console.log(
        "PBService.convertUserFormatToSeconds() pbFormatTypes: ",
        pbFormatTypes
      );
    }
    const lenTypes: number = pbFormatTypes.length;
    if (DEBUG_THIS) {
      console.log(
        "PBService.convertUserFormatToSeconds() lenTypes: " + lenTypes
      );
    }

    if (lenUserTypes > lenTypes) {
      return -23;
    }

    let secs: number = 0;

    let i: number;
    //  Loop over lenUserTypes because user might enter 10 format is s:ss, meaning
    //  they just ran 100m in 10 flat.
    for (i = 0; i < lenUserTypes; i++) {
      const currTimeUnit: string = pbFormatTypes[i];
      let currUserUnit: string = userFormatTypes[i];
      let currSecs: number = 0;

      if (DEBUG_THIS) {
        console.log(
          "PBService.convertUserFormatToSeconds() currTimeUnit: " +
            currTimeUnit +
            ", currUserUnit: " +
            ", currSecs: " +
            currSecs
        );
      }

      // @see https://date-fns.org/v1.29.0/docs/format
      if (currTimeUnit === "SS") {
        if (currUserUnit.length === 0) {
          //  don.t do anything, use prob entered 9 or 9., as in 9 dead.
        }
        if (currUserUnit.length === 1) {
          //  E.g. "3"  ---> "30"
          currUserUnit = currUserUnit + "0";
        }
        if (currUserUnit.length === 2) {
          currSecs = Number(currUserUnit) / 100;
        }
      }

      if (currTimeUnit === "ss" || currTimeUnit === "s") {
        currSecs = Number(currUserUnit);
      }

      if (currTimeUnit === "mm" || currTimeUnit === "m") {
        currSecs = Number(currUserUnit) * 60;
      }

      if (currTimeUnit === "HH") {
        currSecs = Number(currUserUnit) * 3600;
      }
      //  secs = parseFloat((secs + currSecs).toFixed(2));
      secs = secs + currSecs;
      if (DEBUG_THIS) {
        console.log(
          "PBService.convertUserFormatToSeconds() currTimeUnit: " +
            currTimeUnit +
            ", currUserUnit: " +
            currUserUnit +
            ", currSecs: " +
            currSecs +
            ", secs: " +
            secs
        );
      }
    }

    return secs;
  }

  public convertUserFieldFormat(
    userFormat: string,
    event: IAthleteCompSched
  ): IProcessPb {
    //  console.log("===============userFormat: " + userFormat);

    const processPb: IProcessPb = {
      userText: userFormat,
      isValid: false,
      pb: 0,
      eventUomUsed: {} as IEventUom,
      validationResults: [],
      patternResults: [],
    };

    //  Check separator.
    let userSep = this.getSeperator(userFormat);
    userSep = userSep === "" ? "." : userSep;
    //  console.log("userSep: " + userSep);
    const isUserSepValid = this.isSeparatorValid(userSep);
    //  console.log("isUserSepValid: " + isUserSepValid);
    if (!isUserSepValid) {
      processPb.validationResults.push({
        name: ATHLETE_PB_VALIDATION_MESSAGES.INVALID_SEPARATOR.NAME,
        message: ATHLETE_PB_VALIDATION_MESSAGES.INVALID_SEPARATOR.MESSAGE,
      });
    }
    const userFormatTypes = userFormat.split(userSep);
    const lenUserTypes: number = userFormatTypes.length;
    //  console.log("lenUserTypes: " + lenUserTypes);
    if (lenUserTypes > 2) {
      processPb.validationResults.push({
        name: ATHLETE_PB_VALIDATION_MESSAGES.TOO_MANY_SEPARATORS.NAME,
        message: ATHLETE_PB_VALIDATION_MESSAGES.TOO_MANY_SEPARATORS.MESSAGE,
      });
    }

    let userFormatAsNumber: number = Number(userFormat);
    if (isNaN(userFormatAsNumber)) {
      //  console.log("isNaN: " + isNaN(userFormatAsNumber));
      processPb.validationResults.push({
        name: ATHLETE_PB_VALIDATION_MESSAGES.NOT_A_NUMBER.NAME,
        message: ATHLETE_PB_VALIDATION_MESSAGES.NOT_A_NUMBER.MESSAGE,
      });
    } else {
      const userFormatToPattern: string = parseFloat(userFormat).toFixed(2);
      userFormatAsNumber = Number(userFormatToPattern);
      processPb.pb = userFormatAsNumber;

      //  console.log("userFormatAsNumber: " + userFormatAsNumber);

      const isPbValid = this.isPbValid(event, userFormatAsNumber);
      //  console.log("isPbValid: ", isPbValid);
      if (isPbValid.length === 0) {
        processPb.isValid = true;
      } else {
        processPb.validationResults = [
          ...processPb.validationResults,
          ...isPbValid,
        ];
      }
    }
    return processPb;
  }

  public convertAthleteUserFieldFormat(
    userFormat: string,
    perfInfo: IPerfInfo,
    split: number
  ): IProcessPb {
    //  console.log("===============userFormat: " + userFormat);

    const processPb: IProcessPb = {
      userText: userFormat,
      isValid: false,
      pb: 0,
      eventUomUsed: {} as IEventUom,
      validationResults: [],
      patternResults: [],
    };

    //  Check separator.
    let userSep = this.getSeperator(userFormat);
    userSep = userSep === "" ? "." : userSep;
    //  console.log("userSep: " + userSep);
    const isUserSepValid = this.isSeparatorValid(userSep);
    //  console.log("isUserSepValid: " + isUserSepValid);
    if (!isUserSepValid) {
      processPb.validationResults.push({
        name: ATHLETE_PB_VALIDATION_MESSAGES.INVALID_SEPARATOR.NAME,
        message: ATHLETE_PB_VALIDATION_MESSAGES.INVALID_SEPARATOR.MESSAGE,
      });
    }
    const userFormatTypes = userFormat.split(userSep);
    const lenUserTypes: number = userFormatTypes.length;
    //  console.log("lenUserTypes: " + lenUserTypes);
    if (lenUserTypes > 2) {
      processPb.validationResults.push({
        name: ATHLETE_PB_VALIDATION_MESSAGES.TOO_MANY_SEPARATORS.NAME,
        message: ATHLETE_PB_VALIDATION_MESSAGES.TOO_MANY_SEPARATORS.MESSAGE,
      });
    }

    let userFormatAsNumber: number = Number(userFormat);
    if (isNaN(userFormatAsNumber)) {
      //  console.log("isNaN: " + isNaN(userFormatAsNumber));
      processPb.validationResults.push({
        name: ATHLETE_PB_VALIDATION_MESSAGES.NOT_A_NUMBER.NAME,
        message: ATHLETE_PB_VALIDATION_MESSAGES.NOT_A_NUMBER.MESSAGE,
      });
    } else {
      const userFormatToPattern: string = parseFloat(userFormat).toFixed(2);
      userFormatAsNumber = Number(userFormatToPattern);
      processPb.pb = userFormatAsNumber;

      //  console.log("userFormatAsNumber: " + userFormatAsNumber);

      // const isPbValid = this.isPbValid(event, userFormatAsNumber);
      //  console.log("isPbValid: ", isPbValid);
      // const pb = athletePb.pb ? athletePb.pb : 0;
      const min = perfInfo.limits.min;
      const max = perfInfo.limits.max;
      const isPbValid = this.isAthletePbValid(processPb.pb, min, max, split);
      if (isPbValid.length === 0) {
        processPb.isValid = true;
      } else {
        processPb.validationResults = [
          ...processPb.validationResults,
          ...isPbValid,
        ];
      }
    }
    return processPb;
  }

  public convertUserPointsFormat(
    userFormat: string,
    event: IAthleteCompSchedRuleEvent
  ): IProcessPb {
    const processPb: IProcessPb = {
      userText: userFormat,
      isValid: false,
      pb: 0,
      eventUomUsed: {} as IEventUom,
      validationResults: [],
      patternResults: [],
    };

    const userFormatAsNumber: number = Number(userFormat);
    if (isNaN(userFormatAsNumber)) {
      //  console.log("isNaN: " + isNaN(userFormatAsNumber));
      processPb.validationResults.push({
        name: ATHLETE_PB_VALIDATION_MESSAGES.NOT_A_NUMBER.NAME,
        message: ATHLETE_PB_VALIDATION_MESSAGES.NOT_A_NUMBER.MESSAGE,
      });
      return processPb;
    }
    if (userFormatAsNumber < 0) {
      processPb.validationResults.push({
        name: ATHLETE_PB_VALIDATION_MESSAGES.ONLY_POSITIVE_NUMBERS.NAME,
        message: ATHLETE_PB_VALIDATION_MESSAGES.ONLY_POSITIVE_NUMBERS.MESSAGE,
      });
      return processPb;
    }

    processPb.pb = userFormatAsNumber;
    const isPbValid = this.isPbValid(event, userFormatAsNumber);
    //  console.log("isPbValid: ", isPbValid);
    if (isPbValid.length === 0) {
      processPb.isValid = true;
    } else {
      processPb.validationResults = [
        ...processPb.validationResults,
        ...isPbValid,
      ];
    }
    return processPb;
  }

  public getSeperator(userFormat: string): string {
    const allowableSeparators: string[] = [".", ":"];
    const userSeparator: string = allowableSeparators.reduce(
      (accum: string, sep: string) => {
        if (String(userFormat).indexOf(sep) > -1) {
          //  Adding, because is user puts 1.10:13, they prob'y mean 1hr 10min 13secs, BUT
          //  they haven't followed format...major hassle.
          accum = accum + sep;
        }
        return accum;
      },
      ""
    );
    return userSeparator;
  }

  public isSeparatorValid(sep: string): boolean {
    return sep.length === 1;
  }

  public isPbValid(
    event: IAthleteCompSched,
    pb: null | number
  ): IValidationResult[] {
    const validationResults: IValidationResult[] = [];
    if (pb === 0 || pb === null) {
      return validationResults;
    }

    //  console.log("/////////////////////// pb: " + pb);

    const eoptions: IEoptions = event.eoptions;
    //  TODO some min\ max not in config from server.
    const pbMin: null | number = eoptions.min ? eoptions.min : 0;
    const pbMax: null | number = eoptions.max ? eoptions.max : null;
    //  console.log("isPbValid() pbMin: " + pbMin + ", pbMax: " + pbMax + ", pb: " + pb + ", split: " + event.split);

    if (pbMin !== null && pbMax !== null) {
      if (pb < 0) {
        //  console.log("isPbValid() pbMin: " + pbMin + ", pbMax: " + pbMax + ", pb: " + pb + " too small");
        validationResults.push({
          name: ATHLETE_PB_VALIDATION_MESSAGES.ONLY_POSITIVE_NUMBERS.NAME,
          message: ATHLETE_PB_VALIDATION_MESSAGES.ONLY_POSITIVE_NUMBERS.MESSAGE,
        });
      }

      if (pb < pbMin) {
        //  console.log("isPbValid() pbMin: " + pbMin + ", pbMax: " + pbMax + ", pb: " + pb + " too small");
        validationResults.push({
          name: ATHLETE_PB_VALIDATION_MESSAGES.PB_BELOW_MIN.NAME,
          message: ATHLETE_PB_VALIDATION_MESSAGES.PB_BELOW_MIN.MESSAGE,
        });
      }

      if (pb > pbMax) {
        // console.log("isPbValid() pbMin: " + pbMin + ", pbMax: " + pbMax + ", pb: " + pb + " too big");
        validationResults.push({
          name: ATHLETE_PB_VALIDATION_MESSAGES.PB_ABOVE_MIN.NAME,
          message: ATHLETE_PB_VALIDATION_MESSAGES.PB_ABOVE_MIN.MESSAGE,
        });
      }

      // If event has split and user is in pb tolerance, check if in split tolerance.
      if (event.split && event.split !== 0 && pb > pbMin && pb < pbMax) {
        const negativeSplit: boolean = event.split < 0;
        const actualSplit = negativeSplit ? event.split * -1 : event.split;
        //  console.log("isPbValid() negativeSplit: " + negativeSplit + ", pb: " + pb + ", split: " + event.split + ", actualSplit: " + actualSplit);
        if (negativeSplit && pb > actualSplit) {
          validationResults.push({
            name: ATHLETE_PB_VALIDATION_MESSAGES.PB_ABOVE_SPLIT.NAME,
            message: ATHLETE_PB_VALIDATION_MESSAGES.PB_ABOVE_SPLIT.MESSAGE,
          });
        }
        if (!negativeSplit && pb < actualSplit) {
          validationResults.push({
            name: ATHLETE_PB_VALIDATION_MESSAGES.PB_BELOW_SPLIT.NAME,
            message: ATHLETE_PB_VALIDATION_MESSAGES.PB_BELOW_SPLIT.MESSAGE,
          });
        }
      }
    }
    return validationResults;
  }

  public isAthletePbValid(
    pb: number,
    pbMin: number,
    pbMax: number,
    split: number
  ): IValidationResult[] {
    const validationResults: IValidationResult[] = [];
    if (pb === 0 || pb === null) {
      return validationResults;
    }

    if (pbMin > 0 && pbMax > 0) {
      if (pb < 0) {
        //  console.log("isPbValid() pbMin: " + pbMin + ", pbMax: " + pbMax + ", pb: " + pb + " too small");
        validationResults.push({
          name: ATHLETE_PB_VALIDATION_MESSAGES.ONLY_POSITIVE_NUMBERS.NAME,
          message: ATHLETE_PB_VALIDATION_MESSAGES.ONLY_POSITIVE_NUMBERS.MESSAGE,
        });
      }

      if (pb < pbMin) {
        //  console.log("isPbValid() pbMin: " + pbMin + ", pbMax: " + pbMax + ", pb: " + pb + " too small");
        validationResults.push({
          name: ATHLETE_PB_VALIDATION_MESSAGES.PB_BELOW_MIN.NAME,
          message: ATHLETE_PB_VALIDATION_MESSAGES.PB_BELOW_MIN.MESSAGE,
        });
      }

      if (pb > pbMax) {
        // console.log("isPbValid() pbMin: " + pbMin + ", pbMax: " + pbMax + ", pb: " + pb + " too big");
        validationResults.push({
          name: ATHLETE_PB_VALIDATION_MESSAGES.PB_ABOVE_MIN.NAME,
          message: ATHLETE_PB_VALIDATION_MESSAGES.PB_ABOVE_MIN.MESSAGE,
        });
      }

      // If event has split and user is in pb tolerance, check if in split tolerance.
      if (split !== 0 && pb > pbMin && pb < pbMax) {
        const negativeSplit: boolean = split < 0;
        const actualSplit = negativeSplit ? split * -1 : split;
        if (negativeSplit && pb > actualSplit) {
          validationResults.push({
            name: ATHLETE_PB_VALIDATION_MESSAGES.PB_ABOVE_SPLIT.NAME,
            message: ATHLETE_PB_VALIDATION_MESSAGES.PB_ABOVE_SPLIT.MESSAGE,
          });
        }
        if (!negativeSplit && pb < actualSplit) {
          validationResults.push({
            name: ATHLETE_PB_VALIDATION_MESSAGES.PB_BELOW_SPLIT.NAME,
            message: ATHLETE_PB_VALIDATION_MESSAGES.PB_BELOW_SPLIT.MESSAGE,
          });
        }
      }
    }
    return validationResults;
  }

  public getUserValidationMessage(processPb: IProcessPb, perfInfo: IPerfInfo) {
    //  Suppose we could let this be HTML...whatever.
    let message: string = "You entered: " + processPb.userText;

    // const eoptions: IEoptions = compEvent.eoptions;

    if (processPb.eventUomUsed && processPb.eventUomUsed.pattern !== "") {
      message += "  Enter PB like: " + processPb.eventUomUsed.pattern;
    }

    message += this.getEntryHelp(perfInfo);

    message += processPb.validationResults.reduce(
      (accum: string, validationResult: IValidationResult) => {
        accum += validationResult.message;
        return accum;
      },
      " Failures: "
    );

    message += processPb.patternResults.reduce(
      (accum: string, patternResult: IProcessPbPatternResult) => {
        accum +=
          "[" +
          patternResult.pattern +
          "] -> [" +
          this.convertSecondsToUserFormat(
            patternResult.pb,
            patternResult.pattern
          ) +
          "]";
        return accum;
      },
      " PBs tried: "
    );

    return message;
  }

  public getEntryHelp(perfInfo: IPerfInfo): string {
    let message =
      "Accepted pattern" +
      (perfInfo.uom.length > 1 ? "s" : "") +
      ": " +
      perfInfo.uom.map((uom) => {
        return uom.pattern;
      });

    const min = perfInfo.limits.min;
    const max = perfInfo.limits.max;

    message +=
      min > 0 || max > 0
        ? " Limits:" +
          (min > 0 ? " min " + min : "") +
          (max > 0 ? " max " + max : "")
        : "";
    return message;
  }

  public getEntryPatternsHelp(athletePb: IAthletePb) {
    return (
      "Accepted pattern" +
      (athletePb.uomInfo.options.length > 1 ? "s" : "") +
      ": " +
      athletePb.uomInfo.options
        .map((uom) => {
          return uom.pattern;
        })
        .join(", ") +
      " (m = minutes, s = seconds, S = 100th of seconds)"
    );
  }

  public getEntryLimitsHelp(athletePb: IAthletePb): string {
    let min = athletePb.min;
    min = typeof min === "string" ? Number(min) : min;

    let max = athletePb.max;
    max = typeof max === "string" ? Number(max) : max;

    if (min > 0 || max > 0) {
      let minMessage = "";
      if (min > 0) {
        minMessage =
          "min " +
          this.convertSecondsToUserFormat(
            min,
            athletePb.uomInfo.options[0].pattern
          );
      }
      let maxMessage = "";
      if (max > 0) {
        maxMessage =
          "max " +
          this.convertSecondsToUserFormat(
            max,
            athletePb.uomInfo.options[0].pattern
          );
      }
      // " Event Limit:" + (min > 0 ? " min " + min : "") + (max > 0 ? " max " + max : "")
      return (
        " Event Limit:" +
        (minMessage.length > 0 ? " " + minMessage : "") +
        (maxMessage.length > 0 ? " " + maxMessage : "")
      );
    }
    return "";

    // return min > 0 || max > 0 ?  " Event Limit:" + (min > 0 ? " min " + min : "") + (max > 0 ? " max " + max : "") : "";
  }

  public isPbEditable(
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ): boolean {
    //  Get other similar events
    const similarEvents: IAthleteCompSchedRuleEvent[] = events.filter((evt) => {
      return evt.eventid === event.eventid && evt.ceid !== event.ceid;
    });
    // console.log("isPbValid() similarEvents.length: " + similarEvents.length);
    if (similarEvents.length > 0) {
      // console.log("isPbValid() similarEvents.length: " + similarEvents.length + ", event.userSelected: " + event.userSelected);
      //  we have more than 1 similar events, if another
      //  one is selected...the other has to be de-selected.  Else, we get into weird
      //  scenarios where user selects sn event, then edits PB on another which makes
      //  currently selected not select-able.
      const otherselectedEvents: IAthleteCompSchedRuleEvent[] =
        similarEvents.filter((evt) => {
          return evt.userSelected;
        });
      // console.log("isPbValid() otherselectedEvents.length: " + otherselectedEvents.length);
      if (otherselectedEvents.length > 0) {
        return false;
      }
    }
    return true;
  }

  public getUomPatternLength(event: IAthleteCompSchedRuleEvent): number {
    return event.perfInfo.uom.reduce((accum, oum) => {
      if (oum.pattern.length > 0) {
        const patternLength = oum.pattern.split(".").length;
        if (patternLength > accum) {
          accum = patternLength;
        }
      }
      return accum;
    }, 0);
  }

  public uomPatternHas(
    compEvent: IAthleteCompSchedRuleEvent,
    patternPiece: string
  ): boolean {
    return this.uomsPatternHas(compEvent.perfInfo.uom, patternPiece);
  }

  public athleteUomPatternHas(
    athletePb: IAthletePb,
    patternPiece: string
  ): boolean {
    return this.uomsPatternHas(athletePb.uomInfo.options, patternPiece);
  }

  public uomsPatternHas(uoms: IEventUom[], patternPiece: string): boolean {
    return uoms.reduce((accum: boolean, oum: IEventUom) => {
      if (oum.pattern.length > 0) {
        const patternContains = oum.pattern.indexOf(patternPiece);
        if (patternContains > -1) {
          accum = true;
        }
      }
      return accum;
    }, false);
  }

  public getFormattedUserPb(compEvent: IAthleteCompSchedRuleEvent): string {
    if (compEvent.pb === null || compEvent.pb === undefined) {
      return "";
    }

    if (
      [EVENT_TYPE.TRACK, EVENT_TYPE.ROAD, EVENT_TYPE.CROSS_COUNTRY].indexOf(
        compEvent.tf
      ) > -1
    ) {
      if (compEvent.perfInfo.uom && compEvent.perfInfo.uom.length > 0) {
        return this.convertSecondsToUserFormat(
          compEvent.pb,
          compEvent.perfInfo.uom[0].pattern
        );
      }
    }
    return compEvent.pb.toString();
  }

  public getAthleteFormattedUserPb(perfInfo: IPerfInfo): string {
    if (perfInfo.perf === 0) {
      return "";
    }

    const uomInfoType = perfInfo.uom[0].uomType;
    // if (athletePb.uomInfo.type === "T") {
    //  TODO T = Time, R = Road...but R is event Type and should not be here, this is an HF.
    if (["T", "R"].indexOf(uomInfoType) > -1) {
      return this.convertSecondsToUserFormat(
        perfInfo.perf,
        perfInfo.uom[0].pattern
      );
    }
    return perfInfo.perf.toString();
  }

  public getPbPlaceholders(
    pbUserEntry: string,
    compEvent: IAthleteCompSched
  ): Record<string, string> {
    return this.getPbPlaceholdersFromUoms(pbUserEntry, compEvent.perfInfo.uom);
  }

  public getAthletePbPlaceholders(
    pbUserEntry: string,
    perfInfo: IPerfInfo
  ): Record<string, string> {
    return this.getPbPlaceholdersFromUoms(pbUserEntry, perfInfo.uom);
  }

  public getPbPlaceholdersFromUoms(
    pbUserEntry: string,
    uoms: IEventUom[]
  ): Record<string, string> {
    const res: Record<string, string> = {
      m: "",
      s: "",
      S: "",
      input: "",
    };
    // const resNew: Record<string, string> = {};

    if (pbUserEntry.length > 0 && uoms.length > 0) {
      const defaultPattern = uoms[0].pattern;
      const patternLength: number = defaultPattern.split(".").length;

      res.input = pbUserEntry;

      const pbUserEntryArray = pbUserEntry.split(".");
      const pbUserEntryArrayLength = pbUserEntryArray.length;

      if (patternLength === pbUserEntryArrayLength) {
        const timePortions = [];
        if (defaultPattern.indexOf("m") > -1) {
          timePortions.push("m");
        }
        if (defaultPattern.indexOf("s") > -1) {
          timePortions.push("s");
        }
        if (defaultPattern.indexOf("S") > -1) {
          timePortions.push("S");
        }

        timePortions.forEach((key, index) => {
          res[key] = pbUserEntryArray[index];
        });
      } else {
        console.log("EditTimePb.loadUserPb() how lay out PB?");
      }
    }

    return res;
  }

  public hasAccessToPo10(athlete: IAthleteSummary): boolean {
    if (!athlete.URN) {
      return false;
    }
    const hasUrn: boolean = athlete.URN.toString().length > 0;
    return hasUrn && athlete.aocode === "EA";
  }
}

let pbServiceInternal: PBService | null = null;

export function usePbService(): PBService {
  if (pbServiceInternal === null) {
    pbServiceInternal = new PBService();
  }
  return pbServiceInternal;
}
