import {IHelpData} from "./field-help-store"
import {ResourceData} from "../../../resource/resource-service"
import https from "../../../https"
import {IServerResponse} from "../../../common-models"
import {CONFIG} from "../../../config"

export const FieldHelpDataUrlPath = CONFIG.E4S_HOST + "/wp-json/e4s" + "/v5/help";
// https://dev.entry4sports.co.uk/wp-json/e4s/v5/help/waiting-list

export class FieldHelpData extends ResourceData<IHelpData> {

    constructor() {
        super(FieldHelpDataUrlPath);
    }

    public readHelp(key: string): Promise<IServerResponse<IHelpData>> {
        return https.get( this.getEndPoint() + "/" + key.toString()) as Promise<IServerResponse<IHelpData>>;
    }
}


