







import { defineComponent, SetupContext } from "@vue/composition-api";

export default defineComponent({
  name: "counter-v2",
  components: {},
  props: {
    text: {
      type: String,
      default: () => {
        return "Counter";
      },
    },
    counter: {
      type: [String, Number],
      default: () => {
        return "0";
      },
    },
  },
  setup(props: { text: string; counter: string }, context: SetupContext) {
    return {};
  },
});
