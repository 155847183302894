import {
  IServerGenericResponse,
  IServerResponse,
} from "../common/common-models";
import https from "../common/https";
import {
  IAthleteCompSched,
  IAthleteCompSchedResponse,
  IProdIdServerResponse,
} from "./athletecompsched-models";
import { ICartWooCommerce } from "../secondary/secondary-models";

export class AthleteCompSchedData {
  public getCompSchedEventsForAthlete(
    competitionId: number,
    athleteId: number,
    clubId: number
  ): Promise<IAthleteCompSchedResponse> {
    if (competitionId === undefined || athleteId === undefined) {
      return Promise.resolve({} as IAthleteCompSchedResponse);
    }
    // return https.get( "/v5/events/" + competitionId + "/" + athleteId + "/" + clubId) as any as Promise<IAthleteCompSchedResponse>;
    return https.get(
      "/v5/events/" + competitionId + "/" + athleteId
    ) as any as Promise<IAthleteCompSchedResponse>;
  }

  public getProdIdFor(
    ceid: number,
    athleteId: number,
    clubId: number,
    teamId: number,
    perf: number
  ): Promise<IProdIdServerResponse> {
    teamId = teamId ? teamId : 0;
    return https.put(
      "/v5/product/" +
        ceid +
        "/" +
        athleteId +
        "/" +
        clubId +
        "/" +
        teamId +
        "?pb=" +
        perf
    ) as any as Promise<IProdIdServerResponse>;
  }

  public removeFromBasket(prodId: number): Promise<IServerGenericResponse> {
    return https.post(
      "/v5/product/delete/" + prodId
    ) as any as Promise<IServerGenericResponse>;
  }

  public addToBasket(prodIds: number[]): Promise<IServerGenericResponse> {
    const payload = {
      products: prodIds,
    };
    console.warn("AthleteCompSchedData.addToBasket() payload", payload);
    return https
      .post("/v5/basket/add", payload)
      .then((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return error;
      });
  }

  public getCart(
    userId?: number
  ): Promise<
    IServerResponse<IAthleteCompSched[], { wcCart: ICartWooCommerce }>
  > {
    return https.get("/v5/cart" + (userId ? "?id=" + userId : ""));
    //     return Promise.resolve({
    //         "errNo": 0,
    //         "error": "Success",
    //         "data": [],
    //         "meta": {
    //             "wcCart": {
    //                 "url": "https://dev.entry4sports.co.uk/basket/",
    //                 "currency": "&euro;",
    //                 "items": []
    //             }
    //         }
    //     })
  }

  public switchEvent(
    entryId: number,
    ceid: number
  ): Promise<IServerGenericResponse> {
    return https.post("v5/entry/switch", {
      entryid: entryId,
      ceid,
    }) as any as Promise<IServerGenericResponse>;
  }

  public setPaid(prodIds: number[], paid: number, message: string) {
    return https.post("v5/entry/paid", {
      prodIds,
      paid,
      message,
    }) as any as Promise<IServerGenericResponse>;
  }

  public cancelEvent(
    entryId: number,
    reason: string
  ): Promise<IServerGenericResponse> {
    return https.post("v5/entry/cancel/" + entryId, {
      reason,
    }) as any as Promise<IServerGenericResponse>;
  }

  public addEntryToUsersCart(
    prodId: number,
    userId: number
  ): Promise<IServerGenericResponse> {
    return https.post("v5/entry/addtobasket", {
      productid: prodId,
      userid: userId,
    }) as any as Promise<IServerGenericResponse>;
  }
}
