import { format, isAfter, isBefore, parse } from "date-fns";
import * as R from "ramda";
import { IAthlete, IAthletePb } from "../athlete/athlete-models";
import {
  EventGroupIdNumber,
  IObjectKey,
  IObjectKeyType,
} from "../common/common-models";
import {
  IUserMessage,
  USER_MESSAGE_LEVEL,
} from "../user-message/user-message-models";
import {
  EVENT_TYPE,
  EVENT_UOM_TYPE,
  IAgeInfo,
  IAthleteCompSched,
  IAthleteCompSchedResponse,
  IAthleteCompSchedRuleEvent,
  ICeoptions,
  ICompEventEntryCount,
  ICompShedRuleConfig,
  ICompShedRuleOptions,
  ICompUnique,
  IEoptions,
  IEventCheckIn,
  IEventGroupInfo,
  IEventGroupObject,
  IEventGroupSelect,
  IEventTeam,
  IEventTeamCe,
  IFormRow,
  IHeatInfo,
  IMultiEventEventDef,
  IMultiEventOptions,
  IOptionsDefault,
  IOrder,
  IOrderSummary,
  IPbKey,
  IPrice,
  IRowOptions,
  ISchedInfo,
  ISchedInfoDetail,
  ISecondarySpend,
  ISecurity,
  IUnique,
  IUser,
  RULE_TYPE,
  TEAM_NAME_FORMAT_TYPES,
} from "./athletecompsched-models";
import { CommonService } from "../common/common-service";
import { PBService } from "./pb-service";
import { AthleteService } from "../athlete/athlete-service";
import { AgeGroupService } from "../agegroup/agegroup-service";
import {
  ICompetition,
  ICompetitionInfo,
} from "../competition/competition-models";
import {
  IEntity,
  IUserApplication,
  IUserInfo,
} from "../config/config-app-models";
import { ConfigService } from "../config/config-service";
import { isNil, simpleClone } from "../common/common-service-utils";
import { ICompEvent } from "../compevent/compevent-models";
import { factoryPerfInfo } from "./pb/v3/pb-service-v3";
import { IPerfInfo } from "./pb/v3/edit-pb-v3-models";

export const RULE_MESSAGES = {
  COMP_TEAM_MAX_ENTRY: {
    message: "Event full",
  },
  COMP_TEAM_CROSS_AGE_GROUP_SLOT_MAX: {
    message: "Under age quota full.",
  },
};

const commonService: CommonService = new CommonService();
const pbService: PBService = new PBService();
const athleteService: AthleteService = new AthleteService();
const ageGroupService: AgeGroupService = new AgeGroupService();
const configService = new ConfigService();

export class AthleteCompSchedService {
  public factory(): IAthleteCompSched {
    return {
      ceid: 0,
      ageGroupId: 0,
      ageGroupName: "",
      ageGroup: ageGroupService.factoryGetAgeGroup(),
      eventGroup: "",
      athleteid: 0,
      description: "",
      startdate: "",
      tf: EVENT_TYPE.TRACK,
      saleprice: 0,
      price: this.factoryPrice(),
      saledate: "",
      name: "",
      eventid: 0,
      multiid: 0,
      entryId: 0,
      Name: "",
      IsOpen: 0,
      split: 0,
      maxgroup: 0,
      maxathletes: 0,
      entrycnt: 0,
      entryInfo: this.factoryCompEventEntryCount(),
      order: this.factoryOrder(),
      ceoptions: this.factoryCeOptions(),
      eoptions: this.factoryEOptions(),
      eventGroupObject: this.factoryEventGroupObject(),
      entered: false,
      paid: 0,
      pb: null,
      perfInfo: factoryPerfInfo(),
      uomType: EVENT_UOM_TYPE.DISTANCE,
      uomDisplay: "",
      uomformat: "",
      firstName: "",
      surName: "",
      URN: "",
      user: this.factoryUser(),
      club: "",
      clubId: 0,
      teamId: 0,
      timeSelected: "",
      ageInfo: this.factoryAgeInfo(),
      athletes: [],

      userEventAction: {
        id: "NONE",
      },
    };
  }

  public factoryCompEventEntryCount(): ICompEventEntryCount {
    return {
      entryPosition: 0,
      paidCount: 0,
      unpaidCount: 0,
      totalCount: 0,
      entryCreated: "",
    };
  }

  public factoryEventGroupObject(): IEventGroupObject {
    return {
      id: 0,
      name: "",
      isOpen: true,
      maxAthletes: 0,
      maxInHeat: 0,
      trials: "",
      reportInfo: "",
      seed: {
        age: false,
        gender: false,
        type: "O",
      },
    };
  }

  public factoryOrder(): IOrder {
    return {
      creditValue: 0,
      dateOrdered: "",
      e4sLineValue: 0,
      isCredit: false,
      isRefund: false,
      orderId: 0,
      productId: 0,
      refundValue: 0,
      wcLineValue: 0,
    };
  }

  public factoryAthleteCompSchedRuleEvent(): IAthleteCompSchedRuleEvent {
    return {
      ...this.factory(),
      ruleIsDisabledBy: false,
      ruleMessage: "",
      userSelected: false,
      ruleType: RULE_TYPE.NONE,
      post_date: "",
      perfInfo: factoryPerfInfo(),
    };
  }

  public factoryUser(): IUser {
    return {
      userId: 0,
      userName: "",
      userEmail: "",
    };
  }

  public factoryAgeInfo(): IAgeInfo {
    return {
      ageGroup: "",
      ageGroupId: 0,
      currentAge: 0,
      vetAgeGroup: "",
      vetAgeGroupId: 0,
    };
  }

  public factoryOptionsDefault(): IOptionsDefault {
    return {
      helpText: "",
      warningMessage: "",
      rowOptions: undefined,
      unique: [],
      uniqueEventGroups: [],
      eventTeam: this.factoryEventTeam(),
      excludeFromCntRule: false,
      isTeamEvent: false,
      maxInHeat: 0,
      unregisteredAthletes: true,
      registeredAthletes: true,
    };
  }

  public factoryEOptions(): IEoptions {
    return {
      ...this.factoryOptionsDefault(),
      min: 0,
      max: 0,
      unit: "",
      class: "",
      gender: false,
      multiEventOptions: [],
    };
  }

  public factoryHeatInfo(): IHeatInfo {
    return {
      useLanes: "A",
      heatDurationMins: 0,
    };
  }

  public factoryEventGroupInfo(): IEventGroupInfo {
    return {
      reportInfo: "",
      trialInfo: "",
    };
  }

  public factoryCeOptions(): ICeoptions {
    return {
      ...this.factoryOptionsDefault(),
      xeText: "",
      xbText: "",
      xiText: "",
      xrText: "",
      maxAgeGroupCnt: 0,
      secondarySpend: this.factorySecondarySpend(),
      eventTeam: this.factoryEventTeamCe(),
      ageGroups: [],
      availableFrom: "",
      availableFromStatus: 0,
      availableTo: "",
      availableToStatus: 0,
      hideOnDisable: false,
      security: this.factorySecurity(),
      athleteSecurity: this.factorySecurity(),
      isNotAvailable: false,
      singleAge: true,
      trials: "",
      cardNotes: "",
      athleteEventCol: "",
      mandatoryPB: false,
      checkIn: this.factoryEventCheckIn(),
      heatInfo: this.factoryHeatInfo(),
      eventGroupInfo: this.factoryEventGroupInfo(),
      seed: {
        gender: false,
        age: false,
        type: "O",
        qualifyToEg: {
          id: 0,
          name: "",
          eventNo: 0,
          isMultiEvent: false,
        },
        laneCount: 8,
        firstLane: 1,
        waiting: false,
      },
      entriesFrom: {
        id: 0,
        name: "",
        eventNo: 0,
        isMultiEvent: false,
      },
      includeEntriesFromEgId: {
        id: 0,
        name: "",
        eventNo: 0,
        isMultiEvent: false,
      },
      multiEventOptions: this.factoryMultiEventOptions(),
      rowOptions: this.factoryRowOptions(),
    };
  }

  public factoryChildEvent(): IMultiEventEventDef {
    return {
      eventDefId: 0,
      name: "",
      egId: 0,
    };
  }

  public factoryMultiEventOptions(): IMultiEventOptions {
    return {
      childEvents: [],
    };
  }

  public factoryEventCheckIn(): IEventCheckIn {
    return {
      from: -1,
      to: -1,
    };
  }

  public factorySecondarySpend(): ISecondarySpend {
    return {
      isParent: false,
      parentCeid: 0,
      mandatoryGroupId: 0,
      alwaysShow: true,
    };
  }

  public factoryEventTeam(): IEventTeam {
    return {
      min: 0,
      max: 0,
      minTargetAgeGroupCount: 0,
      maxOtherAgeGroupCount: 0,
      maxTeamsForAthlete: 0,
      teamPositionLabel: "",
      teamSubstituteLabel: "",
      maxEventTeams: 0,
      currCount: 0,
      singleClub: true,
      showForm: false,
      formType: "DEFAULT",
      price: "",
      teamNameFormat: this.getDefaultTeamNameFormat(),
      formRows: [],
      mustBeIndivEntered: false,
      disableTeamNameEdit: false,
    };
  }

  public factoryEventTeamCe(): IEventTeamCe {
    return {
      ...this.factoryEventTeam(),
      ageGroupUpScale: [],
    };
  }

  public factorySecurity(): ISecurity {
    return {
      clubs: [],
      counties: [],
      regions: [],
    };
  }

  public factoryAthleteCompSchedResponse(): IAthleteCompSchedResponse {
    return {
      errNo: 0,
      error: "",
      events: [],
      ageInfo: this.factoryAgeInfo(),
      rules: this.factoryCompShedRuleConfig(),
      schedInfo: this.factorySchedInfo(),
      subscriptionText: "",
    };
  }

  public factoryCompShedRuleConfig(): ICompShedRuleConfig {
    return {
      id: 0,
      agid: 0,
      options: this.factoryCompShedRuleOptions(),
    };
  }

  public factoryCompShedRuleOptions(): ICompShedRuleOptions {
    return {
      maxEvents: 0,
      maxField: 0,
      maxTrack: 0,
      maxCompEvents: 0,
      maxCompField: 0,
      maxCompTrack: 0,
      unique: [],
      maxExcludedEvents: 0,
      maxTeamEvents: 0,
      // type: "",
    };
  }

  public factorySchedInfo(): ISchedInfo {
    return {
      title: "",
      shortDescription: "",
      autoExpand: true,
      showLinks: false,
      schedInfoDetails: [],
      rowOptions: this.factoryRowOptions(),
    };
  }

  public factorySchedInfoDetail(): ISchedInfoDetail {
    return {
      title: "",
      body: "",
    };
  }

  public factoryRowOptions(): IRowOptions {
    return {
      autoExpandHelpText: true,
      showPB: true,
      showPrice: true,
      showEntryCount: true,
      hideOnDisable: false,
    };
  }

  public factoryFormRow(): IFormRow {
    return {
      position: -1,
      eventDef: {
        id: 0,
        name: "",
      },
      dateTime: "",
      athlete: athleteService.factoryAthleteSummary(),
    };
  }

  public factoryPrice(): IPrice {
    return {
      id: 0,
      curPrice: 0,
      curFee: 0,
      saleDate: "",
      salePrice: 0,
      stdPrice: 0,
      actualPrice: 0,
    };
  }

  public getDefaultTeamNameFormat(): string {
    return (
      TEAM_NAME_FORMAT_TYPES.ENTITY +
      " " +
      TEAM_NAME_FORMAT_TYPES.EVENT +
      " " +
      TEAM_NAME_FORMAT_TYPES.GENDER +
      " " +
      TEAM_NAME_FORMAT_TYPES.AGE_GROUP
    );
  }

  public tidyUpServerResponse(
    athleteCompSchedResponse: IAthleteCompSchedResponse
  ): IAthleteCompSchedResponse {
    //  console.warn("AthleteCompSchedService.tidyUpServerResponse() DEPRECATED!!");
    athleteCompSchedResponse = { ...athleteCompSchedResponse };
    let athleteCompScheds: IAthleteCompSched[] = [
      ...athleteCompSchedResponse.events,
    ];

    athleteCompScheds = athleteCompScheds.map((event: IAthleteCompSched) => {
      //  TBH, haven#t tracked this down.
      try {
        event.eoptions = JSON.parse(event.eoptions as any) as IEoptions;
      } catch (error) {
        //  console.log("ERROR parse tidyUpServerResponse()", event.eoptions);
      }
      // event.ceoptions = JSON.parse(event.ceoptions as any) as ICeoptions;
      return event;
    });
    athleteCompSchedResponse.events = athleteCompScheds;

    //  TODO options can come out as a string "{}".
    let compUnique: ICompUnique[] =
      athleteCompSchedResponse.rules.options.unique;
    compUnique = compUnique.map((compUni: ICompUnique) => {
      compUni = { ...compUni };
      if (!Array.isArray(compUni.ids)) {
        compUni.ids = (compUni.ids + "").split(",").map(Number);
      }
      return compUni;
    });
    athleteCompSchedResponse.rules.options.unique = compUnique;

    return athleteCompSchedResponse;
  }

  public factoryEventsToEventRule = (
    athleteCompSched: IAthleteCompSched[]
  ): IAthleteCompSchedRuleEvent[] => {
    if (!athleteCompSched) {
      return [];
    }
    return athleteCompSched.map(this.factoryEventToEventRule);
  };

  public addAthleteDetailsToEventDEPRECATED = (
    event: IAthleteCompSchedRuleEvent,
    athlete: IAthlete
  ): IAthleteCompSchedRuleEvent => {
    // return {
    //     ...event,
    //     firstName: athlete.firstName,
    //     surName: athlete.surName,
    //     URN: athlete.URN
    // };
    return event;
  };

  public toggleEventInSelectedList = (
    event: IAthleteCompSchedRuleEvent,
    eventsSelected: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    eventsSelected = [...eventsSelected];

    if (event === null || event === undefined) {
      return eventsSelected;
    }
    eventsSelected = this.removeEventFromSelected(event, eventsSelected);
    if (event.userSelected) {
      return eventsSelected;
    }
    event.timeSelected = format(new Date());
    eventsSelected.push(event);

    //  We want the newest events selected sitting at the top, when displayed in cart, easier for user.
    // @ts-ignore
    // const goldComparator = R.comparator((a, b) =>
    //   R.gt(R.prop("timeSelected", a), R.prop("timeSelected", b))
    // );
    //
    // eventsSelected = R.sort(goldComparator, eventsSelected);

    eventsSelected = commonService.sortArray("timeSelected", eventsSelected);

    return eventsSelected;
  };

  public removeEventFromSelected = (
    event: IAthleteCompSchedRuleEvent,
    eventsSelected: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    eventsSelected = [...eventsSelected];

    if (event === null || event === undefined) {
      return eventsSelected;
    }
    const userEventKey = this.getUserEventKey(event);
    // const userEventKey = this.getSimilarEventsByProdIdKey(event);
    eventsSelected = eventsSelected.filter((evt) => {
      const evtUserEventKey = this.getUserEventKey(evt);
      // const evtUserEventKey = this.getSimilarEventsByProdIdKey(event);
      return !(userEventKey === evtUserEventKey);
    });
    return eventsSelected;
  };

  public removeEventFromSelectedByProdId = (
    event: IAthleteCompSchedRuleEvent,
    eventsSelected: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    eventsSelected = [...eventsSelected];

    if (event === null || event === undefined) {
      return eventsSelected;
    }
    // const userEventKey = this.getUserEventKey(event);
    const userEventKey = this.getSimilarEventsByProdIdKey(event);
    eventsSelected = eventsSelected.filter((evt) => {
      // const evtUserEventKey = this.getUserEventKey(evt);
      const evtUserEventKey = this.getSimilarEventsByProdIdKey(evt);
      return !(userEventKey === evtUserEventKey);
    });
    return eventsSelected;
  };

  public forceIntoSelected = (
    event: IAthleteCompSchedRuleEvent,
    eventsSelected: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    //  E.g. events already entered but not paid, don't set as userSelected.
    eventsSelected = [...eventsSelected];
    if (event === null || event === undefined) {
      return eventsSelected;
    }

    //  If already userSelected, should exist in list to remove...but if we
    //  are adding, it shouldn't already be in list, and if it is...it has
    //   got in the wrong order...so remove it, we will re-add.
    eventsSelected = this.removeEventFromSelected(event, eventsSelected);
    event.userSelected = true;
    event.timeSelected = format(new Date());
    eventsSelected.push(event);
    return eventsSelected;
  };

  public enableForAdminButLeaveMessage(
    athleteCompSchedRuleEvent: IAthleteCompSchedRuleEvent
  ): IAthleteCompSchedRuleEvent {
    const ruleEvent = { ...athleteCompSchedRuleEvent };
    ruleEvent.ruleIsDisabledBy = false;
    ruleEvent.ruleType = RULE_TYPE.NONE;
    return ruleEvent;
  }

  public debugFilter(
    compEvents: IAthleteCompSchedRuleEvent[],
    eventGroupKey: string
  ): string {
    const internal = simpleClone(compEvents);
    const cEvent = internal.find((compEvent) => {
      return compEvent.eventGroup === eventGroupKey;
    });
    return cEvent ? cEvent.eventGroup + ": " + cEvent.ruleMessage : "N/A";
  }

  public processEventsTest(
    athlete: IAthlete,
    pbKey: IPbKey,
    compRule: ICompShedRuleConfig,
    event: null | IAthleteCompSchedRuleEvent,
    eventsSelected: IAthleteCompSchedRuleEvent[],
    events: IAthleteCompSchedRuleEvent[],
    userApplication: IUserApplication,
    ageInfo: IAgeInfo,
    competition: ICompetition,
    userInfo?: IUserInfo
  ): { events: IAthleteCompSchedRuleEvent[]; debug: any } {
    const userEventKey =
      event === null || event === undefined ? "" : this.getUserEventKey(event);

    athlete = { ...athlete };
    pbKey = { ...pbKey };
    compRule = { ...compRule };
    event = event ? { ...event } : null;
    // eventsSelected = [...eventsSelected];
    eventsSelected = simpleClone(eventsSelected);
    // events = [...events];
    events = simpleClone(events);
    ageInfo = { ...ageInfo };

    const debug: any = {};

    // console.log("processEvents()...a userSelected: " + (event ? event.userSelected : "undefined"));

    events = this.removeAllRules(events);

    const userEntities: IEntity[] = userInfo
      ? configService.getEntitiesFromUserInfo(userInfo)
      : [];
    // const hasBuilderPermissionForComp = userInfo ? configService.hasBuilderPermissionForComp(userInfo, (competition as ICompetitionInfo).compOrgId, competition.id) : false;

    // events = events.map((evt) => {
    //     return this.addAthleteDetailsToEvent(evt, athlete);
    // })
    // console.log(userEntities.length);
    events = events
      .map((evt) => {
        if (this.hasEventSecurity(evt) && userEntities.length === 0) {
          //  An Indiv event has security on it and user does not have any entities, it shouldn't
          //  be coming up to ui, so disable it.
          evt.ruleIsDisabledBy = true;

          const hasClubSecurity =
            evt.ceoptions.security.clubs &&
            evt.ceoptions.security.clubs.length > 0
              ? "CLUB"
              : "";
          const hasCountySecurity =
            evt.ceoptions.security.counties &&
            evt.ceoptions.security.counties.length > 0
              ? "COUNTY"
              : "";

          const securityMessage = [hasClubSecurity, hasCountySecurity]
            .filter((et) => {
              return et.length > 0;
            })
            .join(", ");

          evt.ruleMessage =
            "Entry by " + securityMessage + " representative only";
          evt.ruleType = RULE_TYPE.EVENT;
        }
        return evt;
      })
      .map((evt) => {
        // if (evt.order && evt.order.productId && (evt.order.productId > 0)) {
        //     evt.userSelected = true;
        // }
        evt.userSelected =
          evt.order && evt.order.productId && evt.order.productId > 0
            ? true
            : false;
        return evt;
      })
      .map((evt) => {
        return this.ruleApplyHasEntered(evt, userApplication);
      })
      .map((evt) => {
        return this.ruleEventIsScheduleOnly(evt);
      });

    if (
      !competition.options.subscription ||
      !competition.options.subscription.enabled
    ) {
      events = events.map((compEvent) => {
        return this.applyMaxReachedRule(compEvent);
      });
    }

    // events = events.map((evt) => {
    //     return this.ruleApplyIsOpen(evt, competition);
    // });

    events = this.applyRulesToEvents(events, competition);

    events = events
      .map((evt) => {
        return this.applyPbRule(evt);
      })
      .map((evt) => {
        const evtUserEventKey = this.getUserEventKey(evt);
        if (
          event !== undefined &&
          event !== null &&
          userEventKey === evtUserEventKey
        ) {
          //  if (event !== undefined && event !== null && evt.ceid === event.ceid) {
          //  N.B. toggling the selection
          return this.setSelectedEvent(evt, !evt.userSelected);
        }
        return evt;
      })
      .map((evt) => {
        return this.setUserChecked(evt, eventsSelected);
      });
    // .map((evt) => {
    //     return this.applyRulesTeamCompEvent(evt, ageInfo, eventsSelected);
    // });

    const findEventPassedInFilter = events.filter((evt) => {
      const ev = event ? event : { ceid: 0 };
      return ev.ceid === evt.ceid;
    });
    const eventPassedIn = findEventPassedInFilter[0]
      ? findEventPassedInFilter[0]
      : null;
    if (eventPassedIn && !eventPassedIn.userSelected) {
      const assocSecondarySpend = this.getAssociatedSecondarySpendEvents(
        eventPassedIn,
        events
      );
      if (assocSecondarySpend.length > 0) {
        events = this.deselectAssociatedSecondarySpend(eventPassedIn, events);
      }
    }

    events = this.compRuleMaxExcludedEvents(compRule, events);

    //  <debug=================>
    debug.a800m = this.debugFilter(events, "800m");
    debug.a100m = this.debugFilter(events, "100m");
    //  </debug=================>

    debug.w_2 = "";

    const eventsPaid = events.filter((evt) => {
      // return evt.paid > 0;
      return evt.paid > 0;
    });
    eventsPaid.map((evt) => {
      debug.w_3 = "";

      events = this.applyRulesToEventsFromSelected(evt, events);
      events = this.applyCompRulesToEventsFromSelected(
        compRule,
        evt,
        events,
        athlete
      );
      events = this.compRuleMaxExcludedEvents(compRule, events);
    });

    //  <debug=================>
    debug.b800m = this.debugFilter(events, "800m");
    debug.b100m = this.debugFilter(events, "100m");
    //  </debug=================>

    //  In order events were selected, reapply rules.  Important!  Since order records
    //  selected will dictate in order which rules apply.
    const eventsInCart = events.filter((evt) => {
      return evt.order.productId > 0 && evt.paid === 0;
    });

    eventsInCart.map((evt) => {
      // eventsSelected.map((evt) => {

      debug.w_4 = "";

      events = this.applyRulesToEventsFromSelected(evt, events);
      events = this.applyCompRulesToEventsFromSelected(
        compRule,
        evt,
        events,
        athlete
      );
      events = this.compRuleMaxExcludedEvents(compRule, events);
    });

    //  <debug=================>
    debug.c800m = this.debugFilter(events, "800m");
    debug.c100m = this.debugFilter(events, "100m");
    //  </debug=================>

    //  process things in cart
    // .filter((evt) => {
    //     return evt.paid > 0 && evt.order.productId && evt.order.productId > 0;
    //   })
    events.forEach((evt) => {
      events = this.applyEventGroupUnique(evt, events);
    });

    //  <debug=================>
    debug.d800m = this.debugFilter(events, "800m");
    //  </debug=================>

    // events = events.map( (cEvent) => {
    //     return hasBuilderPermissionForComp ? this.enableForAdminButLeaveMessage(cEvent) : cEvent;
    // })

    return {
      events,
      debug,
    };
  }

  public processEvents = (
    athlete: IAthlete,
    pbKey: IPbKey,
    compRule: ICompShedRuleConfig,
    event: null | IAthleteCompSchedRuleEvent,
    eventsSelected: IAthleteCompSchedRuleEvent[],
    events: IAthleteCompSchedRuleEvent[],
    userApplication: IUserApplication,
    ageInfo: IAgeInfo,
    competition: ICompetition,
    userInfo?: IUserInfo
  ): IAthleteCompSchedRuleEvent[] => {
    const userEventKey =
      event === null || event === undefined ? "" : this.getUserEventKey(event);

    athlete = { ...athlete };
    pbKey = { ...pbKey };
    compRule = { ...compRule };
    event = event ? { ...event } : null;
    // eventsSelected = [...eventsSelected];
    eventsSelected = [];
    events = [...events];
    ageInfo = { ...ageInfo };

    // console.log("processEvents()...a userSelected: " + (event ? event.userSelected : "undefined"));

    events = this.removeAllRules(events);

    const userEntities: IEntity[] = userInfo
      ? configService.getEntitiesFromUserInfo(userInfo)
      : [];
    // const hasBuilderPermissionForComp = userInfo ? configService.hasBuilderPermissionForComp(userInfo, (competition as ICompetitionInfo).compOrgId, competition.id) : false;

    // console.log(userEntities.length);
    events = events
      .map((evt) => {
        if (this.hasEventSecurity(evt) && userEntities.length === 0) {
          //  An Indiv event has security on it and user does not have any entities, it shouldn't
          //  be coming up to ui, so disable it.
          evt.ruleIsDisabledBy = true;

          const hasClubSecurity =
            evt.ceoptions.security.clubs &&
            evt.ceoptions.security.clubs.length > 0
              ? "CLUB"
              : "";
          const hasCountySecurity =
            evt.ceoptions.security.counties &&
            evt.ceoptions.security.counties.length > 0
              ? "COUNTY"
              : "";

          const securityMessage = [hasClubSecurity, hasCountySecurity]
            .filter((et) => {
              return et.length > 0;
            })
            .join(", ");

          evt.ruleMessage =
            "Entry by " + securityMessage + " representative only";
          evt.ruleType = RULE_TYPE.EVENT;
        }
        return evt;
      })
      .map((evt) => {
        //  TODO we don't need this anymore ??????
        evt.userSelected =
          evt.order && evt.order.productId && evt.order.productId > 0
            ? true
            : false;
        return evt;
      })
      .map((evt) => {
        return this.ruleApplyHasEntered(evt, userApplication);
      })
      .map((evt) => {
        return this.ruleEventIsScheduleOnly(evt);
      });

    if (
      !competition.options.subscription ||
      !competition.options.subscription.enabled
    ) {
      events = events.map((compEvent) => {
        return this.applyMaxReachedRule(compEvent);
      });
    }

    // events = events.map((evt) => {
    //     return this.ruleApplyIsOpen(evt, competition);
    // });

    events = this.applyRulesToEvents(events, competition);

    events = events
      .map((evt) => {
        return this.applyPbRule(evt);
      })
      .map((evt) => {
        const evtUserEventKey = this.getUserEventKey(evt);
        if (
          event !== undefined &&
          event !== null &&
          userEventKey === evtUserEventKey
        ) {
          //  if (event !== undefined && event !== null && evt.ceid === event.ceid) {
          //  N.B. toggling the selection
          return this.setSelectedEvent(evt, !evt.userSelected);
        }
        return evt;
      })
      .map((evt) => {
        return this.setUserChecked(evt, eventsSelected);
      });

    const findEventPassedInFilter = events.filter((evt) => {
      const ev = event ? event : { ceid: 0 };
      return ev.ceid === evt.ceid;
    });
    const eventPassedIn = findEventPassedInFilter[0]
      ? findEventPassedInFilter[0]
      : null;
    if (eventPassedIn && !eventPassedIn.userSelected) {
      const assocSecondarySpend = this.getAssociatedSecondarySpendEvents(
        eventPassedIn,
        events
      );
      if (assocSecondarySpend.length > 0) {
        events = this.deselectAssociatedSecondarySpend(eventPassedIn, events);
      }
    }

    events = this.compRuleMaxExcludedEvents(compRule, events);

    const eventsPaid = events.filter((evt) => {
      return evt.paid > 0;
    });
    eventsPaid.map((evt) => {
      events = this.applyRulesToEventsFromSelected(evt, events);
      events = this.applyCompRulesToEventsFromSelected(
        compRule,
        evt,
        events,
        athlete
      );
      events = this.compRuleMaxExcludedEvents(compRule, events);
    });

    //  In order events were selected, reapply rules.  Important!  Since order records
    //  selected will dictate in order which rules apply.

    //  If an event is "UserSelected"...it HAS a product ID and MUST be in the cart but is not paid!
    const eventsInCart = events.filter((evt) => {
      return evt.order.productId > 0 && evt.paid === 0;
    });

    eventsInCart.map((evt) => {
      events = this.applyRulesToEventsFromSelected(evt, events);
      events = this.applyCompRulesToEventsFromSelected(
        compRule,
        evt,
        events,
        athlete
      );
      events = this.compRuleMaxExcludedEvents(compRule, events);
    });

    events.forEach((evt) => {
      events = this.applyEventGroupUnique(evt, events);
    });

    return events;
  };

  public processEventsNew = (
    athlete: IAthlete,
    compRuleForAthleteAgeGroup: ICompShedRuleConfig | null,
    event: null | IAthleteCompSchedRuleEvent,
    eventsSelected: IAthleteCompSchedRuleEvent[],
    events: IAthleteCompSchedRuleEvent[],
    userApplication: IUserApplication,
    competition: ICompetition
  ): IAthleteCompSchedRuleEvent[] => {
    const userEventKey =
      event === null || event === undefined ? "" : this.getUserEventKey(event);

    // const pbKey: IPbKey = this.getPersonalBestAsKeyObject(athlete.pbInfo);

    events = R.clone(events);
    events = this.removeAllRules(events);

    events = events
      .map((evt) => {
        return this.ruleApplyHasEntered(evt, userApplication);
      })
      .map((evt) => {
        return this.ruleEventIsScheduleOnly(evt);
      });

    if (
      !competition.options.subscription ||
      !competition.options.subscription.enabled
    ) {
      events = events.map((compEvent) => {
        return this.applyMaxReachedRule(compEvent);
      });
    }

    // events = events.map((evt) => {
    //     return this.ruleApplyIsOpen(evt, competition);
    // });

    events = this.applyRulesToEvents(events, competition);

    events = events
      .map((evt) => {
        return this.applyPbRule(evt);
      })
      .map((evt) => {
        const evtUserEventKey = this.getUserEventKey(evt);
        if (
          event !== undefined &&
          event !== null &&
          userEventKey === evtUserEventKey
        ) {
          //  if (event !== undefined && event !== null && evt.ceid === event.ceid) {
          //  N.B. toggling the selection
          return this.setSelectedEvent(evt, !evt.userSelected);
        }
        return evt;
      })
      .map((evt) => {
        return this.setUserChecked(evt, eventsSelected);
      });

    const findEventPassedInFilter = events.filter((evt) => {
      const ev = event ? event : { ceid: 0 };
      return ev.ceid === evt.ceid;
    });
    const eventPassedIn = findEventPassedInFilter[0]
      ? findEventPassedInFilter[0]
      : null;
    if (eventPassedIn && !eventPassedIn.userSelected) {
      const assocSecondarySpend = this.getAssociatedSecondarySpendEvents(
        eventPassedIn,
        events
      );
      if (assocSecondarySpend.length > 0) {
        events = this.deselectAssociatedSecondarySpend(eventPassedIn, events);
      }
    }

    if (compRuleForAthleteAgeGroup) {
      events = this.compRuleMaxExcludedEvents(
        compRuleForAthleteAgeGroup,
        events
      );
    }

    const eventsPaid = events.filter((evt) => {
      return evt.paid > 0;
    });
    eventsPaid.map((evt) => {
      events = this.applyRulesToEventsFromSelected(evt, events);
      if (compRuleForAthleteAgeGroup) {
        events = this.applyCompRulesToEventsFromSelected(
          compRuleForAthleteAgeGroup,
          evt,
          events,
          athlete
        );
        events = this.compRuleMaxExcludedEvents(
          compRuleForAthleteAgeGroup,
          events
        );
      }
    });

    // In order events were selected, reapply rules.  Important!  Since order records
    // selected will dictate in order which rules apply.
    eventsSelected.map((evt) => {
      events = this.applyRulesToEventsFromSelected(evt, events);
      if (compRuleForAthleteAgeGroup) {
        events = this.applyCompRulesToEventsFromSelected(
          compRuleForAthleteAgeGroup,
          evt,
          events,
          athlete
        );
        events = this.compRuleMaxExcludedEvents(
          compRuleForAthleteAgeGroup,
          events
        );
      }
    });
    return events;
  };

  public applyRulesToEvents = (
    athleteCompSchedRuleEvents: IAthleteCompSchedRuleEvent[],
    comp: ICompetition
  ): IAthleteCompSchedRuleEvent[] => {
    const applyRulesToEvent = (compEvent: IAthleteCompSchedRuleEvent) => {
      return this.applyRulesToEvent(compEvent, comp);
    };
    return athleteCompSchedRuleEvents.map(applyRulesToEvent);
  };

  public applyRulesToEvent = (
    athleteCompSchedRuleEvent: IAthleteCompSchedRuleEvent,
    comp: ICompetition
  ): IAthleteCompSchedRuleEvent => {
    //  TODO need Ramda\ lodash fpo, so we can compose this stuff.
    //  athleteCompSchedRuleEvent = this.ruleApplyHasEntered(athleteCompSchedRuleEvent);
    athleteCompSchedRuleEvent = this.ruleApplyIsOpen(
      athleteCompSchedRuleEvent,
      comp
    );
    athleteCompSchedRuleEvent = this.ruleEventIsScheduleOnly(
      athleteCompSchedRuleEvent
    );
    athleteCompSchedRuleEvent = this.ruleTeamEventClosed(
      athleteCompSchedRuleEvent
    );
    return athleteCompSchedRuleEvent;
  };

  public applyRulesToEventsFromSelected = (
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    events = [...events];
    events = this.applyEventGroupUnique(event, events);
    events = this.ruleUnique(event, events);
    events = this.ruleCompUnique(event, events);
    return events;
  };

  /**
   * E.g. Enter the "Group" 1500m, you can't enter the "Group" 800m
   * @param event
   * @param events
   */
  public applyEventGroupUnique(
    athleteCompSchedRuleEvent: IAthleteCompSchedRuleEvent,
    athleteCompSchedRuleEvents: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] {
    if (
      !athleteCompSchedRuleEvent.ceoptions.uniqueEventGroups ||
      athleteCompSchedRuleEvent.ceoptions.uniqueEventGroups.length === 0
    ) {
      return athleteCompSchedRuleEvents;
    }

    const eventGroupsToDisable =
      athleteCompSchedRuleEvent.ceoptions.uniqueEventGroups.filter(
        (eventGroupSelect) => {
          // check if event group ids match
          return (
            eventGroupSelect.id !==
            (athleteCompSchedRuleEvent.maxgroup as any as number)
          );
        }
      );

    const eventGroupIdsToDisable = eventGroupsToDisable.map((compEvent) => {
      return compEvent.id;
    });

    // const athleteCompSchedRuleEventsThatMatch: IAthleteCompSchedRuleEvent[] =
    //   athleteCompSchedRuleEvents.filter((compEvent) => {
    //     return eventGroupIdsToDisable.indexOf(
    //       compEvent.maxgroup as any as number
    //     );
    //   });

    athleteCompSchedRuleEvents.map((compEvent) => {
      const isEventGroupMatch =
        eventGroupIdsToDisable.indexOf(compEvent.maxgroup as any as number) >
        -1;
      // const isAgeGroupMatch =
      //   athleteCompSchedRuleEvent.ageGroup.id === compEvent.ageGroup.id;

      const isUniqueEventSelectedOrPaid =
        athleteCompSchedRuleEvent.order &&
        athleteCompSchedRuleEvent.order.productId > 0;

      // if (isEventGroupMatch && isAgeGroupMatch && isUniqueEventSelectedOrPaid) {
      if (isEventGroupMatch && isUniqueEventSelectedOrPaid) {
        compEvent.ruleIsDisabledBy = true;
        compEvent.ruleMessage =
          "Discipline disabled due to: " + athleteCompSchedRuleEvent.eventGroup;
        // compEvent.ceoptions.uniqueEventGroups
        //   .filter((eventGroup) => {
        //     return eventGroup.name !== athleteCompSchedRuleEvent.eventGroup;
        //   })
        //   .map((eventGroup) => {
        //     return eventGroup.name;
        //   });
        // compEvent.ruleMessage =
        //     "Discipline disabled due to: " +
        //     eventGroupsToDisable.map((evtG) => {
        //       return evtG.name;
        //     });
        compEvent.ruleType = RULE_TYPE.EVENT;
      }
      return compEvent;
    });

    return athleteCompSchedRuleEvents;
  }

  public applyEventGroupUniqueTemp(
    athleteCompSchedRuleEvent: IAthleteCompSchedRuleEvent,
    athleteCompSchedRuleEvents: IAthleteCompSchedRuleEvent[]
  ): any {
    let debug: any = null;

    if (
      !athleteCompSchedRuleEvent.ceoptions.uniqueEventGroups ||
      athleteCompSchedRuleEvent.ceoptions.uniqueEventGroups.length === 0
    ) {
      return athleteCompSchedRuleEvents;
    }

    const eventGroupsToDisable =
      athleteCompSchedRuleEvent.ceoptions.uniqueEventGroups.filter(
        (eventGroupSelect) => {
          // check if event group ids match
          return (
            eventGroupSelect.id !==
            (athleteCompSchedRuleEvent.maxgroup as any as number)
          );
        }
      );

    const eventGroupIdsToDisable = eventGroupsToDisable.map((compEvent) => {
      // const id = typeof compEvent === "number" ? compEvent : compEvent.id;
      return compEvent.id;
    });

    debug = [];
    athleteCompSchedRuleEvents.map((compEvent) => {
      const isEventGroupMatch =
        eventGroupIdsToDisable.indexOf(compEvent.maxgroup as any as number) >
        -1;

      // const isAgeGroupMatch =
      //   athleteCompSchedRuleEvent.ageGroup.id === compEvent.ageGroup.id;

      const isUniqueEventSelectedOrPaid =
        athleteCompSchedRuleEvent.paid > 0 ||
        athleteCompSchedRuleEvent.userSelected;

      if (isEventGroupMatch && isUniqueEventSelectedOrPaid) {
        compEvent.ruleIsDisabledBy = true;
        compEvent.ruleMessage =
          "Discipline disabled due to: " + athleteCompSchedRuleEvent.eventGroup;
        compEvent.ruleType = RULE_TYPE.EVENT;
      }

      debug.push(compEvent.ageGroup.id);

      return compEvent;
    });

    debug = 1;

    return debug;
  }

  public ruleUnique = (
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    //  This rule makes sure that only 1 eventId can be selected...
    events = [...events];
    let uniqueIds: number[] = [];
    if (event.eoptions === null) {
      //  Gaahhhh!!!  F-ing back end model changing.
      return events;
    }

    if (
      event.eoptions &&
      event.eoptions.unique &&
      event.eoptions.unique !== undefined
    ) {
      uniqueIds = event.eoptions.unique.map((ieoptionsEvent: IUnique) => {
        return ieoptionsEvent.e;
      });
    }

    if (uniqueIds.length === 0) {
      return events;
    }

    events.map((evt: IAthleteCompSchedRuleEvent) => {
      if (evt.athleteid !== event.athleteid) {
        return evt;
      }
      if (evt.eventid === event.eventid && evt.ceid === event.ceid) {
        return evt;
      }
      if (evt.ruleIsDisabledBy) {
        return evt;
      }
      if (evt.userSelected) {
        return evt;
      }

      const isMatch = uniqueIds.indexOf(evt.eventid) > -1;
      if (isMatch) {
        evt.ruleIsDisabledBy = isMatch;
        evt.ruleMessage = this.hasSecondarySpend(event)
          ? "1.Disabled due to: " + event.Name
          : "1.Event disabled due to: " + event.Name;
        evt.ruleType = RULE_TYPE.EVENT;
      }
      return evt;
    });
    return events;
  };

  public ruleCompUnique = (
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    events = [...events];
    let uniqueCeids: number[] = [];

    if (event.ceoptions === null) {
      //  Gaahhhh!!!  F-ing back end model changing.
      return events;
    }

    if (
      event.ceoptions &&
      event.ceoptions.unique &&
      event.ceoptions.unique !== undefined
    ) {
      uniqueCeids = event.ceoptions.unique.map((ieoptionsEvent: IUnique) => {
        return ieoptionsEvent.ce;
      });
    }

    if (uniqueCeids.length === 0) {
      return events;
    }

    events.map((evt: IAthleteCompSchedRuleEvent) => {
      if (evt.ruleIsDisabledBy) {
        return evt;
      }
      if (evt.athleteid !== event.athleteid) {
        return evt;
      }
      if (evt.eventid === event.eventid && evt.ceid === evt.ceid) {
        return evt;
      }
      const isMatch = uniqueCeids.indexOf(evt.ceid) > -1;
      if (isMatch) {
        evt.ruleIsDisabledBy = isMatch;
        evt.ruleMessage = this.hasSecondarySpend(event)
          ? "2.Disabled due to: " + event.Name
          : "2.Event disabled due to: " + event.Name;
        evt.ruleType = RULE_TYPE.EVENT;
      }
      return evt;
    });
    return events;
  };

  public findMatchingById = (
    id: number,
    athleteCompScheds: IAthleteCompSched[]
  ) => {
    const athleteCompSchedsFiltered = athleteCompScheds.filter(
      (athleteCompSched: IAthleteCompSched) => {
        return athleteCompSched.eventid === id;
      }
    );
    return athleteCompSchedsFiltered;
  };

  public setUserChecked(
    event: IAthleteCompSchedRuleEvent,
    eventsSelected: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent {
    const eventAlreadySelected = eventsSelected.filter((evt) => {
      return evt.ceid === event.ceid && evt.athleteid === event.athleteid;
    });
    if (eventAlreadySelected.length > 0) {
      event.userSelected = true;
    }
    return event;
  }

  public ruleTeamCompEventMinReached(
    event: IAthleteCompSchedRuleEvent,
    eventsCart: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent {
    //  E.g. 4 x 200 U13, at specific comp, they might allow U11s toal so enter this event, BUT...
    //  it must have at least x athletes from the U13.
    if (event.ruleIsDisabledBy) {
      return event;
    }
    const isCompTeamEventWithMin: boolean =
      this.isTeamEventWithMaxAgeGroupCount(event);

    if (!isCompTeamEventWithMin) {
      return event;
    }
    return event;
  }

  public getTeamCompMinAgeGroupCnt(event: IAthleteCompSchedRuleEvent): number {
    return event.ceoptions && event.ceoptions.maxAgeGroupCnt
      ? event.ceoptions.maxAgeGroupCnt
      : 0;
  }

  public getTeamEventMax(event: IAthleteCompSchedRuleEvent): number {
    //  If prop is "" or does not exist, returns undefined, so can't simplify this statement.
    return event.eoptions &&
      event.eoptions.eventTeam &&
      event.eoptions.eventTeam.max
      ? event.eoptions.eventTeam.max
      : 0;
  }

  public getTeamCompEventMax(event: IAthleteCompSchedRuleEvent): number {
    //  If prop is "" or does not exist, returns undefined, so can't simplify this statement.
    return event.ceoptions &&
      event.ceoptions.eventTeam &&
      event.ceoptions.eventTeam.max
      ? event.ceoptions.eventTeam.max
      : 0;
  }

  public isTeamEventWithMaxAgeGroupCount(
    event: IAthleteCompSchedRuleEvent
  ): boolean {
    //  If prop is "" or does not exist, returns undefined, so can't simplify this statement.
    return event.ceoptions &&
      event.ceoptions.maxAgeGroupCnt &&
      event.ceoptions.maxAgeGroupCnt > 0
      ? true
      : false;
  }

  public getEventTeamOptions(event: IAthleteCompSchedRuleEvent) {
    if (this.isTeamCompEvent(event)) {
      return event.ceoptions.eventTeam;
    }
    return event.eoptions && event.eoptions.eventTeam
      ? event.eoptions.eventTeam
      : null;
  }

  public isTeamEvent(event: IAthleteCompSchedRuleEvent): boolean {
    //  If prop is "" or does not exist, returns undefined, so can't simplify this statement.
    if (this.isTeamCompEvent(event)) {
      return true;
    }
    return event.eoptions && event.eoptions.isTeamEvent
      ? event.eoptions.isTeamEvent
      : false;
  }

  public isTeamCompEvent(event: IAthleteCompSchedRuleEvent): boolean {
    //  If prop is "" or does not exist, returns undefined, so can't simplify this statement.
    return event.ceoptions && event.ceoptions.isTeamEvent
      ? event.ceoptions.isTeamEvent
      : false;
  }

  public applyRulesTeamCompEvent(
    event: IAthleteCompSchedRuleEvent,
    ageInfo: IAgeInfo,
    eventsCart: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent {
    if (event.ruleIsDisabledBy) {
      return event;
    }
    if (!this.isTeamEvent(event)) {
      console.log("====applyRulesTeamCompEvent not team event");
      return event;
    }
    const similarEventsInCart = eventsCart.filter((evt) => {
      return evt.ceid === event.ceid;
    });
    // console.log("applyRulesTeamCompEvent()...similarEventsInCart: " + similarEventsInCart.length);
    event = this.ruleTeamCompEventMaxReached(event, similarEventsInCart);

    //  Athlete is in this age group
    if (event.ageGroupId === ageInfo.ageGroupId) {
      return event;
    }

    //  E.g. user is U11 but at a comp where they can also enter an U13 event.
    const availableSlots = this.availableTeamCompSlots(
      event,
      ageInfo,
      similarEventsInCart
    );
    console.log("====availableSlots: " + availableSlots);
    if (availableSlots === 0) {
      event.ruleIsDisabledBy = true;
      event.ruleMessage =
        RULE_MESSAGES.COMP_TEAM_CROSS_AGE_GROUP_SLOT_MAX.message;
      event.ruleType = RULE_TYPE.EVENT;
    }
    return event;
  }

  //  TODO should be named availableTeamCompSlotsForOtherAgeGroups
  public availableTeamCompSlots(
    event: IAthleteCompSchedRuleEvent,
    ageInfo: IAgeInfo,
    similarEventsInCart: IAthleteCompSchedRuleEvent[]
  ): number {
    const cartEventsOtherAgeGroup = similarEventsInCart.filter((evt) => {
      if (evt.ageInfo) {
        return evt.ageInfo.ageGroupId !== event.ageGroupId;
      }
      return evt.ageGroupId !== event.ageGroupId;
    });

    //  If no event.ceoptions.minAgeGroupCnt, then there is a back end config issue.
    const maxOtherAgeGroupCnt = this.getTeamCompMinAgeGroupCnt(event);
    const availableSlots = maxOtherAgeGroupCnt - cartEventsOtherAgeGroup.length;
    return availableSlots;
  }

  public ruleTeamCompEventMaxReached(
    event: IAthleteCompSchedRuleEvent,
    similarEventsInCart: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent {
    event = { ...event };
    if (event.ruleIsDisabledBy) {
      return event;
    }
    const eventTeam = this.getEventTeamOptions(event);
    if (!eventTeam) {
      return event;
    }
    if (similarEventsInCart.length >= eventTeam.max) {
      // console.log("ruleTeamCompEventMaxReached()...max reached");
      event.ruleIsDisabledBy = true;
      event.ruleMessage =
        RULE_MESSAGES.COMP_TEAM_MAX_ENTRY.message +
        " " +
        eventTeam.max +
        " athletes.";
      event.ruleType = RULE_TYPE.EVENT;
    }
    return event;
  }

  public ruleApplyHasEntered = (
    athleteCompSched: IAthleteCompSchedRuleEvent,
    userApplication: IUserApplication
  ): IAthleteCompSchedRuleEvent => {
    athleteCompSched = { ...athleteCompSched };
    //  If already false, then leave it...
    if (athleteCompSched.ruleIsDisabledBy) {
      return athleteCompSched;
    }
    // else, if user
    if (athleteCompSched.entryId > 0) {
      athleteCompSched.ruleIsDisabledBy = true;
      /*
      athleteCompSched.ruleMessage =
        athleteCompSched.paid === 0
          ? "Awaiting Payment by: " + athleteCompSched.user.userName
          : athleteCompSched.order && athleteCompSched.order.e4sLineValue &&
            athleteCompSched.order.e4sLineValue === 0
          ? "Entry Confirmed"
          : "Entry Confirmed"; //  Entry Paid
      */
      athleteCompSched.ruleMessage =
        athleteCompSched.paid === 0
          ? "Awaiting Payment by: " + athleteCompSched.user.userName
          : "Entry Confirmed"; //  Entry Paid
      athleteCompSched.ruleType = RULE_TYPE.EVENT;

      if (
        athleteCompSched.paid === 0 &&
        userApplication.id === athleteCompSched.user.userId
      ) {
        athleteCompSched.ruleIsDisabledBy = false;
        athleteCompSched.ruleMessage = "In cart";
        athleteCompSched.ruleType = RULE_TYPE.NONE;
      }

      return athleteCompSched;
    }
    return athleteCompSched;
  };

  public ruleApplyIsOpen = (
    athleteCompSched: IAthleteCompSchedRuleEvent,
    competition: ICompetition
  ): IAthleteCompSchedRuleEvent => {
    let ruleMessage: string = "";

    athleteCompSched = { ...athleteCompSched };
    //  If already false, then leave it...
    if (athleteCompSched.ruleIsDisabledBy) {
      return athleteCompSched;
    }

    if (
      athleteCompSched.ceoptions &&
      athleteCompSched.ceoptions.isNotAvailable
    ) {
      athleteCompSched.ruleIsDisabledBy = true;
      athleteCompSched.ruleMessage = "Event Not Available";
      athleteCompSched.ruleType = RULE_TYPE.EVENT;
      return athleteCompSched;
    }

    //  Is it closed...
    if (athleteCompSched.IsOpen !== 1) {
      // const isEventOverSubscribed = this.isEventOverSubscribed(athleteCompSched);
      // const isSubscriptionKeepingOpen = this.isSubscriptionKeepingOpen(athleteCompSched, competition);
      // console.log("ruleApplyIsOpen() evt:" + athleteCompSched.Name + ", isEventOverSubscribed: " + isEventOverSubscribed + ", isSubscriptionKeepingOpen: " + isSubscriptionKeepingOpen);

      //  Max was 10, back end sends closed, if we isEventOverSubscribed()...that is false!!
      // if (this.isEventOverSubscribed(athleteCompSched) && this.isSubscriptionKeepingOpen(athleteCompSched, competition)) {
      if (this.isSubscriptionKeepingOpen(athleteCompSched, competition)) {
        return athleteCompSched;
      }

      athleteCompSched.ruleIsDisabledBy = true;
      const hasSecondarySpend = this.hasSecondarySpend(athleteCompSched);

      ruleMessage = hasSecondarySpend ? "Item unavailable" : "Event Closed";
      athleteCompSched.ruleType = RULE_TYPE.EVENT;

      if (
        athleteCompSched.ceoptions.entriesFrom &&
        athleteCompSched.ceoptions.entriesFrom.id > 0
      ) {
        ruleMessage = "Part of: " + athleteCompSched.ceoptions.entriesFrom.name;
      }

      athleteCompSched.ruleMessage = ruleMessage;
      return athleteCompSched;
    } else {
      if (
        competition.options.subscription?.enabled &&
        this.isEventOverSubscribed(athleteCompSched) &&
        !this.isBeforeSubscriptionCutOff(competition)
      ) {
        athleteCompSched.ruleIsDisabledBy = true;
        athleteCompSched.ruleMessage = "Waiting List now closed";
        athleteCompSched.ruleType = RULE_TYPE.EVENT;
        return athleteCompSched;
      }
    }
    return athleteCompSched;
  };

  public ruleEventIsScheduleOnly = (
    athleteCompSched: IAthleteCompSchedRuleEvent
  ): IAthleteCompSchedRuleEvent => {
    athleteCompSched = { ...athleteCompSched };
    //  If already false, then leave it...
    if (athleteCompSched.ruleIsDisabledBy) {
      //  ...but server setting this as "Event Closed : Organiser Access"
      if (
        athleteCompSched.ceoptions.entriesFrom &&
        athleteCompSched.ceoptions.entriesFrom.id > 0
      ) {
        athleteCompSched.ruleMessage =
          "Part of: " + athleteCompSched.ceoptions.entriesFrom.name;
      }

      return athleteCompSched;
    }
    // else, if not "entereable"
    if (athleteCompSched.maxathletes === -1) {
      athleteCompSched.ruleIsDisabledBy = true;
      athleteCompSched.ruleMessage = "Schedule Only";
      athleteCompSched.ruleType = RULE_TYPE.EVENT;
      return athleteCompSched;
    }
    return athleteCompSched;
  };

  public ruleTeamEventClosed = (
    athleteCompSched: IAthleteCompSchedRuleEvent
  ): IAthleteCompSchedRuleEvent => {
    // athleteCompSched = {...athleteCompSched};
    //  If already false, then leave it...
    // if (athleteCompSched.ruleIsDisabledBy) {
    //     return athleteCompSched;
    // }
    // ...the team event, e.g. 4 x 100m relay, is full.
    // const eventTeam = this.getEventTeamOptions(athleteCompSched);
    // if (eventTeam && (eventTeam.currCount >= eventTeam.max)) {
    //     athleteCompSched.ruleIsDisabledBy = true;
    //     athleteCompSched.ruleMessage = "Team Event Closed";
    //     athleteCompSched.ruleType = RULE_TYPE.EVENT;
    //     return athleteCompSched;
    // }
    return athleteCompSched;
  };

  public applyCompRulesToEventsFromSelected = (
    compRule: ICompShedRuleConfig,
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[],
    athlete: Pick<IAthlete, "id" | "firstName">
  ): IAthleteCompSchedRuleEvent[] => {
    // events = [...events];
    let eventsInternal = simpleClone(events);

    eventsInternal = this.compRuleMax(compRule, event, eventsInternal, athlete);
    eventsInternal = this.compRuleMaxInDay(compRule, event, eventsInternal);
    eventsInternal = this.compRuleTrackFieldMaxInDay(
      compRule,
      event.tf,
      event.startdate.substring(0, 10),
      event,
      eventsInternal
    );
    eventsInternal = this.compRuleUnique(compRule, event, eventsInternal);

    return eventsInternal;
  };

  public removeExcludeFromCountRule(
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] {
    return events.filter((evt) => {
      return !this.hasExcludeFromCountRule(evt);
    });
  }

  public hasExcludeFromCountRule(event: IAthleteCompSchedRuleEvent): boolean {
    if (
      event.ceoptions &&
      event.ceoptions !== null &&
      event.ceoptions.excludeFromCntRule !== undefined &&
      event.ceoptions.excludeFromCntRule !== null
    ) {
      return event.ceoptions.excludeFromCntRule;
    }
    if (
      event.eoptions &&
      event.eoptions !== null &&
      event.eoptions.excludeFromCntRule !== undefined &&
      event.eoptions.excludeFromCntRule !== null
    ) {
      return event.eoptions.excludeFromCntRule;
    }
    return false;
  }

  public compRuleMaxExcludedEvents(
    compRule: ICompShedRuleConfig,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] {
    // console.log("........compRuleMaxExcludedEvents()", {compRule, events});

    events = R.clone(events);
    const maxExcludedEvents =
      compRule && compRule.options && compRule.options.maxExcludedEvents
        ? compRule.options.maxExcludedEvents
        : 0;

    const aleadySelectedExludedEvents = events.filter((evt) => {
      // console.log("........compRuleMaxExcludedEvents() a..." + evt.Name + " userSel: " + evt.userSelected + ", hasExl: " + this.hasExcludeFromCountRule(evt), {evt});
      return (
        (evt.userSelected || evt.entered) && this.hasExcludeFromCountRule(evt)
      );
    });

    // console.log("........aleadySelectedExludedEvents() aleadySelectedExludedEvents.length: " + aleadySelectedExludedEvents.length, {aleadySelectedExludedEvents});
    if (
      maxExcludedEvents > 0 &&
      aleadySelectedExludedEvents.length >= maxExcludedEvents
    ) {
      return events.map((evt, index) => {
        // console.log("........compRuleMaxExcludedEvents().....index: " + index);
        if (evt.ruleIsDisabledBy) {
          return evt;
        }
        if (!evt.userSelected && this.hasExcludeFromCountRule(evt)) {
          evt = this.compRuleMaxExcludedEventsSetFlag(evt);
        }
        return evt;
      });
    }
    return events;
  }

  public compRuleMaxExcludedEventsSetFlag(event: IAthleteCompSchedRuleEvent) {
    event.ruleIsDisabledBy = true;
    event.ruleMessage = "Comp rule, max entered (team)";
    event.ruleType = RULE_TYPE.COMP;
    return event;
  }

  public compRuleMax = (
    compRule: ICompShedRuleConfig,
    event: IAthleteCompSchedRuleEvent,
    eventsProp: IAthleteCompSchedRuleEvent[],
    athlete: Pick<IAthlete, "id" | "firstName">
  ): IAthleteCompSchedRuleEvent[] => {
    let events = simpleClone(eventsProp);
    const dateIso = "";
    const currentEnteredEvents = this.getEventsEnteredForDate(dateIso, events);
    const getSelectedEvents = this.getSelectedEvents(dateIso, events);

    //  but u could have an event in grid and in cart...so remove dups.
    const uniqueEvents: IAthleteCompSchedRuleEvent[] = [
      ...currentEnteredEvents,
      ...getSelectedEvents,
    ]
      .filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj.ceid).indexOf(obj.ceid) === pos;
      })
      .filter((evt) => {
        return !this.hasExcludeFromCountRule(evt);
      });

    const totalCount = uniqueEvents.length;
    // console.log("compRuleMax() currentEnteredEvents: " + currentEnteredEvents.length +
    //     ", getSelectedEvents: " + getSelectedEvents.length + ",totalCount: " +
    //     totalCount + ", maxEvents: " + compRule.options.maxEvents + ", uniqueEvents.length: " + uniqueEvents.length);

    if (
      compRule.options &&
      compRule.options.maxCompEvents &&
      totalCount > 0 &&
      totalCount >= compRule.options.maxCompEvents
    ) {
      events = events.map((evt) => {
        if (evt.ruleIsDisabledBy) {
          return evt;
        }
        //  public isEventSelectedOrInCart(event: IAthleteCompSchedRuleEvent): boolean {
        if (this.isEventSelectedOrInCart(evt)) {
          return evt;
        }
        if (this.hasExcludeFromCountRule(evt)) {
          return evt;
        }

        evt.ruleIsDisabledBy = true;
        // evt.ruleMessage =
        //   athlete.firstName +
        //   " is now entered into the maximum (" +
        //   compRule.options.maxCompEvents +
        //   ") events allowed.";
        evt.ruleMessage =
          "Entered into the maximum (" +
          compRule.options.maxCompEvents +
          ") events allowed.";
        evt.ruleType = RULE_TYPE.COMP;
        return evt;
      });
    }
    return events;
  };

  public compRuleMaxInDay = (
    compRule: ICompShedRuleConfig,
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    events = [...events];

    if (compRule.options) {
      return events;
    }

    const options: ICompShedRuleOptions = compRule.options;
    const maxEvents: number = options.maxEvents
      ? options.maxEvents
      : options.maxCompEvents;

    if (maxEvents < 0) {
      return events;
    }

    const currentEnteredEvents = this.getEventsEnteredForDate(
      event.startdate.substring(0, 10),
      events
    );
    const getSelectedEvents = this.getSelectedEvents(
      event.startdate.substring(0, 10),
      events
    );

    //  but u could have an event in grid and in cart...so remove dups.
    const uniqueEvents = [...currentEnteredEvents, ...getSelectedEvents]
      .filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj.ceid).indexOf(obj.ceid) === pos;
      })
      .filter((evt) => {
        return !this.hasExcludeFromCountRule(evt);
      });

    const totalCount = uniqueEvents.length;
    // console.log("compRuleMaxInDay() currentEnteredEvents: " + currentEnteredEvents.length +
    //     ", getSelectedEvents: " + getSelectedEvents.length + ",totalCount: " +
    //     totalCount + ", maxEvents: " + compRule.options.maxEvents + ", uniqueEvents.length: " + uniqueEvents.length);

    if (totalCount > 0 && totalCount >= maxEvents) {
      events = events.map((evt) => {
        if (evt.ruleIsDisabledBy) {
          return evt;
        }
        if (this.hasExcludeFromCountRule(evt)) {
          return evt;
        }
        if (
          evt.startdate.substring(0, 10) !== event.startdate.substring(0, 10)
        ) {
          return evt;
        }
        evt.ruleIsDisabledBy = true;
        evt.ruleMessage = "Max in Day";
        evt.ruleType = RULE_TYPE.COMP;
        return evt;
      });
    }
    return events;
  };

  /*
  public compRuleTrackFieldMaxInDay = (
    compRule: ICompShedRuleConfig,
    eventType: EVENT_TYPE,
    dateIso: string = "",
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    events = [...events];

    if (R.isNil(compRule.options)) {
      return events;
    }
    const currentEnteredTrackFieldEvents = this.getTrackFieldEventsEntered(
      dateIso,
      eventType,
      events
    );

    const getSelectedTrackFieldEvents = this.getSelectedEvents(
      event.startdate.substring(0, 10),
      events
    ).filter((evt) => {
      return evt.tf === eventType;
    });

    //  but u could have an event in grid and in cart...so remove dups.
    const uniqueEvents = [
      ...currentEnteredTrackFieldEvents,
      ...getSelectedTrackFieldEvents,
    ]
      .filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj.ceid).indexOf(obj.ceid) === pos;
      })
      .filter((evt) => {
        return !this.hasExcludeFromCountRule(evt);
      });

    // console.log("compRuleTrackFieldMaxInDay kkkkkkkkk", {
    //     currentEnteredTrackFieldEvents,
    //     getSelectedTrackFieldEvents,
    //     uniqueEvents
    // });

    const maxCount =
      eventType === EVENT_TYPE.FIELD
        ? compRule.options.maxField
        : compRule.options.maxTrack;
    const totalCount = uniqueEvents.length;
    // console.log("event: " + event.Name + "-" + event.ceid +  "compRuleTrackFieldMaxInDay() eventType: " +
    //     eventType + " ,currentEnteredTrackFieldEvents: " + currentEnteredTrackFieldEvents.length +
    //     ", getSelectedTrackFieldEvents: " + getSelectedTrackFieldEvents.length + ",totalCount: " +
    //     totalCount + ", maxCount: " + maxCount)

    if (totalCount > 0 && totalCount >= maxCount) {
      events = events.map((evt) => {
        if (evt.ruleIsDisabledBy) {
          return evt;
        }
        if (this.hasExcludeFromCountRule(evt)) {
          return evt;
        }
        if (
          this.isEventAvailable(evt) &&
          event.startdate.substring(0, 10) === evt.startdate.substring(0, 10) &&
          evt.tf === eventType
        ) {
          evt.ruleIsDisabledBy = true;
          evt.ruleMessage =
            "Max " + (eventType === EVENT_TYPE.FIELD ? "Field" : "Track");
          evt.ruleType = RULE_TYPE.COMP;
        }
        return evt;
      });
    }
    return events;
  };
  */

  public compRuleTrackFieldMaxInDay = (
    compRule: ICompShedRuleConfig,
    eventType: EVENT_TYPE,
    dateIso: string = "",
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    let eventsInternal = simpleClone(events);

    if (R.isNil(compRule.options)) {
      return events;
    }

    const options = simpleClone(compRule.options);
    if (!options.maxField) {
      options.maxField = options.maxCompField;
    }

    if (!options.maxTrack) {
      options.maxTrack = options.maxCompTrack;
    }

    const maxCount =
      eventType === EVENT_TYPE.FIELD ? options.maxField : options.maxTrack;

    if (maxCount === 0) {
      return events;
    }

    const currentEnteredTrackFieldEvents = this.getTrackFieldEventsEntered(
      dateIso,
      eventType,
      events
    );

    const getSelectedTrackFieldEvents = this.getSelectedEvents(
      event.startdate.substring(0, 10),
      eventsInternal
    ).filter((evt) => {
      return evt.tf === eventType;
    });

    //  but u could have an event in grid and in cart...so remove dups.
    const uniqueEventsEntered = [
      ...currentEnteredTrackFieldEvents,
      ...getSelectedTrackFieldEvents,
    ]
      .filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj.ceid).indexOf(obj.ceid) === pos;
      })
      .filter((evt) => {
        return !this.hasExcludeFromCountRule(evt);
      });

    // console.log("compRuleTrackFieldMaxInDay kkkkkkkkk", {
    //     currentEnteredTrackFieldEvents,
    //     getSelectedTrackFieldEvents,
    //     uniqueEvents
    // });

    //  Api not sending up maxField or maxTrack, so we need to set them.

    const totalCount = uniqueEventsEntered.length;
    // console.log("event: " + event.Name + "-" + event.ceid +  "compRuleTrackFieldMaxInDay() eventType: " +
    //     eventType + " ,currentEnteredTrackFieldEvents: " + currentEnteredTrackFieldEvents.length +
    //     ", getSelectedTrackFieldEvents: " + getSelectedTrackFieldEvents.length + ",totalCount: " +
    //     totalCount + ", maxCount: " + maxCount)

    if (totalCount > 0 && totalCount >= maxCount) {
      eventsInternal = eventsInternal.map((evt) => {
        if (evt.ruleIsDisabledBy) {
          return evt;
        }
        if (this.hasExcludeFromCountRule(evt)) {
          return evt;
        }
        if (
          this.isEventAvailable(evt) &&
          event.startdate.substring(0, 10) === evt.startdate.substring(0, 10) &&
          evt.tf === eventType
        ) {
          evt.ruleIsDisabledBy = true;
          evt.ruleMessage =
            "Max " + (eventType === EVENT_TYPE.FIELD ? "Field" : "Track");
          evt.ruleType = RULE_TYPE.COMP;
        }
        return evt;
      });
    }
    return eventsInternal;
  };

  public compRuleTrackFieldMaxInDayTest = (
    compRule: ICompShedRuleConfig,
    eventType: EVENT_TYPE,
    dateIso: string = "",
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ): any => {
    let eventsInternal = simpleClone(events);

    if (R.isNil(compRule.options)) {
      return events;
    }
    const currentEnteredTrackFieldEvents = this.getTrackFieldEventsEntered(
      dateIso,
      eventType,
      events
    );

    const getSelectedTrackFieldEvents = this.getSelectedEvents(
      event.startdate.substring(0, 10),
      eventsInternal
    ).filter((evt) => {
      return evt.tf === eventType;
    });

    //  but u could have an event in grid and in cart...so remove dups.
    const uniqueEventsEntered = [
      ...currentEnteredTrackFieldEvents,
      ...getSelectedTrackFieldEvents,
    ]
      .filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj.ceid).indexOf(obj.ceid) === pos;
      })
      .filter((evt) => {
        return !this.hasExcludeFromCountRule(evt);
      });

    // console.log("compRuleTrackFieldMaxInDay kkkkkkkkk", {
    //     currentEnteredTrackFieldEvents,
    //     getSelectedTrackFieldEvents,
    //     uniqueEvents
    // });

    //  Api not sending up maxField or maxTrack, so we need to set them.
    const options = simpleClone(compRule.options);
    if (!options.maxField) {
      options.maxField = options.maxCompField;
    }

    if (!options.maxTrack) {
      options.maxTrack = options.maxCompTrack;
    }

    const maxCount =
      eventType === EVENT_TYPE.FIELD ? options.maxField : options.maxTrack;
    const totalCount = uniqueEventsEntered.length;
    // console.log("event: " + event.Name + "-" + event.ceid +  "compRuleTrackFieldMaxInDay() eventType: " +
    //     eventType + " ,currentEnteredTrackFieldEvents: " + currentEnteredTrackFieldEvents.length +
    //     ", getSelectedTrackFieldEvents: " + getSelectedTrackFieldEvents.length + ",totalCount: " +
    //     totalCount + ", maxCount: " + maxCount)

    if (totalCount > 0 && totalCount >= maxCount) {
      eventsInternal = eventsInternal.map((evt) => {
        if (evt.ruleIsDisabledBy) {
          return evt;
        }
        if (this.hasExcludeFromCountRule(evt)) {
          return evt;
        }
        if (
          this.isEventAvailable(evt) &&
          event.startdate.substring(0, 10) === evt.startdate.substring(0, 10) &&
          evt.tf === eventType
        ) {
          evt.ruleIsDisabledBy = true;
          evt.ruleMessage =
            "Max " + (eventType === EVENT_TYPE.FIELD ? "Field" : "Track");
          evt.ruleType = RULE_TYPE.COMP;
        }
        return evt;
      });
    }
    return {
      eventType,
      maxCount,
      totalCount,
      eventsInternal,
    };
  };

  public compRuleUnique = (
    compRule: ICompShedRuleConfig,
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    events = [...events];

    if (!(compRule.options && compRule.options.unique)) {
      return events;
    }
    // Loop across all ICompUnique, if eventId exists in any of them, we need to disable any ids listed.
    const ids: number[] = compRule.options.unique.reduce(
      (accum: number[], currentUni: ICompUnique) => {
        if (currentUni.ids.indexOf(event.eventid)) {
          return accum.concat(currentUni.ids);
        }
        return accum;
      },
      []
    );

    events = events.map((evt) => {
      if (evt.ruleIsDisabledBy) {
        return evt;
      }
      if (evt.userSelected) {
        return evt;
      }
      if (ids.indexOf(evt.eventid) > -1) {
        evt.ruleIsDisabledBy = true;
        evt.ruleMessage = "Comp Unique: " + event.eventid;
        evt.ruleType = RULE_TYPE.COMP;
      }
      return evt;
    });
    return events;
  };

  public applyPbRule = (
    event: IAthleteCompSchedRuleEvent
  ): IAthleteCompSchedRuleEvent => {
    event = { ...event };
    const perfInfo: IPerfInfo = event.perfInfo;
    const pb = perfInfo.perf;
    const pbSplit: number = event.split ? event.split : 0;
    const isSplitPositive: boolean = pbSplit > 0;

    if (pbSplit === 0) {
      return event;
    }

    const splitAbs = Math.abs(pbSplit);

    if (isSplitPositive && pb < splitAbs) {
      event.ruleIsDisabledBy = true;
      event.ruleMessage =
        "1. Performance " + pb + " less than required " + splitAbs;
      event.ruleType = RULE_TYPE.PB;
      return event;
    }

    if (!isSplitPositive && pb > splitAbs) {
      event.ruleIsDisabledBy = true;
      event.ruleMessage =
        "2. Performance " + pb + " greater than required " + splitAbs;
      event.ruleType = RULE_TYPE.PB;
      return event;
    }
    // if (pb === undefined) {
    //   return event;
    // }
    if (event.ruleIsDisabledBy) {
      return event;
    }
    // const eoptions: IEoptions = event.eoptions;
    const pbMin: number = perfInfo.limits.min;
    const pbMax: number = perfInfo.limits.max;

    // console.log("applyPbRule...pb: " + pb + ", pbMin: " + pbMin + ", pbMax: " +
    //     pbMax + ", pbSplit: " + pbSplit + ", isSplitPositive: " + isSplitPositive  + ", pbKey", pbKey);

    // let pbReadable: string = pb.toString();
    let pbSplitReadable: string = pbSplit.toString();
    if (event.tf === EVENT_TYPE.TRACK) {
      if (event.perfInfo.uom.length > 0) {
        const uom = event.perfInfo.uom[0];
        // const pbFormatted = pbService.convertSecondsToUserFormat(
        //   pb,
        //   uom.pattern
        // );
        // pbReadable = perfInfo.perfText + " " + uom.text;
        pbSplitReadable = pbService.convertSecondsToUserFormat(
          Math.abs(pbSplit),
          uom.pattern
        );
        pbSplitReadable = pbSplitReadable + " " + uom.text;
      }
    }

    // if ((pbMin > 0 && pbMax > 0 && pb <= pbMin) || pb >= pbMax) {
    if (pbMin > 0 && pbMax > 0) {
      if (pb <= pbMin || pb >= pbMax) {
        event.ruleIsDisabledBy = true;
        event.ruleMessage =
          "3. Performance not in range: " + pbMin + " - " + pbMax;
        event.ruleType = RULE_TYPE.PB;
        return event;
      }
    }

    //  EVent has a split, the PB must be within the split.
    if (pbSplit !== 0 && pb > 0) {
      //  If split is e.g. 30 and pb 19, then pb not good enough.
      if (isSplitPositive && pb < splitAbs) {
        event.ruleIsDisabledBy = true;
        event.ruleMessage =
          "4. Performance " +
          perfInfo.perfText +
          " less than required " +
          pbSplitReadable;
        event.ruleType = RULE_TYPE.PB;
        return event;
      }

      if (!isSplitPositive) {
        if (pb > splitAbs) {
          event.ruleIsDisabledBy = true;
          event.ruleMessage =
            "5. Performance " +
            perfInfo.perfText +
            " greater than limit " +
            pbSplitReadable;
          event.ruleType = RULE_TYPE.PB;
          return event;
        }
      }
    }
    return event;
  };

  public updatePbForEventInEvents = (
    events: IAthleteCompSchedRuleEvent[],
    event: IAthleteCompSchedRuleEvent,
    pb: number
  ): IAthleteCompSchedRuleEvent[] => {
    return events.map((evt) => {
      if (evt.ceid === event.ceid) {
        const pbEvt: number = evt.pb === null ? 0 : evt.pb;
        return this.updatePbForEvent(evt, pbEvt);
      } else {
        return evt;
      }
    });
  };

  public updatePbForEvent = (
    event: IAthleteCompSchedRuleEvent,
    pb: number
  ): IAthleteCompSchedRuleEvent => {
    event.pb = pb;
    return event;
  };

  public setSelectedEvent = (
    event: IAthleteCompSchedRuleEvent,
    isSelected: boolean
  ) => {
    return {
      ...event,
      userSelected: isSelected,
    };
  };

  public getSelectedEvents = (
    dateIso: string = "",
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    return [...events].filter((evt) => {
      if (dateIso !== "") {
        return evt.startdate.substring(0, 10) === dateIso && evt.userSelected;
      }
      return evt.userSelected;
    });
  };

  /*
  public getTrackFieldEventsEntered = (
    dateIso: string = "",
    eventType: EVENT_TYPE,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    events = [...events];
    events = events.filter((evt) => {
      return evt.tf === eventType;
    });
    events = events.filter((evt) => {
      return this.isEventSelectedOrInCart(evt);
    });
    // events = this.getEventsEntered(eventType, events);
    if (dateIso === undefined || dateIso === "") {
      return events;
    }
    events = this.getEventsEnteredForDate(dateIso, events);
    return events;
  };
  */

  public getTrackFieldEventsEntered = (
    dateIso: string = "",
    eventType: EVENT_TYPE,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    const eventsInternal = simpleClone(events);
    const eventsType = eventsInternal.filter((evt) => {
      return evt.tf === eventType;
    });
    const eventsSelected = eventsType.filter((evt) => {
      return this.isEventSelectedOrInCart(evt);
    });
    // events = this.getEventsEntered(eventType, events);
    if (dateIso === undefined || dateIso === "") {
      return eventsSelected;
    }
    const eventsForDate = this.getEventsEnteredForDate(dateIso, eventsSelected);
    return eventsForDate;
    // return {
    //   eventType,
    //   eventsIn: events.length,
    //   eventsType: eventsType.length,
    //   eventsSelected: eventsSelected.length,
    //   eventsForDate: eventsForDate.length,
    // };
  };

  public getTrackFieldEventsAvailable = (
    dateIso: string = "",
    eventType: EVENT_TYPE,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    return [...events]
      .filter((evt) => {
        return evt.tf === eventType;
      })
      .filter((evt) => {
        //  Long hand, so I can get my head round it.
        const isAvailable: boolean = this.isEventAvailable(evt); //  evt.userSelected || evt.ruleIsDisabledBy ? false : true;
        if (dateIso === undefined || dateIso === "") {
          return isAvailable;
        }
        return evt.startdate.substring(0, 10) === dateIso && isAvailable;
      });
  };

  public getEventsEnteredNotPaid = (
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    return [...events].filter((event) => {
      return event.entered && event.paid === 0;
    });
  };

  public getEventsEntered = (
    eventType: string = "",
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    events = [...events];

    events = events.filter((event) => {
      if (eventType === "") {
        return this.isEventSelectedOrInCart(event);
      }
      //  return event.entered && event.tf === eventType;
      return this.isEventSelectedOrInCart(event) && event.tf === eventType;
    });
    return events;
  };

  public getAvailableEvents = (
    dateIso: string = "",
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    events = [...events];
    return events.filter((evt) => {
      if (dateIso !== "") {
        return (
          this.isEventAvailableForSelection(evt) &&
          evt.startdate.substring(0, 10) === dateIso
        );
      }
      return this.isEventAvailableForSelection(evt);
    });
  };

  public getUserEventKey = (event: IAthleteCompSchedRuleEvent): string => {
    return event.athleteid + "-" + event.ceid;
  };

  public getSimilarEventsByProdIdKey = (
    event: IAthleteCompSchedRuleEvent
  ): string => {
    return event.athleteid + "-" + event.ceid + event.order.productId;
  };

  public getEventsAsKeyUniqueUserObject = (
    events: IAthleteCompSchedRuleEvent[]
  ): any => {
    const eventsObj = events.reduce(
      (accum: any, evt: IAthleteCompSchedRuleEvent) => {
        const keyUserEventUnique: string = this.getUserEventKey(evt);
        if (!accum[keyUserEventUnique]) {
          accum[keyUserEventUnique] = evt;
        }
        return accum;
      },
      {}
    );

    return eventsObj;
  };

  public getEventsKeyByProp(
    eventProp: string,
    events: IAthleteCompSchedRuleEvent[]
  ): IObjectKey {
    return events.reduce((accum: any, evt: any) => {
      const eventPropValue: any = evt[eventProp];
      if (!accum[eventPropValue]) {
        //  even if number, turnign to string...?????
        accum[eventPropValue] = evt;
      }
      return accum;
    }, {});
    // return eventsObj;
  }

  public setEnteredNotPaidAsSelected = (
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    const eventsEnteredNotPaid = this.getEventsEnteredNotPaid(events);
    const eventsEbteredNotPaidKeyObj =
      this.getEventsAsKeyUniqueUserObject(eventsEnteredNotPaid);
    events = events.map((evt: IAthleteCompSchedRuleEvent) => {
      evt = { ...evt };
      const userEventKey = this.getUserEventKey(evt);
      const athleteCompSchedRuleEvent =
        eventsEbteredNotPaidKeyObj[userEventKey];
      if (athleteCompSchedRuleEvent === undefined) {
        return evt;
      } else {
        //  console.log(userEventKey + ", userSelected: " + evt.userSelected, evt);
        evt.userSelected = true;
        return evt;
      }
    });
    return events;
  };

  public isEventAvailableForClicking = (event: IAthleteCompSchedRuleEvent) => {
    //  User has selected, so they can udeselect
    if (!event.userSelected && event.ruleIsDisabledBy) {
      return false;
    }
    return true;
  };

  public isEventAvailableForSelection = (event: IAthleteCompSchedRuleEvent) => {
    if (event.ruleIsDisabledBy) {
      return false;
    }
    if (event.userSelected) {
      return false;
    }
    return true;
    //  return !(event.ruleIsDisabledBy || event.userSelected);
  };

  // public getEventsEnteredForDate = (
  //   dateIso: string = "",
  //   events: IAthleteCompSchedRuleEvent[]
  // ): IAthleteCompSchedRuleEvent[] => {
  //   events = [...events];
  //
  //   events = this.getEntered(events).filter((event) => {
  //     if (dateIso === undefined || dateIso === "") {
  //       //  return event.entered === true;
  //       return this.isEventSelectedOrInCart(event);
  //     }
  //     //  All dates come back iso, so no need to faff about with date-fns.
  //     return event.startdate.substring(0, 10) === dateIso && event.entered;
  //   });
  //   return events;
  // };

  public getEventsEnteredForDate = (
    dateIso: string = "",
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    let eventsInternal = simpleClone(events);

    eventsInternal = eventsInternal.filter((event) => {
      // if (dateIso === undefined || dateIso === "") {
      //  return event.entered === true;
      //   return this.isEventSelectedOrInCart(event);
      // }
      //  All dates come back iso, so no need to faff about with date-fns.
      // return event.startdate.substring(0, 10) === dateIso && event.entered;

      return this.isEventSelectedOrInCart(event);
    });
    if (isNil(dateIso)) {
      return eventsInternal;
    }

    const dateToCheck = dateIso.substring(0, 10);

    eventsInternal = eventsInternal.filter((event) => {
      return event.startdate.substring(0, 10) === dateToCheck;
    });
    return eventsInternal;
  };

  public getEntered = (
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] => {
    return [...events].filter((evt) => {
      //  return event.entered;
      return this.isEventSelectedOrInCart(evt);
    });
  };

  public getPersonalBestAsKeyObject = (athletePb: IAthletePb[]): IPbKey => {
    //  Server passing as array.
    return athletePb.reduce((accum: any, pb: IAthletePb) => {
      accum[pb.eventid] = pb.pb;
      return accum;
    }, {});
  };

  // public addPersonalBestToEvent = (
  //   event: IAthleteCompSchedRuleEvent,
  //   athletePbKey: IPbKey
  // ): IAthleteCompSchedRuleEvent => {
  //   if (athletePbKey[event.eventid]) {
  //     event.pb = athletePbKey[event.eventid];
  //     return event;
  //   }
  //   return event;
  // };

  public getOrderSummaryTotals(
    events: IAthleteCompSchedRuleEvent[]
  ): IOrderSummary {
    if (!events) {
      return {
        athleteIds: {},
        athleteNames: {},
        athleteCount: 0,
        eventCount: 0,
        totalPrice: 0,
      };
    }
    return events.reduce(
      (
        accum: IOrderSummary,
        evt: IAthleteCompSchedRuleEvent,
        index: number
      ) => {
        if (evt.price && evt.price.curPrice) {
          accum.totalPrice = accum.totalPrice + evt.price.curPrice;
        }
        if (!accum.athleteIds[evt.athleteid]) {
          accum.athleteIds[evt.athleteid] = evt.athleteid;
          accum.athleteNames[evt.surName] = evt.firstName + " " + evt.surName;
          accum.athleteCount++;
        }
        return accum;
      },
      {
        athleteIds: {},
        athleteNames: {},
        athleteCount: 0,
        eventCount: events.length,
        totalPrice: 0,
      } as IOrderSummary
    );
  }

  public canCartBeSubmittedToBasket(
    events: IAthleteCompSchedRuleEvent[]
  ): IUserMessage[] {
    const accumState = {
      eventIdKey: {} as IObjectKey,
      messages: [] as IUserMessage[],
    };

    if (events.length === 0) {
      return [
        {
          level: USER_MESSAGE_LEVEL.WARN,
          message: "<div>No events in basket.</div>",
        },
      ];
    }

    const cartEventsObj = commonService.convertArrayToObject("ceid", events);
    // console.log("canCartBeSubmittedToBasket() cartEventsObj", cartEventsObj);

    //  TODO Get team events.
    const result = this.getTeamEvents(events).reduce(
      (accum, evt: IAthleteCompSchedRuleEvent) => {
        const eventTeam = this.getEventTeamOptions(evt);
        if (eventTeam) {
          //  We only need to run this once for any given ceid.
          if (!accumState.eventIdKey[evt.ceid]) {
            accumState.eventIdKey[evt.ceid] = evt.ceid;
            const eventsWithSameId: IAthleteCompSchedRuleEvent[] =
              this.getEventsFilteredByCeid(evt.ceid, events);
            if (eventsWithSameId.length < eventTeam.min) {
              accum.messages.push({
                level: USER_MESSAGE_LEVEL.WARN,
                message:
                  "<div>Team event '" +
                  evt.Name +
                  "' requires between " +
                  eventTeam.min +
                  " and " +
                  eventTeam.max +
                  " athletes, only " +
                  eventsWithSameId.length +
                  " selected.</div>",
              });
            }
            if (eventsWithSameId.length > eventTeam.max) {
              accum.messages.push({
                level: USER_MESSAGE_LEVEL.WARN,
                message:
                  "<div>Team event '" +
                  evt.Name +
                  "' is over the maximum allowed athletes " +
                  eventTeam.max +
                  "</div>",
              });
            }
          }
        }
        return accum;
      },
      accumState
    );

    this.getSelectedSecondarySpendItems(events)
      .filter((evt) => {
        return !evt.ceoptions.secondarySpend.isParent;
      })
      .reduce((accum, evt: IAthleteCompSchedRuleEvent) => {
        if (!accumState.eventIdKey[evt.ceid]) {
          if (this.isEventSelectedOrInCart(evt)) {
            if (cartEventsObj[evt.ceoptions.secondarySpend.parentCeid]) {
              if (
                !this.isSecondarySpendEventParentUserSelected(
                  evt,
                  cartEventsObj
                )
              ) {
                accum.messages.push({
                  level: USER_MESSAGE_LEVEL.WARN,
                  message:
                    "<div>Event '" +
                    evt.Name +
                    "' requires '" +
                    cartEventsObj[evt.ceoptions.secondarySpend.parentCeid]
                      .name +
                    "' to be selected.</div>",
                });
              }
            } else {
              accum.messages.push({
                level: USER_MESSAGE_LEVEL.WARN,
                message:
                  "<div>Event '" +
                  evt.Name +
                  "' requires parent event to be selected.</div>",
              });
            }
          }
        }
        return accum;
      }, result);

    return result.messages;
  }

  public isEventSelectedOrInCart(event: IAthleteCompSchedRuleEvent): boolean {
    // TODO next line..TS doesn't like ???
    // return event.userSelected || (event.prodId && (event.prodId > 0));
    if (event.userSelected) {
      return true;
    }
    if (!event.order) {
      return false;
    }
    if (event.order.productId > 0) {
      return true;
    }
    return false;
  }

  public getTeamEvents(
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] {
    return events.filter((evt) => {
      // return evt.eoptions && evt.eoptions.eventTeam;
      return this.isTeamEvent(evt);
    });
  }

  public getEventsFilteredByCeid(
    ceid: number,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] {
    return events.filter((evt) => {
      return evt.ceid === ceid;
    });
  }

  public deselectAssociatedSecondarySpend(
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] {
    if (!event.userSelected) {
      return events.map((evt: IAthleteCompSchedRuleEvent) => {
        if (
          this.hasSecondarySpend(evt) &&
          evt.ceoptions.secondarySpend.parentCeid === event.ceid
        ) {
          evt.userSelected = false;
        }
        return evt;
      });
    }
    return events;
  }

  public isSecondarySpendParent(event: IAthleteCompSchedRuleEvent): boolean {
    if (!this.hasSecondarySpend(event)) {
      return false;
    }
    return event.ceoptions.secondarySpend.isParent;
  }

  public getSecondarySpendSelectedChildren(
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] {
    return this.getAssociatedSecondarySpendEvents(event, events).filter(
      (evt) => {
        return evt.userSelected || evt.order.productId > 0;
      }
    );
  }

  public hasSecondarySpend(event: IAthleteCompSchedRuleEvent) {
    if (event.ceoptions === null) {
      //  Gaahhhh!!!  F-ing back end model changing.
      return false;
    }
    if (
      event.ceoptions &&
      event.ceoptions.secondarySpend !== undefined &&
      event.ceoptions.secondarySpend !== null
    ) {
      return true;
    }
    return false;
  }

  public getSecondarySpendItems(
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] {
    return events.filter(this.hasSecondarySpend);
  }

  public getSelectedSecondarySpendItems(
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] {
    return events
      .filter(this.hasSecondarySpend)
      .filter((evt: IAthleteCompSchedRuleEvent) => {
        return evt.order && evt.order.productId && evt.order.productId > 0;
      });
  }

  public getAssociatedSecondarySpendEvents(
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ) {
    return events.filter((evt) => {
      return (
        this.hasSecondarySpend(evt) &&
        evt.ceoptions.secondarySpend.parentCeid === event.ceid
      );
    });
  }

  public getMandatorySecondarySpendObjectGroupedByMandId(
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ): IObjectKey {
    //   Get mandatory 2ndry associated with event.ceid that have a group id.
    //  Need at least one of them in cart, e.g. selected.
    return this.getAssociatedSecondarySpendEvents(event, events)
      .filter((evt) => {
        return evt.ceoptions.secondarySpend.mandatoryGroupId;
      })
      .reduce((accum, evt: IAthleteCompSchedRuleEvent) => {
        //  Produce a keyed object
        const mandatoryGroupId = evt.ceoptions.secondarySpend.mandatoryGroupId;
        if (!accum[mandatoryGroupId]) {
          accum[mandatoryGroupId] = [];
        }
        accum[mandatoryGroupId].push(evt);
        return accum;
      }, {} as IObjectKey);
  }

  public getMandatorySpendEventsRequiringAction(
    mandatoryKey: IObjectKey
  ): IAthleteCompSchedRuleEvent[] {
    const keys = Object.keys(mandatoryKey);
    return keys.reduce((accum, key: string) => {
      const mandatoryEvents = mandatoryKey[key];
      if (this.doesThisGroupOfMandatoryNeedSelection(mandatoryEvents)) {
        accum = accum.concat(mandatoryEvents);
      }
      return accum;
    }, [] as IAthleteCompSchedRuleEvent[]);
  }

  public getMandatorySpendEventsMessages(
    event: IAthleteCompSchedRuleEvent,
    events: IAthleteCompSchedRuleEvent[]
  ) {
    const result = this.getMandatorySecondarySpendObjectGroupedByMandId(
      event,
      events
    );
    const xxx = this.getMandatorySpendEventsRequiringAction(result);
    // console.log("vvvvvvvvvv", xxx);
    return xxx;
  }

  public getSelectedAsObjectByCeid(
    events: IAthleteCompSchedRuleEvent[]
  ): IObjectKey {
    const selectedEvents = (
      evts: IAthleteCompSchedRuleEvent[]
    ): IAthleteCompSchedRuleEvent[] => {
      return evts.filter((evt: IAthleteCompSchedRuleEvent) => {
        return evt.userSelected;
      });
    };

    const getKeyObjByCeid = (
      evts: IAthleteCompSchedRuleEvent[]
    ): IObjectKey => {
      return this.getEventsKeyByProp("ceid", evts);
    };

    return R.compose(getKeyObjByCeid, selectedEvents)(events) as IObjectKey;
  }

  public isSecondarySpendEventParentUserSelected(
    event: IAthleteCompSchedRuleEvent,
    eventObjByCeid: IObjectKeyType<IAthleteCompSchedRuleEvent>
  ): boolean {
    const parentEvent =
      eventObjByCeid[event.ceoptions.secondarySpend.parentCeid];
    if (!parentEvent) {
      return false;
    }
    return (
      parentEvent.order.productId > 0 ||
      this.isEventSelectedOrInCart(parentEvent)
    );
  }

  //  TODO this function isn't correct.
  public isSecondarySpendParentSelected(
    objKeyByCeid: IObjectKey,
    event: IAthleteCompSchedRuleEvent
  ): boolean {
    if (
      event.ceoptions &&
      event.ceoptions.secondarySpend &&
      event.ceoptions.secondarySpend.parentCeid
    ) {
      return objKeyByCeid[event.ceoptions.secondarySpend.parentCeid + ""]
        ? true
        : false;
    }
    return false;
  }

  public hideThisEvent(
    objKeyByCeid: IObjectKey,
    event: IAthleteCompSchedRuleEvent
  ): boolean {
    if (this.hasSecondarySpend(event)) {
      if (
        event.ceoptions &&
        event.ceoptions.secondarySpend &&
        (event.ceoptions.secondarySpend.alwaysShow ||
          event.ceoptions.secondarySpend.isParent)
      ) {
        //  as in...always show
        return false;
      }
      if (!this.isSecondarySpendParentSelected(objKeyByCeid, event)) {
        //  If secondary spend parent NOT selected...hide it.
        return true;
      }
    }
    return false;
  }

  public getEventDate(event: IAthleteCompSchedRuleEvent) {
    if (event === undefined || event.startdate === undefined) {
      return "";
    }
    return format(parse(event.startdate), "Do MMM YY");
  }

  public getEventTime(event: IAthleteCompSchedRuleEvent) {
    if (this.hasSecondarySpend(event)) {
      return "";
    }

    //  @See CommonServiceUtils.eventTimeDisplay()
    let startTime = event.startdate.replace("+01:00", "");

    startTime = format(parse(startTime), "HH:mm");
    startTime = startTime === "00:00" ? "Time TBC" : startTime;
    return startTime;
  }

  public getOrderDateTime(event: IAthleteCompSchedRuleEvent) {
    if (!event.post_date || event.post_date.length === 0) {
      return "N/A";
    }
    return format(parse(event.post_date.replace(" ", "T")), "Do MMM YY HH:mm");
  }

  public getRowOptions(
    event: IAthleteCompSchedRuleEvent,
    eventsServerResponse: IAthleteCompSchedResponse
  ): IRowOptions {
    let rowOptions: IRowOptions = this.factoryRowOptions();
    if (
      eventsServerResponse.schedInfo &&
      eventsServerResponse.schedInfo.rowOptions
    ) {
      rowOptions = Object.assign(
        rowOptions,
        eventsServerResponse.schedInfo.rowOptions
      );
    }
    if (event.eoptions && event.eoptions.rowOptions) {
      rowOptions = Object.assign(rowOptions, event.eoptions.rowOptions);
    }
    if (event.ceoptions && event.ceoptions.rowOptions) {
      rowOptions = Object.assign(rowOptions, event.ceoptions.rowOptions);
    }
    return rowOptions;
  }

  public isScheduleOnly(compEvent: IAthleteCompSchedRuleEvent) {
    return compEvent.maxathletes === -1;
  }

  public showPbInput(event: IAthleteCompSchedRuleEvent): boolean {
    const hasSecondarySpend = this.hasSecondarySpend(event);
    const isScheduleOnly = event.maxathletes === -1;
    if (hasSecondarySpend || isScheduleOnly) {
      return false;
    }
    return event.ceoptions.mandatoryPB;
    // if (event.ceoptions.mandatoryPB) {
    //   return true;
    // }
    // return event.ceoptions.rowOptions.showPB;
  }

  public updatePbs(events: IAthleteCompSchedRuleEvent[], pbInfo: IAthletePb[]) {
    const pbInfoByKey = new CommonService().convertArrayToObject(
      "eventid",
      pbInfo
    );
    return events.map((athleteCompSchedRuleEvent) => {
      if (pbInfoByKey[athleteCompSchedRuleEvent.eventid]) {
        athleteCompSchedRuleEvent.pb =
          pbInfoByKey[athleteCompSchedRuleEvent.eventid].pb;
      }
      return athleteCompSchedRuleEvent;
    });
  }

  public getEventsForAthlete(
    athleteId: number,
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] {
    return events.filter((evt) => {
      return evt.athleteid === athleteId;
    });
  }

  public applyMaxReachedRule(
    compEvent: IAthleteCompSchedRuleEvent
  ): IAthleteCompSchedRuleEvent {
    if (compEvent.ruleIsDisabledBy) {
      return compEvent;
    }
    if (this.reachedMaxAllowedAthletes(compEvent)) {
      const cEvent = { ...compEvent };
      cEvent.ruleIsDisabledBy = true;
      cEvent.ruleMessage = "Event full. Waiting list disabled by organiser.";
      cEvent.ruleType = RULE_TYPE.EVENT;
      return cEvent;
    }
    return compEvent;
  }

  public reachedMaxAllowedAthletes(
    compEvent: IAthleteCompSchedRuleEvent
  ): boolean {
    if (compEvent.maxathletes === 0) {
      return false;
    }
    return compEvent.entryInfo.paidCount >= compEvent.maxathletes;
  }

  public isEventOverSubscribed(compEvent: IAthleteCompSchedRuleEvent): boolean {
    if (compEvent.maxathletes === 0) {
      return false;
    }
    if (!compEvent.entryInfo) {
      return false;
    }
    if (compEvent.entryInfo.totalCount >= compEvent.maxathletes) {
      return true;
    }
    return false;
  }

  public isBeforeSubscriptionCutOff(
    comp: ICompetition,
    date: Date = new Date()
  ): boolean {
    const subsClose = comp.options.subscription?.timeCloses;
    if (!subsClose) {
      //  If no datetime, then not allowed.
      return true;
    }
    // console.log(
    //     "isBeforeSubscriptionCutOff() isbefore " +
    //     "now: " + format(date, "YYYY-MM-DD[T]HH:mm:ssZ") +
    //     "subsClose: " + format(parse(subsClose), "YYYY-MM-DD[T]HH:mm:ssZ") +
    //     "isBefore: " + isBefore(date, parse(subsClose))
    // );
    return isBefore(date, parse(subsClose));
  }

  public isSubscriptionKeepingOpen(
    compSched: IAthleteCompSchedRuleEvent,
    competition: ICompetition,
    dateTime?: Date
  ): boolean {
    if (competition.options.subscription?.enabled) {
      if (this.isBeforeSubscriptionCutOff(competition, dateTime)) {
        return true;
      }
    }
    return false;
  }

  public areAnySubscriptionEvents(
    compEvents: IAthleteCompSchedRuleEvent[]
  ): boolean {
    return compEvents.reduce((accum, compEvent) => {
      if (compEvent.maxathletes > 0) {
        accum = true;
      }
      return accum;
    }, false as boolean);
  }

  public getCompEventName(compEvent: IAthleteCompSchedRuleEvent) {
    const hasUpScaling = compEvent.ceoptions.ageGroups.length > 0;
    return (
      compEvent.eventGroup +
      (hasUpScaling && compEvent.ageGroup && compEvent.ageGroup.shortName
        ? ": " + compEvent.ageGroup.shortName
        : "")
    );
  }

  public getEventsSchedInfo(
    serverSchedInfo: ISchedInfo,
    selectedCompetition: ICompetitionInfo,
    selectedAthlete: IAthlete,
    compEvents: IAthleteCompSchedRuleEvent[]
  ): ISchedInfo {
    const schedInfo = this.factorySchedInfo();
    // if (serverSchedInfo && serverSchedInfo.title && serverSchedInfo.title.length > 0) {
    // }
    return schedInfo;
  }

  private doesThisGroupOfMandatoryNeedSelection(
    events: IAthleteCompSchedRuleEvent[]
  ): boolean {
    return events.reduce((accum: boolean, evt: IAthleteCompSchedRuleEvent) => {
      if (evt.userSelected) {
        accum = false;
      }
      return accum;
    }, true);
  }

  private removeAllRules(
    events: IAthleteCompSchedRuleEvent[]
  ): IAthleteCompSchedRuleEvent[] {
    return events.map((event) => {
      return this.removeRule(event);
    });
  }

  private removeRule(
    event: IAthleteCompSchedRuleEvent
  ): IAthleteCompSchedRuleEvent {
    event = { ...event };
    event.ruleType = RULE_TYPE.NONE;
    event.ruleIsDisabledBy = false;
    event.ruleMessage = "";
    return event;
  }

  private factoryEventToEventRule(
    athleteCompSched: IAthleteCompSched
  ): IAthleteCompSchedRuleEvent {
    return {
      ...athleteCompSched,
      ruleIsDisabledBy: false,
      ruleMessage: "",
      userSelected: false,
      ruleType: RULE_TYPE.NONE,
      post_date: "",
    };
  }

  private isEventAvailable(event: IAthleteCompSchedRuleEvent): boolean {
    //  Long hand, so I can get my head round it.
    return event.userSelected || event.ruleIsDisabledBy ? false : true;
  }

  public hasEventSecurity(athleteCompSched: IAthleteCompSched): boolean {
    return Object.keys(athleteCompSched.ceoptions.security).length > 0;
  }

  public hasAthleteRegExpiredForCompetition(
    athlete: IAthlete,
    competitionInfo: ICompetitionInfo
  ): boolean {
    if (!this.isAthleteAndCompValid(athlete, competitionInfo)) {
      return false;
    }

    if (competitionInfo.options.allowAdd.unregistered) {
      return false;
    }

    if (!athlete.URN || athlete.URN.toString().length === 0) {
      return false;
    }

    if (!(athlete.activeEndDate && athlete.activeEndDate.length > 0)) {
      return false;
    }

    if (!(athlete.aocode && athlete.aocode.length > 0)) {
      return false;
    }

    return isAfter(parse(competitionInfo.date), parse(athlete.activeEndDate));
  }

  public isUnRegAthleteInRegOnlyComp(
    athlete: IAthlete,
    competitionInfo: ICompetitionInfo
  ): boolean {
    if (!this.isAthleteAndCompValid(athlete, competitionInfo)) {
      return false;
    }
    const hasURN: boolean = !!(
      athlete.URN && athlete.URN.toString().length > 0
    );
    return !competitionInfo.options.allowAdd.unregistered && !hasURN;
  }

  public isAthleteAndCompValid(
    athlete: IAthlete,
    competitionInfo: ICompetitionInfo
  ): boolean {
    if (!(athlete && competitionInfo)) {
      return false;
    }

    return !(competitionInfo.id === 0 || athlete.id === 0);
  }

  public isEventGroupSelectFromAMultiEventGroup(
    compEvents: ICompEvent[],
    eventGroupSelect: IEventGroupSelect
  ): boolean {
    const parentCompEventSched = this.getCompSchedsByEventGroupId(
      compEvents,
      eventGroupSelect.id
    );
    return !!(
      parentCompEventSched &&
      parentCompEventSched.options.multiEventOptions &&
      parentCompEventSched.options.multiEventOptions.childEvents.length > 0
    );
  }

  public getCompSchedsByEventGroupId(
    compEvents: ICompEvent[],
    eventGroupIdNumber: EventGroupIdNumber
  ): ICompEvent | null {
    const eventGroupSelects = compEvents.filter((compEvent) => {
      return compEvent.eventGroupSummary.id === eventGroupIdNumber;
    });
    return eventGroupSelects.length > 0 ? eventGroupSelects[0] : null;
  }

  public isPbRequiredForEvent(event: IAthleteCompSchedRuleEvent): boolean {
    return event.ceoptions.mandatoryPB;
  }

  public doesEventHavePb(event: IAthleteCompSchedRuleEvent): boolean {
    return event.perfInfo.perf > 0;
  }
}
