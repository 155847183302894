import { reactive } from "@vue/composition-api";

const fieldControllerCss = {
  error: "e4s-input-field--error",
  disabled: "e4s-input-field--disabled",
  dirty: "e4s-input-field--dirty",
} as const;

type t = typeof fieldControllerCss;

export type fieldControllerCssType = keyof t;
export type fieldControllerCssValue = t[keyof t];

export interface IFieldControllerState {
  isDirty: boolean;
  original: string | number | boolean | null;
  current: string | number | boolean | null;
}
export function useFieldController() {
  const state = reactive<IFieldControllerState>({
    isDirty: false,
    original: null,
    current: null,
  });

  function init(value: string | number | boolean | null) {
    state.original = value;
    state.current = value;
    state.isDirty = false;
  }

  function updateCurrent(value: string | number | boolean | null) {
    if (typeof value === "undefined" || value == null) {
      return;
    }

    state.current = value;
    state.isDirty = state.current !== state.original;
  }

  return {
    state,
    init,
    updateCurrent,
    fieldControllerCss,
  };
}
