





















import {
  defineComponent,
  PropType,
  ref,
  SetupContext,
  watch,
} from "@vue/composition-api";
import ButtonGenericV2 from "../buttons/button-generic-v2.vue";
import { IBaseConcreteDropDown } from "./field-models";

/**
 * Exmaple usage
 *
 <FieldSelectV2 :data-array="clubCompCategories" v-model="clubCompDefinitionInternal.category">
  <template slot-scope="{obj}">
    {{obj.categoryName}}
  </template>
 </FieldSelectV2>
 *
 */
export default defineComponent({
  name: "field-select-v2",
  components: { ButtonGenericV2 },
  inheritAttrs: false,
  props: {
    // formLabel: {
    //   type: String,
    //   default: () => {
    //     return "";
    //   },
    // },
    placeHolder: {
      type: String,
      default: () => {
        return "";
      },
    },
    value: {
      type: Object as PropType<IBaseConcreteDropDown>,
      default: () => {
        return {
          id: 0,
          name: "",
        };
      },
    },
    errorMessage: {
      type: String,
      default: () => {
        return "";
      },
    },
    dataArray: {
      type: Array as PropType<IBaseConcreteDropDown[]>,
      default: () => {
        return [];
      },
    },
  },
  setup(
    props: {
      value: IBaseConcreteDropDown;
      // formLabel: string;
      placeHolder: string;
      errorMessage: string;
      dataArray: IBaseConcreteDropDown[];
    },
    context: SetupContext
  ) {
    const valueInternal = ref(props.value);

    watch(
      () => props.value,
      (newValue: IBaseConcreteDropDown) => {
        if (newValue !== valueInternal.value) {
          console.log("field-drop-down-v2 value changed", {
            new: newValue,
            internal: valueInternal.value,
          });
          valueInternal.value = newValue;
        }
      },
      {
        immediate: true,
      }
    );

    function onChanged() {
      context.emit("input", valueInternal.value);
    }

    return { valueInternal, onChanged };
  },
});
