import {
  useConfigController,
  useConfigStore,
} from "../../config/useConfigStore";
import {
  ChildLinkId,
  ILinkParentV2,
  ILinkV2,
  ParentLinkId,
} from "./launchlink/launch-link-models";
import { computed, reactive } from "@vue/composition-api";
import {
  useAthleteCompSchedStore,
  useAthleteCompSchedStoreController,
} from "../../athleteCompSched/store/athleteCompSched-store";
import { CONFIG } from "../../common/config";
import { EmailMessageService } from "../../email-message/email-message-service";
import {
  LAUNCH_ROUTES_PATHS_V2,
  LAUNCH_ROUTES_PATHS_V2_BASE,
} from "./launch-routes-v2";
import {
  convertArrayToObject,
  simpleClone,
} from "../../common/common-service-utils";
import { AUTH_STORE_CONST } from "../../auth/auth-store";
import { useStore } from "../../app.store";
import { LAUNCH_ROUTES_PATHS, LaunchRouteKey } from "../launch-routes";

export interface IUseLaunchHeaderStateV2 {
  links: ILinkParentV2[];
  subLinksV1: Partial<Record<LaunchRouteKey, ILinkV2>>;
  showMobile: boolean;
  showChildrenLink: ILinkParentV2;
}

let showMobileGlobal = false;

export function useLaunchHeaderV2() {
  const store = useStore();
  const athleteCompSchedStore = useAthleteCompSchedStore();
  const athleteCompSchedStoreController = useAthleteCompSchedStoreController();

  const configStore = useConfigStore();
  const configController = useConfigController();

  const state = reactive<IUseLaunchHeaderStateV2>({
    links: [],
    showMobile: false,
    showChildrenLink: factoryLinkParentV2("HOME_V2"),
    subLinksV1: {
      BUILDER: {
        linkId: "BUILDER",
        linkDisplayName: "Create Comp",
        path: "#/" + LAUNCH_ROUTES_PATHS.BUILDER + "/0",
        disabled: false,
      },
    },
  });

  const showMobileRef = computed(() => {
    return showMobileGlobal;
  });

  const cartItemCount = computed(() => {
    let itemCount = 0;
    const cart = athleteCompSchedStore.cart;
    if (cart.meta && cart.meta.wcCart) {
      itemCount = cart.meta.wcCart.items.length;
    }
    return itemCount;
  });

  function reloadCart() {
    athleteCompSchedStoreController.reloadCart();
  }

  createUserLinks();

  function factoryLinkParentV2(linkId: ParentLinkId): ILinkParentV2 {
    return {
      linkId,
      linkDisplayName: "",
      path: "",
      showBadge: false,
      badgeText: "",
      children: [],
    };
  }

  function createUserLinks() {
    const linksForUser: ILinkParentV2[] = [];

    const unReadMessageCount = new EmailMessageService().getUnreadMessages(
      configStore.configApp.messages
    ).length;

    linksForUser.push({
      ...factoryLinkParentV2("MESSAGES_V2"),
      linkDisplayName: "Messages",
      path:
        LAUNCH_ROUTES_PATHS_V2_BASE + "/" + LAUNCH_ROUTES_PATHS_V2.MESSAGES_V2,
      showBadge: true,
      badgeText: unReadMessageCount.toString(),
    });

    //  My Account.
    linksForUser.push(createMyAccountLinks());

    //  Admin
    // if (configController.isAdmin.value) {
    //   linksForUser.push(createAdminLinks());
    // }

    // linksForUser.push({
    //   ...factoryLinkParentV2(),
    //   linkId: "CART",
    //   linkDisplayName: "Cart",
    //   showBadge: true,
    //   badgeText: cartItemCount.value.toString(),
    // });

    // linksForUser.push({
    //   ...factoryLinkParentV2(),
    //   linkId: "LOGIN",
    //   linkDisplayName: "Login",
    //   path: "#/v2/login-v2",
    // });

    state.links = linksForUser;
  }

  function createMyAccountLinks(): ILinkParentV2 {
    const link: ILinkParentV2 = {
      ...factoryLinkParentV2("PARENT__MY_ACCOUNT"),
      linkDisplayName: "My Profile",
      children: [
        // {
        //   linkId: "MY_NAME",
        //   linkDisplayName: "Nick Wall",
        //   path: "#",
        //   disabled: true,
        // },
        {
          linkId: "ATHLETES_V2",
          linkDisplayName: "My Athletes",
          path:
            LAUNCH_ROUTES_PATHS_V2_BASE +
            "/" +
            LAUNCH_ROUTES_PATHS_V2.ATHLETES_V2,
          disabled: false,
        },
        {
          linkId: "MY_ACCOUNT",
          linkDisplayName: "My Account",
          path: CONFIG.E4S_HOST + "/my-account",
          disabled: false,
        },
        {
          linkId: "USER_SEARCH_V2",
          linkDisplayName: "Users",
          path:
            LAUNCH_ROUTES_PATHS_V2_BASE +
            "/" +
            LAUNCH_ROUTES_PATHS_V2.USER_SEARCH_V2,
          disabled: false,
        },
        {
          linkId: "CLUB_SEARCH_V2",
          linkDisplayName: "Clubs",
          path:
            LAUNCH_ROUTES_PATHS_V2_BASE +
            "/" +
            LAUNCH_ROUTES_PATHS_V2.CLUB_SEARCH_V2,
          disabled: false,
        },
      ],
    };
    // if (configController.isAdmin.value) {
    //   link.children.push(        {
    //     linkId: "USER_SEARCH_V2",
    //     linkDisplayName: "Users",
    //     path: LAUNCH_ROUTES_PATHS_V2_BASE + "/" + LAUNCH_ROUTES_PATHS_V2.USER_SEARCH_V2,
    //     disabled: false,
    //   })
    // }
    return link;
  }

  function createCart(): ILinkParentV2 {
    return {
      linkId: "CART",
      linkDisplayName: "Cart",
      path: CONFIG.WP_BASKET,
      showBadge: true,
      badgeText: cartItemCount.value.toString(),
      children: [],
    };
  }

  const loginLink = computed(() => {
    return {
      linkId: "LOGIN",
      linkDisplayName: "Login",
      path: LAUNCH_ROUTES_PATHS_V2_BASE + "/" + LAUNCH_ROUTES_PATHS_V2.LOGIN_V2,
      showBadge: false,
      badgeText: "",
      children: [],
    };
  });

  // function createAdminLinks(): ILinkParentV2 {
  //   return {
  //     ...factoryLinkParentV2(),
  //     linkId: "PARENT__MY_ADMIN",
  //     linkDisplayName: "Admin",
  //     children: [
  //       {
  //         linkId: "ATHLETES",
  //         linkDisplayName: "Athletes",
  //         path: "#/v2/" + LAUNCH_ROUTES_PATHS_V2.ATHLETES_V2,
  //       },
  //       {
  //         linkId: "",
  //         linkDisplayName: "My Account",
  //         path: CONFIG.E4S_HOST + "/my-account",
  //       },
  //     ],
  //   };
  // }

  function toggleNav() {
    state.showMobile = !state.showMobile;
    showMobileGlobal = state.showMobile;

    // handleSticky();
  }

  /*
  function handleSticky() {
    const navigationMenu = document.querySelector(".e4s-navigation-bar-menu");

    if (navigationMenu !== null) {
      navigationMenu.classList.toggle("e4s-navigation-bar-menu--mobile");

      //  Got this issue where the "filter" which is sticky overlays everything, this is
      //  quickest and simplest solution.
      const bodyWrapper: HTMLElement | null = document.querySelector(".e4s-body-wrapper");
      if (bodyWrapper) {
        bodyWrapper.style.display = state.showMobile ? "none" : "";
      }
    }
  }
*/

  function hideMobileNav() {
    state.showMobile = false;
    showMobileGlobal = false;

    // handleSticky();
  }

  function setShowChildrenLink(linkParentV2: ILinkParentV2) {
    if (state.showChildrenLink.linkId === linkParentV2.linkId) {
      state.showChildrenLink = factoryLinkParentV2("HOME_V2");
    } else {
      state.showChildrenLink = simpleClone(linkParentV2);
    }
  }

  function hideChildrenLink() {
    state.showChildrenLink = factoryLinkParentV2("HOME_V2");
  }

  function doLogout() {
    store.dispatch(
      AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME +
        "/" +
        AUTH_STORE_CONST.AUTH_ACTIONS_LOGOUT,
      { redirectToHome: true, logoutUrl: configStore.configApp.logout }
    );
  }

  const getLinksById = computed<Record<ParentLinkId, ILinkParentV2>>(() => {
    return convertArrayToObject<ILinkParentV2>("linkId", state.links);
  });

  const getSubLinksById = computed<Record<ChildLinkId, ILinkV2>>(() => {
    return state.links.reduce<Record<string, ILinkV2>>((accum, parentLink) => {
      parentLink.children.forEach((child) => {
        accum[child.linkId] = child;
      });
      return accum;
    }, {});
  });

  return {
    state,
    configController,
    createUserLinks,
    createCart,
    toggleNav,
    setShowChildrenLink,
    hideChildrenLink,
    cartItemCount,
    showMobileRef,
    hideMobileNav,
    reloadCart,
    doLogout,
    getLinksById,
    getSubLinksById,
    loginLink,
  };
}
