
























import {
  defineComponent,
  SetupContext,
  onBeforeUnmount,
} from "@vue/composition-api";
import LaunchHeaderBlankV2 from "../../../../launch/v2/launch-header-blank-v2.vue";

export default defineComponent({
  name: "modal-v2",
  components: { LaunchHeaderBlankV2 },
  props: {
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    alwaysShowHeaderBlank: {
      type: Boolean,
      default: false,
    },
    disableScroll: {
      type: Boolean,
      default: false,
    },
  },
  setup(
    props: {
      isFullScreen: boolean;
      alwaysShowHeaderBlank: boolean;
      disableScroll: boolean;
    },
    context: SetupContext
  ) {
    const cacheOverflow = document.body.style.overflow;
    console.log("ModalV2 cacheOverflow", cacheOverflow);
    if (props.disableScroll) {
      document.body.style.overflow = "hidden";
    }
    // document.body.style.overflow = "hidden";

    onBeforeUnmount(() => {
      console.log("ModalV2 onBeforeUnmount setting overflow to 'auto'");
      document.body.style.overflow = "auto";
    });

    return {};
  },
});
