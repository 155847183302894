import {ResourceData} from "../common/resource/resource-service";
import {IPrice} from "./price-models";
import {IServerPagingResponseList} from "../common/common-models";
import https from "../common/https";


export class PriceData extends ResourceData<IPrice> {
    constructor() {
        super("/v5/price");
    }

    public getPricesForComp(compId: number): Promise<IServerPagingResponseList<IPrice>> {
        return https.get( this.getEndPoint() + "?compId=" + compId) as any as Promise<IServerPagingResponseList<IPrice>>;
    }
}
