import Vue from "vue";

import VueI18n from "vue-i18n";
import IL8N_EN from "./lang/en";
// import IL8N_FR from "./lang/fr";


Vue.use(VueI18n);

export const LANGUAGE_OPTIONS = {
    EN: {
        id: "en",
        label: "EN"
    },
    FR: {
        id: "fr",
        label: "FR"
    }
};

const loadedLanguages: string[] = [LANGUAGE_OPTIONS.EN.id];

export const i18n = new VueI18n({
    locale: LANGUAGE_OPTIONS.EN.id,
    fallbackLocale: LANGUAGE_OPTIONS.EN.id,
    messages: {
        en: IL8N_EN
    }
});

export const setI18nLanguage = (lang: string) => {
    i18n.locale = lang;
    return lang;
};

export const loadLanguageAsync = (lang: string) => {
    if (i18n.locale !== lang) {
        if (!loadedLanguages.includes(lang)) {
            return import(/* webpackChunkName: "dyn-lang-[request]" */ "./lang/" + lang)
                .then((msgs: any) => {
                    i18n.setLocaleMessage(lang, msgs.default);
                    loadedLanguages.push(lang);
                    return setI18nLanguage(lang);
                });
        }
        return Promise.resolve(setI18nLanguage(lang));
    }
    return Promise.resolve(lang);
};

