











import { defineComponent, PropType, SetupContext } from "@vue/composition-api";
import { UiVersion } from "./config-app-models";
import { useConfigVersionSwitch } from "./useConfigVersionSwitch";
import { useConfigStore } from "./useConfigStore";

export default defineComponent({
  name: "config-version-switch-v1",
  components: {},
  props: {
    currentUiVersion: {
      type: String as PropType<UiVersion>,
      default: () => {
        const uiVersion: UiVersion = "v1";
        return uiVersion;
      },
    },
  },
  setup(props: { currentUiVersion: UiVersion }, context: SetupContext) {
    const configStore = useConfigStore();
    const configVersionSwitch = useConfigVersionSwitch(
      props.currentUiVersion,
      configStore.configApp.userInfo.user.version
    );
    return { configVersionSwitch };
  },
});
