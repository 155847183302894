import * as R from "ramda";
import {ActionContext, Module} from "vuex";
import {IRootState} from "../app.store";
import {IServerGenericResponse} from "../common/common-models";
import {ClubData} from "./club-data";
import {IClub} from "./club-models";

export enum CLUB_STORE_CONST {
    CLUB_STORE_CONST_MODULE_NAME = "CLUB_STORE_CONST_MODULE_NAME",

    // <MUTATIONS>
    CLUB_STORE_MUTATIONS_SET_CLUBS = "CLUB_STORE_MUTATIONS_SET_CLUBS",
    CLUB_STORE_MUTATIONS_SET_CLUBS_LOADING = "CLUB_STORE_MUTATIONS_SET_CLUBS_LOADING",
    // </MUTATIONS>

    // <ACTIONS>
    CLUB_STORE_ACTIONS_GET_COMP_ORGS = "CLUB_STORE_ACTIONS_GET_COMP_ORGS"
    // </ACTIONS>
}

export interface IClubDropDown {
    clubs: IClub[];
    clubSelected: IClub;
    clubsLoading: boolean;
}
export interface IClubStoreState {
    clubDropDown: IClubDropDown;
}

const mutations = {
    [ CLUB_STORE_CONST.CLUB_STORE_MUTATIONS_SET_CLUBS ](state: IClubStoreState, clubs: IClub[]) {
        state.clubDropDown.clubs = R.clone(clubs);
    },
    [ CLUB_STORE_CONST.CLUB_STORE_MUTATIONS_SET_CLUBS_LOADING ](state: IClubStoreState, isLoading: boolean) {
        state.clubDropDown.clubsLoading = isLoading;
    }
};

const actions = {
    [ CLUB_STORE_CONST.CLUB_STORE_ACTIONS_GET_COMP_ORGS ](
        context: ActionContext<IClubStoreState, IRootState>,
        payload: {
            public: boolean,
            defaultValueText: string,
            orgId: number
        }
     ) {
        const clubData = new ClubData();

        context.commit(CLUB_STORE_CONST.CLUB_STORE_MUTATIONS_SET_CLUBS_LOADING, true);
        const promData = payload.public ? clubData.getCompOrgsPublic() : clubData.getCompOrgs(payload.orgId);

        return promData
        .then( (response: IServerGenericResponse) => {
            if (response.errNo > 0) {
                return;
            }
            let clubs: IClub[] = response.data as IClub[];
            clubs = clubs.sort((a: IClub, b: IClub) => {
                return a.club.localeCompare(b.club);
            });
            clubs.unshift({club: (payload.defaultValueText ? payload.defaultValueText : "Select Organiser..."), clubid: 0, id: 0});
            context.commit(CLUB_STORE_CONST.CLUB_STORE_MUTATIONS_SET_CLUBS, response.data);
            context.commit(CLUB_STORE_CONST.CLUB_STORE_MUTATIONS_SET_CLUBS_LOADING, false);
            return;
        })
        .catch((error) => {
            context.commit(CLUB_STORE_CONST.CLUB_STORE_MUTATIONS_SET_CLUBS, []);
            context.commit(CLUB_STORE_CONST.CLUB_STORE_MUTATIONS_SET_CLUBS_LOADING, false);
            return;
        });
    }
};

export const clubStore: Module<IClubStoreState, IRootState> = {
    namespaced: true,
    state: {
        clubDropDown: {
            clubs: [],
            clubsLoading: false,
            clubSelected: {} as IClub
        }
    },
    mutations,
    actions,
};
