import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { appStore } from "../app.store";
import { router } from "../index";
import { AUTH_STORE_CONST } from "../auth/auth-store";
import {
  IUserMessage,
  USER_MESSAGE_LEVEL,
} from "../user-message/user-message-models";
import {
  messageDispatchHelper,
  USER_MESSAGE_STORE_CONST,
} from "../user-message/user-message-store";
import { CONFIG } from "./config";
import { RawLocation } from "vue-router";
import { LAUNCH_ROUTES_PATHS } from "../launch/launch-routes";
import { useConfigController } from "../config/useConfigStore";
import { LAUNCH_ROUTES_PATHS_V2_BASE } from "../launch/v2/launch-routes-v2";

const https: AxiosInstance = Axios.create({
  baseURL: CONFIG.REST_URL,
});

export interface HttpGenericError extends AxiosError {
  message: string;
  stack: string;
}

// https.defaults.transformResponse = [
//     (data: any) => {
//         //  Leaving here as exmaple, N.B. transforms happen before intercepts.
//         return (data);
//     }
// ];

// add a request interceptor
https.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    // do something before request is sent
    //  console.warn("https.interceptors.request AxiosRequestConfig", config);

    //  JWT token.
    const isUserLoggedIn =
      appStore.state[AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME].isLoggedIn;
    //  console.warn("isUserLoggedIn isUserLoggedIn isUserLoggedIn isUserLoggedIn isUserLoggedIn: " + isUserLoggedIn);
    if (isUserLoggedIn) {
      const userToken =
        appStore.state[AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME].tokenReponse
          .token;
      config.headers.Authorization = "Bearer " + userToken;
    }
    return config;
  },
  function (error: AxiosError) {
    // do something with request error
    console.error("https.interceptors.request AxiosError", error);
    return Promise.reject(error);
  }
);

// add a response interceptor
https.interceptors.response.use(
  function (response: AxiosResponse) {
    //  console.warn("https.interceptors.response AxiosResponse", response);
    //  If we send back AxiosResponse, we are tightly coupling App + Axios.
    //  Any http\ status errors, etc. handle them here.
    return response.data as any;
  },
  function (error: AxiosError) {
    appStore.commit(
      USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME +
        "/" +
        USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE,
      {
        level: USER_MESSAGE_LEVEL.INFO,
        message:
          "Error: " +
          (error.response && error.response.status
            ? error.response.status
            : "Server ERROR") +
          (error.response && error.response.data
            ? " - " + error.response.data.error
            : ""),
      } as IUserMessage
    );

    const apiMessage: string =
      error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : "";

    messageDispatchHelper(
      error.message + (apiMessage.length > 0 ? ", " + apiMessage : ""),
      USER_MESSAGE_LEVEL.ERROR.toString()
    );

    if (error.response && error.response.status === 401) {
      console.log("401: Authentication error");
      // appStore.commit(
      //     USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME + "/" + USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE, {
      //         level: USER_MESSAGE_LEVEL.INFO,
      //         message: error.response.data.error
      //     } as IUserMessage
      // );
      // appStore.dispatch(AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME + "/" + AUTH_STORE_CONST.MUTATIONS_SHOW_LOGIN);
      // query: {
      //     redirectFrom: to.fullPath
      // }

      //  This handles a user routing to a route or requesting something that requires
      //  authentication...really, the route should have a route guard on it....?!?!?!
      const configController = useConfigController();
      let redirectPath: string = "";
      if (configController.getVersion.value === "v2") {
        redirectPath = LAUNCH_ROUTES_PATHS_V2_BASE;
        // console.log("401: Authentication error, redirectPath: " + redirectPath);
        // router.push( {
        //   path: redirectPath
        // } as RawLocation);
      } else {
        redirectPath = LAUNCH_ROUTES_PATHS.SHOW_ENTRIES;
        // console.log("401: Authentication error, redirectPath: " + redirectPath);
        //  Dont' need redirect...not sending them to login....since url might be an api call.
        // router.push({
        //   name: redirectPath,
        //   query: {
        //     redirectFrom: window.location.hash,
        //   },
        // } as RawLocation);
      }
      console.log("401: Authentication error, redirectPath: " + redirectPath);
      router.push({
        path: redirectPath,
      } as RawLocation);

      // router.push({name: "login"});
    }

    console.error("https.interceptors.response AxiosError>>>", error);
    return Promise.reject(error);
  }
);

export default https;
