import { IBuilderOptions, IContact } from "../../builder/builder-models";
import {
  ICompetitionInfo,
  ICompetitionSummaryPublic,
} from "../competition-models";

export function getOrganiserDetails(
  competition: ICompetitionSummaryPublic | ICompetitionInfo
): Pick<IContact, "userName" | "email"> {
  const defaultContact: Pick<IContact, "userName" | "email"> = {
    userName: "",
    email: "",
  };
  if ((competition as ICompetitionInfo).id === 0) {
    return defaultContact;
  }

  if (competition.compId && competition.compId === 0) {
    return defaultContact;
  }

  const options: IBuilderOptions = competition.options;
  if (options) {
    defaultContact.userName = options.contact.userName || "";
    defaultContact.email = options.contact.email || "";
  }
  return defaultContact;
}

export function getOrganiserDetailsToDisplay(
  competition: ICompetitionSummaryPublic | ICompetitionInfo
): string {
  if ((competition as ICompetitionInfo).id === 0) {
    return "";
  }

  if (competition.compId && competition.compId === 0) {
    return "";
  }

  const options: IBuilderOptions = competition.options;
  const contactParts: string[] = [];
  if (options) {
    if (options.contact.userName) {
      contactParts.push(options.contact.userName);
    }
    if (options.contact.email) {
      contactParts.push(options.contact.email);
    }
    if (options.contact.tel.length > 0 && options.contact.visible) {
      contactParts.push(options.contact.tel);
    }
    return contactParts.join(", ");
  }
  return "";
}
