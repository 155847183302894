




















import { IUserInfo } from "../../config/config-app-models";

export default {
  props: ["userInfo", "currency"],
  methods: {
    getCredit: (userInfo: IUserInfo): number => {
      const credit: number = Math.trunc(userInfo.e4s_credit);
      return credit;
    },
    getCreditMessage: (userInfo: IUserInfo, currency: string): string => {
      // const credit = -6;
      const credit: number = userInfo.e4s_credit;
      return Math.trunc(credit) === 0
        ? ""
        : "You have a " +
            (credit > 0 ? "credit" : " surcharge") +
            " of " +
            currency +
            Math.abs(credit);
    },
  },
};
