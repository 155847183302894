










































import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import { ICompetitionSummaryPublic } from "../../../../competition/competition-models";
import * as R from "ramda";
import { CONFIG } from "../../../../common/config";
import { CompetitionService } from "../../../../competition/competiton-service";
import { useConfigController } from "../../../../config/useConfigStore";
import { competitionStatusDescription } from "../../../../competition/v2/competiton-service-v2";
import PillV2 from "../../../../common/ui/layoutV2/pills/pill-v2.vue";
import E4sLogoSvg from "../../../../common/ui/svg/E4sLogoSvg.vue";
import CompetitionEntryCounts from "../../../../competition/v2/CompetitionEntryCounts.vue";

export default defineComponent({
  name: "comp-header-logo-counts-v2",
  components: { CompetitionEntryCounts, E4sLogoSvg, PillV2 },
  props: {
    competitionSummaryPublic: {
      type: Object as PropType<ICompetitionSummaryPublic>,
      default: () => {
        return [];
      },
    },
  },
  setup(
    props: { competitionSummaryPublic: ICompetitionSummaryPublic },
    context: SetupContext
  ) {
    const competitionService = new CompetitionService();
    const configController = useConfigController();

    const getAthleteCount = computed(() => {
      return R.isNil(props.competitionSummaryPublic.entries.athletes)
        ? 0
        : props.competitionSummaryPublic.entries.athletes;
    });

    const getEntryCount = computed(() => {
      return R.isNil(props.competitionSummaryPublic.entries.indiv)
        ? 0
        : props.competitionSummaryPublic.entries.indiv;
    });

    const getTeamCount = computed(() => {
      return R.isNil(props.competitionSummaryPublic.entries.team)
        ? 0
        : props.competitionSummaryPublic.entries.team;
    });

    const getLogo = computed(() => {
      if (
        props.competitionSummaryPublic.logo === "/resources/default_logo.gif"
      ) {
        return "";
      }
      return CONFIG.E4S_HOST + "/" + props.competitionSummaryPublic.logo;
    });

    const isEntryOpen = computed(() => {
      if (!props.competitionSummaryPublic.active) {
        return false;
      }
      return competitionService.isEntryOpen(props.competitionSummaryPublic);
    });

    const hasBuilderPermissionForComp = computed(() => {
      return configController.hasBuilderPermissionForComp(
        props.competitionSummaryPublic.compOrgId,
        props.competitionSummaryPublic.compId
      );
    });

    const getAccessLevel = computed(() => {
      if (configController.isAdmin.value || hasBuilderPermissionForComp.value) {
        return (
          " (Access: " +
          (configController.isAdmin.value ? "Admin" : "Builder") +
          ")"
        );
      }
      return "";
    });

    const getPillMessage = computed(() => {
      return (
        competitionStatusDescription(props.competitionSummaryPublic) +
        " " +
        getAccessLevel.value
      );
    });

    return {
      getLogo,
      getAthleteCount,
      getEntryCount,
      getTeamCount,
      isEntryOpen,
      getAccessLevel,
      getPillMessage,
    };
  },
});
