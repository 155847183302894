import {ActionContext, Module} from "vuex";
import {IRootState} from "../app.store";
import https from "../common/https";
import {ITokenFailureResponse, ITokenReponse} from "./auth-models";
import {LoginData} from "./login-data";
import {messageDispatchHelper} from "../user-message/user-message-store";
import {USER_MESSAGE_LEVEL} from "../user-message/user-message-models";

export enum AUTH_STORE_CONST {
    AUTH_CONST_MODULE_NAME = "AUTH_CONST_MODULE_NAME",

    // <MUTATIONS>
    AUTH_MUTATIONS_PROCESSING_REQ = "AUTH_MUTATIONS_PROCESSING_REQ",
    AUTH_MUTATIONS_TOKEN_RESPONSE = "AUTH_MUTATIONS_TOKEN_RESPONSE",
    AUTH_MUTATIONS_TOKEN_RESPONSE_FAILURE = "AUTH_MUTATIONS_TOKEN_RESPONSE_FAILURE",
    AUTH_MUTATIONS_LOGGED_OUT = "AUTH_MUTATIONS_IS_LOGGED_IN",
    AUTH_MUTATIONS_TOGGLE_LOGGED_IN = "AUTH_MUTATIONS_TOGGLE_LOGGED_IN",
    MUTATIONS_LOGGED_IN = "MUTATIONS_LOGGED_IN",
    MUTATIONS_SHOW_LOGIN = "MUTATIONS_SHOW_LOGIN",
    // </MUTATIONS>

    // <GETTERS>
    AUTH_GETTERS_IS_LOGGED_IN = "AUTH_GETTERS_IS_LOGGED_IN ",
    // </GETTERS>

     // <ACTIONS>
     AUTH_ACTIONS_LOGIN = "AUTH_ACTIONS_LOGIN",
     AUTH_ACTIONS_LOGOUT = "AUTH_ACTIONS_LOGOUT"
     // </ACTIONS>
}

export interface IAuthStoreState {
    isProcessingLoginRequest: boolean;
    isLoggedIn: boolean;
    tokenReponse: ITokenReponse;
    tokenResponseFailure: ITokenFailureResponse;
    showLogin: boolean;
}

const mutations = {
    [ AUTH_STORE_CONST.AUTH_MUTATIONS_PROCESSING_REQ ](state: IAuthStoreState, isProcessingRequest: boolean) {
        state.isProcessingLoginRequest = isProcessingRequest;
    },

    [ AUTH_STORE_CONST.AUTH_MUTATIONS_TOKEN_RESPONSE ](state: IAuthStoreState, tokenReponse: ITokenReponse) {
        state.isLoggedIn = true;
        state.tokenReponse = tokenReponse;
    },

    [ AUTH_STORE_CONST.AUTH_MUTATIONS_TOKEN_RESPONSE_FAILURE ](state: IAuthStoreState, tokenReponseFailure: ITokenFailureResponse) {
        state.isLoggedIn = false;
        state.tokenResponseFailure = tokenReponseFailure;
    },

    [ AUTH_STORE_CONST.AUTH_MUTATIONS_LOGGED_OUT ](state: IAuthStoreState) {
        state.isLoggedIn = false;
        state.tokenReponse = {} as ITokenReponse;
        state.tokenResponseFailure = {} as ITokenFailureResponse;
    },

    [ AUTH_STORE_CONST.AUTH_MUTATIONS_TOGGLE_LOGGED_IN ](state: IAuthStoreState) {
        state.isLoggedIn = !state.isLoggedIn;
    },

    [ AUTH_STORE_CONST.MUTATIONS_LOGGED_IN ](state: IAuthStoreState, isLoggedIn: boolean) {
        state.isLoggedIn = isLoggedIn;
    },

    [ AUTH_STORE_CONST.MUTATIONS_SHOW_LOGIN ](state: IAuthStoreState, showLogin: boolean) {
        state.showLogin = showLogin;
    }
};

const getters = {
    [ AUTH_STORE_CONST.AUTH_GETTERS_IS_LOGGED_IN ](state: IAuthStoreState) {
        return state.tokenReponse && state.tokenReponse.token && state.tokenReponse.token.length > 0;
    }
};

const actions = {

    [ AUTH_STORE_CONST.AUTH_ACTIONS_LOGIN ](
        context: ActionContext<IAuthStoreState, IRootState>,
        payload: {
            username: string,
            password: string
        }
    ) {

        context.commit(AUTH_STORE_CONST.AUTH_MUTATIONS_PROCESSING_REQ, true);
        context.commit(AUTH_STORE_CONST.AUTH_MUTATIONS_TOKEN_RESPONSE, {});
        context.commit(AUTH_STORE_CONST.AUTH_MUTATIONS_TOKEN_RESPONSE_FAILURE, {});

        return https.
            post( process.env.E4S_HOST + "/wp-json/jwt-auth/v1/token?username=" + payload.username + "&password=" + payload.password)
            .then((response: any) => {
                context.commit(AUTH_STORE_CONST.AUTH_MUTATIONS_TOKEN_RESPONSE, response as ITokenReponse);
                context.commit(AUTH_STORE_CONST.AUTH_MUTATIONS_PROCESSING_REQ, false);
            }).catch((error: any) => {
                context.commit(AUTH_STORE_CONST.AUTH_MUTATIONS_TOKEN_RESPONSE_FAILURE, error.response.data);
                context.commit(AUTH_STORE_CONST.AUTH_MUTATIONS_PROCESSING_REQ, false);
            });
    },


    [ AUTH_STORE_CONST.AUTH_ACTIONS_LOGOUT ](
        context: ActionContext<IAuthStoreState, IRootState>, payload: {redirectToHome: boolean, logoutUrl: string}) {
        const loginData: LoginData = new LoginData();
        loginData.logout(payload.logoutUrl)
            .then((response: any) => {
                console.log("AUTH_ACTIONS_LOGOUT", response);
            }).catch((error: any) => {
                messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
            })
            .finally( () => {
                if (payload.redirectToHome) {
                    window.location.href = window.location.href.split("#")[0];
                }
            });
    }
};


export const authStore: Module<IAuthStoreState, IRootState> = {
    namespaced: true,
    state: {
        isProcessingLoginRequest: false,
        isLoggedIn: false,
        tokenReponse: {} as ITokenReponse,
        tokenResponseFailure: {} as ITokenFailureResponse,
        showLogin: false
    },
    mutations,
    getters,
    actions
};

