




























import Vue from "vue";
import Component from "vue-class-component";
import https from "../https";
import { IFileUploadResponse } from "../common-models";
import { USER_MESSAGE_LEVEL } from "../../user-message/user-message-models";
import { messageDispatchHelper } from "../../user-message/user-message-store";
import LoadingSpinner from "./loading-spinner.vue";
import Status from "../../competition/status/status.vue";
import { Prop } from "vue-property-decorator";

@Component({
  name: "file-upload",
  components: {
    Status,
    "loading-spinner": LoadingSpinner,
  },
})
export default class FileUpload extends Vue {
  @Prop({ default: "/v5/upload" }) public endPoint: string;

  public file: any = null;
  public isLoading: boolean = false;

  public doUploadForm() {
    const memberData = new FormData();
    memberData.append("file", this.file);

    const req = https.post(this.endPoint, memberData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }) as any as Promise<IFileUploadResponse>;

    this.isLoading = true;
    req
      .then((response: IFileUploadResponse) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        messageDispatchHelper(
          "File uploaded",
          USER_MESSAGE_LEVEL.INFO.toString()
        );
        this.$emit("onUpload", response.path);
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public onChangeImgFileStore() {
    // @ts-ignore
    this.file = this.$refs.file.files[0];
    this.doUploadForm();
  }
}
