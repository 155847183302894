




import { defineComponent, SetupContext } from "@vue/composition-api";
import { useAuthStoreController } from "../../auth/useAuthStore";
import { useRoute } from "../../router/migrateRouterVue3";
import { LAUNCH_ROUTES_PATHS } from "../launch-routes";

export default defineComponent({
  name: "SecureRedirect",
  components: {},
  props: {},
  setup(props: any, context: SetupContext) {
    const authStoreController = useAuthStoreController();
    const route = useRoute();
    if (authStoreController.isLoggedIn) {
      const url = route.query.url as string;
      window.location.href = url;
    } else {
      //  This route is protected...so something went wrong.
      // window.location.href = "/login";
      window.location.href = "/" + LAUNCH_ROUTES_PATHS.LOGIN_V1;
    }

    return {};
  },
});
