import { Validator } from "vee-validate";
import {CommonService} from "./common-service";

const commonService: CommonService = new CommonService();


const dateValidator = {
    getMessage(field: any, args: any) {
        return "Please enter date DD/MM/YYYY, e.g. 28/03/1970!";
    },
    validate(value: any, args: any) {
        return commonService.validateDateEntry(value);
    }
};

Validator.extend("dateValidator", dateValidator);

const timeValidator = {
    getMessage(field: any, args: any) {
        return "Please enter time HH:mm, e.g. 09:25, 23:59!";
    },
    validate(value: any, args: any) {
        return commonService.validateTime(value);
    }
};
Validator.extend("timeValidator", timeValidator);
