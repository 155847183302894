import {IOrg} from "../org/org-models";
import {ILocation} from "../location/location-models";
import {IBuilderPermission} from "./builder-models";
// import {IAgeGroup} from "../agegroup/agegroup-models";
import {IEventE4S} from "../event/event-models";
import {IEventUomType} from "../uom/uom-models";
import {IBuilderStoreState} from "./builder-store";
import {BuilderService} from "./builder-service";
import {ICompEvent} from "../compevent/compevent-models";
import {IDiscount} from "../discount/discount-models";
import {ICompRule} from "../comprule/comprule-models.";
import {IObjectKeyType} from "../common/common-models";
import {IArea} from "../area/area-models";

const builderService: BuilderService = new BuilderService();

export class BuilderStoreService {
    public factoryGetBuilder(): IBuilderStoreState {
        return {
            ageGroupsDefaultLoading: false,
            builderCompetition: builderService.factoryGetBuilder({}),
            builderCompetitionLoading: false,
            builderCompetitionLoadingMessage: "",
            compOrgs: [] as IOrg[],
            compOrgsLoading: false,
            locations: [] as ILocation[],
            locationsLoading: false,
            areas: [] as IArea[],
            areasLoading: false,
            ageGroupsForComp: [],
            ageGroupsDefault: [],
            ageGroupCompCoverageModels: [],
            ageGroupsForCompLoading: false,
            ageGroupsAll: [],
            ageGroupsAllLoading: false,
            ageGroupCreateLoading: false,
            events: [] as IEventE4S[],
            eventsLoading: false,
            uoms: [] as IEventUomType[],
            uomsLoading: false,
            pricesLoading: false,
            prices: [],
            compEventLoading: false,
            builderCompEventBeingEdited: builderService.factoryBuildCompEvent(),
            compEventsSchedule: [] as ICompEvent[],
            discounts: [] as IDiscount[],
            compRules: [] as ICompRule[],
            builderPermissions: {
                FRONT: {
                    levelid: 0,
                    level: ""
                } as IBuilderPermission
            } as IObjectKeyType<IBuilderPermission>,
            ui: {
                showLocationPicker: false
            },
            stickyEntryDate: ""
            // secondaries: [],
            // secondariesLoading: false
        };
    }
}
