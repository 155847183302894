import * as R from "ramda";
import {
  ICompEvent,
  IEventGroupLimits,
  IEventGroupSummary,
  IMultiEventInfo,
} from "./compevent-models";
import { AgeGroupService } from "../agegroup/agegroup-service";
import {
  IEventGroupSelect,
  IEventSeed,
  IRowOptions,
  ISecondarySpend,
} from "../athletecompsched/athletecompsched-models";
import { IEventE4S, IUniqueEventDisplay } from "../event/event-models";
import { IAgeGroupBase } from "../agegroup/agegroup-models";
import { EventService } from "../event/event-service";
import { CommonService } from "../common/common-service";
import { PriceService } from "../price/price-service";
import { AthleteCompSchedService } from "../athletecompsched/athletecompsched-service";
import { IObjectKeyType } from "../common/common-models";
import { IValidationProp } from "../validation/validation-models";
import { ValidationService } from "../validation/validation-service";
import { ICompEventSchedGrid } from "./compeventschedule/compeventschedule-models";
import * as CommonServiceUtils from "../common/common-service-utils";
// import {CompEventScheduleService} from "./compeventschedule/compeventschedule-service";

const sgeGroupService: AgeGroupService = new AgeGroupService();
const eventService: EventService = new EventService();
const commonService: CommonService = new CommonService();
const priceService: PriceService = new PriceService();
const athleteCompSchedService: AthleteCompSchedService =
  new AthleteCompSchedService();

const validationService: ValidationService = new ValidationService();

// const compEventScheduleService = new CompEventScheduleService();

export class CompEventService {
  //  TODO this needs changing.
  public factoryGetCompEvent(): ICompEvent {
    return {
      id: 0,
      compId: 0,
      event: eventService.factoryGetEvent(),
      startDateTime: "",
      isOpen: 1,
      ageGroup: sgeGroupService.factoryGetAgeGroup(),
      price: priceService.factoryGetPrice(),
      maxAthletes: 0,
      maxGroup: "",
      eventNo: 0,
      eventGroup: "",
      multiEventInfo: [] as IMultiEventInfo[],
      split: 0,
      sortDateTime: "",
      eoptions: {
        class: "",
      },
      options: {
        athleteSecurity: {
          clubs: [],
          counties: [],
          regions: [],
        },
        maxInHeat: 0,
        unregisteredAthletes: true,
        registeredAthletes: true,
        helpText: "",
        xeText: "",
        xiText: "",
        xrText: "",
        xbText: "",
        maxAgeGroupCnt: 0,
        unique: [] as IUniqueEventDisplay[],
        uniqueEventGroups: [],
        secondarySpend: {
          isParent: false,
          parentCeid: 0,
          mandatoryGroupId: 0,
          alwaysShow: false,
        } as ISecondarySpend,
        isTeamEvent: false,
        eventTeam: athleteCompSchedService.factoryEventTeamCe(),
        excludeFromCntRule: false,
        ageGroups: [] as IAgeGroupBase[],
        mandatoryPB: false,
        trials: "",
        cardNotes: "",
        athleteEventCol: "",
        availableFrom: "",
        availableFromStatus: 0,
        availableTo: "",
        availableToStatus: 0,
        hideOnDisable: false,
        singleAge: true,
        warningMessage: "",
        security: {
          clubs: [],
          counties: [],
          regions: [],
        },
        rowOptions: {
          autoExpandHelpText: false,
          showPB: true,
          showPrice: false,
          showEntryCount: true,
        } as IRowOptions,
        checkIn: {
          // checkInMins: 60,
          from: -1,
          to: -1,
        },
        heatInfo: {
          useLanes: "A",
          heatDurationMins: 0,
        },
        eventGroupInfo: {
          reportInfo: "",
          trialInfo: "",
        },
        seed: {
          gender: false,
          age: false,
          type: "O",
          qualifyToEg: {
            id: 0,
            name: "",
            eventNo: 0,
            isMultiEvent: false,
          },
          laneCount: 8,
          firstLane: 1,
          waiting: false,
        },
        entriesFrom: {
          id: 0,
          name: "",
          eventNo: 0,
          isMultiEvent: false,
        },
        includeEntriesFromEgId: {
          id: 0,
          name: "",
          eventNo: 0,
          isMultiEvent: false,
        },
        multiEventOptions: {
          childEvents: [],
        },
      },
      eventGroupSummary: this.factoryEventGroupSummary(),
      entryCount: {
        total: 0,
        waiting: 0,
      },
    };
  }

  // as unknown as ICeoptions

  public factoryEventGroupSummary(): IEventGroupSummary {
    return {
      id: 0,
      isOpen: 1,
      eventNo: 1,
      name: "",
      notes: "",
      type: "T",
      typeNo: 1,
      bibSortNo: "",
      eventDateTime: "",
      options: {
        seed: this.factoryEventSeed(),
      },
      limits: this.factoryEventGroupLimits(),
    };
  }

  public factoryEventSeed(): IEventSeed {
    return {
      age: false,
      firstLane: 0,
      gender: false,
      laneCount: 0,
      qualifyToEg: this.factoryEventGroupSelect(),
      type: "O",
      waiting: false,
    };
  }

  public factoryEventGroupLimits(): IEventGroupLimits {
    return {
      maxAthletes: 0,
      maxInHeat: 0,
    };
  }

  public factoryEventGroupSelect(): IEventGroupSelect {
    return {
      id: 0,
      eventNo: 0,
      name: "",
      isMultiEvent: false,
    };
  }

  public addInitialEventToCompEvent(
    compEvent: ICompEvent,
    eventE4s: IEventE4S
  ) {
    compEvent = R.clone(compEvent);
    eventE4s = R.clone(eventE4s);
    compEvent.event = eventE4s;

    // R.mergeDeepRight(compEventTemplate, newValue)

    // compEvent.options = R.mergeDeepRight(compEvent.options, eventE4s.options) as any as ICeoptions

    //  On initial load, take the "default" options from the Event.
    //  TODO has to be better way, but I can't use any of the R merge funcs, only need to merge props of IOptionsDefault.
    compEvent.options.helpText =
      eventE4s.options.helpText || compEvent.options.helpText;
    // if (eventE4s.options.rowOptions) {
    //     compEvent.options.rowOptions = eventE4s.options.rowOptions;
    // } else {
    //     compEvent.options.rowOptions = eventE4s.options.rowOptions;
    //     const xxx = (R.mergeRight(compEvent.options.rowOptions, eventE4s.options.rowOptions)) as IRowOptions;
    //     // compEvent.options.rowOptions = ;
    //     console.log(xxx);
    // }
    const currentRowOptions = compEvent.options.rowOptions
      ? compEvent.options.rowOptions
      : new AthleteCompSchedService().factoryRowOptions();
    compEvent.options.rowOptions = eventE4s.options.rowOptions
      ? (R.mergeDeepRight(
          currentRowOptions,
          eventE4s.options.rowOptions
        ) as IRowOptions)
      : compEvent.options.rowOptions;
    // ->>>compEvent.options.unique = eventE4s.options.unique ? eventE4s.options.unique : compEvent.options.unique;
    compEvent.options.isTeamEvent = eventE4s.options.isTeamEvent
      ? eventE4s.options.isTeamEvent
      : compEvent.options.isTeamEvent;
    //  TODO Until we sort the model...casting.
    // compEvent.options.eventTeam = eventE4s.options.eventTeam ? eventE4s.options.eventTeam as any as IEventTeamCe : compEvent.options.eventTeam;
    compEvent.options.excludeFromCntRule = compEvent.options.excludeFromCntRule
      ? compEvent.options.excludeFromCntRule
      : eventE4s.options.excludeFromCntRule;

    return compEvent;
  }

  public isStartDateTimeOk(compEvent: ICompEvent) {
    return commonService.isDateTimeValid(compEvent.startDateTime);
  }

  public isPriceOk(compEvent: ICompEvent) {
    if (this.isScheduleOnly(compEvent)) {
      return true;
    }
    return compEvent.price.id > 0;
  }

  public isScheduleOnly(compEvent: ICompEvent) {
    return compEvent.maxAthletes === -1;
  }

  public validate(compEvent: ICompEvent): IObjectKeyType<IValidationProp> {
    let validationState: IObjectKeyType<IValidationProp> = {};

    if (compEvent.compId === 0) {
      validationState = validationService.addMessage(
        "compId",
        "Valid competition needs selecting.",
        validationState
      );
    }

    if (compEvent.eventGroup) {
      if (compEvent.eventGroup.length === 0) {
        validationState = validationService.addMessageCompound(
          {
            title: "Basic Section",
            subTitle: "Event Group",
            propPath: "eventGroup",
            message: "Event discipline description required",
          },
          validationState
        );
      }
      if (compEvent.eventGroup.length > 30) {
        validationState = validationService.addMessageCompound(
          {
            title: "Basic Section",
            subTitle: "Event Group",
            propPath: "eventGroup",
            message: "Event discipline description restricted to 30 chars",
          },
          validationState
        );
      }
    } else {
      //  Handling legacy data
      validationState = validationService.addMessageCompound(
        {
          title: "Basic Section",
          subTitle: "Event Group",
          propPath: "eventGroup",
          message: "Event discipline description required",
        },
        validationState
      );
    }

    if (compEvent.startDateTime.length === 0) {
      validationState = validationService.addMessageCompound(
        {
          title: "Basic Section",
          subTitle: "Start Time",
          propPath: "startDateTime",
          message: "Start time required",
        },
        validationState
      );
    } else {
      if (!this.isStartDateTimeOk(compEvent)) {
        validationState = validationService.addMessageCompound(
          {
            title: "Basic Section",
            subTitle: "Start Time",
            propPath: "startDateTime",
            message: "Start time not valid",
          },
          validationState
        );
      }
    }

    return validationState;
  }

  public createEventGroupSummaryFromCompEvent(
    compEvent: ICompEvent
  ): IEventGroupSummary {
    const eventGroupSummary = {
      ...this.factoryEventGroupSummary(),
      ...compEvent.eventGroupSummary,
    };

    eventGroupSummary.isOpen = compEvent.isOpen;
    eventGroupSummary.eventDateTime = compEvent.startDateTime;
    eventGroupSummary.limits.maxAthletes = compEvent.maxAthletes;

    eventGroupSummary.options.seed = CommonServiceUtils.simpleClone(
      compEvent.options.seed
    );

    return eventGroupSummary;
  }

  public convertCompEventsIntoEventGroupSummaries(
    compEventSchedGrids: ICompEventSchedGrid[]
  ): IEventGroupSummary[] {
    const compEventsSchedGridsObject =
      CommonServiceUtils.convertArrayToObjectArray(
        "eventGroup",
        compEventSchedGrids
      );

    return Object.values(compEventsSchedGridsObject).map(
      (compEventSchedGrid: ICompEventSchedGrid[]) => {
        const compEventSched = compEventSchedGrid[0];

        return this.createEventGroupSummaryFromCompEvent(
          compEventSched.compEvent
        );
      }
    );
  }

  public convertCompEventsIntoEventGroupSummaryMap(
    compEventSchedGrids: ICompEventSchedGrid[]
  ): Record<string, IEventGroupSummary> {
    const eventGroupSummaries: IEventGroupSummary[] =
      this.convertCompEventsIntoEventGroupSummaries(compEventSchedGrids);

    return eventGroupSummaries.reduce<Record<string, IEventGroupSummary>>(
      (accum, eventGroupSummary) => {
        accum[eventGroupSummary.id.toString()] = eventGroupSummary;
        return accum;
      },
      {}
    );
  }
}
