























































































































































































import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
  watch,
} from "@vue/composition-api";
import { useStore } from "../../app.store";
import { CONFIG_STORE_CONST } from "../../config/config-store";
import { IConfigApp, USER_ROLES } from "../../config/config-app-models";
import { EmailMessageService } from "../../email-message/email-message-service";
import {
  ATH_COMP_SCHED_STORE_CONST,
  IAthCompSchedStoreState,
} from "../../athleteCompSched/store/athleteCompSched-store";
import { CONFIG } from "../../common/config";
import LaunchLinkV2 from "./launchlink/launch-link-v2.vue";
import { useLaunchHeaderV2 } from "./useLaunchHeaderV2";
import {
  useConfigController,
  useConfigStore,
} from "../../config/useConfigStore";
import InfoMinorSvg from "../../common/ui/svg/InfoMinorSvg.vue";
import CartMajorSvg from "../../common/ui/svg/CartMajorSvg.vue";
import { useAuthStoreController } from "../../auth/useAuthStore";
import E4sLogoSvg from "../../common/ui/svg/E4sLogoSvg.vue";
import { useRoute, useRouter } from "../../router/migrateRouterVue3";
import { RawLocation } from "vue-router";
import {
  LAUNCH_ROUTES_PATHS_V2,
  LaunchRouteValueV2,
  widerRoutesClass,
} from "./launch-routes-v2";
import { router } from "../../index";
import LaunchSublinkV2 from "./launchlink/launch-sublink-v2.vue";
import LaunchSublinkSimpleV2 from "./launchlink/launch-sublink-simple.v2.vue";
import { ENTRY_STORE_CONST } from "../../entry/entry-store";
import ConfigVersionSwitch from "../../config/ConfigVersionSwitch.vue";
import ConfigVersionSwitchV1 from "../../config/ConfigVersionSwitchV1.vue";
import { useConfigVersionSwitch } from "../../config/useConfigVersionSwitch";

export default defineComponent({
  name: "launch-header-v2",
  components: {
    ConfigVersionSwitchV1,
    ConfigVersionSwitch,
    LaunchSublinkSimpleV2,
    LaunchSublinkV2,
    E4sLogoSvg,
    CartMajorSvg,
    InfoMinorSvg,
    LaunchLinkV2,
  },
  props: {
    entries: {
      type: Array as PropType<unknown[]>,
      default: () => {
        return [];
      },
    },
  },
  setup(props: { entries: unknown[] }, context: SetupContext) {
    const store = useStore();
    const configStore = useConfigStore();
    const configController = useConfigController();
    const authStoreController = useAuthStoreController();
    const launchHeaderControllerV2 = useLaunchHeaderV2();
    const routerInternal = useRouter();
    const route = useRoute();
    // const e4sRouteController = useE4sRouteController();

    const configVersionSwitch = useConfigVersionSwitch(
      "v2",
      configStore.configApp.userInfo.user.version
    );

    router.beforeEach((to: any, from: any, next: any) => {
      console.log("launch-header-v2 setup() router.beforeEach()...");
      launchHeaderControllerV2.hideMobileNav();
      next();
    });

    const configApp = computed<IConfigApp>(() => {
      return store.state[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME].configApp;
    });

    const athCompSchedStoreState = computed<IAthCompSchedStoreState>(() => {
      return store.state[
        ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME
      ];
    });

    store.commit(
      CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_UI_VERSION,
      "v2"
    );

    watch(
      () => configStore.configApp.role,
      (newValue: string) => {
        //  User is initially ANON, but if logs in etc., recalc
        launchHeaderControllerV2.createUserLinks();
      },
      {
        immediate: true,
      }
    );

    //  launchHeaderControllerV2.reloadCart();

    watch(
      () => authStoreController.isLoggedIn.value,
      (newValue: boolean, oldValue: boolean) => {
        //  User is initially ANON, but if logs in etc., recalc
        // if (newValue !== oldValue) {
        //   launchHeaderControllerV2.reloadCart();
        // }
      }
    );

    // const configApp: IConfigApp = store.state[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME].configApp;
    // const athCompSchedStoreState: IAthCompSchedStoreState  = store.state[ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME];

    const emailMessageService: EmailMessageService = new EmailMessageService();

    const getLogo = computed(() => {
      return CONFIG.E4S_HOST + "/" + configApp.value.logo;
    });

    const getMessageUnreadCount = computed(() => {
      return emailMessageService.getUnreadMessages(configApp.value.messages)
        .length;
      // return configApp.value.messages.length;
    });

    const getCartItemCount = computed(() => {
      const cart = athCompSchedStoreState.value.cart;
      if (cart.meta && cart.meta.wcCart) {
        return cart.meta.wcCart.items.length;
      }
      return 0;
    });

    // function toggleNav(target: Event) {
    //   const tar: HTMLElement = target.currentTarget as HTMLElement;
    //
    //   LaunchHeaderV2js.toggleNav(tar);
    // }

    function toggleAdmin() {
      const userRole =
        configStore.configApp.role === "E4SUSER"
          ? USER_ROLES.USER
          : USER_ROLES.E4SUSER;
      store.commit(
        CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
          "/" +
          CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_ROLE,
        userRole
      );
    }

    // function showMobileNavigation() {
    //   launchHeaderControllerV2.toggleNav();
    // }

    function goHome() {
      routerInternal
        .push({
          name: LAUNCH_ROUTES_PATHS_V2.HOME_V2,
        } as RawLocation)
        .catch((error: any) => {
          console.log("launch-header goHome error");
          store.commit(
            ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME +
              "/" +
              ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_ROUTE_ERROR,
            error
          );
        });
    }

    const getContentWidth = computed(() => {
      const routeName: LaunchRouteValueV2 = route.name
        ? route.name
        : ("" as any as LaunchRouteValueV2);
      if (widerRoutesClass[routeName]) {
        return widerRoutesClass[routeName];
      }
      return "e4s-width-controller";
    });

    const getMenuMobileClass = computed(() => {
      const cssClasses: string[] = [];
      if (!launchHeaderControllerV2.state.showMobile) {
        return cssClasses;
      }

      cssClasses.push("e4s-navigation-bar-menu--mobile");
      const getCssEnvSuffix: string = configController.getCssEnvSuffix.value;
      if (getCssEnvSuffix.length > 0) {
        cssClasses.push(
          "e4s-navigation-bar-menu--" + getCssEnvSuffix + "mobile"
        );
      }

      return cssClasses;
    });

    return {
      store,
      configApp,
      getLogo,
      getMessageUnreadCount,
      getCartItemCount,
      toggleAdmin,
      launchHeaderControllerV2,
      configController,
      authStoreController,
      goHome,
      getContentWidth,
      CONFIG,
      configVersionSwitch,
      getMenuMobileClass,
    };
  },
});
