import { LAUNCH_ROUTES_PATHS } from "../../launch/launch-routes";
import {
  ICompetitionSummaryPublic,
  ICompSummary,
} from "../../competition/competition-models";
import { IClub } from "../../club/club-models";
import { CompetitionService } from "../../competition/competiton-service";
import { isAfter, parse } from "date-fns";

export class EntryPublicService {
  public launchRoutesPaths = LAUNCH_ROUTES_PATHS;
  public competitionService = new CompetitionService();

  public getPathToComp(compOrgId: number, compId: number): string {
    return (
      "/#/" +
      this.launchRoutesPaths.ENTRY +
      "?comporgid=" +
      compOrgId +
      "&compid=" +
      compId
    );
  }

  public getLoginPathToComp(compOrgId: number, compId: number): string {
    return (
      "/wp-login.php?redirect_to=" +
      encodeURIComponent(this.getPathToComp(compOrgId, compId))
    );
  }

  public mapCompToClub(comps: ICompetitionSummaryPublic[]): IClub[] {
    return comps.map((comp) => {
      return {
        id: comp.compOrgId,
        clubid: comp.compOrgId,
        club: comp.club,
      } as IClub;
    });
  }

  public getStatusClass(
    comp: ICompetitionSummaryPublic | ICompSummary
  ): string {
    if (!comp.status) {
      return "";
    }
    const currentStatus = comp.status.status;
    if (currentStatus && this.competitionService.isPastCompDate(comp)) {
      return "status-" + currentStatus.toLowerCase().replace(/_/g, "-");
    }
    return "";
  }

  public canCheckinLinkBeActive(
    comp: ICompetitionSummaryPublic | ICompSummary
  ): boolean {
    if (!comp.options.checkIn.enabled) {
      return false;
    }
    const dateTimeNow = new Date();
    const checkInOpens = parse(comp.options.checkIn.checkInDateTimeOpens);
    return isAfter(dateTimeNow, checkInOpens);
  }
}
