import {IValidationResult} from "../../common/common-models";
import {CommonService} from "../../common/common-service";
import {IStatusBase} from "./status-models";

const commonService: CommonService = new CommonService();

export class StatusService {
    public validateStatusBase(statusBase: IStatusBase): IValidationResult[] {
        const validationResults = [] as IValidationResult[];
        if (commonService.stripChars(statusBase.paid.toString()).length === 0) {
            validationResults.push({
                name: "",
                message: "Enter Paid."
            });
        }
        if (statusBase.moveto === 0) {
            validationResults.push({
                name: "",
                message: "Enter move to."
            });
        }
        return validationResults;
    }
}
