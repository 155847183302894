import * as R from "ramda";
import { ActionContext, Module } from "vuex";
import { IRootState } from "../../app.store";
import { IServerGenericResponse } from "../../common/common-models";
import { USER_MESSAGE_LEVEL } from "../../user-message/user-message-models";
import { messageDispatchHelper } from "../../user-message/user-message-store";
import { CompEventTeamHeaderData } from "./comp-event-team-header-data";
import { IEventTeamHeader } from "./event-teams-models";
import { ICompetitionInfo } from "../../competition/competition-models";
import { ENTRY_STORE_CONST } from "../../entry/entry-store";
import { IEntity } from "../../config/config-app-models";
import { CONFIG_STORE_CONST } from "../../config/config-store";
import { CompetitionService } from "../../competition/competiton-service";

const compEventTeamHeaderData: CompEventTeamHeaderData =
  new CompEventTeamHeaderData();
const competitionService: CompetitionService = new CompetitionService();

export enum COMP_EVENT_TEAMS_STORE_CONST {
  COMP_EVENT_TEAMS_CONST_MODULE_NAME = "COMP_EVENT_TEAMS_CONST_MODULE_NAME",

  // <MUTATIONS>
  COMP_EVENT_TEAMS_MUTATIONS_SET_TEAM_HEADERS = "COMP_EVENT_TEAMS_MUTATIONS_SET_TEAM_HEADERS",
  COMP_EVENT_TEAMS_MUTATIONS_SET_TEAM_HEADERS_LOADING = "COMP_EVENT_TEAMS_MUTATIONS_SET_TEAM_HEADERS_LOADING",
  COMP_EVENT_TEAMS_MUTATIONS_SUBMIT_COMP_EVENT_TEAM_LOADING = "COMP_EVENT_TEAMS_MUTATIONS_SUBMIT_COMP_EVENT_TEAM_LOADING",
  COMP_EVENT_TEAMS_MUTATIONS_SHOW_NEW_TEAM = "COMP_EVENT_TEAMS_MUTATIONS_SHOW_NEW_TEAM",
  // </MUTATIONS>

  // <ACTIONS>
  COMP_EVENT_TEAMS_ACTIONS_GET_TEAM_HEADERS = "COMP_EVENT_TEAMS_ACTIONS_GET_TEAM_HEADERS",
  // </ACTIONS>
}

export interface ICompEventTeamsStoreState {
  eventTeamHeaders: IEventTeamHeader[];
  eventTeamHeadersLoading: boolean;
  compEventTeamSubmitLoading: boolean;
  showNewTeam: boolean;
}

const mutations = {
  [COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_MUTATIONS_SET_TEAM_HEADERS](
    state: ICompEventTeamsStoreState,
    eventTeamHeaders: IEventTeamHeader[]
  ) {
    // state.eventTeamHeaders = R.clone(eventTeamHeaders);
    state.eventTeamHeaders = R.clone(eventTeamHeaders).filter(
      (eventTeamHeader) => {
        // return eventTeamHeader.IsOpen === 1 && eventTeamHeader.maxAthletes > -1;
        return eventTeamHeader.maxAthletes > -1;
      }
    );
  },
  [COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_MUTATIONS_SET_TEAM_HEADERS_LOADING](
    state: ICompEventTeamsStoreState,
    isLoading: boolean
  ) {
    state.eventTeamHeadersLoading = isLoading;
  },
  [COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_MUTATIONS_SUBMIT_COMP_EVENT_TEAM_LOADING](
    state: ICompEventTeamsStoreState,
    isLoading: boolean
  ) {
    state.compEventTeamSubmitLoading = isLoading;
  },
  [COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_MUTATIONS_SHOW_NEW_TEAM](
    state: ICompEventTeamsStoreState,
    showNewTeam: boolean
  ) {
    state.showNewTeam = showNewTeam;
  },
};

const actions = {
  [COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_ACTIONS_GET_TEAM_HEADERS](
    context: ActionContext<ICompEventTeamsStoreState, IRootState>,
    payload: {
      // compId: number,
      // entityLevel: number,
      // entityId: number
    }
  ): Promise<void> {
    // context.commit(COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_MUTATIONS_SET_TEAM_HEADERS, []);
    const selectedCompetition: ICompetitionInfo =
      context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
        .entryForm.selectedCompetition;
    const userEntity: IEntity =
      context.rootState[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME].userEntity;

    let entityLevel = userEntity.entityLevel;
    let entityId = userEntity.id;

    if (userEntity.entityName === "Club") {
      const isSchoolComp = competitionService.isSchool(selectedCompetition);
      if (!isSchoolComp && userEntity.clubType === "S") {
        //  User passing a school entity, this is club comp
        entityLevel = 0;
        entityId = 0;
      }
      if (isSchoolComp && userEntity.clubType !== "S") {
        //  User passing a club entity, this is school comp
        entityLevel = 0;
        entityId = 0;
      }
    }

    if (selectedCompetition && selectedCompetition.id > 0) {
      context.commit(
        COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_MUTATIONS_SET_TEAM_HEADERS_LOADING,
        true
      );
      return compEventTeamHeaderData
        .getEventHeaders(selectedCompetition.id, entityLevel, entityId)
        .then((response: IServerGenericResponse) => {
          if (response.errNo > 0) {
            messageDispatchHelper(
              response.error,
              USER_MESSAGE_LEVEL.ERROR.toString()
            );
            return;
          }

          context.commit(
            COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_MUTATIONS_SET_TEAM_HEADERS,
            response.data
          );
          context.commit(
            COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_MUTATIONS_SET_TEAM_HEADERS_LOADING,
            false
          );
          return;
        })
        .catch((error) => {
          messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
          context.commit(
            COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_MUTATIONS_SET_TEAM_HEADERS,
            []
          );
          context.commit(
            COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_MUTATIONS_SET_TEAM_HEADERS_LOADING,
            false
          );
          return;
        });
    }
    return Promise.resolve();
  },
};

export const compEventTeamStore: Module<ICompEventTeamsStoreState, IRootState> =
  {
    namespaced: true,
    state: {
      eventTeamHeaders: [] as IEventTeamHeader[],
      eventTeamHeadersLoading: false,
      compEventTeamSubmitLoading: false,
      showNewTeam: false,
    },
    mutations,
    actions,
  };
