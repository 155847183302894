import { isLoggedInGuardV2 } from "../../index";

export const LAUNCH_ROUTES_PATHS_V2_DIR = "v2";
export const LAUNCH_ROUTES_PATHS_V2_BASE = "#/" + LAUNCH_ROUTES_PATHS_V2_DIR;

export const LAUNCH_ROUTES_PATHS_V2 = {
  HOME_V2: "home-v2",
  LOGIN_V2: "login-v2",
  REGISTER_V2: "register-v2",
  FORGOT_V2: "forgot-v2",
  RESET_V2: "reset_pwd", //  N.B. the underscore, not a dash.
  ENTRY_V2: "entry-v2",
  SHOW_ENTRY_v2: "show-entry-v2",
  ATHLETE_V2: "athlete-v2",
  ATHLETES_V2: "athletes-v2",
  SHOP_V2: "shop-v2",
  SCHED_COMP_PUBLIC: "schedule-v2",
  MESSAGES_V2: "messages-v2",
  USER_SEARCH_V2: "user-search-v2",
  USER_V2: "user-v2",
  CLUB_SEARCH_V2: "club-search-v2",
  CLUB_V2: "club-v2",

  BUILDER_V2: "builder-v2",
  CONTACT_ORGANISER_V2: "contact-organiser-v2",

  FORM_GENERIC_EXAMPLE_V2: "form-generic-example-v2",
};

type LaunchRoutesV2 = typeof LAUNCH_ROUTES_PATHS_V2;
export type LaunchRouteKeyV2 = keyof LaunchRoutesV2;
export type LaunchRouteValueV2 = LaunchRoutesV2[keyof LaunchRoutesV2];

import AskOrganiserRoute from "../../competition/askorganiser/AskOrganiserRoute.vue";

export const launchRoutesV2 = [
  {
    name: LAUNCH_ROUTES_PATHS_V2.HOME_V2,
    path: "",
    component: () => {
      return import(
        /* webpackChunkName: "entry-public-v2" */ "../../public/entry-public/v2/entry-public-v2.vue"
      );
    },
    meta: {
      title: "Home",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.HOME_V2,
    path: LAUNCH_ROUTES_PATHS_V2.HOME_V2,
    component: () => {
      return import(
        /* webpackChunkName: "entry-public-v2" */ "../../public/entry-public/v2/entry-public-v2.vue"
      );
    },
    meta: {
      title: "Home",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.LOGIN_V2,
    path: LAUNCH_ROUTES_PATHS_V2.LOGIN_V2,
    component: () => {
      return import(
        /* webpackChunkName: "login-container-v2" */ "../../auth/v2/login-container-v2.vue"
      );
    },
    meta: {
      title: "Login",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.REGISTER_V2,
    path: LAUNCH_ROUTES_PATHS_V2.REGISTER_V2,
    component: () => {
      return import(
        /* webpackChunkName: "register-container-v2" */ "../../auth/v2/register/register-container-v2.vue"
      );
    },
    meta: {
      title: "Register",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.RESET_V2,
    path: LAUNCH_ROUTES_PATHS_V2.RESET_V2,
    component: () => {
      return import(
        /* webpackChunkName: "reset-container-v2" */ "../../auth/v2/reset/reset-container-v2.vue"
      );
    },
    meta: {
      title: "Reset",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.FORGOT_V2,
    path: LAUNCH_ROUTES_PATHS_V2.FORGOT_V2,
    component: () => {
      return import(
        /* webpackChunkName: "forgot-container-v2" */ "../../auth/v2/forgot/forgot-container-v2.vue"
      );
    },
    meta: {
      title: "Forgot",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.ENTRY_V2,
    path: LAUNCH_ROUTES_PATHS_V2.ENTRY_V2 + "/:id",
    beforeEnter: isLoggedInGuardV2,
    component: () => {
      return import(
        /* webpackChunkName: "entry-route-v2" */ "../../entry/v2/entry-route-v2.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Entry";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.SHOW_ENTRY_v2,
    path: LAUNCH_ROUTES_PATHS_V2.SHOW_ENTRY_v2 + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "entry-direct-v2" */ "../../public/entry-public/v2/entry-direct-v2.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Comp";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.ATHLETE_V2,
    path: LAUNCH_ROUTES_PATHS_V2.ATHLETE_V2 + "/:id",
    beforeEnter: isLoggedInGuardV2,
    component: () => {
      return import(
        /* webpackChunkName: "athlete-form-route-v2" */ "../../athlete/v2/form/athlete-form-route-v2.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Athlete";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.ATHLETES_V2,
    path: LAUNCH_ROUTES_PATHS_V2.ATHLETES_V2,
    beforeEnter: isLoggedInGuardV2,
    component: () => {
      return import(
        /* webpackChunkName: "athlete-search-route-v2" */ "../../athlete/v2/search/athlete-search-route-v2.vue"
      );
    },
    meta: {
      title: "Athletes",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.SCHED_COMP_PUBLIC,
    path: LAUNCH_ROUTES_PATHS_V2.SCHED_COMP_PUBLIC + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "schedule-comp-public-v2" */ "../../competition/results/public/schedule/v2/schedule-comp-public-route-v2.vue"
      );
    },
    meta: {
      title: "Schedule",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.MESSAGES_V2,
    path: LAUNCH_ROUTES_PATHS_V2.MESSAGES_V2 + "/:compId?",
    beforeEnter: isLoggedInGuardV2,
    component: () => {
      return import(
        /* webpackChunkName: "email-message-grid-route-v2" */ "../../email-message/grid/v2/email-message-grid-route-v2.vue"
      );
    },
    meta: {
      title: (params: { compId: number }) => {
        return (
          "Messages" +
          (params.compId && params.compId > 0
            ? " for Comp: " + params.compId
            : "")
        );
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.USER_SEARCH_V2,
    path: LAUNCH_ROUTES_PATHS_V2.USER_SEARCH_V2,
    beforeEnter: isLoggedInGuardV2,
    component: () => {
      return import(
        /* webpackChunkName: "user-search-v2" */ "../../admin/user/v2/user-search-v2.vue"
      );
    },
    meta: {
      title: () => {
        return "Users";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.USER_V2,
    path: LAUNCH_ROUTES_PATHS_V2.USER_V2 + "/:id?",
    beforeEnter: isLoggedInGuardV2,
    component: () => {
      return import(
        /* webpackChunkName: "user-search-v2" */ "../../admin/user/v2/user-route-container-v2.vue"
      );
    },
    meta: {
      title: () => {
        return "User";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.BUILDER_V2,
    path: LAUNCH_ROUTES_PATHS_V2.BUILDER_V2 + "/:id",
    beforeEnter: isLoggedInGuardV2,
    component: () => {
      return import(
        /* webpackChunkName: "builder-v2" */ "../../builder/form/builder-form.vue"
      );
    },
    meta: {
      title: (params: { id: number }) => {
        return params.id + ": Builder";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.FORM_GENERIC_EXAMPLE_V2,
    path: LAUNCH_ROUTES_PATHS_V2.FORM_GENERIC_EXAMPLE_V2,
    component: () => {
      return import(
        /* webpackChunkName: "form-generic-example" */ "../../common/ui/layoutV2/form/form-generic-example.vue"
      );
    },
    meta: {
      title: (params: { id: number }) => {
        return "Example Form";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.CONTACT_ORGANISER_V2,
    path: "/" + LAUNCH_ROUTES_PATHS_V2.CONTACT_ORGANISER_V2 + "/:id",
    component: AskOrganiserRoute,
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Contact";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.CLUB_SEARCH_V2,
    path: LAUNCH_ROUTES_PATHS_V2.CLUB_SEARCH_V2,
    beforeEnter: isLoggedInGuardV2,
    component: () => {
      return import(
        /* webpackChunkName: "club-search-v2" */ "../../club/v2/club-search.vue"
      );
    },
    meta: {
      title: () => {
        return "Clubs";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS_V2.CLUB_V2,
    path: LAUNCH_ROUTES_PATHS_V2.CLUB_V2 + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "club-route-v2" */ "../../club/v2/club-route.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Club";
      },
    },
  },
];

export const widerRoutesClass: Record<LaunchRouteValueV2, string> = {
  "home-v2": "e4s-width-controller-entry",
  "builder-v2": "e4s-width-controller--builder",
};
