var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"e4s-flex-column e4s-sticky-navigation",attrs:{"id":"e4s-nav-bar"}},[_c('div',{staticClass:"e4s-navigation-bar",class:'e4s-navigation-bar--' +
      _vm.configController.getCssEnvSuffix.value +
      'primary'},[_c('div',{staticClass:"e4s-navigation-bar--content-wrapper",class:_vm.getContentWidth},[_c('div',{staticClass:"e4s-navigation-bar-logo--container"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goHome.apply(null, arguments)}}},[_c('E4sLogoSvg',{attrs:{"height":"45px"}})],1),_vm._v(" "),(_vm.configVersionSwitch.canUserViewSwitcher.value)?_c('ConfigVersionSwitchV1',{attrs:{"current-ui-version":"v2"}}):_vm._e()],1),_vm._v(" "),_c('ul',{staticClass:"e4s-navigation-bar-menu",class:_vm.getMenuMobileClass},[(_vm.authStoreController.isLoggedIn.value)?_c('LaunchLinkV2',{attrs:{"link-parent-v2":_vm.launchHeaderControllerV2.getLinksById.value.MESSAGES_V2,"show-children-link-parent-v2":_vm.launchHeaderControllerV2.state.showChildrenLink}}):_vm._e(),_vm._v(" "),(_vm.authStoreController.isLoggedIn.value)?_c('LaunchLinkV2',{attrs:{"link-parent-v2":_vm.launchHeaderControllerV2.getLinksById.value.PARENT__MY_ACCOUNT,"show-children-link-parent-v2":_vm.launchHeaderControllerV2.state.showChildrenLink},on:{"showChildren":_vm.launchHeaderControllerV2.setShowChildrenLink,"hideChildren":function($event){return _vm.launchHeaderControllerV2.hideChildrenLink()}}},[_c('template',{slot:"child-links"},[_c('LaunchSublinkSimpleV2',{attrs:{"link-display-name":_vm.launchHeaderControllerV2.configController.getStore.value
                  .configApp.userInfo.user.display_name,"is-link":false}}),_vm._v(" "),_c('LaunchSublinkV2',{attrs:{"link-v2":_vm.launchHeaderControllerV2.state.subLinksV1.BUILDER}}),_vm._v(" "),_c('LaunchSublinkV2',{attrs:{"link-v2":_vm.launchHeaderControllerV2.getSubLinksById.value.ATHLETES_V2}}),_vm._v(" "),(_vm.configController.isAdmin.value)?_c('LaunchSublinkV2',{attrs:{"link-v2":_vm.launchHeaderControllerV2.getSubLinksById.value.USER_SEARCH_V2}}):_vm._e(),_vm._v(" "),(_vm.configController.isAdmin.value)?_c('LaunchSublinkV2',{attrs:{"link-v2":_vm.launchHeaderControllerV2.getSubLinksById.value.CLUB_SEARCH_V2}}):_vm._e(),_vm._v(" "),_c('LaunchSublinkV2',{attrs:{"link-v2":_vm.launchHeaderControllerV2.getSubLinksById.value.MY_ACCOUNT}}),_vm._v(" "),_c('LaunchSublinkSimpleV2',{attrs:{"link-display-name":"Logout"},on:{"linkClicked":function($event){return _vm.launchHeaderControllerV2.doLogout()}}})],1)],2):_vm._e(),_vm._v(" "),_c('LaunchLinkV2',{attrs:{"id":"okmijn","link-parent-v2":{
            linkId: 'CART',
            linkDisplayName: 'Cart',
            path: _vm.CONFIG.WP_BASKET,
            showBadge: true,
            badgeText: _vm.launchHeaderControllerV2.cartItemCount.value,
            children: [],
          },"show-children-link-parent-v2":_vm.launchHeaderControllerV2.state.showChildrenLink}}),_vm._v(" "),(!_vm.authStoreController.isLoggedIn.value)?_c('LaunchLinkV2',{attrs:{"link-parent-v2":_vm.launchHeaderControllerV2.loginLink.value,"show-children-link-parent-v2":_vm.launchHeaderControllerV2.state.showChildrenLink}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"\n          e4s-flex-row e4s-flex-center\n          e4s-navigation-bar--mobile-wrapper\n        "},[(_vm.authStoreController.isLoggedIn.value)?_c('div',{staticClass:"e4s-navigation-bar-menu--item",attrs:{"id":"qazwsx"}},[_c('a',{staticClass:"e4s-subheader--200",attrs:{"href":_vm.CONFIG.WP_BASKET}},[_c('span',{staticClass:"e4s-subheader--200"},[_vm._v("Cart")]),_vm._v(" "),_c('div',{staticClass:"e4s-badge e4s-badge--navigation"},[_c('span',{staticClass:"e4s-subheader--300",domProps:{"textContent":_vm._s(_vm.launchHeaderControllerV2.cartItemCount.value)}})])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"e4s-navigation-bar__hamburger-container"},[_c('button',{staticClass:"\n              hamburger hamburger--slider\n              e4s-navigation__hamburger\n              e4s-hamburger__menu-closed\n            ",attrs:{"type":"button"},on:{"click":function($event){return _vm.launchHeaderControllerV2.toggleNav()}}},[_vm._m(0)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"hamburger-box"},[_c('span',{staticClass:"hamburger-inner"})])}]

export { render, staticRenderFns }