import {useRouter} from "../router/migrateRouterVue3"
import {computed, ref} from "@vue/composition-api"
import {LAUNCH_ROUTES_PATHS} from "../launch/launch-routes"
import {LAUNCH_ROUTES_PATHS_V2_BASE} from "../launch/v2/launch-routes-v2"
import {UiVersion} from "./config-app-models"
import {RawLocation} from "vue-router"
import {UserProfileData} from "../admin/user/user-data"
import {handleResponseMessages} from "../common/handle-http-reponse"
import {IUserVersion} from "../admin/user/user-models"

export function useConfigVersionSwitch(currentUiVersion: UiVersion, userVersion: IUserVersion) {
  const routerInternal = useRouter();

  const isLoading = ref(false);

  const v1Path = computed(() => {
    return "/#/" + LAUNCH_ROUTES_PATHS.SHOW_ENTRIES;
  });

  const v2Path = computed(() => {
    return "/" + LAUNCH_ROUTES_PATHS_V2_BASE;
  });

  const getSwitchPath = computed(()=>{
    return currentUiVersion === "v1" ? v2Path.value : v1Path.value;
  })

  function goto(uiVersion: UiVersion) {
    const location: RawLocation = {
      path: uiVersion === "v1" ? v1Path.value : v2Path.value,
    };
    routerInternal.push(location);
  }

  function setUserVersion(uiVersion: UiVersion) {
    // new UserProfileData().setVersion(uiVersion);
    console.log("useConfigVersionSwitch.setUserVersion() deprecated for now.")
  }

  function setUserVersionAdmin(uiVersion: UiVersion, toggle: boolean, userId: number) {
    isLoading.value = true;
    const prom =new UserProfileData().setVersion(uiVersion, toggle, userId);
    handleResponseMessages(prom);
    prom.then(()=>{
      return;
    }).finally(()=>{
      isLoading.value = false;
    })
  }

  const canUserViewSwitcher = computed(()=> {
    return userVersion.toggle;
  })

  return { v1Path, v2Path, goto, getSwitchPath, setUserVersion, setUserVersionAdmin, isLoading, canUserViewSwitcher };
}
