

























import Vue from "vue"
import Component from "vue-class-component"
import { mapState } from "vuex";
import { CONFIG_STORE_CONST, IConfigStoreState } from "../config/config-store";
import {IConfigApp} from "../config/config-app-models"
import {FIELD_HELP_STORE_CONST, IHelpData} from "../common/ui/field/field-help/field-help-store"
import {FieldHelpService} from "../common/ui/field/field-help/field-help-service"
import { LAUNCH_ROUTES_PATHS } from "./launch-routes";

@Component({
    name: "launch-menu-help",
    computed: {
        ...mapState(CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME, {
            configApp: (state: IConfigStoreState) => state.configApp
        })
    }
})
export default class LaunchMenuHelp extends Vue {
    public readonly configApp: IConfigApp;

    public LAUNCH_ROUTES_PATHS = LAUNCH_ROUTES_PATHS;

    public get getSupportLink() {
        return "https://support.entry4sports.com/";
    }

    public get getHasUserGuide(): boolean {
        if (!this.configApp.userguide) {
            return false;
        }
        return this.configApp.userguide.length > 0
    }

    public get getRouteHelp(): IHelpData {
        let helpData = new FieldHelpService().factoryHelpData();
        if (this.configApp.help) {
            const routeName = this.$route.name === this.LAUNCH_ROUTES_PATHS.HOME ? this.LAUNCH_ROUTES_PATHS.SHOW_ENTRIES : this.$route.name;
            console.log("getRouteHelp() this.$route.name: " + this.$route.name);
            helpData = this.configApp.help.reduce( (accum, help) => {
                if (help.key === routeName) {
                    accum = {...help};
                }
                return accum;
            }, helpData);
        }
        return helpData;
    }

    public helpClick() {
        this.$store.commit(
            FIELD_HELP_STORE_CONST.CONST_MODULE_NAME + "/" +
            FIELD_HELP_STORE_CONST.MUTATIONS_SET_HELP_DATA,
            this.getRouteHelp
        );
    }

}
