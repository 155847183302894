














































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters, mapState } from "vuex";
import { AUTH_STORE_CONST, IAuthStoreState } from "../auth/auth-store";
import Logout from "../auth/logout.vue";
import { CONFIG_STORE_CONST, IConfigStoreState } from "../config/config-store";
import { LAUNCH_ROUTES_PATHS } from "./launch-routes";
import { IConfigApp, IUserApplication } from "../config/config-app-models";
import { ConfigService } from "../config/config-service";
import {
  ATH_COMP_SCHED_STORE_CONST,
  IAthCompSchedStoreState,
} from "../athletecompsched/store/athletecompsched-store";
import {
  IAthleteCompSched,
  IAthleteCompSchedRuleEvent,
} from "../athletecompsched/athletecompsched-models";
import { Watch } from "vue-property-decorator";
import { VUE_MQ_SIZES } from "..";
import LaunchMenuAdmin from "./launch-menu-admin.vue";
import LaunchMenuAccount from "./launch-menu-account.vue";
import LaunchMenuHelp from "./launch-menu-help.vue";
import E4sModal from "../common/ui/e4s-modal.vue";
import CartMini from "../cart/cart-mini.vue";
import { CONFIG } from "../common/config";
import {
  ISecondaryStoreState,
  SECONDARY_STORE_CONST,
} from "../secondary/secondary-store";
import { IServerResponse } from "../common/common-models";
import { ICartWooCommerce } from "../secondary/secondary-models";
import StandardForm from "../common/ui/standard-form/standard-form.vue";
import CloseIcon from "../common/ui/close-icon.vue";
import E4sModalSimple from "../common/ui/modal/e4s-modal-simple.vue";
import SubBanner from "../entry/sub-banner/sub-banner.vue";
import { Socket } from "socket.io-client";
import { EmailMessageService } from "../email-message/email-message-service";
import ConfigVersionSwitch from "../config/ConfigVersionSwitch.vue";
import ConfigVersionSwitchV1 from "../config/ConfigVersionSwitchV1.vue";
// import AdminConfigSection from "../location/admin-section/admin-config-section.vue";

// import {io} from "socket.io-client";
// import Axios from "axios"

@Component({
  name: "launch-header",
  components: {
    ConfigVersionSwitchV1,
    ConfigVersionSwitch,
    AdminConfigSection: () => {
      return import(
        /* webpackPrefetch: true */
        /* webpackChunkName: "admin-config-section" */
        "../location/admin-section/admin-config-section.vue"
      );
    },
    SubBanner,
    E4sModalSimple,
    CloseIcon,
    StandardForm,
    CartMini,
    E4sModal,
    LaunchMenuHelp,
    LaunchMenuAccount,
    LaunchMenuAdmin,
    logout: Logout,
  },
  computed: {
    ...mapState(AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME, {
      isLoggedIn: (state: IAuthStoreState) => state.isLoggedIn,
    }),
    ...mapState(CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME, {
      configApp: (state: IConfigStoreState) => state.configApp,
      userApplication: (state: IConfigStoreState) =>
        state.configApp.userInfo.user,
      il8nLanguageX: (state: IConfigStoreState) => state.il8nLanguage,
    }),
    ...mapState(ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME, {
      cartItems: (state: IAthCompSchedStoreState) => state.eventsSelected,
      cart: (state: IAthCompSchedStoreState) => state.cart,
    }),
    ...mapState(SECONDARY_STORE_CONST.SECONDARY_CONST_MODULE_NAME, {
      secondaryStoreState: (state: ISecondaryStoreState) => state,
    }),
    ...mapGetters({
      isAdmin:
        CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_GETTERS_IS_ADMIN,
      hasChequesAccess:
        CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_GETTERS_HAS_CHEQUES_ACCESS,
      hasCredit:
        CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_GETTERS_HAS_CREDIT,
    }),
  },
})
export default class LaunchHeader extends Vue {
  public readonly isLoggedIn: boolean;
  public readonly configApp: IConfigApp;
  public readonly userApplication: IUserApplication;
  public readonly cartItems: IAthleteCompSchedRuleEvent[];
  public readonly isAdmin: boolean;
  public readonly hasChequesAccess: boolean;
  public readonly secondaryStoreState: ISecondaryStoreState;
  public readonly cart: IServerResponse<
    IAthleteCompSched[],
    { wcCart: ICartWooCommerce }
  >;

  public burgerInstance: any = null;

  public dropDownAdmin: any = null;
  public dropDownHelp: any = null;
  public dropDownMyAccount: any = null;

  public isMiniCartDisplayed = false;
  public showLogoutConfirm = false;

  public launchRoutesPaths = LAUNCH_ROUTES_PATHS;
  public PREFIX = Math.random().toString(36).substring(2);
  // public language: string = "en";
  public selectedLanguage = {
    id: "en",
    label: "EN",
  };
  // public languageOptions = LANGUAGE_OPTIONS;
  public languageOptions = [
    {
      id: "en",
      label: "EN",
    },
    {
      id: "fr",
      label: "FR",
    },
  ];

  public isLoading: boolean = false;
  public $mq: any;
  public VUE_MQ_SIZES = VUE_MQ_SIZES;
  public CONFIG = CONFIG;
  public configService: ConfigService = new ConfigService();
  public emailMessageService: EmailMessageService = new EmailMessageService();

  public ioSocket: Socket;

  public mounted() {
    this.$store.commit(
      CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_UI_VERSION,
      "v1"
    );

    //  Some rendering lag, so defer for a second.
    window.setTimeout(() => {
      this.initBurger();
      this.initAdmin();
      this.initMyAccountDropDown();
      this.initHelpDropDown();
    }, 1000);

    // this.ioSocket = io("ws://109.228.60.74:3000");
    // this.ioSocket = getSocketInstance();
  }

  public testSocketSend() {
    //  this.ioSocket.emit("test", "hi from Nick at " + new Date().toISOString());
  }

  @Watch("isLoggedIn")
  public isLoggedInChanged(newValue: boolean, oldValue: boolean) {
    if (newValue !== oldValue && newValue === true) {
      this.$store.dispatch(
        ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME +
          "/" +
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_GET_CART
      );
    }
  }

  public doLogout() {
    this.showLogoutConfirm = false;
    this.$store.dispatch(
      AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME +
        "/" +
        AUTH_STORE_CONST.AUTH_ACTIONS_LOGOUT,
      { redirectToHome: true, logoutUrl: this.configApp.logout }
    );
  }

  public get getSupportLink() {
    return "https://support.entry4sports.com/";
  }

  public changeLanguage() {
    this.$store.dispatch(
      CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_ACTIONS_SWITCH_IL8N_LANGUAGE,
      this.selectedLanguage
    );
  }

  public unImpersonate() {
    this.isLoading = true;
    this.$store
      .dispatch(
        CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
          "/" +
          CONFIG_STORE_CONST.CONFIG_ACTIONS_IMPERSONATE,
        { id: 0 }
      )
      .then(() => {
        this.isLoading = false;
      });
  }

  public get hasAdminMenuAccess() {
    return (
      this.isAdmin ||
      this.hasBuilderPermissionForAnyOrg ||
      this.hasChequesAccess
    );
  }

  public get hasBuilderPermissionForAnyOrg() {
    return this.configService.hasBuilderPermissionForAnyOrg(
      this.configApp.userInfo
    );
  }

  public initBurger() {
    const defaultOptions = {};
    this.burgerInstance = document.getElementById("nav-mobile");
    if (this.burgerInstance) {
      (window as any).M.Sidenav.init(this.burgerInstance, defaultOptions);
    }
  }

  public initAdmin() {
    this.dropDownAdmin = document.getElementById("dropdown-trigger-admin");
    //  @ts-ignore
    if (this.dropDownAdmin) {
      (window as any).M.Dropdown.init(this.dropDownAdmin, {
        constrainWidth: false,
        hover: false,
      });
    }
  }

  public initMyAccountDropDown() {
    this.dropDownMyAccount = document.getElementById(
      "dropdown-trigger-my-account"
    );
    //  @ts-ignore
    if (this.dropDownMyAccount) {
      (window as any).M.Dropdown.init(this.dropDownMyAccount, {
        constrainWidth: false,
        hover: false,
      });
    }
  }

  public initHelpDropDown() {
    this.dropDownHelp = document.getElementById("dropdown-trigger-help");
    if (this.dropDownHelp) {
      (window as any).M.Dropdown.init(this.dropDownHelp, {
        constrainWidth: false,
        hover: false,
      });
    }
  }

  public destroyWidgets() {
    if (this.burgerInstance && this.burgerInstance.destroy) {
      this.burgerInstance.destroy();
    }

    if (this.dropDownAdmin && this.dropDownAdmin.destroy) {
      this.dropDownAdmin.destroy();
    }

    if (this.dropDownHelp && this.dropDownHelp.destroy) {
      this.dropDownHelp.destroy();
    }

    if (this.dropDownMyAccount && this.dropDownMyAccount.destroy) {
      this.dropDownMyAccount.destroy();
    }
  }

  public get getCartItemCount(): number {
    if (this.cart.meta && this.cart.meta.wcCart) {
      return this.cart.meta.wcCart.items.length;
    }
    return 0;
  }

  public get getMessageUnreadCount(): number {
    return this.emailMessageService.getUnreadMessages(this.configApp.messages)
      .length;
  }

  public openMessages() {}

  public miniCartShow(showIt: boolean) {
    console.log("LaunchHeader.miniCartShow() showIt: " + showIt);
    this.isMiniCartDisplayed = showIt;
  }

  public confirmCart() {
    // this.$store.dispatch(
    //     ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME + "/" +
    //     ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_SEND_TO_BASKET
    // );
    window.location.href = CONFIG.WP_BASKET;
  }

  public get getShowHealth() {
    if (!this.isAdmin) {
      return false;
    }
    if (this.configApp.env !== "prod") {
      return false;
    }
    return this.configService.isThereHealthIssue(this.configApp);
  }

  public destroy() {
    console.log("LaunchHeader.destroy():");
    this.destroyWidgets();
  }
}
