


































































































import { defineComponent, SetupContext } from "@vue/composition-api";
import LoadingSpinnerV2 from "../../loading-spinner-v2.vue";
import FormGenericButtonBar from "./form-generic-button-bar.vue";

export default defineComponent({
  name: "form-generic-v2",
  components: { FormGenericButtonBar, LoadingSpinnerV2 },
  props: {
    formTitle: {
      type: String,
      default: () => {
        return "";
      },
    },
    formOverview: {
      type: String,
      default: () => {
        return "";
      },
    },
    useStickyHeader: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    showFullScreenIcon: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    submitButtonText: {
      type: String,
      default: () => {
        return "Submit";
      },
    },
    cancelButtonText: {
      type: String,
      default: () => {
        return "Cancel";
      },
    },
    isLoading: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  setup(
    props: {
      formTitle: string;
      formOverview: string;
      useStickyHeader: boolean;
      showFullScreenIcon: boolean;
      submitButtonText: string;
      cancelButtonText: string;
      isLoading: boolean;
    },
    context: SetupContext
  ) {
    function cancel() {
      console.log("form-generic-v2.cancel()");
      context.emit("cancel");
    }
    function submit() {
      console.log("form-generic-v2.submit()");
      context.emit("submit");
    }
    return { cancel, submit };
  },
});
