


























import * as R from "ramda";
import Vue from "vue";
import Component from "vue-class-component";
import LoadingSpinner from "../../common/ui/loading-spinner.vue";
import Status from "./status.vue";
import {Prop, Watch} from "vue-property-decorator";
import {ICompetitionSummaryPublic, IWorkFlow} from "../competition-models";
import {CompetitionService} from "../competiton-service";
import {StatusBaseData} from "./status-data";
import {IServerGenericResponse, IValidationResult} from "../../common/common-models";
import {messageDispatchHelper} from "../../user-message/user-message-store";
import {USER_MESSAGE_LEVEL} from "../../user-message/user-message-models";
import UserValidationMessages from "../../user-message/user-validation-messages.vue";
import {StatusService} from "./status-service";
import {IStatusBase} from "./status-models";
import {PUBLIC_COMPS_STORE_CONST} from "../../public/entry-public/public-comps-store";

@Component({
    name: "status-container",
    components: {
        "loading-spinner": LoadingSpinner,
        "status": Status,
        "user-validation-messages": UserValidationMessages,
    }
})
export default class StatusContainer extends Vue {
    @Prop(
        {
            default: (): ICompetitionSummaryPublic => {
                return {
                    id: 0
                } as ICompetitionSummaryPublic;
            }
        }
    ) public readonly competitionSummaryPublicProp: ICompetitionSummaryPublic;

    @Prop({default: () => []}) public readonly workFlows: IWorkFlow[];

    public competitionService = new CompetitionService();
    public statusService: StatusService = new StatusService();
    public statusBaseData: StatusBaseData = new StatusBaseData();
    public competitionSummaryPublic: ICompetitionSummaryPublic = this.competitionService.factorySummaryPublic();
    public statusBase: IStatusBase = this.competitionService.factoryStatusBase(this.competitionSummaryPublic);
    public isLoading: boolean = false;
    public validationResults: IValidationResult[] = [];

    public created() {
        this.competitionSummaryPublic = R.clone(this.competitionSummaryPublicProp);
    }

    @Watch("competitionSummaryPublicProp")
    public onCompetitionSummaryPublicPropChanged(newValue: ICompetitionSummaryPublic) {
        this.competitionSummaryPublic = R.clone(newValue);
        const statusBase = this.competitionService.factoryStatusBase(newValue);
        this.statusBase = statusBase;
    }

    public get getHeaderText() {
        return this.competitionSummaryPublic.compId + " : " + this.competitionSummaryPublic.compName + " Status";
    }

    public onClose() {
        this.$emit("onClose");
    }

    // public onChange(statusBase: IStatusBase) {
    //     statusBase = R.clone(statusBase);
    //     statusBase.compid = this.competitionSummaryPublicProp.compId;
    //     this.statusBase = statusBase;
    // }

    public onSubmit(statusBase: IStatusBase) {
        this.validationResults = this.statusService.validateStatusBase(statusBase);
        if (this.validationResults.length > 0 ) {
            return;
        }

        this.isLoading = true;
        this.statusBaseData.create(statusBase)
            .then( (response: IServerGenericResponse) => {
                if (response.errNo > 0) {
                    messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
                    return;
                }
                this.onClose();
                messageDispatchHelper("Status saved.", USER_MESSAGE_LEVEL.INFO.toString());
                this.$store.dispatch(PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_CONST_MODULE_NAME + "/" +
                    PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_ACTIONS_GET_COMPS
                );
                return;
            })
            .catch((error) => {
                messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
                return;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

}

