















import Vue from "vue"
import Component from "vue-class-component"
import {LAUNCH_ROUTES_PATHS} from "./launch-routes"
import {Prop} from "vue-property-decorator"

@Component({
    name: "launch-menu-account"
})
export default class LaunchMenuAccount extends Vue {
    @Prop({default: false}) public readonly sideNavClose: boolean;
    public readonly launchRoutesPaths = LAUNCH_ROUTES_PATHS;
}
