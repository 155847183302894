import {IScoreboardEvent} from "./scoreboard-models";
import {Module, MutationTree} from "vuex";
import {IRootState} from "../../app.store";
import {ScoreboardService} from "./scoreboard-service"
import * as R from "ramda";
import {IScoreboardsEventQuery} from "./scoreboard-data"

const scoreboardService: ScoreboardService = new ScoreboardService();

export enum SCOREBOARD_STORE_CONST {

    SCOREBOARD_CONST_MODULE_NAME =  "SCOREBOARD_CONST_MODULE_NAME",

    SCOREBOARD_MUTATIONS_SET_SCOREBOARD = "SCOREBOARD_MUTATIONS_SET_SCOREBOARD",
    SCOREBOARD_MUTATIONS_SET_SCOREBOARD_ALL = "SCOREBOARD_MUTATIONS_SET_SCOREBOARD_ALL",
    SCOREBOARD_MUTATIONS_SET_SCOREBOARD_EVT_GRP_SEL = "SCOREBOARD_MUTATIONS_SET_SCOREBOARD_EVT_GRP_SEL"
}

export interface IScoreboardStoreState {
    scoreboardEvent: IScoreboardEvent,
    scoreboardEventsAll: IScoreboardEvent[],
    scoreboardsEventQuery: IScoreboardsEventQuery[]
}

const mutations: MutationTree<IScoreboardStoreState> = {
    [SCOREBOARD_STORE_CONST.SCOREBOARD_MUTATIONS_SET_SCOREBOARD](state: IScoreboardStoreState, scoreboardEvent: IScoreboardEvent) {
        state.scoreboardEvent = R.clone(scoreboardEvent);
    },
    [SCOREBOARD_STORE_CONST.SCOREBOARD_MUTATIONS_SET_SCOREBOARD_ALL](state: IScoreboardStoreState, scoreboardEventsAll: IScoreboardEvent[]) {
        state.scoreboardEventsAll = R.clone(scoreboardEventsAll);
    },
    [SCOREBOARD_STORE_CONST.SCOREBOARD_MUTATIONS_SET_SCOREBOARD_EVT_GRP_SEL](state: IScoreboardStoreState, scoreboardsEventQuery: IScoreboardsEventQuery[]) {
        state.scoreboardsEventQuery = R.clone(scoreboardsEventQuery);
    },
}

const getters = {};

const actions = {};

export const scoreboardStore: Module<IScoreboardStoreState, IRootState> = {
    namespaced: true,
    state: {
        scoreboardEvent: scoreboardService.factoryScoreboardEvent(),
        scoreboardEventsAll: [],
        scoreboardsEventQuery: []
    },
    mutations,
    getters,
    actions
};
