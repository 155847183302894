



































































































































































































































































































































































































































































































































































































































































































































import * as R from "ramda";
import { format, isBefore, parse } from "date-fns";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import {
  CONFIG_STORE_CONST,
  IConfigStoreState,
} from "../../../config/config-store";
import { AUTH_STORE_CONST, IAuthStoreState } from "../../../auth/auth-store";
import { ICompetitionSummaryPublic } from "../../../competition/competition-models";
import { IConfigApp } from "../../../config/config-app-models";
import { EntryPublicService } from "../entry-public-service";
import { CompetitionService } from "../../../competition/competiton-service";
import { CONFIG, STADIUM_URL_MANCHESTER } from "../../../common/config";

import { LAUNCH_ROUTES_PATHS } from "../../../launch/launch-routes";
import { ConfigService } from "../../../config/config-service";
import CloseBack from "../../../common/ui/close-back.vue";
import { RawLocation } from "vue-router";
import CompRestricted from "../../../competition/restricted/comp-restricted.vue";
import { VUE_MQ_SIZES } from "../../../index";

@Component({
  name: "public-comp-card-body",
  components: {
    CompRestricted,
    CloseBack,
    BuilderContactOrganisers: () => {
      return import(
        "../../../builder/form/builder-contact/builder-contact-organisers.vue"
      );
    },
  },
  computed: {
    ...mapState(CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME, {
      configApp: (state: IConfigStoreState) => state.configApp,
    }),
    ...mapState(AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME, {
      isLoggedIn: (state: IAuthStoreState) => state.isLoggedIn,
    }),
    ...mapGetters({
      isAdmin:
        CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_GETTERS_IS_ADMIN,
    }),
  },
})
export default class PublicListCompBody extends Vue {
  public configApp: IConfigApp;
  public isLoggedIn: boolean;
  public isAdmin: boolean;

  @Prop() public comp: ICompetitionSummaryPublic;

  public entryPublicService: EntryPublicService = new EntryPublicService();
  public competitionService = new CompetitionService();
  public configService = new ConfigService();

  public LAUNCH_ROUTES_PATHS = LAUNCH_ROUTES_PATHS;
  public $mq: any;
  public VUE_MQ_SIZES = VUE_MQ_SIZES;

  public CONFIG = CONFIG;

  public get getCompDate() {
    const d = this.comp.dates.reduce((accum, currentDate, index) => {
      const startTime = format(parse(currentDate), "Do MMM YYYY");
      // accum += startTime;

      accum = index > 0 ? accum + " - " : accum;
      accum += startTime;

      return accum;
    }, "");
    return d;
  }

  public get getCompName() {
    return this.competitionService.getCompetitionTitle(this.comp);
  }

  public get getCanEnter(): boolean {
    return this.competitionService.canUserEnter(this.comp);
  }

  public get isPastLateEntry() {
    return this.competitionService.isPastLateEntry(this.comp);
  }

  public get getHasSaleEndDate() {
    return this.competitionService.hasSaleEndDate(this.comp);
  }

  public get getSaleEndDate() {
    return this.getHasSaleEndDate
      ? this.competitionService.getSaleEndDate(this.comp)
      : "Not Applicable";
  }

  public get getHasBuilderAccess() {
    return this.configService.hasBuilderPermissionForComp(
      this.configApp.userInfo,
      this.comp.compOrgId,
      this.comp.compId
    );
  }

  public get getHasAutoEntryAccess() {
    return (
      this.getHasBuilderAccess &&
      this.comp.options.autoEntries &&
      this.comp.options.autoEntries.selectedTargetComp.id > 0
    );
  }

  public get getHasLiveDataAccess() {
    return this.getHasBuilderAccess;
  }

  public get getLocationText() {
    return (
      this.comp.location.name +
      ": " +
      ["address1", "address2", "town", "county", "postcode"]
        .reduce((accum, prop) => {
          //  @ts-ignore
          if (this.comp.location[prop]) {
            //  @ts-ignore
            accum.push(this.comp.location[prop]);
          }
          return accum;
        }, [])
        .join(", ")
    );
  }

  public get showFlyerUrl() {
    return this.comp.link && this.comp.link.length > 0;
  }

  public get getScheduleUrl() {
    return (
      CONFIG.E4S_HOST +
      "/entry/v5/competition/schedule.php?compid=" +
      this.comp.compId
    );
  }

  public get getAllEntriesUrl() {
    return CONFIG.E4S_HOST + "/" + this.comp.compId + "/entries";
  }

  public get getLogo() {
    return CONFIG.E4S_HOST + "/" + this.comp.logo;
  }

  public get isLive() {
    return this.competitionService.isLive(this.comp);
  }

  public goToComp() {
    this.$emit("enterComp", this.comp);
  }

  public get getStatusClass() {
    return this.entryPublicService.getStatusClass(this.comp);
  }

  public get getShowTeamCount() {
    return !R.isNil(this.comp.entries.team);
  }

  public get getShowIndivCount() {
    return !R.isNil(this.comp.entries.indiv);
  }

  public get getShowAthleteCount() {
    return !R.isNil(this.comp.entries.athletes);
  }

  public get getEntryOpenDate() {
    const pattern = "Do MMM HH:mm";
    return format(parse(this.comp.opendate), pattern);
  }

  public get getIsEntryOpen() {
    return this.competitionService.isEntryOpen(this.comp);
  }

  public get getEntryCloseDate() {
    const dateClose = format(parse(this.comp.closedate), "Do MMM HH:mm");
    if (this.competitionService.hasSaleEndDate(this.comp)) {
      return (
        dateClose +
        " (" +
        this.competitionService.getSaleEndMessage(this.comp) +
        ")"
      );
    }
    return dateClose;
  }

  public get getContactDetails() {
    const contact = this.comp.options.contact;
    const email = contact.email;
    const tel = contact.tel.length > 0 ? "tel: " + contact.tel : "";
    return email + (contact.email.length > 0 ? " " : "") + tel;
  }

  public showStatus() {
    this.$emit("onShowStatus", R.clone(this.comp));
  }

  public onCloseBody() {
    this.$emit("onCloseBody");
  }

  public get getShowOrganiserCheckIn() {
    //  getCanCheckinBeActive checks if past Check in date, this is wrong.
    // return this.getCanCheckinBeActive && this.hasCheckinAccess;
    return this.comp.options.checkIn.enabled && this.hasCheckinAccess;
  }

  public get getCanCheckinBeActive() {
    return this.entryPublicService.canCheckinLinkBeActive(this.comp);
  }

  public get hasCheckinAccess() {
    return this.configService.hasCheckinPermissionForComp(
      this.configApp.userInfo,
      this.comp.compOrgId,
      this.comp.compId
    );
  }

  public get hasTicketAccess() {
    return this.configService.hasTicketPermissionForComp(
      this.configApp.userInfo,
      this.comp.compOrgId,
      this.comp.compId
    );
  }

  public get hasScoreboardAccess() {
    return this.configService.hasScoreBoardPermissionForComp(
      this.configApp.userInfo,
      this.comp.compOrgId,
      this.comp.compId
    );
  }

  public get getCheckinDateTime() {
    const checkInDateTimeOpens = this.comp.options.checkIn.checkInDateTimeOpens;
    if (checkInDateTimeOpens.length === 0) {
      return "";
    }
    return format(parse(checkInDateTimeOpens), "Do MMM HH:mm");
  }

  public get getStadiumUrl() {
    return STADIUM_URL_MANCHESTER;
  }

  public openStadiumSeating() {
    window.open(this.getStadiumUrl, "_seating");
  }

  public get getEnterButtonText() {
    return this.competitionService.getEnterButtonText(this.comp.options);
  }

  public get getShowCompRestrictedMessage(): boolean {
    if (
      this.comp.options.athleteSecurity.clubs &&
      this.comp.options.athleteSecurity.clubs.length > 0
    ) {
      if (
        this.comp.options.athleteSecurity.onlyClubsUpTo &&
        this.comp.options.athleteSecurity.onlyClubsUpTo.length > 0
      ) {
        const onlyClubsUpTo = parse(
          this.comp.options.athleteSecurity.onlyClubsUpTo
        );
        return isBefore(new Date(), onlyClubsUpTo);
      }
      //  No date set, so comp completely locked down to these clubs.
      return true;
    }
    return false;
  }

  public showContactOrganiser() {
    this.$emit("showContactOrganiser", this.comp);
  }

  public goToEmailMessages() {
    let location: RawLocation;
    location = {
      path: "/" + LAUNCH_ROUTES_PATHS.EMAIL_MESSAGES + "/" + this.comp.compId,
    };
    this.$router.push(location);
  }

  public goToResults() {
    let location: RawLocation;
    location = {
      path:
        "/" + LAUNCH_ROUTES_PATHS.R4S_RESULTS_PUBLIC + "/" + this.comp.compId,
    };
    this.$router.push(location);
    // window.location.href = this.getResultsUrl;
  }

  public get hasLinkingTicketComp(): boolean {
    return !!(
      this.comp.options.ui.ticketComp && this.comp.options.ui.ticketComp > 0
    );
  }

  public get getLinkingTicketCompUrl(): string {
    return CONFIG.E4S_HOST + "/" + this.comp.options.ui.ticketComp;
  }

  public goToLinkingTicketComp() {
    window.location.href = this.getLinkingTicketCompUrl;
  }

  public get getFeederUrl(): string {
    return (
      "#/" +
      LAUNCH_ROUTES_PATHS.RESULTS_IMPORT +
      "/" +
      this.comp.options.autoEntries.selectedTargetComp.id +
      "?sourceid=" +
      this.comp.compId
    );
  }
}
