import https from "../../common/https";

import {
  IBaseConcrete,
  IPaging,
  IServerResponse,
  IServerResponseList,
  IServerResponseListMeta,
  IsoDate,
} from "../../common/common-models";
import {
  CompType,
  ICompetitionMoreInfoV2,
  ICompetitionSummaryPublic,
  ICompetitionSummaryPublicV2,
  ICompetitionV2,
  IWorkFlow,
} from "../competition-models";
import { EventType } from "../../athleteCompSched/athletecompsched-models";
import { DEFAULT_PAGE_SIZE } from "../../config/config-store";

export const COMP_FILTER_PAGE_SIZE = DEFAULT_PAGE_SIZE;

// import {IAgeGroupCompCoverageModel} from "../agegroup/agegroup-models";

export interface IServerResponseListCompPublic
  extends IServerResponseList<ICompetitionSummaryPublic> {
  meta: {
    workflow: IWorkFlow[];
  };
}

// export interface IServerResponseListCompPublicV2 extends IServerResponseList<ICompetitionSummaryPublicV2> {
//     meta: {
//         workflow: IWorkFlow[]
//     }
// }

// export function getCompetitionsByid(id: number, isPublic?: boolean, compId?: number): Promise<IServerCompetitionLookupResponse> {
//     return https.get( "/v5/"  + (isPublic ? "public/" : "") + "compdates/" + id + (compId ? "/" + compId : "")) as any as Promise<IServerCompetitionLookupResponse>;
// }

export function getAllComps(
  orgId?: number
): Promise<IServerResponseListCompPublic> {
  return https.get(
    "/v5/public/get-all-comps" + (orgId ? "?orgid=" + orgId : "")
  ) as any as Promise<IServerResponseListCompPublic>;
}

export function getCompById(
  compId?: number
): Promise<IServerResponse<ICompetitionSummaryPublic>> {
  return https.get("/v5/competition/" + compId) as any as Promise<
    IServerResponse<ICompetitionSummaryPublic>
  >;
}

export interface IValidateCompResponseMessage {
  msg: string;
  type: number | 2;
}

export function validateComp(
  compId?: number
): Promise<IServerResponse<IValidateCompResponseMessage[]>> {
  return https.get("/v5/competition/validate/" + compId) as any as Promise<
    IServerResponse<IValidateCompResponseMessage[]>
  >;
}

export interface ICompFilterParams {
  fromDate: IsoDate; //   default from today
  toDate: IsoDate;
  freeTextSearch: string; // club, location, organiser,comp name
  organiser: IBaseConcrete;
  location: IBaseConcrete;
  type: "ALL" | EventType;
  compOrg: IBaseConcrete; //  the user has builder perms. "My comps"
  event: IBaseConcrete[];
  pagenumber: number;
  compTypes?: CompType[];
}

export interface ICompFilterMeta extends IPaging {
  workflow: IWorkFlow[];
}

export function getFilteredComps(
  compsFilterParams: ICompFilterParams
): Promise<
  IServerResponseListMeta<ICompetitionSummaryPublicV2, ICompFilterMeta>
> {
  const getParams: Record<string, string | number> = {
    fromdate: compsFilterParams.fromDate,
    todate: compsFilterParams.toDate,
    freetextsearch: compsFilterParams.freeTextSearch,
    organiserid: compsFilterParams.organiser.id,
    locationid: compsFilterParams.location.id,
    type: compsFilterParams.type,
    comporgid: compsFilterParams.compOrg.id,
    pagenumber: compsFilterParams.pagenumber,
    pagesize: COMP_FILTER_PAGE_SIZE,
    compTypes: compsFilterParams.compTypes
      ? compsFilterParams.compTypes.join("~")
      : "",
  };

  // as any as Promise<
  //   IServerResponseListMeta<ICompetitionSummaryPublicV2, IPaging>
  // >;

  return https
    .get("/v5/public/comps/filter", {
      params: getParams,
    })
    .then((resp) => {
      const respData = resp as any as IServerResponseListMeta<
        ICompetitionSummaryPublicV2,
        ICompFilterMeta
      >;

      const comps = respData.data.map((comp) => {
        const competition: ICompetitionV2 = comp.competition;
        //  @ts-ignore
        if (Array.isArray(competition.text)) {
          competition.text = {
            summary: "",
            information: "",
            termsConditions: "",
            newsFlash: "",
          };
        }
        return comp;
      });

      respData.data = comps;
      return respData;
    });
}

export function getCompMoreInfo(
  compId: number
): Promise<IServerResponse<ICompetitionMoreInfoV2>> {
  //  3
  return https.get("/v5/public/comp/" + compId + "/info") as any as Promise<
    IServerResponse<ICompetitionMoreInfoV2>
  >;
}

// export function validatePriorityCode(compId: number, code: string): Promise<IServerBaseResponse> {
//     return https.post( "/v5/competition/priority/" + compId, {
//         code
//     }) as any as Promise<IServerBaseResponse>;
// }
//
// export function getCompetitionAgeGroupCoverage(compId: number): Promise<IServerResponseList<IAgeGroupCompCoverageModel>> {
//     return https.get( "/v5/competition/agegroups/" + compId);
// }
