










import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {IValidationResult} from "../common/common-models";

@Component({
    name: "user-validation-messages"
})
export default class UserValidationMessages extends Vue {
    @Prop({default: () => []}) public validationMessages: IValidationResult[];
}
