import {ResourceData} from "../../common/resource/resource-service";
import {ICheckinAthlete, ICheckinCompSummary, ICheckinStats, IVerifyResponse} from "./checkin-models"
import {IServerGenericResponse, IServerResponse} from "../../common/common-models"

import https from "../../common/https";
import {IEntity} from "../../config/config-app-models";
import {IClearDownPayload} from "./checkin-store"

// export const BIB_COLLECTED_STATUS = {
//     COLLECTED: "1",
//     NOT_COLLECTED: "0",
//     ALL: "-1"
// }

export enum BIB_COLLECTED_STATUS {
    COLLECTED = "1",
    NOT_COLLECTED = "0",
    ALL = "-1",
}

export interface IGetCheckinSearchParams {
    compId: number;
    isoDate: string;
    firstName: string;
    lastName: string;
    club: string;
    entity: IEntity;
    urn: string;
    eventName: string;
    bibNo: string;
    pageSize: number;
    pageNumber: number;
    collected: BIB_COLLECTED_STATUS;
    dob: string;
    nonce: string;
    isOrganiser: boolean;
    whois: boolean;
    egid?: number;
}

export class CheckinData extends ResourceData<ICheckinAthlete> {
    constructor() {
        super("/v5/comp/registration");
    }

    public getCheckinSummary(compId: number): Promise<IServerResponse<ICheckinCompSummary>> {
        return https.get( "/v5/competition/checkinsummary?compid=" + compId) as any as Promise<IServerResponse<ICheckinCompSummary>>;
    }

    public getCheckin(searchCriteria: IGetCheckinSearchParams): Promise<IServerResponse<ICheckinAthlete[], {checkIn: ICheckinStats}>> {
        const params: Record<string, string | number | boolean> = {
            compid: searchCriteria.compId,
            date: searchCriteria.isoDate,
            firstname: searchCriteria.firstName,
            surname: searchCriteria.lastName,
            club: searchCriteria.club,
            entityId: searchCriteria.entity.id,
            entityLevel: searchCriteria.entity.entityLevel,
            urn: searchCriteria.urn,
            bibno: searchCriteria.bibNo,
            event: searchCriteria.eventName,
            collected: searchCriteria.collected,
            pagesize: searchCriteria.pageSize,
            page: searchCriteria.pageNumber,
            nonce: searchCriteria.nonce,
            whois: searchCriteria.whois
        };

        if (searchCriteria.egid) {
            params["egid"] = searchCriteria.egid;
        }

        return https.get( "/v5/competition/checkin", {
            params
        }) as any as Promise<IServerResponse<ICheckinAthlete[], {checkIn: ICheckinStats}>>;
    }

    public getAthleteCheckin(searchCriteria: IGetCheckinSearchParams): Promise<IServerResponse<ICheckinAthlete[], {checkIn: ICheckinStats}>> {
        const params = {
            compid: searchCriteria.compId,
            date: searchCriteria.isoDate,
            firstname: searchCriteria.firstName,
            surname: searchCriteria.lastName,
            dob: searchCriteria.dob,
            club: searchCriteria.club,
            entityId: searchCriteria.entity.id,
            entityLevel: searchCriteria.entity.entityLevel,
            urn: searchCriteria.urn,
            bibno: searchCriteria.bibNo,
            event: searchCriteria.eventName,
            collected: searchCriteria.collected,
            pagesize: searchCriteria.pageSize,
            page: searchCriteria.pageNumber,
            nonce: searchCriteria.nonce,
            whois: searchCriteria.whois
        };
        return https.get( "/v5/athlete/checkin", {
            params
        }) as any as Promise<IServerResponse<ICheckinAthlete[], {checkIn: ICheckinStats}>>;
    }

    public submitCheckinOrganiser(compId: number, athleteCheckin: ICheckinAthlete[]) {
        return https.post( "/v5/competition/checkin/" + compId, {data: athleteCheckin}) as any as Promise<IServerResponse<ICheckinAthlete[]>>;
    }

    public submitCheckinAthlete(compId: number, athleteCheckin: ICheckinAthlete[]) {
        return https.post( "/v5/athlete/checkin/" + compId, {data: athleteCheckin}) as any as Promise<IServerResponse<ICheckinAthlete[]>>;
    }

    public verifyCodeCode(emailId: string, compCode: string, dateIso?: string) {
        return https.get( "/v5/competition/checkin/verify?emailid=" + emailId + "&compcode=" + compCode + "&date=" + dateIso) as any as Promise<IServerResponse<IVerifyResponse>>;
    }

    public clearDownCheck(compId: number, payload: IClearDownPayload) {
        return https.get( "/v5/competition/checkin/clear?" +
            "compid=" + compId +
            "&collected=" + (payload.collected ? "1" : "0") +
            "&checkedin=" + (payload.checkedIn ? "1" : "0") +
            "&all=" + (payload.all ? "1" : "0")
        ) as any as Promise<IServerGenericResponse>;
    }

    public changeBibNo(compId: number, athleteId: number, bibNo: number) {
        return https.post( "/v5/bib/change/" + compId + "/" + athleteId+ "/" + bibNo) as any as Promise<IServerResponse<unknown>>;
    }

    public getSpareBibNos(compId: number) {
        return https.get( "/v5/bib/unused/" + compId) as any as Promise<IServerResponse<string>>;
    }

}
