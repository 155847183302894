

























import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { InputRestrictController } from "./input-restrict-controller";

@Component({
  name: "Input-restrict-length",
})
export default class InputRestrictLength extends Vue {
  @Prop({
    default: "",
  })
  public readonly value: string;

  @Prop({
    default: 100,
  })
  public readonly maxLength: number;

  @Prop({
    default: "",
  })
  public readonly placeholder: string;

  @Prop({ default: false }) public readonly useTextArea: boolean;
  @Prop({ default: "" }) public readonly textAreaClass: string;
  @Prop({ default: "" }) public readonly inputClass: string;

  public valueInternal: string = "";
  public inputRestrictController: InputRestrictController =
    new InputRestrictController();

  public created() {
    this.init();
  }

  @Watch("value")
  public onValueChange(newValue: string) {
    if (newValue === this.valueInternal) {
      return;
    }
    this.init();
  }

  public init() {
    this.valueInternal = this.value;
    this.inputRestrictController.setMaxLength(this.maxLength);
    this.inputRestrictController.setInputValue(this.valueInternal);
    this.valueInternal = this.inputRestrictController.getInputValue();
  }

  public setFieldValue() {
    this.inputRestrictController.setInputValue(this.valueInternal);
    this.valueInternal = this.inputRestrictController.getInputValue();
    this.$emit("input", this.valueInternal);
    this.$emit("onChanged", this.valueInternal);
    this.$emit("isMaxLength", this.inputRestrictController.getIsMaxLength());
    this.$emit("charLength", this.valueInternal.length);
  }

  // public get getIsMaxLength() {
  //     return this.inputRestrictController.getIsMaxLength();
  // }

  public onKeyDown(evt: any) {
    this.inputRestrictController.onKeyDown(evt);
  }

  public get getTextAreaClass() {
    return this.textAreaClass.length > 0 ? this.textAreaClass : "";
  }
}
