



































































































import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import { ICompetitionSummaryPublic } from "../../../../competition/competition-models";
import { format, parse } from "date-fns";
import { CompetitionService } from "../../../../competition/competiton-service";
import ButtonGenericV2 from "../../../../common/ui/layoutV2/buttons/button-generic-v2.vue";

export default defineComponent({
  name: "comp-content-v2",
  components: { ButtonGenericV2 },
  props: {
    competitionSummaryPublic: {
      type: Object as PropType<ICompetitionSummaryPublic>,
      default: () => {
        return [];
      },
    },
    showContactInfo: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  setup(
    props: {
      competitionSummaryPublic: ICompetitionSummaryPublic;
      showContactInfo: boolean;
    },
    context: SetupContext
  ) {
    const competitionService = new CompetitionService();

    const getFirstCompDate = computed(() => {
      return format(
        parse(props.competitionSummaryPublic.options.dates[0]),
        "Do MMM YYYY"
      );
    });

    const getCheckinDateTime = computed(() => {
      const checkInDateTimeOpens =
        props.competitionSummaryPublic.options.checkIn.checkInDateTimeOpens;
      if (checkInDateTimeOpens.length === 0) {
        return "";
      }
      return format(parse(checkInDateTimeOpens), "Do MMM YYYY HH:mm");
    });

    const getEntryCloseDate = computed(() => {
      const dateClose = format(
        parse(props.competitionSummaryPublic.closedate),
        "Do MMM HH:mm"
      );
      if (competitionService.hasSaleEndDate(props.competitionSummaryPublic)) {
        return (
          dateClose +
          " (" +
          competitionService.getSaleEndMessage(props.competitionSummaryPublic) +
          ")"
        );
      }
      return dateClose;
    });

    const getContactName = computed(() => {
      const contact = props.competitionSummaryPublic.options.contact;
      return contact.userName.length > 0 ? contact.userName : "";
    });

    const getContactTel = computed<string>(() => {
      const contact = props.competitionSummaryPublic.options.contact;
      const tel = contact.tel.toString()
      return tel.length > 0 ? tel : "";
    });

    const getContactEmail = computed(() => {
      const contact = props.competitionSummaryPublic.options.contact;
      return contact.email.length > 0 ? contact.email : "";
    });

    const hasContactInfo = computed(() => {
      if (!props.showContactInfo) {
        return false;
      }
      return (
        getContactName.value.length > 0 ||
        getContactTel.value.length > 0 ||
        getContactEmail.value.length > 0
      );
    });

    // function contactOrganiser() {
    //   context.emit("showContactOrganiser", props.competitionSummaryPublic);
    // }

    function contactOrganiser() {
      context.emit("contactOrganiser", props.competitionSummaryPublic);
    }

    return {
      getFirstCompDate,
      getCheckinDateTime,
      getEntryCloseDate,
      getContactName,
      getContactTel,
      getContactEmail,
      hasContactInfo,
      contactOrganiser,
    };
  },
});
