import {Module} from "vuex"
import {IRootState} from "../../app.store"
import {SecondaryCustService} from "./secondary-cust-service"
import {ISecondaryCustDef} from "./secondary-cust-models"
import * as R from "ramda"

const secondaryCustService: SecondaryCustService = new SecondaryCustService();

export interface ISecondaryCustStoreState {
    secondaryCustDefs: ISecondaryCustDef[];
}


export enum SECONDARY_CUST_STORE_CONST {
    SECONDARY_CUST_CONST_MODULE_NAME =  "SECONDARY_CUST_CONST_MODULE_NAME",

    // <MUTATIONS>
    SECONDARY_CUST_MUTATIONS_SECONDARIES_SET = "SECONDARY_CUST_MUTATIONS_SECONDARIES_SET",
    // </MUTATIONS>

    // <GETTERS>
    SECONDARY_CUST_GETTER_ANY_ATHLETE_ITEMS = "SECONDARY_CUST_GETTER_ANY_ATHLETE_ITEMS"
    // </GETTERS>
}

const mutations = {
    [ SECONDARY_CUST_STORE_CONST.SECONDARY_CUST_MUTATIONS_SECONDARIES_SET ](state: ISecondaryCustStoreState, secondaryDefs: ISecondaryCustDef[]) {
        state.secondaryCustDefs = R.clone(secondaryDefs);
    }
}

const getters = {
    [ SECONDARY_CUST_STORE_CONST.SECONDARY_CUST_GETTER_ANY_ATHLETE_ITEMS ](state: ISecondaryCustStoreState) {
        return secondaryCustService.anyAthleteItems(state.secondaryCustDefs);
    }
};

const actions = {};

export const secondaryCustStore: Module<ISecondaryCustStoreState, IRootState> = {
    namespaced: true,
    state: secondaryCustService.factorySecondaryCustStoreState(),
    mutations,
    getters,
    actions
};
