






































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { IAthleteSecurity } from "../../builder/builder-models";
import { IBase } from "../../common/common-models";
import { IAthlete } from "../../athlete/athlete-models";
import { AthleteService } from "../../athlete/athlete-service";
import { LAUNCH_ROUTES_PATHS } from "../../launch/launch-routes";
import { format, parse } from "date-fns";
import { simpleClone } from "../../common/common-service-utils";

@Component({
  name: "comp-restricted",
})
export default class CompRestricted extends Vue {
  @Prop({
    default: () => {
      const athleteSecurity: IAthleteSecurity = {
        areas: [],
        clubs: [],
        onlyClubsUpTo: "",
      };
      return athleteSecurity;
    },
  })
  public readonly athleteSecurity: IAthleteSecurity;

  @Prop({
    default: () => {
      return new AthleteService().factoryGetAthlete();
    },
  })
  public readonly athlete!: IAthlete;

  public maxRestrictedClubsDisplay: number = 2;
  public showAllRestrictedClubs = false;

  public restrictedClubs: IBase[] = [];

  public created() {
    this.restrictedClubs = this.getRestrictedClubsAll();
  }

  @Watch("athleteSecurity")
  public onAthleteSecurityChanged(newValue: IAthleteSecurity) {
    this.restrictedClubs = this.getRestrictedClubsAll();
  }

  public getRestrictedClubsAll(): IBase[] {
    const clubs = simpleClone(this.athleteSecurity.clubs);
    return clubs.sort((a: IBase, b: IBase) => {
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      }
      return 1;
    });
  }

  public get getAthleteProfileLink(): string {
    return "#/" + LAUNCH_ROUTES_PATHS.ATHLETE + "/" + this.athlete.id;
  }

  public get getMyAccountAthletes(): string {
    return "#/" + LAUNCH_ROUTES_PATHS.ATHLETES;
  }

  public get getCutOffDateTime(): string {
    if (
      this.athleteSecurity.onlyClubsUpTo &&
      this.athleteSecurity.onlyClubsUpTo.length > 0
    ) {
      const onlyClubsUpTo = format(
        parse(this.athleteSecurity.onlyClubsUpTo),
        "Do MMM HH:mm"
      );
      return " Restriction Ends: " + onlyClubsUpTo;
    }
    //  No date set, so comp completely locked down to these clubs.
    return "";
  }
}
