

























































import { defineComponent, PropType, SetupContext } from "@vue/composition-api";
import { ICompetitionSummaryPublic } from "../../../../competition/competition-models";
import ButtonGenericV2 from "../../../../common/ui/layoutV2/buttons/button-generic-v2.vue";
import CompHeaderLogoCountsV2 from "./comp-header-logo-counts-v2.vue";
import CompHeaderNameLocationV2 from "./comp-header-name-location-v2.vue";
import CompAdminRoute from "../../../../competition/admin/comp-admin-route.vue";
import CompContentV2 from "./comp-content-v2.vue";
import { CompetitionService } from "../../../../competition/competiton-service";
import FormGenericV2 from "../../../../common/ui/layoutV2/form/form-generic-v2.vue";
import LoadingSpinnerV2 from "../../../../common/ui/loading-spinner-v2.vue";
import ButtonGotoCompV2 from "../../../../common/ui/layoutV2/buttons/button-goto-comp-v2.vue";
import ButtonGotoResultsV2 from "../../../../common/ui/layoutV2/buttons/button-goto-results-v2.vue";
import FormGenericButtonBar from "../../../../common/ui/layoutV2/form/form-generic-button-bar.vue";

const competitionService = new CompetitionService();

export default defineComponent({
  name: "public-comp-card-wrapper-v2",
  components: {
    FormGenericButtonBar,
    ButtonGotoResultsV2,
    ButtonGotoCompV2,
    LoadingSpinnerV2,
    FormGenericV2,
    CompContentV2,
    CompAdminRoute,
    CompHeaderNameLocationV2,
    CompHeaderLogoCountsV2,
    ButtonGenericV2,
  },
  props: {
    competitionSummaryPublic: {
      type: Object as PropType<ICompetitionSummaryPublic>,
      default: () => {
        return competitionService.factorySummaryPublic();
      },
    },
    submitButtonText: {
      type: String,
      default: () => {
        return "Submit";
      },
    },
    cancelButtonText: {
      type: String,
      default: () => {
        return "Cancel";
      },
    },
    isLoading: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    showCompResultsButtons: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  setup(
    props: {
      competitionSummaryPublic: ICompetitionSummaryPublic;
      submitButtonText: string;
      cancelButtonText: string;
      isLoading: boolean;
      showCompResultsButtons: boolean;
    },
    context: SetupContext
  ) {
    function cancel() {
      console.log("public-comp-card-wrapper-v2.cancel()");
      context.emit("cancel");
    }
    function submit() {
      console.log("public-comp-card-wrapper-v2.submit()");
      context.emit("submit");
    }

    return { cancel, submit };
  },
});
