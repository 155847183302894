














































import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator"

@Component({
  name: "e4s-modal-simple",
})
export default class E4sModalSimple extends Vue {
  @Prop({ default: "" }) public cssClass: string;
  @Prop({ default: "Cancel" }) public buttonSecondaryText: string;
  @Prop({ default: "OK" }) public buttonPrimaryText: string;
  @Prop({default: false}) public isLoading: boolean;
  @Prop({default: false}) public showDefaultButtons: boolean;


  public cacheOverflow = "";

  public created() {
    this.cacheOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";
  }

  public get getCssClass() {
    return this.cssClass.length === 0 ? "e4s-modal-wrapper" : this.cssClass;
  }

  public closeSecondary() {
    this.$emit("closeSecondary");
  }

  public closePrimary() {
    this.$emit("closePrimary");
  }

  public resetStyles() {
    document.body.style.overflow = this.cacheOverflow;
  }

  public destroy() {
    this.resetStyles();
  }

  public beforeDestroy() {
    this.resetStyles();
  }

}
