









import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "hide-minor",
  props: {
    height: {
      type: String,
      default: () => {
        return "20px";
      },
    },
  },
});
