






























import Vue from "vue";
import Component from "vue-class-component";
import {mapState} from "vuex";
import {AUTH_STORE_CONST, IAuthStoreState} from "../../../auth/auth-store";
import {CONFIG_STORE_CONST, IConfigStoreState} from "../../../config/config-store";
import {Prop} from "vue-property-decorator";
import {IConfigApp} from "../../../config/config-app-models";

@Component({
    name: "standard-form",
    computed: {
        ...mapState(AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME, {
            isLoggedIn: (state: IAuthStoreState) => state.isLoggedIn
        }),
        ...mapState(CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME, {
            configApp: (state: IConfigStoreState) => state.configApp
        })
    }
})
export default class StandardForm extends Vue {
    @Prop({default: ""}) public readonly title: string;

    public configApp: IConfigApp;
}

