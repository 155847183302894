import https from "../common/https";
import {AxiosRequestConfig} from "axios";
import {IServerGenericResponse} from "../common/common-models";
import {CONFIG} from "../common/config";

export class LoginData {

    public login(userName: string, password: string): Promise<string>  {
        const formData = new FormData();
        formData.append("log", userName);
        formData.append("pwd", password);
        formData.append("interim-login", "1");
        const axiosRequestConfig: AxiosRequestConfig = {
            headers: {
                "content-type": "application/x-www-form-urlencoded"
            }
        };
        return https.post( CONFIG.E4S_HOST + "/wp-login.php", formData, axiosRequestConfig);
    }

    public logout(logoutUrl: string) {
        return https.get(logoutUrl);
    }

    public register(userName: string, email: string): Promise<IServerGenericResponse>  {
        return https.post( CONFIG.E4S_HOST + "/wp-json/e4s/v5/register", {name: userName, email});
    }

    public registerV2(userName: string, email: string, password: string): Promise<IServerGenericResponse>  {
        return https.post( CONFIG.E4S_HOST + "/wp-json/e4s/v5/register", {name: userName, email, password});
    }

    public requestPasswordReset(email: string): Promise<IServerGenericResponse>  {
        return https.post( CONFIG.E4S_HOST + "/wp-json/e4s/v5/public/pwdresetrequest?key=" + email);
    }

    public resetPassword(userIdentifier: string, resetKey: string, password: string): Promise<IServerGenericResponse>  {
        return https.post( CONFIG.E4S_HOST + "/wp-json/e4s/v5/public/pwdreset?&key=" + resetKey + "&login=" + userIdentifier + "&pwd=" + password);
    }
}
