import {IUserProfile, IUserSummary} from "./user-models";
import {ResourceData} from "../../common/resource/resource-service";
import https from "../../common/https";
import {IServerGenericResponse, IServerResponse} from "../../common/common-models";
import {UiVersion} from "../../config/config-app-models"

export interface IPayloadUiVersion {
    version: UiVersion;
}

export interface IPayloadUiVersionAdmin extends IPayloadUiVersion {
    toggle: number;
    userId: number;
}

export class UserProfileData extends ResourceData<IUserProfile> {
    constructor() {
        super("/v5/admin/user");
    }

    public getUserProfileByProp(key: string, prop: string): Promise<IServerResponse<IUserProfile>> {
        return https.get( this.getEndPoint() + "?" + prop + "=" + key) as any as Promise<IServerResponse<IUserProfile>>;
    }

    public getUserProfileByEmail(email: string): Promise<IServerResponse<IUserProfile>> {
        return https.get( this.getEndPoint() + "?email=" + email) as any as Promise<IServerResponse<IUserProfile>>;
    }

    public getUserProfileById(id: number): Promise<IServerResponse<IUserProfile>> {
        return https.get( this.getEndPoint() + "?id=" + id) as any as Promise<IServerResponse<IUserProfile>>;
    }

    public addAreaToUserProfile(userId: number, areaId: number) {
        return https.post( this.getEndPoint() + "/area?userid=" + userId + "&areaid=" + areaId) as any as Promise<IServerResponse<any>>;
    }

    public removeAreaFromUserProfile(userId: number, areaId: number) {
        return https.delete( this.getEndPoint() + "/area?userid=" + userId + "&areaid=" + areaId) as any as Promise<IServerResponse<IUserProfile>>;
    }


    public addClubToUserProfile(userId: number, clubId: number) {
        return https.post( this.getEndPoint() + "/club?userid=" + userId + "&clubid=" + clubId) as any as Promise<IServerResponse<any>>;
    }

    public removeClubFromUserProfile(userId: number, clubId: number) {
        return https.delete( this.getEndPoint() + "/club?userid=" + userId + "&clubid=" + clubId) as any as Promise<IServerResponse<any>>;
    }

    public impersonate(id: number): Promise<IServerGenericResponse> {
        return https.get( "/v5/admin/impersonate/" + id ) as any as Promise<IServerGenericResponse>;
    }

    public search(key: string, pageNumber: number = 1, pageSize: number = 10, sortKey: string): Promise<IServerResponse<IUserSummary[]>> {
        return https.get( "/v5/users?search=" + key + "&page=" + pageNumber + "&pagesize=" + pageSize ) as any as Promise<IServerResponse<IUserSummary[]>>;
    }

    public setUserCredit(id: number, value: number, reason: string): Promise<IServerResponse<IUserProfile>> {
        return https.post( "/v5/user/credit/" + id + "?value=" + value + "&reason=" + reason) as any as Promise<IServerResponse<IUserProfile>>;
    }

    public addUserCredit(id: number, value: number): Promise<IServerResponse<IUserProfile>> {
        return https.post( "/v5/user/credit/update/" + id + "?value=" + value) as any as Promise<IServerResponse<IUserProfile>>;
    }

    public setVersion(
      uiVersion: UiVersion,
      toggle?: boolean,
      userId?: number
    ): Promise<IServerResponse<null>> {
        let payload: IPayloadUiVersion | IPayloadUiVersionAdmin;
        payload = {
            version: uiVersion,
        }
        if (toggle) {
            (payload as IPayloadUiVersionAdmin).toggle = toggle ? 1 : 0;
            (payload as IPayloadUiVersionAdmin).userId = userId ? userId : 0;
        }

        return https.post(
          "/v5/user/version", payload
        ) as any as Promise<IServerResponse<null>>;
    }

}
