// import {ISecondaryDefDisplay, ISecondaryPayment} from "./secondary-models"
import {ActionContext, Module} from "vuex"
import {IRootState} from "../app.store"
import {SecondaryService} from "./secondary-service"
import * as R from "ramda"
import {SecondaryData} from "./secondary-data"
import {messageDispatchHelper} from "../user-message/user-message-store"
import {USER_MESSAGE_LEVEL} from "../user-message/user-message-models"
import {ISecondaryDef, SECONDARY_OBJECT_TYPES} from "./secondary-models"
import {ISecondaryPurchaseRead} from "./cust/secondary-cust-models"
import {handleResponseMessages} from "../common/handle-http-reponse"
import {SecondaryCustData} from "./cust/secondary-cust-data"

const secondaryData: SecondaryData = new SecondaryData();
const secondaryCustData: SecondaryCustData = new SecondaryCustData();
const secondaryService: SecondaryService = new SecondaryService();

export interface ISecondaryStoreState {
    secondaryDefs: ISecondaryDef[];
    secondariesLoading: boolean;
    userSecondaryPurchaseRead: ISecondaryPurchaseRead[];
    athleteSecondaryPurchaseRead: ISecondaryPurchaseRead[];
    athleteSecondaryPurchasesLoading: boolean;
}

export enum SECONDARY_STORE_CONST {

    SECONDARY_CONST_MODULE_NAME =  "SECONDARY_CONST_MODULE_NAME",

    // <MUTATIONS>
    SECONDARY_MUTATIONS_SECONDARIES_SET = "SECONDARY_MUTATIONS_SECONDARIES_SET",
    SECONDARY_MUTATIONS_SECONDARIES_LOADING = "SECONDARY_MUTATIONS_SECONDARIES_LOADING",
    SECONDARY_MUTATIONS_USER_PURCHASES = "SECONDARY_MUTATIONS_USER_PURCHASES",
    SECONDARY_MUTATIONS_ATHLETE_PURCHASES = "SECONDARY_MUTATIONS_ATHLETE_PURCHASES",
    SECONDARY_MUTATIONS_ATHLETE_PURCHASES_LOADING = "SECONDARY_MUTATIONS_ATHLETE_PURCHASES_LOADING",
    // </MUTATIONS>

    // <GETTERS>
    SECONDARY_GETTERS_SECONDARIES_BY_CEID = "SECONDARY_GETTERS_SECONDARIES_BY_CEID",
    // </GETTERS>

    // <ACTIONS>
    SECONDARY_ACTIONS_SECONDARIES_GET = "SECONDARY_ACTIONS_SECONDARIES_GET",
    SECONDARY_ACTIONS_USER_PURCHASES_GET = "SECONDARY_ACTIONS_USER_PURCHASES_GET",
    SECONDARY_ACTIONS_ATHLETE_PURCHASES_GET = "SECONDARY_ACTIONS_ATHLETE_PURCHASES_GET",
    SECONDARY_ACTIONS_ATHLETE_PURCHASES_REMOVE = "SECONDARY_ACTIONS_ATHLETE_PURCHASES_REMOVE"
    // </ACTIONS>
}

const mutations = {
    [ SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_SECONDARIES_SET ](state: ISecondaryStoreState, secondaryDef: ISecondaryDef[]) {
        state.secondaryDefs = R.clone(secondaryDef);
    },
    [ SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_SECONDARIES_LOADING ](state: ISecondaryStoreState, isLoading: boolean) {
        state.secondariesLoading = isLoading;
    },
    [ SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_ATHLETE_PURCHASES_LOADING ](state: ISecondaryStoreState, isLoading: boolean) {
        state.athleteSecondaryPurchasesLoading = isLoading;
    },
    [ SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_USER_PURCHASES ](state: ISecondaryStoreState, secondaryPurchaseRead: ISecondaryPurchaseRead[]) {
        state.userSecondaryPurchaseRead = secondaryPurchaseRead;
    },
    [ SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_ATHLETE_PURCHASES ](state: ISecondaryStoreState, secondaryPurchaseRead: ISecondaryPurchaseRead[]) {
        state.athleteSecondaryPurchaseRead = secondaryPurchaseRead;
    }
};

const getters = {
    [ SECONDARY_STORE_CONST.SECONDARY_GETTERS_SECONDARIES_BY_CEID ](state: ISecondaryStoreState) {
        // return secondaryService.secondariesByCeid(state.secondaryDefs);
        return [];
    }
};

export interface ISecondariesGetPayload {
    objType: SECONDARY_OBJECT_TYPES,
    objId: number,
    compId: number
}

const actions = {
    [SECONDARY_STORE_CONST.SECONDARY_ACTIONS_SECONDARIES_GET](
        context: ActionContext<ISecondaryStoreState, IRootState>,
        payload: ISecondariesGetPayload
    ) {
        context.commit(SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_SECONDARIES_LOADING, true);
        secondaryData.getSecondaries(payload.objType, payload.objId, payload.compId)
            .then((response) => {
                if (response.errNo > 0) {
                    messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
                    return;
                }
                context.commit(SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_SECONDARIES_SET, response.data);
            })
            .catch((error) => {
                messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
                return;
            })
            .finally(() => {
                context.commit(SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_SECONDARIES_LOADING, false);
            });
    },

    [SECONDARY_STORE_CONST.SECONDARY_ACTIONS_USER_PURCHASES_GET](
        context: ActionContext<ISecondaryStoreState, IRootState>,
        compId: number
    ) {
        context.commit(SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_SECONDARIES_LOADING, true);

        const userPurchasesProm = secondaryCustData.getPurchases(compId);
        handleResponseMessages(userPurchasesProm);
        userPurchasesProm
            .then((response) => {
                if (response.errNo > 0) {
                    return;
                }
                context.commit(SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_USER_PURCHASES, response.data);
            })
            .finally(() => {
                context.commit(SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_SECONDARIES_LOADING, false);
            })
    },

    [SECONDARY_STORE_CONST.SECONDARY_ACTIONS_ATHLETE_PURCHASES_GET](
        context: ActionContext<ISecondaryStoreState, IRootState>,
        payload: {
            compId: number,
            athleteId: number
        }
    ) {
        context.commit(SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_ATHLETE_PURCHASES_LOADING, true);
        const userPurchasesProm = secondaryCustData.getPurchasesAthlete(payload.compId, payload.athleteId);
        handleResponseMessages(userPurchasesProm);
        userPurchasesProm
            .then((response) => {
                if (response.errNo > 0) {
                    return;
                }
                if (response.data === null) {
                    messageDispatchHelper("Get Purchases Athlete returned null.", USER_MESSAGE_LEVEL.ERROR.toString());
                }
                context.commit(SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_ATHLETE_PURCHASES, response.data === null ? [] : response.data);
            })
            .finally(() => {
                context.commit(SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_ATHLETE_PURCHASES_LOADING, false);
            })
    }

    // [SECONDARY_STORE_CONST.SECONDARY_ACTIONS_ATHLETE_PURCHASES_REMOVE](
    //     context: ActionContext<ISecondaryStoreState, IRootState>,
    //     payload: {
    //         compId: number,
    //         purchaseOrderIds: number[]
    //     }
    // ) {
    //     context.commit(SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_SECONDARIES_LOADING, true);
    //     const userPurchasesProm = secondaryCustData.getPurchasesAthlete(payload.compId, payload.athleteId);
    //     handleResponseMessages(userPurchasesProm);
    //     userPurchasesProm
    //         .then((response) => {
    //             if (response.errNo > 0) {
    //                 return;
    //             }
    //             context.commit(SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_ATHLETE_PURCHASES, response.data);
    //         })
    //         .finally(() => {
    //             context.commit(SECONDARY_STORE_CONST.SECONDARY_MUTATIONS_SECONDARIES_LOADING, false);
    //         })
    // }
};

export const secondaryStore: Module<ISecondaryStoreState, IRootState> = {
    namespaced: true,
    state: secondaryService.factorySecondaryStoreState(),
    mutations,
    getters,
    actions
};
