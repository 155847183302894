import { IAthlete, IAthletePb } from "../../../athlete/athlete-models";
import { PBErrorMessageType } from "./pb-service-v3";
import { IEditPbV3Props } from "../pb-models";
import { IsoDate } from "../../../common/common-models";
import { IEventUom } from "../../../uom/uom-models";

export const ATHLETE_PERF_TEXT_PREFIX = "!#!";

export type AthleteMini = Pick<
  IAthlete,
  "id" | "firstName" | "surName" | "URN" | "aocode"
>;
export type IPbCompSchedMini = {
  split: number;
  entryId: number;
};

export interface IPerfInfoBase {
  perf: number;
  perfText: string;
}

export interface IPerfInfo extends IPerfInfoBase {
  athleteId: number;
  eventId: number;
  eventType: "D" | "H" | "R" | "T" | "F";
  info: string;
  pb: number;
  pbText: string;
  pbAchieved: IsoDate | "";
  sb: number;
  sbText: string;
  sbAchieved: IsoDate | "";
  ageGroup: string;
  curAgeGroup: string;
  eventName: string;
  limits: {
    min: number;
    max: number;
  };
  uom: IEventUom[];
}

export type PerfInfoInput = IPerfInfo;

export interface EditPbV3Options {
  doSubmit: boolean;
}

export interface EditPbV3InputProp {
  athleteMini: AthleteMini;
  athleteCompSchedMini: IPbCompSchedMini;
  performance: PerfInfoInput;
}

export interface EntryPerfServer {
  entryId: number;
  athleteMini: AthleteMini;
  compEventMini: {
    ceId: number;
    split: number;
  };
  performance: PerfInfoInput;
}

export interface IPbEditBaseV3State {
  editTimePbV3Props: IEditPbV3Props;
  athletePb: IAthletePb;
  outputText: string;
  errors: Partial<Record<PBErrorMessageType, string>>;
  isValid: boolean;
}

export interface IPbEditV3RouteOutput {
  entryId: number;
  perfInfo: IPerfInfo;
}
