








































































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "E4s-Logo-svg",
  props: {
    height: {
      type: String,
      default: () => {
        return "50px";
      },
    },
  },
});
