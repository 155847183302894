import {IServerBaseResponse, IServerResponse} from "./common-models"
import {messageDispatchHelper} from "../user-message/user-message-store"
import {USER_MESSAGE_LEVEL} from "../user-message/user-message-models"

export function handleResponseMessages(prom: Promise<IServerBaseResponse>, successMessage?: string): void {
    prom
        .then( (response: IServerBaseResponse) => {
            if (response.errNo > 0) {
                if (response.message && response.message.length > 0) {
                    messageDispatchHelper(response.message, USER_MESSAGE_LEVEL.ERROR.toString());
                } else {
                    messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
                }
            } else {
                if (successMessage && successMessage.length > 0) {
                    messageDispatchHelper(successMessage, USER_MESSAGE_LEVEL.INFO.toString());
                }
                if(response.message && response.message.length > 0) {
                    messageDispatchHelper(response.message, USER_MESSAGE_LEVEL.INFO.toString());
                }
            }
            // return response.data;
        })
        .catch((error) => {
            messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        });
}


export interface IHttpResponseControllerState<Data> {
    isLoading: boolean;
    data: Data | null;
    error: string;
    getData: (prom: Promise<IServerResponse<Data>>)=>Promise<any>;
    deferred: Promise<any>;
    updatedAt: number;
}

export function useHttpResponseController<Data>(initData: Data | null = null, successMessage?: string): IHttpResponseControllerState<Data> {

    const state: IHttpResponseControllerState<Data> = {
        isLoading: false,
        data: initData,
        error: "",
        getData,
        deferred: Promise.resolve(),
        updatedAt: 0
    };

    function getData(prom: Promise<IServerResponse<Data>>, cacheInSeconds = -1): Promise<any> {

        if (state.isLoading) {
            return state.deferred;
        }

        if (cacheInSeconds > 0) {
            const isFresh = Date.now() - state.updatedAt < (cacheInSeconds * 1000);
            if (isFresh) {
                return Promise.resolve(state.data);
            }
        }

        state.isLoading = true;
        state.deferred = prom;

        return state.deferred
            .then( (response: IServerResponse<Data>) => {
                if (response.errNo > 0) {
                    state.error = response.error;
                    messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
                } else {
                    if (successMessage && successMessage.length > 0) {
                        messageDispatchHelper(successMessage, USER_MESSAGE_LEVEL.INFO.toString());
                    }
                }
                state.data = response.data;
                state.updatedAt = Date.now();
                return state.data;
            })
            .catch((error: Error) => {
                messageDispatchHelper(error.message, USER_MESSAGE_LEVEL.ERROR.toString());
                state.error = error.message;
            })
            .finally(()=> {
                state.isLoading = false;
            })
    }
    return state;
}


