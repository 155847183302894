































































































































import * as R from "ramda";
import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import LoadingSpinner from "../../common/ui/loading-spinner.vue";
import {ICompetitionStatus, IWorkFlow} from "../competition-models";
import {CompetitionService} from "../competiton-service";
import {IStatusBase} from "./status-models";
import FileUpload from "../../common/ui/file-upload.vue";
import {ValidationController} from "../../validation/validation-controller";
import {IObjectKeyType} from "../../common/common-models";
import {IValidationProp} from "../../validation/validation-models";
import {ValidationService} from "../../validation/validation-service";
import FieldValidationLabel from "../../validation/validation-field-lable.vue";

const validationService: ValidationService = new ValidationService();

@Component({
    name: "status",
    components: {
        "loading-spinner": LoadingSpinner,
        "file-upload": FileUpload,
        FieldValidationLabel
    }
})
export default class Status extends Vue {
    @Prop(
        {
            default: (): ICompetitionStatus => {
                return {
                    id: 0
                } as ICompetitionStatus;
            }
        }
    ) public statusProp: ICompetitionStatus;
    @Prop({default: () => []}) public workFlows: IWorkFlow[];
    @Prop({default: false}) public isLoading: boolean;
    public workFlow: IWorkFlow = {id: 0} as IWorkFlow;

    public PREFIX = Math.random().toString(36).substring(2);
    public competitionService: CompetitionService = new CompetitionService();
    public status: ICompetitionStatus = this.competitionService.factoryStatus();

    public validationController: ValidationController = new ValidationController();

    public created() {
        // this.status = {...R.clone(this.statusProp)};
        this.status = {...this.competitionService.factoryStatus(), ...R.clone(this.statusProp)};
        this.setCurrentWorkFlow();
    }

    @Watch("statusProp")
    public onStatusPropChanged(newValue: ICompetitionStatus) {
        this.status = {...this.competitionService.factoryStatus(), ...newValue};
        this.setCurrentWorkFlow();
    }

    @Watch("workFlows")
    public onWorkFlowsPropChanged(newValue: IWorkFlow[]) {
        this.setCurrentWorkFlow();
    }

    public setCurrentWorkFlow() {
        this.workFlows.forEach(( workFlow, index) => {
            if (workFlow.id === this.status.wfid) {
                this.workFlow = this.workFlows[index];
            }
        });
    }

    public get getOpenInvoiceLink() {
        return (this.status.invoicelink.indexOf("http") === -1 ? "https://" : "") + this.status.invoicelink;
    }

    public getOutput(): IStatusBase {
        return {
            id: this.status.id,
            compid: this.status.compid,
            invoicelink: this.status.invoicelink,
            paid: this.status.paid,
            moveto: this.workFlow.id,
            value: this.status.value,
            ref: this.status.ref,
            notes: this.status.notes
        } as IStatusBase;
    }

    public validate() {
        let errors: IObjectKeyType<IValidationProp> = {};
        if (this.workFlow.status === "PAID" && (!this.status.ref || this.status.ref.length === 0)) {
            errors = validationService.addMessage("ref", "Bank ref required.", errors);
        }
        this.validationController.setErrors(errors);
    }

    public submit() {
        this.validate();
        if (!this.validationController.isValid) {
            return;
        }
        this.$emit("onSubmit", R.clone(this.getOutput()));
    }

    public close() {
        this.$emit("onClose", this.getOutput());
    }

}
