











import { defineComponent, ref, SetupContext } from "@vue/composition-api";
import AskOrganiserFormV2 from "./ask-organiser-form-v2.vue";
import { handleResponseMessages } from "../../common/handle-http-reponse";
import { CompetitionData } from "../competition-data";
import { useRoute, useRouter } from "../../router/migrateRouterVue3";
import LoadingSpinnerV2 from "../../common/ui/loading-spinner-v2.vue";
import { ICompetitionSummaryPublic } from "../competition-models";
import { LAUNCH_ROUTES_PATHS } from "../../launch/launch-routes";

export default defineComponent({
  name: "AskOrganiserRoute",
  components: { LoadingSpinnerV2, AskOrganiserFormV2 },
  props: {},
  setup(props: any, context: SetupContext) {
    const competitionSummaryPublic = ref<ICompetitionSummaryPublic>({
      id: 0,
    } as any as ICompetitionSummaryPublic);
    const isLoading = ref(false);
    const route = useRoute();
    const router = useRouter();
    const id = isNaN(Number(route.params.id))
      ? 0
      : parseInt(route.params.id, 0);

    if (id > 0) {
      loadData();
    }

    function loadData() {
      const competitionData = new CompetitionData();
      isLoading.value = true;
      const promComp = competitionData.getCompById(id);
      handleResponseMessages(promComp);
      return promComp
        .then((resp) => {
          if (resp.errNo === 0) {
            competitionSummaryPublic.value = resp.data;
          }
          return;
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    function cancel() {
      router.push({
        path:
          "/" +
          LAUNCH_ROUTES_PATHS.SHOW_ENTRY +
          "/" +
          competitionSummaryPublic.value.compId.toString(),
      });
    }

    return { competitionSummaryPublic, isLoading, cancel };
  },
});
