// import {DEFAULT_PAGE_SIZE} from "../config/config-store"

export interface IBaseNameValue<VALUE> {
  name: string;
  value: VALUE;
}

export interface IBaseRaw {
  id: number;
}

export interface IBase extends IBaseRaw {
  name?: string;
}

export interface IBaseConcrete extends IBaseRaw {
  name: string;
}

export interface IBaseConcreteString {
  id: string;
  name: string;
}

export interface IValidationResult {
  id?: number; //  TODO fix sometime.
  name: string;
  message: string;
}

export interface IServerBaseResponse {
  errNo: number;
  error: string;
  message?: string;
}

export interface IServerGenericResponse extends IServerBaseResponse {
  data: any;
  meta?: any;
}

export interface IPaging {
  page: number;
  pageSize: number;
  totalCount: number;
}

export interface IServerGenericPagingResponse extends IServerGenericResponse {
  meta: IPaging;
}

export interface IServerResponse<T, V = {}> {
  errNo: number;
  error: string;
  data: T;
  meta?: V;
  message?: string;
}

export interface IServerResponseList<T> {
  errNo: number;
  error: string;
  data: T[];
}

export interface IServerPagingResponseList<T> extends IServerResponseList<T> {
  meta: IPaging;
}

export interface IServerResponseListMeta<T, Meta> {
  errNo: number;
  error: string;
  data: T[];
  meta: Meta;
}

//  @See Record(), way easier.
export interface IObjectKey {
  [key: string]: any;
  //  Another way to write this is: let stuff: { [s: string]: string; } = {};
}

export interface IObjectKeyType<T> {
  [key: string]: T;
  //  Another way to write this is: let stuff: { [s: string]: string; } = {};
}

export interface IObjectKeyTypeArray<T> {
  [key: string]: T[];
}

export interface ISelectOption<T> {
  label: string;
  value: T;
}

// export interface ISelctOption: Record<string, T> {
//     label: string;
//     value: T;
// }

// const myob: Record<string, IAthleteSearch> = {
//     one: {} as IAthleteSearch,
//     two: {} as IAthleteSearch
// }

export interface IConfig {
  ENV: string;
  E4S_HOST: string;
  API_URL: string;
  REST_URL: string;
  WP_BASKET: string;
  ORGANISER_AND_PUBLIC_CARD: string;
  ORGANISER_REPORT: string;
}

export type GenderType = "" | "F" | "M" | "O";
export enum GENDER {
  UNKNOWN = "",
  MALE = "M",
  FEMALE = "F",
  OPEN = "O",
}

export interface IFileUploadResponse extends IServerGenericResponse {
  path: string;
}

export interface IVideoDevice {
  label: string;
  deviceId: string;
}

export interface ITrigger<T> {
  timeIso: string;
  data?: T;
}

export interface ISimpleDateModel {
  iso: string; //  The iso  bit of a date, e.g. 2021-03-28
  display: string; //  Whatever pattern you want.
}

export const ISO_FORMAT = "YYYY-MM-DD[T]HH:mm:ssZ";
export type IsoDate = string;
export type IsoDateTime = string;
export type IsoIshDateTime = string; // YYYY-MM-DD[T]HH:mm:ssZ  e.g. 2021-03-28 16:23:59
export type IsoTime = string; //  16:23:59
export type UrlFullPath = string;
export type UrlPathOnly = string;
export type ContentStringHtml = string;
export type HtmlString = string;

export const PaidTypes = {
  0: "Not Paid",
  1: "Paid",
  2: "Cheque/Awaiting Payment",
  3: "Auto Free Price",
};

type PaidTypesTypeOf = typeof PaidTypes;
export type PaidType = keyof PaidTypesTypeOf; //  '0' | '1' | '2' | '3'
export type PaidTypeValue = PaidTypesTypeOf[keyof PaidTypesTypeOf]; //  'Not Paid' | 'Paid' | 'Cheque/Awaiting Payment' | 'Auto Free Price'

export type DropDownAll = "ALL";

export type AthleteId = number;
export type AthleteIdString = string;
export type AthleteUrn = string;
export type AthleteUrnNumber = number;
export type EventGroupIdString = string;
export type EventGroupIdNumber = number;
export type EntryIdString = string;
export type EntryIdNumber = number;

export type Opaque<K, T> = T & { __TYPE__: K };
