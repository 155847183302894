import * as R from "ramda";
import { ICompRule, ICompUnique } from "./comprule-models.";
import { IAgeGroup } from "../agegroup/agegroup-models";
import { GENDER, IValidationResult } from "../common/common-models";
import { IEventSummary } from "../event/event-models";
import { CommonService } from "../common/common-service";
import { EVENT_TYPE } from "../athletecompsched/athletecompsched-models";

const commonService: CommonService = new CommonService();

export class CompRuleService {
  public factory(): ICompRule {
    return {
      id: 0,
      compId: 0,
      ageGroup: {
        id: 0,
      } as IAgeGroup,
      ageGroups: [],
      options: {
        maxEvents: 0,
        maxField: 0,
        maxTrack: 0,
        maxCompEvents: 0,
        maxCompField: 0,
        maxCompTrack: 0,
        maxExcludedEvents: 0,
        type: "",
        unique: [] as ICompUnique[],
        displayMaxDayFields: false,
        maxTeamEvents: 0,
      },
    } as ICompRule;
  }

  public factoryCompRuleUnique(): ICompUnique {
    return {
      ids: [],
      events: [],
      type: "",
      text: "",
    } as ICompUnique;
  }

  public validate(compRule: ICompRule): IValidationResult[] {
    const validationResults: IValidationResult[] = [];
    return validationResults;
  }

  public validateCompRuleUnique(
    compRuleUnique: ICompUnique
  ): IValidationResult[] {
    const validationResults: IValidationResult[] = [];

    // if (commonService.stripChars(compRuleUnique.type).length === 0) {
    //     validationResults.push({
    //         name: "type",
    //         message: "Enter an Type."
    //     });
    // }

    if (commonService.stripChars(compRuleUnique.text).length === 0) {
      validationResults.push({
        name: "text",
        message: "Enter an Text label.",
      });
    }

    if (compRuleUnique.events.length < 2) {
      validationResults.push({
        name: "text",
        message: "Select at least 2 events.",
      });
    }

    return validationResults;
  }

  public getCompRuleUniqueRowText(compRuleUnique: ICompUnique): string {
    return compRuleUnique.events.reduce((accum: string, evt: IEventSummary) => {
      accum = accum.length === 0 ? evt.name : accum + ", " + evt.name;
      return accum;
    }, "");
  }

  public getCompRuleSummaryLabel(compRule: ICompRule): string {
    return (
      "Max Comp: " +
      compRule.options.maxCompEvents +
      " (Trk: " +
      compRule.options.maxCompTrack +
      ", Fld: " +
      compRule.options.maxCompField +
      ") " +
      "Max Day: " +
      compRule.options.maxEvents +
      " (Trk: " +
      compRule.options.maxTrack +
      ", Fld: " +
      compRule.options.maxField +
      ")"
    );
  }

  public getForAgeGroup(
    ageGroupId: number,
    compRules: ICompRule[]
  ): ICompRule[] {
    compRules = R.clone(compRules);
    return compRules.filter((obj) => {
      return obj.ageGroup.id === ageGroupId;
    });
  }

  public canBeSaved(
    compRule: ICompRule,
    compRules: ICompRule[]
  ): IValidationResult[] {
    const validationResults: IValidationResult[] = [];
    const compRulesWithSameAgeGroupId: ICompRule[] = this.getForAgeGroup(
      compRule.ageGroup.id,
      compRules
    );
    if (
      compRulesWithSameAgeGroupId.length > 0 &&
      compRulesWithSameAgeGroupId[0].ageGroup.id !== compRule.ageGroup.id
    ) {
      validationResults.push({
        message:
          "A comp rule for [" +
          compRule.ageGroup.id +
          "] " +
          " already exists.",
      } as IValidationResult);
    }
    return validationResults;
  }

  public mapRulesFromServer(compRules: ICompRule[]): ICompRule[] {
    return compRules.map((compRule) => {
      return this.mapRuleFromServer(compRule);
    });
  }

  public mapRuleFromServer(compRule: ICompRule): ICompRule {
    compRule = R.clone(compRule);
    const compUniques: ICompUnique[] = compRule.options.unique as ICompUnique[];
    compUniques.map((compUnique: ICompUnique, index: number) => {
      if (R.isNil(compUnique.events)) {
        compUnique.events = compUnique.ids.reduce((accum, id: number) => {
          accum.push({
            id,
            name: "Unknown",
            gender: GENDER.UNKNOWN,
            tf: EVENT_TYPE.ALL,
          });
          return accum;
        }, [] as IEventSummary[]);
      }
      return compUnique;
    });
    compRule.options.unique = compUniques;
    return compRule;
  }

  public getCompRuleName(compRule: ICompRule) {
    return compRule.ageGroup.id === 0
      ? "All Age Groups"
      : compRule.ageGroup.name;
  }
}
