









import { defineComponent, PropType, SetupContext } from "@vue/composition-api";
import { ButtonGenericType } from "./button-generic-models";
import ButtonGenericV2 from "./button-generic-v2.vue";
import PrimaryLink from "../href/PrimaryLink.vue";

export default defineComponent({
  name: "button-generic-back-v2",
  components: { PrimaryLink, ButtonGenericV2 },
  props: {
    text: {
      type: String,
      default: () => {
        return "Back";
      },
    },
    buttonType: {
      type: String as PropType<ButtonGenericType>,
      default: () => {
        return "primary";
      },
      validator: function (value: ButtonGenericType) {
        return ["primary", "secondary", "tertiary"].indexOf(value) !== -1;
      },
    },
  },
  setup(
    props: {
      text: string;
      buttonType: "primary" | "secondary" | "tertiary";
    },
    context: SetupContext
  ) {
    function buttonClicked() {
      context.emit("click");
    }

    return { buttonClicked };
  },
});
