






import Vue from "vue";
import Component from "vue-class-component";
import {Watch} from "vue-property-decorator";
import {mapState} from "vuex";
import {IUserMessage} from "./user-message-models"
import {IUserMessageStoreState, USER_MESSAGE_STORE_CONST} from "./user-message-store";

// enum MATERIALIZE_MESSAGE_LEVEL {
//     INFO = "toast-info",
//     WARN = "toast-warning",
//     ERROR = "toast-error"
// }

@Component({
    computed: {
     ...mapState(USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME, {
      message: (state: IUserMessageStoreState) => state.currentMessage
    })
  }
})
export default class Toaster extends Vue {

  @Watch("message")
  public onMessageChanged(userMessage: IUserMessage, oldValue: IUserMessage) {

    // (window as any).M.toast({
    //   html: userMessage.message, // "<div>Drag left or right to close.</div>"
    //   classes: userMessage.level === USER_MESSAGE_LEVEL.WARN ? MATERIALIZE_MESSAGE_LEVEL.WARN : MATERIALIZE_MESSAGE_LEVEL.INFO,
    //   displayLength: userMessage.timeOut && userMessage.timeOut > 0  ? userMessage.timeOut : 7000,
    //   inDuration: 500,
    //   outDuration: 500
    // });

    //  This is how to use.
    //  import {IUserMessage, USER_MESSAGE_LEVEL} from "../user-message/user-message-models";
    //  import {USER_MESSAGE_STORE_CONST} from "../user-message/user-message-store";
    //  this.$store.commit(
    //     USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME + "/" + USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE, {
    //         level: USER_MESSAGE_LEVEL.DEBUG,
    //         message: "<div><b>Hi</b> there!</div>"
    //     } as IUserMessage
    //  );

  }

}
