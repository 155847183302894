



















































import {defineComponent} from "@vue/composition-api";

export default defineComponent({
  name: "loading-spinner-v2",
  props: {
    loadingMessage: {
      type: String,
      default: function() {
        return "Loading, please wait...";
      }
    }
  }
});
