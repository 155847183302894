import https from "../../common/https";
import {IServerGenericResponse} from "../../common/common-models";

export class AskOrganiserData {
    public submitAskOrganiser(compId: number, category: string, body: string, email: string) {
        return https.post( "/v5/competition/contactorganiser", {
            compId,
            category,
            body,
            email
        }) as any as Promise<IServerGenericResponse>;
    }
}
