// import "core-js/es";
// import "core-js/es/array";
// import "core-js/es/promise";
// import "core-js/modules/es.promise.finally";

import Vue from "vue";

import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

import VeeValidate from "vee-validate";

import VueMq from "vue-mq";
import VueRouter from "vue-router";
import { appStore } from "./app.store";
import "./common/common-vue";
import "./assets/custom.css";
import "./assets/materialize.css";
import "./assets/vue-select-3-20-0.css";
import "./assets/materialize.min.js";
import "./assets/entry4sports.css";
import "./common/validator-extend";
import { CONFIG_STORE_CONST, IConfigStoreState } from "./config/config-store";
import { i18n } from "./config/il8n";
import Launch from "./launch/launch.vue";
import { LAUNCH_ROUTES_PATHS, launchRoutes } from "./launch/launch-routes";
import LoadingSpinner from "./common/ui/loading-spinner.vue";
import formControllerFieldDirective from "./common/ui/form/form-controller/form-controller-field-directive";
import ScoreboardSection from "./competition/scoreboard/scoreboard-section.vue";
import scoreboardRoutes from "./competition/scoreboard/scoreboard-routes";
import { ConfigData } from "./config/config-data";
import { handleResponseMessages } from "./common/handle-http-reponse";
import {
  messageDispatchHelper,
  messageDispatchHelperAdmin,
} from "./user-message/user-message-store";
import { USER_MESSAGE_LEVEL } from "./user-message/user-message-models";
import { AUTH_STORE_CONST } from "./auth/auth-store";
import R4sSection from "./competition/scoreboard/rs4/r4s-section.vue";
import rs4Routes from "./competition/scoreboard/rs4/r4s-routes";
import LaunchV2 from "./launch/v2/launch-v2.vue";

Vue.use(VueRouter);
Vue.use(VeeValidate);

Vue.component("LoadingSpinner", LoadingSpinner);
Vue.directive("form-controller-field", formControllerFieldDirective);
Vue.directive("nick-purple", nickPurple);
Vue.directive("click-outside", clickOutside);

// @ts-ignore
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import { launchRoutesV2 } from "./launch/v2/launch-routes-v2";
import { clickOutside, nickPurple } from "./directives/click-outside";
// import LoginContainer from "./auth/login/login-container.vue";
import RegisterContainer from "./auth/register/register-container.vue";
import LoginContainerV2 from "./auth/v2/login-container-v2.vue";
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);

export const VUE_MQ_SIZES = {
  // MOBILE_XS: {
  //     name: "mobile-xs",
  //     size: 320
  // },
  // MOBILE_XS_LANDSCAPE: {
  //     name: "mobile-xs",
  //     size: 568
  // },
  MOBILE: {
    name: "mobile",
    size: 700,
  },
  TABLET: {
    name: "tablet",
    size: 1000,
  },
  DESKTOP: {
    name: "desktop",
    size: Infinity,
  },
};

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    [VUE_MQ_SIZES.MOBILE.name]: VUE_MQ_SIZES.MOBILE.size,
    [VUE_MQ_SIZES.TABLET.name]: VUE_MQ_SIZES.TABLET.size,
    [VUE_MQ_SIZES.DESKTOP.name]: VUE_MQ_SIZES.DESKTOP.size,
  },
  defaultBreakpoint: "sm", // customize this for SSR
});

// window.onerror = function(message, source, line, column, error) {
//     console.log("ONE ERROR HANDLER TO RULE THEM ALL:", message);
// };

Vue.config.errorHandler = function (err, vm, info) {
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the e

  console.error("x x x x x Vue.config.errorHandler ", {
    err,
    vm,
    info,
  });
  // appStore.commit(
  //   USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME +
  //     "/" +
  //     USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE,
  //   {
  //     level: USER_MESSAGE_LEVEL.INFO,
  //     message: "ERROR: code error.",
  //   } as IUserMessage
  // );
  messageDispatchHelperAdmin(
    "Error Message for Admin: " + err.message,
    USER_MESSAGE_LEVEL.ERROR.toString()
  );
};

Vue.use(VueRouter);

//  For DEV tools, very useful.
Vue.config.performance = process.env.NODE_ENV !== "production";

// const uiVersion =
//   appStore.state[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME].ui.version;

// const uiVersion = computed( () => {
//     return appStore.state[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME].ui.version
// })

const routes = [
  // {
  //   name: "homev2",
  //   path: "",
  //   component: LaunchV2,
  //   children: launchRoutesV2,
  //   meta: {
  //     title: () => {
  //       return "Home v2";
  //     },
  //   },
  // },
  {
    name: "home",
    path: "",
    component: Launch,
    children: launchRoutes,
    meta: {
      title: () => {
        return "Home v2";
      },
    },
  },
  {
    name: "v2",
    path: "/v2",
    component: LaunchV2,
    children: launchRoutesV2,
  },
  {
    name: "launch",
    path: "/launch",
    component: Launch,
    children: launchRoutes,
    meta: {
      title: (params: { id: string }) => {
        return "Home 1";
      },
    },
  },
  {
    name: "login",
    path: "/login",
    component: LoginContainerV2,
    meta: {
      title: (params: { id: string }) => {
        return "Login";
      },
    },
  },
  {
    name: "register",
    path: "/register",
    component: RegisterContainer,
    meta: {
      title: (params: { id: string }) => {
        return "Register";
      },
    },
  },
  {
    name: "scoreboard",
    path: "/scoreboard",
    component: ScoreboardSection,
    children: scoreboardRoutes,
    meta: {
      title: (params: { id: string }) => {
        return "Scoreboard";
      },
    },
  },
  {
    name: "r4s",
    path: "/r4s",
    component: R4sSection,
    children: rs4Routes,
    meta: {
      title: (params: { id: string }) => {
        return "Results 2";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.ATHLETE_PB,
    path: "/" + LAUNCH_ROUTES_PATHS.ATHLETE_PB + "/:entryId",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "athlete-pb-route" */ "./athleteCompSched/pb/v3/AthletePbRoute.vue"
      );
    },
    meta: {
      title: (params: { entryId: number }) => {
        return "Entry Id" + params.entryId;
      },
    },
  },
];

export const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return desired position
    console.log("router  scrollBehavior()...");
    return { x: 0, y: 0 };
  },
});

// router.afterEach((to, from) => {
//   console.log("Temp Hack!  Woo commerce messing with overflow", {to, from});
// });

// router.beforeEach((to, from, next) => {
//   // Get the page title from the route meta data that we have defined
//   // See further down below for how we setup this data
//   const title = to.meta.title
// // If the route has a title, set it as the page title of the document/page
//   if (title) {
//     document.title = title
//   }
//   // Continue resolving the route
//   next()
// })

/*
router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title;

  //  Take the title from the parameters
  const titleFromParams = to.params.pageTitle;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title;
  }
  // If we have a title from the params, extend the title with the title
  // from our params
  if (titleFromParams) {
    document.title = `${titleFromParams} - ${document.title}`;
  }
  // Continue resolving the route
  next();
});
*/

export function isLoggedInGuardV2(to: any, from: any, next: any) {
  // const loginPathV2 = "/" + LAUNCH_ROUTES_PATHS_V2_BASE + "/" + LAUNCH_ROUTES_PATHS_V2.LOGIN_V2
  // isLoggedInGuardGeneric(to, from, next, loginPathV2);
  isLoggedInGuardGeneric(to, from, next, "login-v2");
}

export function isLoggedInGuard(to: any, from: any, next: any) {
  // const loginPathV1 = "/#/login";
  // isLoggedInGuardGeneric(to, from, next, loginPathV1);
  isLoggedInGuardGeneric(to, from, next, "login-v1");
}

export function isLoggedInGuardGeneric(
  to: any,
  from: any,
  next: any,
  loginRoute: string
) {
  // const isAuthRequired = to.matched.some((route: RouteConfig) => route.meta.isAuthRequired);

  console.log(".............isLoggedInGuardGeneric");

  Vue.nextTick(() => {
    const title: unknown = to.meta && to.meta.title ? to.meta.title : null;
    if (title) {
      if (typeof title === "string") {
        document.title = title;
        return;
      }
      if (typeof title === "function") {
        document.title = title(to.params);
        return;
      }
      return;
    }
    document.title = "Entry4Sports";
  });

  let isUserLoggedIn = false;
  const configStoreState: IConfigStoreState =
    appStore.state[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME];
  const userName = configStoreState.configApp.userInfo.user.display_name;

  if (userName === "" || userName === "ANONYMOUS") {
    //  ANONYMOUS
    const prom = new ConfigData().getAppConfig();
    handleResponseMessages(prom);
    prom.then((response) => {
      if (response.errNo > 0) {
        messageDispatchHelper(
          response.error,
          USER_MESSAGE_LEVEL.ERROR.toString()
        );
        return;
      }
      appStore.commit(
        CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
          "/" +
          CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_APP_CONFIG,
        response.data
      );
      const userNameData = response.data.userInfo.user.display_name;
      const isLoggedIn = userNameData.replace("ANONYMOUS", "").length > 0;
      appStore.commit(
        AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME +
          "/" +
          AUTH_STORE_CONST.MUTATIONS_LOGGED_IN,
        isLoggedIn
      );
      if (isLoggedIn) {
        next();
      } else {
        // name: loginRoute, // back to safety route //
        next({
          name: loginRoute,
          query: {
            redirectFrom: to.fullPath,
          },
        });
      }
    });
    return;
  } else {
    isUserLoggedIn = true;
  }

  // isUserLoggedIn = true;

  // const isUserLoggedIn = process.env.NODE_ENV === "development" ? true : appStore.state[AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME].isLoggedIn;
  // const isUserLoggedIn = appStore.state[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME].configApp.userInfo.user.name !== "ANONYMOUS";
  // const isUserLoggedIn = false;
  // const isUserLoggedIn = appStore.state[AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME].isLoggedIn;
  if (!isUserLoggedIn) {
    // if (isAuthRequired && !isUserLoggedIn) {
    // appStore.commit(AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME + "/" + AUTH_STORE_CONST.MUTATIONS_SHOW_LOGIN, true);
    next({
      name: "login-v1", // back to safety route //
      query: {
        redirectFrom: to.fullPath,
      },
    });
    return;
  } else {
    next();
  }
  next();
}

// tslint:disable-next-line:no-unused-expression
new Vue({
  el: "#app",
  router,
  i18n,
  store: appStore,
  beforeCreate: () => {
    // console.log(`..........Vue.beforeCreate().......Website Mode = [${process.env.NODE_ENV}]`);
    appStore.dispatch(
      CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_ACTIONS_GET_APP_CONFIG
    );

    const theme: string = (window as any).E4S_GLOBAL_THEME_IDENTIFIER as string;
    appStore.commit(
      CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
        "/" +
        CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_THEME,
      theme
    );

    return window.setTimeout(() => {
      console.log(".....beforeCreate.....");
      return;
    }, 3000);
  },
  mounted() {
    if (
      "-ms-scroll-limit" in document.documentElement.style &&
      "-ms-ime-align" in document.documentElement.style
    ) {
      // detect it's IE11
      window.addEventListener(
        "hashchange",
        (event) => {
          const currentPath = window.location.hash.slice(1);
          if (this.$route.path !== currentPath) {
            this.$router.push(currentPath);
          }
        },
        false
      );
    }
  },
});
