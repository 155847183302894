





















import {
  computed,
  defineComponent,
  PropType,
  reactive,
  SetupContext,
} from "@vue/composition-api";
import { CompetitionService } from "../../competition/competiton-service";
import { ICompetitionSummaryPublic } from "../../competition/competition-models";
import TermsConditionsV2 from "./terms-conditions/terms-conditions-v2.vue";
import PriorityV2 from "./priority/priority-v2.vue";

const competitionService = new CompetitionService();

export default defineComponent({
  name: "entry-conditions-v2",
  components: { PriorityV2, TermsConditionsV2 },
  props: {
    competitionSummaryPublic: {
      type: Object as PropType<ICompetitionSummaryPublic>,
      default: () => {
        return competitionService.factorySummaryPublic();
      },
    },
  },
  setup(
    props: { competitionSummaryPublic: ICompetitionSummaryPublic },
    context: SetupContext
  ) {
    const state = reactive({
      showTC: false,
      showPriority: false,
      priorityBeenConfirmed: false,
    });

    const hasValidPriority = computed(() => {
      return competitionService.isPriorityRequired(
        props.competitionSummaryPublic
      );
    });

    const hasTermsAndConditions = computed(() => {
      return props.competitionSummaryPublic.termsConditions.length > 0;
    });

    process();

    function processPriority() {
      state.priorityBeenConfirmed = true;
      if (!hasTermsAndConditions.value) {
        proceedToComp();
        return;
      }
      process();
    }

    function process() {
      if (!hasValidPriority.value && !hasTermsAndConditions.value) {
        //  Er...why have we been routed here!?!?
        proceedToComp();
      }

      if (hasValidPriority.value) {
        if (!state.priorityBeenConfirmed) {
          state.showPriority = true;
          state.showTC = false;
        } else {
          state.showPriority = false;
          state.showTC = true;
        }
      } else {
        state.showPriority = false;
        state.showTC = true;
      }
    }

    function proceedToComp() {
      context.emit("proceedToComp", props.competitionSummaryPublic);
    }

    function cancel() {
      context.emit("cancel", props.competitionSummaryPublic);
    }

    return {
      state,
      hasTermsAndConditions,
      hasValidPriority,
      cancel,
      process,
      processPriority,
      proceedToComp,
    };
  },
});
