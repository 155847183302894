



















































import Vue from "vue";
import Component from "vue-class-component";
import {FIELD_HELP_STORE_CONST, IFieldHelpStoreState} from "./field-help-store";
import {mapState} from "vuex";

const E4sModal = () => {
    // import E4sModal from "../../e4s-modal.vue";
    return import(/* webpackChunkName: "dyn-field-help-modal" */ "../../e4s-modal.vue");
};

@Component({
    name: "field-help-modal",
    computed: {
        ...mapState(FIELD_HELP_STORE_CONST.CONST_MODULE_NAME, {
            fieldHelpStoreState: (state: IFieldHelpStoreState) => state
        }),
    },
    components: {
        E4sModal
    }
})
export default class FieldHelpModal extends Vue {
    public readonly fieldHelpStoreState: IFieldHelpStoreState;

    public get getMessage() {
        return this.fieldHelpStoreState.helpData.data;
    }

    public hideHelp() {
        this.$store.commit(
            FIELD_HELP_STORE_CONST.CONST_MODULE_NAME + "/" +
            FIELD_HELP_STORE_CONST.MUTATIONS_SET_SHOW_HELP,
            false
        );
    }
}
