














































































import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  SetupContext,
} from "@vue/composition-api";
import { ICompetitionSummaryPublic } from "../competition-models";
import { useCompPermissions } from "../../config/useCompPermissions";
import { AskOrganiserData } from "./ask-organiser-data";
import { messageDispatchHelper } from "../../user-message/user-message-store";
import { USER_MESSAGE_LEVEL } from "../../user-message/user-message-models";
import CompContentSectionCellV2 from "../../public/entry-public/public-list/v2/comp-content-section-cell-v2.vue";
import FormGenericSectionTitleV2 from "../../common/ui/layoutV2/form/form-generic-section-title-v2.vue";
import InputRestrictLength from "../../common/ui/field/input-restrict-length/input-restrict-length.vue";
import { ValidationController } from "../../validation/validation-controller";
import { useAuthStore } from "../../auth/useAuthStore";
import ValidationFieldLable from "../../validation/validation-field-lable.vue";
import { useValidationControllerV2 } from "../../validation/useValidationControllerV2";
import FormGenericInputErrorMessageV2 from "../../common/ui/layoutV2/form/form-generic-input-error-message-v2.vue";
import FormGenericInputTextV2 from "../../common/ui/layoutV2/form/form-generic--input-text-v2.vue";
import CardGenericV2 from "../../common/ui/layoutV2/card-generic-v2.vue";
import FormGenericButtonBar from "../../common/ui/layoutV2/form/form-generic-button-bar.vue";
import { useConfigStore } from "../../config/useConfigStore";
import ButtonGenericV2 from "../../common/ui/layoutV2/buttons/button-generic-v2.vue";
import LoadingSpinnerV2 from "../../common/ui/loading-spinner-v2.vue";
import { getOrganiserDetailsToDisplay } from "./ask-organiser-service";
import FormGenericInputTemplateV2 from "../../common/ui/layoutV2/form/form-generic-input-template-v2.vue";

export default defineComponent({
  name: "ask-organiser-form-v2",
  components: {
    FormGenericInputTemplateV2,
    LoadingSpinnerV2,
    ButtonGenericV2,
    FormGenericButtonBar,
    CardGenericV2,
    FormGenericInputTextV2,
    FormGenericInputErrorMessageV2,
    ValidationFieldLable,
    InputRestrictLength,
    FormGenericSectionTitleV2,
    CompContentSectionCellV2,
  },
  props: {
    competitionSummaryPublic: {
      type: Object as PropType<ICompetitionSummaryPublic>,
      default: () => {
        return [];
      },
    },
  },
  setup(
    props: { competitionSummaryPublic: ICompetitionSummaryPublic },
    context: SetupContext
  ) {
    const compPermissions = useCompPermissions(props.competitionSummaryPublic);
    const authStore = useAuthStore();
    const configStore = useConfigStore();

    const selectedCategory = ref("");
    const email = ref(
      authStore.isLoggedIn ? configStore.configApp.userInfo.user.user_email : ""
    );
    const body = ref("");
    const isLoading = ref(false);
    const isMaxLength = ref(false);
    const maxBodyLength = 600;
    const currentCharLength = ref(600);

    const errorMessages = reactive({
      selectedCategory: "",
      body: "",
      email: "",
    });

    const validationController: ValidationController =
      new ValidationController();

    const validationControllerV2 =
      useValidationControllerV2(validationController);

    const categories: string[] = [
      "Competition query",
      "Technical or Payment query",
    ];

    const organiserDetailsToDisplay = computed(() => {
      return getOrganiserDetailsToDisplay(props.competitionSummaryPublic);
    });

    function close() {
      context.emit("cancel");
    }

    function validate() {
      validationController.reset();
      errorMessages.body = "";
      errorMessages.email = "";
      errorMessages.selectedCategory = "";
      if (selectedCategory.value.length === 0) {
        validationController.addError("selectedCategory", [
          "Please select a category.",
        ]);
        errorMessages.selectedCategory = "Please select a category.";
      }
      const bodyInt = body.value.replace(/\s/g, "");
      if (bodyInt.length === 0) {
        validationController.addError("body", ["Please enter a message."]);
        errorMessages.body = "Please enter a message.";
      }
      if (bodyInt.length > maxBodyLength) {
        validationController.addError("body", [
          "Please only enter " + maxBodyLength + " characters.",
        ]);
        errorMessages.body =
          "Please only enter " + maxBodyLength + " characters.";
      }
      if (!authStore.isLoggedIn && email.value.length === 0) {
        validationController.addError("email", [
          "Please enter a contact email address.",
        ]);
        errorMessages.email = "Please enter a contact email address.";
      }
    }

    function submit() {
      validate();
      if (!validationController.isValid) {
        return;
      }

      isLoading.value = true;
      new AskOrganiserData()
        .submitAskOrganiser(
          props.competitionSummaryPublic.compId,
          selectedCategory.value,
          body.value,
          email.value
        )
        .then((response) => {
          if (response.errNo > 0) {
            messageDispatchHelper(
              response.error,
              USER_MESSAGE_LEVEL.ERROR.toString()
            );
            return;
          }

          messageDispatchHelper(
            "Message sent.",
            USER_MESSAGE_LEVEL.INFO.toString()
          );
          close();
        })
        .catch((error) => {
          messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    return {
      maxBodyLength,
      organiserDetailsToDisplay,
      categories,
      selectedCategory,
      body,
      email,
      isLoading,
      isMaxLength,
      close,
      compPermissions,
      submit,
      validationController,
      validationControllerV2,
      errorMessages,
      authStore,
      currentCharLength,
    };
  },
});
