

















import Vue from "vue";
import Component from "vue-class-component";

@Component({
    name: "loading-spinner"
})
export default class LoadingSpinner extends Vue {
}
