import { ConfigService } from "./config-service";
import {
  ICompetitionInfo,
  ICompetitionSummaryPublic,
} from "../competition/competition-models";
import { computed, ref } from "@vue/composition-api";
import { CompetitionService } from "../competition/competiton-service";
import { useConfigStore } from "./useConfigStore";
import { simpleClone } from "../common/common-service-utils";

export function useCompPermissions(
  competitionSummaryPublic: ICompetitionSummaryPublic
) {
  const configStore = useConfigStore();
  const configService = new ConfigService();
  const competitionService = new CompetitionService();

  const competitionSummaryPublicInternal = ref(
    simpleClone(competitionSummaryPublic)
  );

  function init(comp: ICompetitionSummaryPublic) {
    competitionSummaryPublicInternal.value = simpleClone(comp);
  }

  function initWithCompetitionInfo(comp: ICompetitionInfo) {
    //  Too much to refactor right now...force values across.
    const compPublicTemp: ICompetitionSummaryPublic = simpleClone(
      comp
    ) as any as ICompetitionSummaryPublic;
    compPublicTemp.compId = comp.id;
    compPublicTemp.closedate = comp.entriesClose;

    competitionSummaryPublicInternal.value = simpleClone(compPublicTemp);
  }

  const hasBuilderPermissionForComp = computed(() => {
    return configService.hasBuilderPermissionForComp(
      configStore.configApp.userInfo,
      competitionSummaryPublicInternal.value.compOrgId,
      competitionSummaryPublicInternal.value.compId
    );
  });

  const hasTicketPermissionForComp = computed(() => {
    return configService.hasTicketPermissionForComp(
      configStore.configApp.userInfo,
      competitionSummaryPublicInternal.value.compOrgId,
      competitionSummaryPublicInternal.value.compId
    );
  });

  const hasScoreBoardPermissionForComp = computed(() => {
    return configService.hasTicketPermissionForComp(
      configStore.configApp.userInfo,
      competitionSummaryPublicInternal.value.compOrgId,
      competitionSummaryPublicInternal.value.compId
    );
  });

  const hasCheckinPermissionForComp = computed(() => {
    return configService.hasCheckinPermissionForComp(
      configStore.configApp.userInfo,
      competitionSummaryPublicInternal.value.compOrgId,
      competitionSummaryPublicInternal.value.compId
    );
  });

  const hasAutoEntryAccess = computed(() => {
    return (
      hasBuilderPermissionForComp.value &&
      competitionSummaryPublicInternal.value.options.autoEntries &&
      competitionSummaryPublicInternal.value.options.autoEntries
        .selectedTargetComp.id > 0
    );
  });

  const hasSomeBuilderTypePermission = computed(() => {
    return (
      hasBuilderPermissionForComp.value ||
      hasTicketPermissionForComp.value ||
      hasScoreBoardPermissionForComp.value ||
      hasCheckinPermissionForComp.value ||
      hasAutoEntryAccess.value
    );
  });

  const canUserEnter = computed(() => {
    return competitionService.canUserEnter(
      competitionSummaryPublicInternal.value
    );
  });

  return {
    init,
    initWithCompetitionInfo,
    competitionSummaryPublicInternal,
    hasBuilderPermissionForComp,
    hasTicketPermissionForComp,
    hasScoreBoardPermissionForComp,
    hasCheckinPermissionForComp,
    hasAutoEntryAccess,
    hasSomeBuilderTypePermission,
    canUserEnter,
  };
}
