







import Vue from "vue";
import Component from "vue-class-component";
import { mapState } from "vuex";
import {
  CONFIG_STORE_CONST,
  IConfigStoreState,
} from "../../../config/config-store";
import { IConfigApp } from "../../../config/config-app-models";
import Toaster from "../../../user-message/toaster.vue";

@Component({
  name: "r4s-section",
  components: {
    Toaster,
  },
  computed: {
    ...mapState(CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME, {
      configApp: (state: IConfigStoreState) => state.configApp,
    }),
  },
})
export default class R4sSection extends Vue {
  public readonly configApp: IConfigApp;

  public overFlow = "";

  public mounted() {
    //  WP is setting page heights and stuff
    this.overFlow = document.body.style.overflow;
    console.warn("r4s-section mounted() overflow set to hidden!");
    document.body.style.overflow = "hidden";
  }

  public beforeDestroy() {
    console.warn("r4s-section mounted() overflow reset to: " + this.overFlow);
    document.body.style.overflow = this.overFlow;
  }
}
