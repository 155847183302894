import * as R from "ramda";
import { ActionContext, Module } from "vuex";
import { IRootState } from "../app.store";
import { IObjectKeyType, IServerResponse } from "../common/common-models";
import { CommonService } from "../common/common-service";
import {
  E4sTheme,
  IConfigApp,
  IEntity,
  UiVersion,
  USER_ROLES,
} from "./config-app-models";
import { ConfigData, IConfigMeta } from "./config-data";
import { loadLanguageAsync } from "./il8n";
import {
  AO_CODE,
  IAthleticsOrganisation,
} from "../common/ui/athletic-org/athletic-org-models";
import { UserProfileData } from "../admin/user/user-data";
import { IUserProfile } from "../admin/user/user-models";
import { USER_MESSAGE_LEVEL } from "../user-message/user-message-models";
import { messageDispatchHelper } from "../user-message/user-message-store";
import { ConfigService } from "./config-service";
import { ISelfService } from "../builder/builder-models";
import { AUTH_STORE_CONST } from "../auth/auth-store";
import {
  IE4sEmailMessageApp,
  IE4sEmailMessageComp,
} from "../email-message/email-message-models";
import * as CommonServiceUtils from "../common/common-service-utils";
import { ATH_COMP_SCHED_STORE_CONST } from "../athleteCompSched/store/athleteCompSched-store";

const configService = new ConfigService();
const commonService = new CommonService();
const configData = new ConfigData();

export const DEFAULT_PAGE_SIZE = 25; //  tets

export enum CONFIG_STORE_CONST {
  CONFIG_CONST_MODULE_NAME = "CONFIG_CONST_MODULE_NAME",

  // <MUTATIONS>
  CONFIG_MUTATIONS_SET_APP_CONFIG = "CONFIG_MUTATIONS_SET_APP_CONFIG",
  CONFIG_MUTATIONS_SET_APP_CONFIG_META = "CONFIG_MUTATIONS_SET_APP_CONFIG_META",
  CONFIG_MUTATIONS_SET_IL8N_LANGUAGE = "CONFIG_MUTATIONS_SET_IL8N_LANGUAGE",
  CONFIG_MUTATIONS_SET_THEME = "CONFIG_MUTATIONS_SET_THEME",
  CONFIG_MUTATIONS_SET_ROLE = "CONFIG_MUTATIONS_SET_ROLE",
  CONFIG_MUTATIONS_SET_USER_ENTITY = "CONFIG_MUTATIONS_SET_USER_ENTITY",
  CONFIG_MUTATIONS_SET_USER_MESSAGES = "CONFIG_MUTATIONS_SET_USER_MESSAGES",
  CONFIG_MUTATIONS_SET_UI_VERSION = "CONFIG_MUTATIONS_SET_UI_VERSION",
  // </MUTATIONS>

  //  <GETTERS>
  CONFIG_GETTERS_IS_ADMIN = "CONFIG_GETTERS_IS_ADMIN",
  CONFIG_GETTERS_APP_ADMIN = "CONFIG_GETTERS_APP_ADMIN",
  CONFIG_GETTERS_BUILDER_PERMS = "CONFIG_GETTERS_BUILDER_PERMS",
  CONFIG_GETTERS_USER_ENTITIES = "CONFIG_GETTERS_USER_ENTITIES",
  CONFIG_GETTERS_HAS_ANY_ORG_BUILDER_PERM = "CONFIG_GETTERS_HAS_ANY_ORG_BUILDER_PERM",
  CONFIG_GETTERS_HAS_CHEQUES_ACCESS = "CONFIG_GETTERS_HAS_CHEQUES_ACCESS",
  CONFIG_GETTERS_HAS_FINANCE = "CONFIG_GETTERS_HAS_FINANCE",
  CONFIG_GETTERS_HAS_AAI_FINANCE = "CONFIG_GETTERS_HAS_AAI_FINANCE",
  CONFIG_GETTERS_HAS_CREDIT = "CONFIG_GETTERS_HAS_CREDIT",
  CONFIG_GETTERS_REGISTRATION_SYSTEM_AO_CODE = "CONFIG_GETTERS_REGISTRATION_SYSTEM_AO_CODE",
  //  </GETTERS>

  // <ACTIONS>
  CONFIG_ACTIONS_GET_APP_CONFIG = "CONFIG_ACTIONS_GET_APP_CONFIG",
  CONFIG_ACTIONS_IMPERSONATE = "CONFIG_ACTIONS_IMPERSONATE",
  CONFIG_ACTIONS_SWITCH_IL8N_LANGUAGE = "CONFIG_ACTIONS_SWITCH_IL8N_LANGUAGE",
  CONFIG_ACTIONS_SELF_SERVICE = "CONFIG_ACTIONS_SELF_SERVICE",
  // </ACTIONS>
}

export interface IConfigStoreState {
  configApp: IConfigApp;
  configMeta: IConfigMeta;
  il8nLanguage: string;
  athleticOrgObj: IObjectKeyType<IAthleticsOrganisation>;
  userEntity: IEntity;
  ui: {
    version: UiVersion;
  };
}

const mutations = {
  [CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_APP_CONFIG](
    state: IConfigStoreState,
    configApp: IConfigApp
  ) {
    configApp.theme = configApp.theme.toLowerCase() as E4sTheme;

    if (!configApp.options.cartTimeLimit) {
      configApp.options.cartTimeLimit = 30;
    }

    state.configApp = R.clone(configApp);
    if (configApp.aos && configApp.aos.length > 0) {
      state.athleticOrgObj = commonService.convertArrayToObject(
        "code",
        configApp.aos
      );
    }
    const userEntity = configService.getEntityLevelFromUserInfo(configApp);
    // console.log("CONFIG_MUTATIONS_SET_APP_CONFIG CONFIG_MUTATIONS_SET_APP_CONFIG CONFIG_MUTATIONS_SET_APP_CONFIG ", userEntity);
    if (userEntity) {
      state.userEntity = userEntity;
    }

    // let location: RawLocation;
    // location = {
    //   path: "/v2",
    // };
    // router.push(location);
  },

  [CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_APP_CONFIG_META](
    state: IConfigStoreState,
    meta: IConfigMeta
  ) {
    const metaInternal: IConfigMeta = CommonServiceUtils.simpleClone(meta);

    if (!Array.isArray(meta?.locations)) {
      metaInternal!.locations = Object.values(meta!.locations);
    }

    metaInternal.locations = CommonServiceUtils.sortArray(
      "name",
      metaInternal.locations
    );
    metaInternal.organisers = CommonServiceUtils.sortArray(
      "name",
      metaInternal.organisers
    );

    state.configMeta = metaInternal;
  },

  [CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_IL8N_LANGUAGE](
    state: IConfigStoreState,
    language: string
  ) {
    state.il8nLanguage = language;
  },

  [CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_THEME](
    state: IConfigStoreState,
    theme: string
  ) {
    if (!(R.isNil(theme) || R.isEmpty(theme))) {
      const configApp: IConfigApp = R.clone(state.configApp);
      configApp.theme = theme.toLowerCase() as E4sTheme;
      state.configApp = configApp;
    }
  },

  [CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_USER_ENTITY](
    state: IConfigStoreState,
    userEntity: IEntity
  ) {
    state.userEntity = R.clone(userEntity);
  },

  [CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_USER_MESSAGES](
    state: IConfigStoreState,
    messages: (IE4sEmailMessageApp | IE4sEmailMessageComp)[]
  ) {
    state.configApp.messages = R.clone(messages);
  },

  [CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_UI_VERSION](
    state: IConfigStoreState,
    uiVersion: UiVersion
  ) {
    state.ui.version = uiVersion;
  },

  [CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_ROLE](
    state: IConfigStoreState,
    userRole: USER_ROLES
  ) {
    state.configApp.role = userRole;
  },
};

const getters = {
  [CONFIG_STORE_CONST.CONFIG_GETTERS_IS_ADMIN](state: IConfigStoreState) {
    return state.configApp.role === USER_ROLES.E4SUSER;
  },
  [CONFIG_STORE_CONST.CONFIG_GETTERS_APP_ADMIN](state: IConfigStoreState) {
    return state.configApp.role === USER_ROLES.APPADMIN;
  },
  [CONFIG_STORE_CONST.CONFIG_GETTERS_BUILDER_PERMS](state: IConfigStoreState) {
    return R.clone(
      commonService.convertArrayToObject(
        "level",
        state.configApp.userInfo.security.permLevels.builder
      )
    );
  },
  [CONFIG_STORE_CONST.CONFIG_GETTERS_USER_ENTITIES](
    state: IConfigStoreState
  ): IEntity[] {
    return configService.getEntitiesFromUserInfo(state.configApp.userInfo);
  },
  [CONFIG_STORE_CONST.CONFIG_GETTERS_HAS_ANY_ORG_BUILDER_PERM](
    state: IConfigStoreState
  ): boolean {
    return configService.hasBuilderPermissionForAnyOrg(
      state.configApp.userInfo
    );
  },
  [CONFIG_STORE_CONST.CONFIG_GETTERS_HAS_CHEQUES_ACCESS](
    state: IConfigStoreState
  ): boolean {
    return configService.hasChequesPermissionForOrg(state.configApp.userInfo);
  },
  [CONFIG_STORE_CONST.CONFIG_GETTERS_HAS_FINANCE](
    state: IConfigStoreState
  ): boolean {
    return configService.hasFinancePermissionForAnyOrg(
      state.configApp.userInfo
    );
  },
  [CONFIG_STORE_CONST.CONFIG_GETTERS_HAS_AAI_FINANCE](
    state: IConfigStoreState
  ): boolean {
    return configService.hasAAIFinancePermissionForAnyOrg(
      state.configApp.userInfo
    );
  },
  [CONFIG_STORE_CONST.CONFIG_GETTERS_HAS_CREDIT](
    state: IConfigStoreState
  ): boolean {
    const credit =
      state.configApp.userInfo && state.configApp.userInfo.e4s_credit;
    if (state.configApp.userInfo && state.configApp.userInfo.e4s_credit) {
      return Math.trunc(credit) !== 0;
    }
    return false;
  },
  [CONFIG_STORE_CONST.CONFIG_GETTERS_REGISTRATION_SYSTEM_AO_CODE](
    state: IConfigStoreState
  ): AO_CODE {
    return configService.getRegistrationSystemAoCode(state.configApp);
  },
};

const actions = {
  [CONFIG_STORE_CONST.CONFIG_ACTIONS_GET_APP_CONFIG](
    context: ActionContext<IConfigStoreState, IRootState>
  ) {
    return configData
      .getAppConfig()
      .then((serverGenericResponse) => {
        context.commit(
          CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_APP_CONFIG,
          serverGenericResponse.data
        );

        context.commit(
          CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_APP_CONFIG_META,
          serverGenericResponse.meta
        );

        const userHasId = serverGenericResponse.data.userInfo.user.id > 0;
        context.commit(
          AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME +
            "/" +
            AUTH_STORE_CONST.MUTATIONS_LOGGED_IN,
          userHasId,
          {
            root: true,
          }
        );

        if (userHasId) {
          context.dispatch(
            ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME +
              "/" +
              ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_GET_CART,
            {},
            {
              root: true,
            }
          );
        }
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        return {};
      });
  },

  [CONFIG_STORE_CONST.CONFIG_ACTIONS_IMPERSONATE](
    context: ActionContext<IConfigStoreState, IRootState>,
    userProfile: IUserProfile
  ) {
    const userProfileData: UserProfileData = new UserProfileData();
    const id = userProfile.id === 0 ? 0 : userProfile.user.id;
    return userProfileData
      .impersonate(id)
      .then((response: IServerResponse<IUserProfile>) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        messageDispatchHelper(
          id === 0
            ? "Impersonating stopped."
            : "Now impersonating: " + userProfile.user.displayName,
          USER_MESSAGE_LEVEL.INFO.toString()
        );

        return context.dispatch(
          CONFIG_STORE_CONST.CONFIG_ACTIONS_GET_APP_CONFIG
        );
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        return {};
      });
  },

  [CONFIG_STORE_CONST.CONFIG_ACTIONS_SELF_SERVICE](
    context: ActionContext<IConfigStoreState, IRootState>,
    payload: { entity: IEntity; selfService: ISelfService }
  ) {
    return configData
      .selfService(payload.selfService.id, payload.entity.id)
      .then((response: IServerResponse<any>) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }

        context.commit(
          CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_USER_ENTITY,
          payload.entity
        );

        messageDispatchHelper(
          response.message ? response.message : "Profile successfully updated.",
          USER_MESSAGE_LEVEL.ERROR.toString()
        );

        return context.dispatch(
          CONFIG_STORE_CONST.CONFIG_ACTIONS_GET_APP_CONFIG
        );
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        return {};
      });
  },

  [CONFIG_STORE_CONST.CONFIG_ACTIONS_SWITCH_IL8N_LANGUAGE](
    context: ActionContext<IConfigStoreState, IRootState>,
    language: any
  ) {
    // i18n.locale = language.id;
    loadLanguageAsync(language.id);
  },
};

export const configStore: Module<IConfigStoreState, IRootState> = {
  namespaced: true,
  state: {
    configApp: configService.factoryConfigApp(),
    configMeta: {
      events: [],
      locations: [],
      organisers: [],
    },
    il8nLanguage: "en",
    athleticOrgObj: {},
    userEntity: configService.factoryEntity(),
    ui: {
      version: "v2",
    },
  },
  mutations,
  getters,
  actions,
};

// export function useConfigStore(): IConfigStoreState {
//   const store = useStore();
//   return store.state[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME];
// }
