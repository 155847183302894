

























































import {
  defineComponent,
  PropType,
  ref,
  SetupContext,
  watch,
} from "@vue/composition-api";
import FieldTextV2 from "../fields/field-text-v2.vue";

/**
 * Use this as follows, obv' pass whatever native events needed:
 *       <FormGenericInputTextV2
 *        form-label="Priority Code"
 *        v-bind:value="priorityCode"
 *        place-holder="Case in-sensitive.  E.g. if code ABCDE, can enter abcde"
 *        :error-message="message"
 *        @change="priorityCode = $event.target.value"
 *        @keyup.enter="priorityCode = $event.target.value"
 *        />
 */
export default defineComponent({
  name: "form-generic-input-text-v2",
  components: { FieldTextV2 },
  inheritAttrs: false,
  props: {
    formLabel: {
      type: String,
      default: () => {
        return "";
      },
    },
    formLabelClass: {
      type: String,
      default: () => {
        return "";
      },
    },
    placeHolder: {
      type: String,
      default: () => {
        return "";
      },
    },
    value: {
      type: String,
      default: () => {
        return "";
      },
    },
    fieldType: {
      type: String as PropType<"text" | "password" | "email">,
      default: () => {
        return "text";
      },
    },
    errorMessage: {
      type: String,
      default: () => {
        return "";
      },
    },
    showHelp: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    showLabel: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    isDisabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  setup(
    props: {
      value: string;
      formLabel: string;
      placeHolder: string;
      errorMessage: string;
      showHelp: boolean;
      showLabel: boolean;
      isDisabled: boolean;
    },
    context: SetupContext
  ) {
    const initialValue = props.value;
    const valueInternal = ref(props.value);

    // const attrs = useAttrs();

    watch(
      () => props.value,
      (newValue: string) => {
        if (newValue !== valueInternal.value) {
          valueInternal.value = newValue;
        }
      },
      {
        immediate: true,
      }
    );

    function onChange(newValue: string) {
      valueInternal.value = newValue;
      submit();
    }

    function onKeyUp(newValue: string) {
      valueInternal.value = newValue;
      console.log(
        "form-generic-input-text-v2.onKeyUp() value: " + valueInternal.value
      );
      context.emit("onKeyUp", valueInternal.value);
    }

    function onKeyUpEnter(newValue: string) {
      valueInternal.value = newValue;
      console.log(
        "form-generic-input-text-v2.onKeyUpEnter() value: " +
          valueInternal.value
      );
      context.emit("keyUpEnter", valueInternal.value);
    }

    function submit() {
      console.log(
        "form-generic-input-text-v2.submit() value: " + valueInternal.value
      );
      context.emit("input", valueInternal.value);
      context.emit("change", valueInternal.value);
      context.emit("onIsDirty", valueInternal.value !== initialValue);
      // console.log(">>>>>>>>>>", attrs.value);
    }
    // function keypressEnter() {
    //   console.log(
    //     "form-generic-input-text-v2.keypressEnter() value: " +
    //       valueInternal.value
    //   );
    //   context.emit("keypressEnter", valueInternal.value);
    // }
    //
    // function everything(x: unknown) {
    //   console.log("everything");
    // }

    return { valueInternal, submit, onChange, onKeyUpEnter, onKeyUp };
  },
});
