import {IOrg, IOrgOptions} from "./org-models"

export class OrgService {
    public factory(): IOrg {
        return {
            id: 0,
            name: "",
            logo: "",
            stripeUser: {
                id: 0,
                name: ""
            },
            options: this.factoryOrgOptions()
        };
    }

    public factoryOrgOptions():IOrgOptions {
        return {
            tAndCUsers: []
        }
    }
}
