import { factoryClubCompInfo } from "../../../competition/v2/competiton-service-v2";
import {
  IClubCompInfo,
  IClubCompInfoEntryBase,
  IClubCompInfoEntryIndiv,
  IClubCompInfoEntryIndivAthlete,
  IClubCompInfoEntryTeam,
} from "./clubCompInfo-models";
import * as CommonService from "../../../common/common-service-utils";
import {
  IR4sCompSchedule,
  IR4sScheduledEvent,
  IScheduleTableRow,
} from "../../../competition/scoreboard/rs4/rs4-scoreboard-models";
import {
  EventGroupIdString,
  ISimpleDateModel,
  IsoDateTime,
  IsoTime,
} from "../../../common/common-models";
import { format, parse } from "date-fns";

export interface IClubCompInfoState {
  clubCompInfo: IClubCompInfo;
  userMessage: string;
  teamMessage: string;
}

// TODO  is dup of isClubCompInfoCompetition
export function hasClubCompInfoCompetition(
  clubCompInfo: IClubCompInfo
): boolean {
  return (
    clubCompInfo &&
    Object.keys(clubCompInfo).length > 0 &&
    clubCompInfo.configData.clubId > 0
  );
}

export function isClubCompInfoCompetition(
  clubCompInfo: IClubCompInfo
): boolean {
  if (hasClubCompInfoCompetition(clubCompInfo)) {
    return clubCompInfo.configData.clubId > 0;
  }
  return false;
}

export function hasClubCompInfoEntries(clubCompInfo: IClubCompInfo): boolean {
  return !!(clubCompInfo.entryData && clubCompInfo.entryData.entries);
}

export function userMessageEntriesHtml(clubCompInfo: IClubCompInfo): string {
  return "<b>" + userMessageEntries(clubCompInfo) + "</b>";
}

export function userMessageEntries(clubCompInfo: IClubCompInfo): string {
  return (
    clubCompInfo.configData.clubName + ": " + getIndivMessage(clubCompInfo)
  );
}

export function getIndivMessage(clubCompInfo: IClubCompInfo): string {
  return (
    "Entered " +
    clubCompInfo.entryData.entryCnt +
    " of max " +
    clubCompInfo.configData.maxEntries +
    " individuals." +
    " " +
    clubCompInfo.configData.maxPerEvent +
    " max per event."
  );
}

export function userScheduleEventMessage(
  clubCompInfo: IClubCompInfo,
  maxGroup: number
): string {
  if (hasClubCompInfoEntries(clubCompInfo)) {
    const entry = clubCompInfo.entryData.entries[maxGroup.toString()];
    if (entry) {
      return entry.egCount + " entries.";
    }
  }

  return "";
}

export function userMessageEntrantList(
  clubCompInfo: IClubCompInfo,
  maxGroup: number
): string {
  if (hasClubCompInfoEntries(clubCompInfo)) {
    const entry = clubCompInfo.entryData.entries[maxGroup.toString()];
    if (entry) {
      const athletes = getEntriesDescriptionIndiv(entry.athletes);
      if (athletes.length > 0) {
        return "Athletes: " + athletes.join(", ");
      }
      return "";
    }
  }
  return "";
}

export function userMessageTeams(clubCompInfo: IClubCompInfo): string {
  return (
    "Entered " +
    clubCompInfo.entryData.teamCnt +
    " of max " +
    clubCompInfo.configData.maxRelays +
    " teams."
  );
}

export function userMessageTeamsHtml(clubCompInfo: IClubCompInfo): string {
  return (
    "<b>" +
    clubCompInfo.configData.clubName +
    ": " +
    userMessageTeams(clubCompInfo) +
    "</b>"
  );
}

export function hasTeamEvents(clubCompInfo: IClubCompInfo): boolean {
  return (
    clubCompInfo.entryData &&
    clubCompInfo.entryData.teams &&
    Object.keys(clubCompInfo.entryData.teams).length > 0
  );
}

export function getEntryMatchByEventGroupId(
  eventGroupId: EventGroupIdString,
  clubCompInfo: IClubCompInfo
): IClubCompInfoEntryIndiv | IClubCompInfoEntryTeam | null {
  //  Try team stuff first...
  if (hasTeamEvents(clubCompInfo)) {
    const team = clubCompInfo.entryData.teams[eventGroupId];
    if (team) {
      return team;
    }
  }

  //  ...then indiv stuff.
  const entry = clubCompInfo.entryData.entries[eventGroupId];
  if (entry) {
    return entry;
  }
  return null;
}

export function getEntryMatch(
  scheduleTableRow: IScheduleTableRow,
  clubCompInfo: IClubCompInfo
): IClubCompInfoEntryIndiv | IClubCompInfoEntryTeam | null {
  if (!isClubCompInfoCompetition(clubCompInfo)) {
    return null;
  }
  const key = scheduleTableRow.eventGroupId.toString();

  return getEntryMatchByEventGroupId(key, clubCompInfo);
}

export function getEntries(
  scheduleTableRow: IScheduleTableRow,
  clubCompInfoEntryBase: IClubCompInfoEntryBase
): (string | IClubCompInfoEntryIndivAthlete)[] {
  if ((scheduleTableRow.scheduledEvent as IR4sScheduledEvent).isTeamEvent) {
    return (clubCompInfoEntryBase as IClubCompInfoEntryTeam).teams;
  } else {
    return (clubCompInfoEntryBase as IClubCompInfoEntryIndiv).athletes;
  }
}

export function getEntriesDescriptionIndiv(
  clubCompInfoEntryIndivAthlete: IClubCompInfoEntryIndivAthlete[]
): string[] {
  return clubCompInfoEntryIndivAthlete.map((athlete) => {
    return "(" + athlete.teamBibNo + ") " + athlete.entryName;
  });
}

export function getCountDescription(
  scheduleTableRow: IScheduleTableRow,
  clubCompInfo: IClubCompInfo,
  clubCompInfoEntryBase: IClubCompInfoEntryBase
): {
  count: number;
  description: string;
} {
  let matches;
  if ((scheduleTableRow.scheduledEvent as IR4sScheduledEvent).isTeamEvent) {
    matches = (clubCompInfoEntryBase as IClubCompInfoEntryTeam).teams;

    return {
      count: matches.length,
      description:
        (clubCompInfoEntryBase as IClubCompInfoEntryTeam).teams.length +
        " of " +
        clubCompInfo.configData.maxRelays,
    };
  } else {
    matches = (clubCompInfoEntryBase as IClubCompInfoEntryIndiv).athletes;

    return {
      count: matches.length,
      description:
        (clubCompInfoEntryBase as IClubCompInfoEntryIndiv).athletes.length +
        " of " +
        clubCompInfo.configData.maxPerEvent,
    };
  }
}

export function getMaxAllowed(
  scheduleTableRow: IScheduleTableRow,
  clubCompInfo: IClubCompInfo
): number {
  if ((scheduleTableRow.scheduledEvent as IR4sScheduledEvent).isTeamEvent) {
    return clubCompInfo.configData.maxRelays;
  } else {
    return clubCompInfo.configData.maxPerEvent;
  }
}

export interface IScheduleTableRowState {
  eventTime: IsoDateTime | IsoTime | "TBC";
  eventDescription: string;
  eventGroupId: number;
  entryMatch: IClubCompInfoEntryIndiv | IClubCompInfoEntryTeam | null;
  entries: (string | IClubCompInfoEntryIndivAthlete)[];
  entriesDescription: string;
  countDescription: string;
  maxAllowed: number;
  isEventFull: boolean;
  cssClass:
    | "entity-entries--has-entries"
    | "entity-entries--has-entries-full"
    | "";
  isTeamEvent: boolean;
  scheduleTableRow: IScheduleTableRow;
}
export function getScheduleTableRowState(
  scheduleTableRow: IScheduleTableRow,
  clubCompInfo: IClubCompInfo
): IScheduleTableRowState {
  let eventTime = format(parse(scheduleTableRow.startTime), "HH:mm");
  eventTime = eventTime === "00:00" ? "TBC" : eventTime;
  const r4sScheduledEvent =
    scheduleTableRow.scheduledEvent as IR4sScheduledEvent;
  const eventDescription =
    eventTime === "TBC"
      ? scheduleTableRow.eventName.split(": ")[1]
      : scheduleTableRow.eventName;

  const entryMatch = getEntryMatch(scheduleTableRow, clubCompInfo);
  let entries: (string | IClubCompInfoEntryIndivAthlete)[] = [];
  let entriesDescription = "";
  let countDescription = "";
  let maxAllowed = 0;
  let isEventFull = false;
  let cssClass:
    | "entity-entries--has-entries"
    | "entity-entries--has-entries-full"
    | "" = "";
  if (entryMatch) {
    entries = getEntries(scheduleTableRow, entryMatch);
    entriesDescription =
      entryMatch.eventAgeGroup +
      ": " +
      (r4sScheduledEvent.isTeamEvent
        ? entries
        : getEntriesDescriptionIndiv(
            entries as IClubCompInfoEntryIndivAthlete[]
          )
      ).join(", ");
    countDescription = getCountDescription(
      scheduleTableRow,
      clubCompInfo,
      entryMatch
    ).description;
    maxAllowed = getMaxAllowed(scheduleTableRow, clubCompInfo);
    isEventFull = entries.length === maxAllowed;
    if (isEventFull) {
      cssClass = "entity-entries--has-entries-full";
    } else {
      cssClass = entries.length > 0 ? "entity-entries--has-entries" : "";
    }
  }

  return {
    eventTime,
    eventDescription,
    eventGroupId: scheduleTableRow.eventGroupId,
    entryMatch,
    entries,
    entriesDescription,
    countDescription,
    maxAllowed,
    isEventFull,
    cssClass,
    isTeamEvent: r4sScheduledEvent.isTeamEvent,
    scheduleTableRow: CommonService.simpleClone(scheduleTableRow),
  };
}

export function getEditBibsValidationMessage(
  athletesEntryIdMap: Record<string, IClubCompInfoEntryIndivAthlete>
): string {
  const entryIds = Object.keys(athletesEntryIdMap);

  const bibCountMap = entryIds.reduce<Record<string, number>>(
    (accum, entryId) => {
      const athlete = athletesEntryIdMap[entryId.toString()];
      const key = athlete.teamBibNo.toString();
      if (!accum[key]) {
        accum[key] = 0;
      }
      accum[key]++;
      return accum;
    },
    {}
  );

  const bibIds = Object.keys(bibCountMap);

  return entryIds.length === bibIds.length
    ? ""
    : "Please make sure all entries have unique bib numbers.";
}

export function getCompResultDates(
  r4sCompSchedule: IR4sCompSchedule
): ISimpleDateModel[] {
  return CommonService.sortArray(
    "iso",
    CommonService.uniqueArrayById(
      r4sCompSchedule.schedule.map((schedule) => {
        return {
          iso: schedule.startDate.split("T")[0],
          display: format(parse(schedule.startDate), "Do MMM"),
        };
      }),
      "iso"
    )
  );
}

export function factoryClubCompInfoState(): IClubCompInfoState {
  return {
    clubCompInfo: factoryClubCompInfo(),
    get userMessage(): string {
      return userMessageEntries(this.clubCompInfo);
    },
    get teamMessage(): string {
      return userMessageTeams(this.clubCompInfo);
    },
  };
}

/**
 * Global state
 */
const state = factoryClubCompInfoState();

export function factoryClubCompInfoServiceController() {
  function init(clubCompInfo: IClubCompInfo) {
    state.clubCompInfo = CommonService.simpleClone(clubCompInfo);
  }

  return {
    state,
    init,
  };
}
