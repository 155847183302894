







































































import Vue from "vue";
import Component from "vue-class-component";
import { LoginData } from "../login-data";
import { messageDispatchHelper } from "../../user-message/user-message-store";
import { USER_MESSAGE_LEVEL } from "../../user-message/user-message-models";
import { AUTH_STORE_CONST } from "../auth-store";
import { Prop } from "vue-property-decorator";
import { ValidationService } from "../../validation/validation-service";
import { ValidationController } from "../../validation/validation-controller";
import { IObjectKeyType } from "../../common/common-models";
import { IValidationProp } from "../../validation/validation-models";
import FieldValidationLabel from "../../validation/validation-field-lable.vue";
import {
  CONFIG_STORE_CONST,
  IConfigStoreState,
} from "../../config/config-store";
import FormGenericInputTextV2 from "../../common/ui/layoutV2/form/form-generic--input-text-v2.vue";
import {
  LAUNCH_ROUTES_PATHS_V2,
  LAUNCH_ROUTES_PATHS_V2_BASE,
} from "../../launch/v2/launch-routes-v2";
import { CONFIG } from "../../common/config";
import FormGenericInputTemplateV2 from "../../common/ui/layoutV2/form/form-generic-input-template-v2.vue";
import InputWithButton from "../../common/ui/layoutV2/fields/InputWithButton.vue";
import FieldTextV2, {
  FieldTextType,
} from "../../common/ui/layoutV2/fields/field-text-v2.vue";
import ButtonGenericV2 from "../../common/ui/layoutV2/buttons/button-generic-v2.vue";
import HideMinor from "../../common/ui/svg/HideMinor.vue";
import { ref } from "@vue/composition-api";
import { mapState } from "vuex";
import { UiVersion } from "../../config/config-app-models";
import { LAUNCH_ROUTES_PATHS } from "../../launch/launch-routes";
import { RawLocation } from "vue-router";

const validationService: ValidationService = new ValidationService();

@Component({
  name: "login-form-v2",
  computed: {
    ...mapState(CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME, {
      uiVersion: (state: IConfigStoreState) => state.ui.version,
    }),
  },
  components: {
    HideMinor,
    ButtonGenericV2,
    FieldTextV2,
    InputWithButton,
    FormGenericInputTemplateV2,
    FormGenericInputTextV2,
    FieldValidationLabel,
  },
})
export default class LoginFormV2 extends Vue {
  public readonly uiVersion!: UiVersion;

  @Prop({ default: "" }) public readonly redirectedFrom: string;

  public userName: string = "";
  public password: string = "";
  public message: string = "";
  public isLoading: boolean = false;

  public CONFIG = CONFIG;

  public validationController: ValidationController =
    new ValidationController();

  public created() {
    // @ts-ignore
    // this.redirectedFrom = this.$route.query && this.$route.query.redirectFrom ? this.$route.query.redirectFrom  : "";
  }

  public doLogin() {
    this.validate();
    if (!this.validationController.isValid) {
      return;
    }

    const loginData: LoginData = new LoginData();
    this.message = "";
    this.isLoading = true;
    loginData
      .login(this.userName, this.password)
      .then((response: string) => {
        const isSuccess =
          response.indexOf("You have logged in successfully") > -1;

        this.$store.commit(
          AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME +
            "/" +
            AUTH_STORE_CONST.MUTATIONS_LOGGED_IN,
          isSuccess
        );

        if (isSuccess) {
          this.$store
            .dispatch(
              CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
                "/" +
                CONFIG_STORE_CONST.CONFIG_ACTIONS_GET_APP_CONFIG
            )
            .then(() => {
              // this.$router.push({ path: this.redirectedFrom });
              const redirect = window.location.href.split("#")[0];
              if (this.redirectedFrom.length > 0) {
                window.location.href = redirect + "#" + this.redirectedFrom;
              } else {
                // window.location.href =
                //   redirect + "#" + (this.uiVersion === "v2" ? "/v2" : "");

                const homeHref =
                  "/" +
                  (this.uiVersion === "v2"
                    ? LAUNCH_ROUTES_PATHS_V2_BASE +
                      LAUNCH_ROUTES_PATHS_V2.HOME_V2
                    : LAUNCH_ROUTES_PATHS.SHOW_ENTRIES);

                let location: RawLocation = {
                  path: homeHref,
                };
                this.$router.push(location);
              }
            });
        } else {
          this.message =
            "Username or password was not recognised.  Please try again or reset password.";
        }
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        this.$store.commit(
          AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME +
            "/" +
            AUTH_STORE_CONST.MUTATIONS_LOGGED_IN,
          false
        );
        this.message =
          "Error: Username or password was not recognised.  Please try again or reset password.";
        return;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public doCancel() {
    // this.$store.commit(AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME + "/" + AUTH_STORE_CONST.MUTATIONS_SHOW_LOGIN, false);
    this.$emit("onCancel");
  }

  public validate() {
    let errors: IObjectKeyType<IValidationProp> = {};
    if (this.userName.length === 0) {
      errors = validationService.addMessage("userName", "Required", errors);
    }

    if (this.password.length === 0) {
      errors = validationService.addMessage("password", "Required", errors);
    }

    this.validationController.setErrors(errors);
  }

  public get getRegisterHref() {
    return this.uiVersion === "v2"
      ? LAUNCH_ROUTES_PATHS_V2_BASE + "/" + LAUNCH_ROUTES_PATHS_V2.REGISTER_V2
      : "#/" + LAUNCH_ROUTES_PATHS.REGISTER_V1;
  }

  public get getForgotHref() {
    return this.uiVersion === "v2"
      ? LAUNCH_ROUTES_PATHS_V2_BASE + "/" + LAUNCH_ROUTES_PATHS_V2.FORGOT_V2
      : "#/" + LAUNCH_ROUTES_PATHS.FORGOT_V1;
  }

  public passwordFieldType = ref<FieldTextType>("password");

  public togglePasswordType() {
    this.passwordFieldType.value =
      this.passwordFieldType.value === "password" ? "text" : "password";
  }
}
