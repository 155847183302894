import Vue from "vue";
import Vuex, { Store, StoreOptions } from "vuex";

import {
  COMP_EVENT_TEAMS_STORE_CONST,
  compEventTeamStore,
  ICompEventTeamsStoreState,
} from "./athletecompsched/comp-event-teams/comp-event-store";
import {
  ATH_COMP_SCHED_STORE_CONST,
  athCompSchedStore,
  IAthCompSchedStoreState,
} from "./athletecompsched/store/athletecompsched-store";
import {
  AUTH_STORE_CONST,
  authStore,
  IAuthStoreState,
} from "./auth/auth-store";
import { builderStore, IBuilderStoreState } from "./builder/builder-store";
import { BUILDER_STORE_CONST } from "./builder/builder-store-constants";
import {
  CART_STORE_CONST,
  cartStore,
  ICartStoreState,
} from "./cart/cart-store";
import {
  CLUB_STORE_CONST,
  clubStore,
  IClubStoreState,
} from "./club/club-store";
import {
  CONFIG_STORE_CONST,
  configStore,
  IConfigStoreState,
} from "./config/config-store";
import {
  ENTRY_STORE_CONST,
  entryStore,
  IEntryStoreState,
} from "./entry/entry-store";
import {
  ITeamStoreState,
  TEAM_STORE_CONST,
  teamStore,
} from "./team/team-store";
import {
  IUserMessageStoreState,
  USER_MESSAGE_STORE_CONST,
  userMessageStore,
} from "./user-message/user-message-store";
import {
  IPublicCompsStoreState,
  PUBLIC_COMPS_STORE_CONST,
  publicCompStore,
} from "./public/entry-public/public-comps-store";
import {
  IPermissionStoreState,
  PERMISSION_STORE_CONST,
  permissionStore,
} from "./admin/permissions/permission-store";
import {
  IFieldHelpStoreState,
  FIELD_HELP_STORE_CONST,
  fieldHelpStore,
} from "./common/ui/field/field-help/field-help-store";
import {
  CHECKIN_STORE_CONST,
  checkInStore,
} from "./competition/checkin/checkin-store";
import { ICheckInStoreState } from "./competition/checkin/checkin-models";
import {
  IScoreboardStoreState,
  SCOREBOARD_STORE_CONST,
  scoreboardStore,
} from "./competition/scoreboard/scorboard-store";
import {
  ISecondaryStoreState,
  SECONDARY_STORE_CONST,
  secondaryStore,
} from "./secondary/secondary-store";
import {
  ISecondaryCustStoreState,
  SECONDARY_CUST_STORE_CONST,
  secondaryCustStore,
} from "./secondary/cust/secondary-cust-store";
import {
  IResultsStoreState,
  RESULTS_STORE_CONST,
  resultsStore,
} from "./competition/results/results-store";
import {
  IScheduleCompPublicStoreState,
  SCHEDULE_COMP_PUBLIC_STORE_CONST,
  scheduleCompPublicStore,
} from "./competition/results/public/schedule/schedule-comp-public-store";

Vue.use(Vuex);
export interface IRootState {
  [CLUB_STORE_CONST.CLUB_STORE_CONST_MODULE_NAME]: IClubStoreState;
  [USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME]: IUserMessageStoreState;
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME]: IAthCompSchedStoreState;
  [CART_STORE_CONST.CART_CONST_MODULE_NAME]: ICartStoreState; //   TODO incorrectly named<---
  [AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME]: IAuthStoreState;
  [ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]: IEntryStoreState;
  [TEAM_STORE_CONST.TEAM_STORE_CONST_MODULE_NAME]: ITeamStoreState;
  [CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME]: IConfigStoreState;
  [BUILDER_STORE_CONST.BUILDER_CONST_MODULE_NAME]: IBuilderStoreState;
  [COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_CONST_MODULE_NAME]: ICompEventTeamsStoreState;
  [PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_CONST_MODULE_NAME]: IPublicCompsStoreState;
  [PERMISSION_STORE_CONST.PERMISSION_CONST_MODULE_NAME]: IPermissionStoreState;
  [FIELD_HELP_STORE_CONST.CONST_MODULE_NAME]: IFieldHelpStoreState;
  [CHECKIN_STORE_CONST.CHECKIN_CONST_MODULE_NAME]: ICheckInStoreState;
  [SCOREBOARD_STORE_CONST.SCOREBOARD_CONST_MODULE_NAME]: IScoreboardStoreState;
  [SECONDARY_STORE_CONST.SECONDARY_CONST_MODULE_NAME]: ISecondaryStoreState;
  [SECONDARY_CUST_STORE_CONST.SECONDARY_CUST_CONST_MODULE_NAME]: ISecondaryCustStoreState;
  [RESULTS_STORE_CONST.RESULTS_CONST_MODULE_NAME]: IResultsStoreState;
  [SCHEDULE_COMP_PUBLIC_STORE_CONST.SCHEDULE_COMP_PUBLIC_MODULE_NAME]: IScheduleCompPublicStoreState;
}

// https://stackoverflow.com/questions/47163495/vuex-getter-with-argument-written-in-typescript
const store: StoreOptions<IRootState> = {
  strict: true,
  modules: {
    [CLUB_STORE_CONST.CLUB_STORE_CONST_MODULE_NAME]: clubStore,
    [USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME]: userMessageStore,
    [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME]:
      athCompSchedStore,
    [CART_STORE_CONST.CART_CONST_MODULE_NAME]: cartStore,
    [AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME]: authStore,
    [ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]: entryStore,
    [TEAM_STORE_CONST.TEAM_STORE_CONST_MODULE_NAME]: teamStore,
    [CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME]: configStore,
    [BUILDER_STORE_CONST.BUILDER_CONST_MODULE_NAME]: builderStore,
    [COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_CONST_MODULE_NAME]:
      compEventTeamStore,
    [PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_CONST_MODULE_NAME]: publicCompStore,
    [PERMISSION_STORE_CONST.PERMISSION_CONST_MODULE_NAME]: permissionStore,
    [FIELD_HELP_STORE_CONST.CONST_MODULE_NAME]: fieldHelpStore,
    [CHECKIN_STORE_CONST.CHECKIN_CONST_MODULE_NAME]: checkInStore,
    [SCOREBOARD_STORE_CONST.SCOREBOARD_CONST_MODULE_NAME]: scoreboardStore,
    [SECONDARY_STORE_CONST.SECONDARY_CONST_MODULE_NAME]: secondaryStore,
    [SECONDARY_CUST_STORE_CONST.SECONDARY_CUST_CONST_MODULE_NAME]:
      secondaryCustStore,
    [RESULTS_STORE_CONST.RESULTS_CONST_MODULE_NAME]: resultsStore,
    [SCHEDULE_COMP_PUBLIC_STORE_CONST.SCHEDULE_COMP_PUBLIC_MODULE_NAME]:
      scheduleCompPublicStore,
  },
};

export const appStore = new Vuex.Store<IRootState>(store);

export interface IAppStoreDispatchHelper {
  actionName: string;
  dataProp: string;
  loadingProp: string;
}

//  Until v3, use this.
export function useStore(): Store<IRootState> {
  return appStore;
}
