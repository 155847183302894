
export const SCOREBOARD_ROUTES = {
    SCOREBOARD_COMP_ROUTE: "comp",
    SCOREBOARD_COMP_EVENT_ROUTE: "event",
    SCOREBOARD_COMP_EVENT_ALL_ROUTE: "all",
    SCOREBOARD_OUTPUT_DISPLAY: "output"
};


export default [
    {
        name: "",
        path: ":id",
        component: () => {
            return import(/* webpackChunkName: "scoreboard-route" */ "./scoreboard-route.vue");
        }
    },
    {
        name: SCOREBOARD_ROUTES.SCOREBOARD_COMP_EVENT_ALL_ROUTE,
        path: ":compid/all",
        component: () => {
            return import(/* webpackChunkName: "scoreboard-event-all" */ "./scoreboard-event-all/scoreboard-event-all.vue");
        }
    },
    {
        name: SCOREBOARD_ROUTES.SCOREBOARD_COMP_EVENT_ROUTE,
        path: ":compid/:eventno",
        component: () => {
            return import(/* webpackChunkName: "scoreboard-event-route" */ "./scoreboard-event/scoreboard-event-route.vue");
        }
    },
    {
        name: SCOREBOARD_ROUTES.SCOREBOARD_OUTPUT_DISPLAY,
        path: SCOREBOARD_ROUTES.SCOREBOARD_OUTPUT_DISPLAY + "/:compId/:outputId",
        component: () => {
            return import(/* webpackChunkName: "scoreboard-output-display-route" */ "./scoreboard-output/display/scoreboard-output-display-route.vue");
        }
    }
    // {
    //     name: SCOREBOARD_ROUTES.SCOREBOARD_OUTPUT_DISPLAY_V2,
    //     path: SCOREBOARD_ROUTES.SCOREBOARD_OUTPUT_DISPLAY_V2 + "/:compId/:outputId",
    //     component: () => {
    //         return import(/* webpackChunkName: "scoreboard-output-display-v2-route" */ "./scoreboard-output/display/v2/scoreboard-output-display-v2-route.vue");
    //     }
    // }
];
