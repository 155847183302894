

















import { defineComponent, SetupContext } from "@vue/composition-api";

import E4sLogoSvg from "../../common/ui/svg/E4sLogoSvg.vue";
import { useConfigStore } from "../../config/useConfigStore";

export default defineComponent({
  name: "launch-header-blank-v2",
  components: { E4sLogoSvg },
  props: {
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: { isFullScreen: boolean }, context: SetupContext) {
    const configStore = useConfigStore();
    return { configStore };
  },
});
