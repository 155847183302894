




















































import { defineComponent, SetupContext } from "@vue/composition-api";
import LoadingSpinnerV2 from "../../loading-spinner-v2.vue";
import ButtonGenericV2 from "../buttons/button-generic-v2.vue";

export default defineComponent({
  name: "form-generic-button-bar",
  components: { ButtonGenericV2, LoadingSpinnerV2 },
  props: {
    submitButtonText: {
      type: String,
      default: () => {
        return "Submit";
      },
    },
    cancelButtonText: {
      type: String,
      default: () => {
        return "Cancel";
      },
    },
    isLoading: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  setup(
    props: {
      submitButtonText: string;
      cancelButtonText: string;
      isLoading: boolean;
    },
    context: SetupContext
  ) {
    function cancel() {
      console.log("form-generic-button-bar.cancel()");
      context.emit("cancel");
    }
    function submit() {
      console.log("form-generic-button-bar.submit()");
      context.emit("submit");
    }

    return { cancel, submit };
  },
});
