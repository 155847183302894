import {IValidationMessage, IValidationProp} from "./validation-models"
import {IObjectKeyType} from "../common/common-models";
import * as R from "ramda";

import {CommonService} from "../common/common-service";

const commonService: CommonService = new CommonService();

export class ValidationService {

    public factoryValidationProp(): IValidationProp {
        return {
            title: "",
            propPath: "",
            messages: []
        };
    }

    public createSimpleValidationProp(propPath: string, message: string): IValidationProp {
        return {
            ...this.factoryValidationProp(),
            propPath,
            messages: [message]
        };
    }

    public addValidationProp(validationProp: IValidationProp, validationProps: IObjectKeyType<IValidationProp>): IObjectKeyType<IValidationProp> {
        if (!validationProp.propPath || validationProp.propPath.length === 0) {
            return validationProps;
        }
        const validationPropsInternal = R.clone(validationProps);
        validationPropsInternal[ validationProp.propPath ] = validationProp;
        return validationPropsInternal;
    }

    public addMessage(propPath: string, message: string, validationProps: IObjectKeyType<IValidationProp>): IObjectKeyType<IValidationProp> {
        if (!propPath || propPath.length === 0) {
            return validationProps;
        }
        const validationPropsInternal = R.clone(validationProps);
        if (!validationPropsInternal[ propPath ]) {
            const validationProp = this.factoryValidationProp();
            validationProp.propPath = propPath;
            validationPropsInternal[ propPath ] = validationProp;
        }
        validationPropsInternal[ propPath ].messages.push(message);
        return validationPropsInternal;
    }

    public addMessageCompound(validationMessage: IValidationMessage, validationProps: IObjectKeyType<IValidationProp>): IObjectKeyType<IValidationProp> {
        const propPath = validationMessage.propPath;
        if (propPath.length === 0) {
            return validationProps;
        }
        const validationPropsInternal = R.clone(validationProps);
        if (!validationPropsInternal[ propPath ]) {
            const validationPropTemp = this.factoryValidationProp();
            validationPropTemp.propPath = propPath;
            validationPropTemp.title = validationMessage.title;
            validationPropTemp.subTitle = validationMessage.subTitle ? validationPropTemp.subTitle : "";
            validationPropsInternal[ propPath ] = validationPropTemp;
        } else {
            validationPropsInternal[ propPath ].title = validationMessage.title;
            if (validationMessage.subTitle) {
                validationPropsInternal[ propPath ].subTitle = validationMessage.subTitle;
            }
        }
        validationPropsInternal[ propPath ].messages.push(validationMessage.message);
        return validationPropsInternal;
    }

    public isEmailValid(email: string) {
        const regexPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return new RegExp(regexPattern).test(email);
    }


    public fieldHasAValue(fieldValue: string | number): boolean {
        return fieldValue.toString().replace( /\s/g, "" ).length > 0;
    }

    public isNumberEqualOrAbove(fieldValue: number, isEqualOrAbove: number) {
        return this.fieldHasAValue(fieldValue) && (fieldValue >= isEqualOrAbove);
    }

    public getValidationSummary(validationProps: IObjectKeyType<IValidationProp>): string[] {
        return Object.values(validationProps).reduce( (accum, validationProp) => {
            // accum.push((validationProp.title ? validationProp.title : validationProp.propPath)  + ": " + validationProp.messages.join(", "));
            accum.push(validationProp.messages.join(", "));
            return accum;
        }, [] as string[]);
    }

    public groupErrorsByTitle(validationProps: IObjectKeyType<IValidationProp>): Record<string, IValidationProp[]> {
        // function getTitle(validationProp: IValidationProp) {
        //     return validationProp.title ? validationProp.title : validationProp.propPath;
        // }
        // return commonService.convertArrayToObject(getTitle, commonService.convertObjectToArray(validationProps)) ;
        // return commonService.convertArrayToObjectArray(getTitle, commonService.convertObjectToArray(validationProps));
        return commonService.convertObjectToArray(validationProps) as any as Record<string, IValidationProp[]>;
    }

    // public getErrorsHtml(validationProps: IObjectKeyType<IValidationProp>): string {
    //     const errorsByTitle = this.groupErrorsByTitle(validationProps);
    //     return Object.keys(errorsByTitle).reduce( (accum, title) => {
    //
    //         const validationProp: IValidationProp = errorsByTitle[title];
    //
    //         accum.push("<div>");
    //         accum.push(title);
    //         accum.push("</div>");
    //
    //         accum.push("<div>");
    //         // const validationProp: IValidationProp = validationProps[title];
    //         //
    //         const subTitle = validationProp.subTitle ? validationProp.subTitle : "";
    //         //
    //         accum.push(subTitle + ": " + validationProp.messages.join(" "));
    //         accum.push("</div>");
    //
    //         return accum;
    //     }, [] as string[]).join("");
    // }

    public allErrorsByProperty(errors: Record<string, IValidationProp>): Record<string, string> {
        return Object.keys(errors).reduce<Record<string, string>>( (accum, prop) => {
            accum[prop] = errors[prop].messages.join(", ");
            return accum;
        }, {})
    }

}
