export enum USER_MESSAGE_LEVEL {
    DEBUG = 0,
    INFO = 1,
    WARN = 2,
    ERROR = 3
}

export interface IUserMessage {
    id?: string;                // If you push the same "shaped" message in, Vue thinks not chnaned.
                                         // stick some random string in to prevent this.  That is default behaviour.
    level: USER_MESSAGE_LEVEL;
    message: string;
    timeOut?: number;                    //  message will disappear after x millisecs.
}
