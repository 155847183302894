import {
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  distanceInWordsStrict,
  format,
  parse,
} from "date-fns";
import * as R from "ramda";
import {
  GENDER,
  IBase,
  IBaseConcrete,
  IObjectKey,
  IObjectKeyType,
  IObjectKeyTypeArray,
  IsoDate,
  IsoDateTime,
} from "./common-models";

export class CommonService {
  public validateDateEntry(value: string): boolean {
    if (value === undefined || value.length === 0) {
      return false;
    }
    // This pattern is DD/MM/YYYY
    // tslint:disable-next-line:max-line-length
    const regexPattern =
      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    //   This is YYYY-MM-DD
    //  const regexPattern = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    const regexTest = new RegExp(regexPattern);
    const result = regexTest.test(value);
    return result;
  }

  public validateTime(value: string): boolean {
    // const regexPattern = /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/;
    //  const regexPattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    const regexPattern = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/;
    const regexTest = new RegExp(regexPattern);
    const result = regexTest.test(value);
    return result;
  }

  public getIsoDateTimePattern(): string {
    return "YYYY-MM-DD[T]HH:mm:ssZ";
  }

  public getDateDisplayFormat(): string {
    return "DD/MM/YYYY";
  }

  public getTimeDisplayFormat(): string {
    return "HH:mm";
  }

  public getDateTimeDisplayFormat(): string {
    return this.getDateDisplayFormat() + " " + this.getTimeDisplayFormat();
  }

  public convertToIsoDate(dateValue: string) {
    //  I just can NOT f-ing believe u can't currently do parse(someString, someFormat)
    //  with datefns 1.9.x  WTF!  They are doing it in v2, still in alpha.
    //  Should have flippin' stuck with moment.js
    const fYou: string[] = dateValue.split("/");
    return fYou[2] + "-" + fYou[1] + "-" + fYou[0];
  }

  public convertToIsoDateTimeWithOffset(
    isoDate: string,
    isoTimeNoSeconds: string
  ) {
    return format(
      parse(isoDate + "T" + isoTimeNoSeconds + ":00"),
      this.getIsoDateTimePattern()
    );
    //  ...so...
    // return this.toIsoString(isoDate + "T" + isoTimeNoSeconds + ":00");
  }

  public toIsoString(isoDate: string) {
    const date = new Date(isoDate);
    const tzo = -date.getTimezoneOffset();
    const dif = tzo >= 0 ? "+" : "-";
    function pad(num: number) {
      return (num < 10 ? "0" : "") + num;
    }

    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      "T" +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds()) +
      dif +
      pad(Math.floor(Math.abs(tzo) / 60)) +
      ":" +
      pad(Math.abs(tzo) % 60)
    );
  }

  public isDateTimeValid(isoDateTime: string): boolean {
    return !(
      format(parse(isoDateTime), this.getIsoDateTimePattern()).toUpperCase() ===
      "INVALID DATE"
    );
  }

  /**
   *
   * @param date          The pattern MUST be DD/MM/YYYY  !!!!
   * @param time          The pattern MUST be HH:mm
   */
  public convertUserDateTimeInputToIso(date: string, time: string) {
    return this.convertToIsoDateTimeWithOffset(
      this.convertToIsoDate(date),
      time
    );
  }

  public transformIsoForInputField(
    dateTimeIso: string,
    convertDate: boolean,
    convertTime: boolean
  ) {
    let dateFns;
    if (dateTimeIso.length === 0) {
      return "";
    }
    if (convertDate && !convertTime) {
      dateFns = parse(dateTimeIso);
      return format(dateFns, this.getDateDisplayFormat());
    }

    if (!convertDate && convertTime) {
      dateFns = parse(dateTimeIso);
      return format(dateFns, this.getTimeDisplayFormat());
    }

    if (convertDate && convertTime) {
      //  Not really sure how you would display this, as this will have a reverse
      //  function of combining 2 users fields date & time and creating ISO...since
      //  date fns does NOT have a parse(datetime, "somePattern")...ghah!!!!!
      dateFns = parse(dateTimeIso);
      return format(dateFns, this.getDateTimeDisplayFormat());
    }
    return "";
  }

  public getE4sStandardHumanDateOutPut(
    dateTime: IsoDate | IsoDateTime
  ): string {
    return format(parse(dateTime), "Do MMM YYYY");
  }

  public getE4sStandardHumanDateTimeOutPut(
    dateTime: IsoDate | IsoDateTime
  ): string {
    return format(parse(dateTime), "Do MMM YYYY HH:mm");
  }

  public getE4sStandardDateOutPut(dateTime: string): string {
    return format(parse(dateTime), this.getDateDisplayFormat());
  }

  public getE4sStandardDateTimeOutPut(dateTime: string): string {
    return format(parse(dateTime), this.getDateTimeDisplayFormat());
  }

  public getE4sStandardTimeOutPut(dateTime: string): string {
    return format(parse(dateTime), this.getTimeDisplayFormat());
  }

  /**
   * Use this if there is a one to one mapping.   E.g.
   * {
   *      "MA": {city: "Boston"},
   *      "CT": {"city": "New York"}
   * }
   * @param prop
   * @param someArray
   */
  // public convertArrayToObject<T>(prop: string, someArray: T[]): IObjectKeyType<T> {
  //     if (R.isNil(someArray) || someArray.length === 0) {
  //         return {};
  //     }
  //     // @ts-ignore
  //     const objFromListWith = R.curry((fn, list) => R.chain(R.zipObj, R.map(fn))(list));
  //     const result = objFromListWith(
  //         R.prop(prop)
  //     )(someArray);
  //     // @ts-ignore
  //     return result;
  // }
  public convertArrayToObject<T>(
    prop: ((t: T) => any) | string,
    someArray: T[]
  ): IObjectKeyType<T> {
    if (R.isNil(someArray) || someArray.length === 0) {
      return {};
    }
    return someArray.reduce((accum, obj: T) => {
      // @ts-ignore
      const propValueString = typeof prop === "string" ? obj[prop] : prop(obj);
      //  @ts-ignore
      accum[propValueString] = obj;
      return accum;
    }, {} as IObjectKey);
  }

  /**
   * Use this if there is a one to many mapping.   E.g.
   * {
   *      "MA": [
   *          {city: "Boston"},
   *          {city: "Winchester"}
   *      ],
   *      "CT": [
   *          {city: "New Canaan"}
   *      ]
   * }
   * @param prop
   * @param someArray
   */
  public convertArrayToObjectArray<T>(
    prop: ((t: T) => any) | string,
    someArray: T[]
  ): IObjectKeyTypeArray<T> {
    return someArray.reduce((accum, obj: T) => {
      // @ts-ignore
      const propValueString = typeof prop === "string" ? obj[prop] : prop(obj);
      //  Produce a keyed object
      if (!accum[propValueString]) {
        accum[propValueString] = [];
      }
      accum[propValueString].push(obj);
      return accum;
    }, {} as IObjectKey);
  }

  public convertObjectToArray<T>(someObject: IObjectKeyType<T>): T[] {
    const arr: T[] = [];
    for (const key in someObject) {
      if (someObject.hasOwnProperty(key)) {
        arr.push(someObject[key]);
      }
    }
    return arr;
  }

  public pluck<InputObject, OutputObject>(
    prop: ((t: InputObject) => OutputObject) | keyof InputObject,
    someArray: InputObject[]
  ): OutputObject[] {
    return someArray.reduce<OutputObject[]>((accum, obj: InputObject) => {
      // @ts-ignore
      const propValue = typeof prop === "string" ? obj[prop] : prop(obj);
      accum.push(propValue);
      return accum;
    }, []);
  }

  public pluckUnique<InputObject, OutputObject>(
    prop: ((t: InputObject) => OutputObject) | keyof InputObject,
    someArray: InputObject[]
  ): OutputObject[] {
    return this.unique(this.pluck(prop, someArray));
  }

  public chunkArray(myArray: any, chunkSize: number): any {
    const arrayLength: number = myArray.length;
    const tempArray: any = [];

    let index: number;
    for (index = 0; index < arrayLength; index += chunkSize) {
      const myChunk = myArray.slice(index, index + chunkSize);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }

    return tempArray;
  }

  public onlyContainsNumber(stringToTest: string) {
    const regOnlyNumbers = new RegExp(/^\d+$/);
    return regOnlyNumbers.test(stringToTest);
  }

  public stripChars(str: string) {
    return str.replace(/[\n\r\s\t]+/g, "");
  }

  public getObjectByIdFromArray(id: number, objs: IBase[]): IBase | null {
    return objs.reduce((accum: IBase | null, o) => {
      if (o.id === id) {
        accum = o;
      }
      return accum;
    }, null);
  }

  public getObjectFromArray(obj: IBase, objs: IBase[]): IBase | null {
    return this.getObjectByIdFromArray(obj.id, objs);
  }

  public updateObjectByIdInArray(obj: IBase, objs: IBase[]): IBase[] {
    obj = R.clone(obj);
    objs = R.clone(objs);
    let counter: number = 0;
    const didUpdate: boolean = objs.reduce((accum: boolean, o: IBase) => {
      if (o.id === obj.id) {
        objs[counter] = obj;
        accum = true;
      }
      counter++;
      return accum;
    }, false);
    if (!didUpdate) {
      objs.push(obj);
    }
    return objs;

    //  TODO why didn't I just do...seems way simpler.
    // return objs.map((o) => {
    //     if (o.id === obj.id) {
    //         return obj;
    //     }
    //     return o;
    // });
  }

  // public convertArrayToObjectArray<T>(prop: ((t: T) => any) | string, someArray: T[]): IObjectKeyTypeArray<T> {
  public uniqueArrayById<T>(objs: T[], idProp?: string): T[] {
    // const uniques = Array.from(new Set(objs.map((a) => a.id)))
    //     .map((id) => {
    //         return objs.find((a) => a.id === id);
    //     }) as IBase[];
    const prop: string = idProp ? idProp : "id";
    const uniques = objs.reduce((acc: T[], current: T) => {
      //  TODO find does not work in IE11
      const x = acc.find((item: T) => {
        // return item.id === current.id;
        // @ts-ignore
        return item[prop] === current[prop];
      });
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return uniques;
  }

  public unique<T>(objs: T[]): T[] {
    // // @ts-ignore
    // return [...new Set(objs)];
    return R.uniq(objs);
  }

  public removeById(objs: IBase[], id: number): IBase[] {
    return objs.filter((base: IBase) => {
      return base.id !== id;
    });
  }

  public getGenderLabel(gender: GENDER): string {
    if (gender === GENDER.OPEN) {
      return "Open";
    }
    if (gender === GENDER.MALE) {
      return "Male";
    }
    if (gender === GENDER.FEMALE) {
      return "Female";
    }
    return "Unknown";
  }

  public differenceBetweenTwoArrays(arrayOne: any[], arrayTwo: any[]): any[] {
    return R.difference(arrayOne, arrayTwo);
  }

  public differenceBetweenTwoObjects(obj1: any, obj2: any): any {
    // const groupObjBy = R.curry(R.pipe(
    //     // Call groupBy with the object as pairs, passing only the value to the key function
    //     // @ts-ignore
    //     R.useWith(R.groupBy, [R.useWith(R.__, [R.last]), R.toPairs]),
    //     R.map(R.fromPairs)
    // ));
    //
    // // @ts-ignore
    // const diffObjs = R.pipe(
    //     R.useWith(R.mergeWith(R.merge), [R.map(R.objOf("leftValue")), R.map(R.objOf("rightValue"))]),
    //     // @ts-ignore
    //     groupObjBy(R.cond([
    //         // @ts-ignore
    //         [ R.both(R.has("leftValue"), R.has("rightValue")), R.pipe(R.values, R.ifElse(R.apply(R.equals), R.always("common"), R.always("difference")))],
    //         [R.has("leftValue"), R.always("onlyOnLeft")],
    //         [R.has("rightValue"), R.always("onlyOnRight")],
    //     ])),
    //     R.evolve({
    //         common: R.map(R.prop("leftValue")),
    //         onlyOnLeft: R.map(R.prop("leftValue")),
    //         onlyOnRight: R.map(R.prop("rightValue"))
    //     })
    // );
    //
    // // @ts-ignore
    // return diffObjs(obj1, obj2);

    // return Object.keys(obj2).reduce((diff, key) => {
    //     if (obj1[key] === obj2[key]) {
    //         return diff;
    //     }
    //     return {
    //         ...diff,
    //         [key]: obj2[key]
    //     };
    // }, {});

    let k;
    let kDiff;
    const diff = {};
    for (k in obj1) {
      if (!obj1.hasOwnProperty(k)) {
        //  do nothign
      } else if (typeof obj1[k] !== "object" || typeof obj2[k] !== "object") {
        if (!(k in obj2) || obj1[k] !== obj2[k]) {
          // @ts-ignore
          diff[k] = obj2[k];
        }
        // tslint:disable-next-line:no-conditional-assignment
      } else if ((kDiff = this.differenceBetweenTwoObjects(obj1[k], obj2[k]))) {
        // @ts-ignore
        diff[k] = kDiff;
      }
    }
    for (k in obj2) {
      if (obj2.hasOwnProperty(k) && !(k in obj1)) {
        // @ts-ignore
        diff[k] = obj2[k];
      }
    }
    for (k in diff) {
      if (diff.hasOwnProperty(k)) {
        return diff;
      }
    }
    return false;
  }

  public objectHasPropPath(obj: any, propPath: string): boolean {
    const propPathArray: string[] = propPath.split(".");
    return R.hasPath(propPathArray, obj);
  }

  public valueAtPath(obj: any, propPath: string): any {
    const lens = R.lensPath(propPath.split("."));
    return R.view(lens, obj);
  }

  public createObjectPath<T>(
    sourceObject: T,
    propPath: string,
    propValue: any
  ): any {
    const props: string[] = propPath.split(".");
    // const loopTill: number = props.length - 1;
    const objCopy = Object.assign({}, sourceObject);
    let currentObj: any = objCopy;
    for (let i = 0; i < props.length - 1; i++) {
      // Get the current key we are looping
      const key = props[i];

      // If the requested level on the current object doesn't exist,
      // make a blank object.
      if (typeof currentObj[key] === "undefined") {
        currentObj[key] = {};
      }

      // Set the current object to the next level of the keypath,
      // allowing us to drill in.
      currentObj = currentObj[key];
    }
    const lastKey = props[props.length - 1];

    // Set the property of the deepest object to the value.
    currentObj[lastKey] = propValue;

    return objCopy;
  }

  public deleteAtPath<T>(sourceObject: T, propPath: string): T {
    const props: string[] = propPath.split(".");
    const objCopy = Object.assign({}, sourceObject);
    let currentObj: any = objCopy;

    if (props.length === 1) {
      delete currentObj[props[0]];
      return currentObj;
    }

    for (let i = 0; i < props.length - 1; i++) {
      const key = props[i];
      currentObj = currentObj[key];

      // if (typeof currentObj[key] === 'undefined') {
      //     currentObj = null;
      // }
      // currentObj = null;
    }
    // if (typeof currentObj !== 'undefined') {
    delete currentObj[props[props.length - 1]];
    // }
    return objCopy;

    // const lens = R.lensPath(props);
    // return R.set(lens, null, objCopy);
  }

  public roundNumberToDecimalPlaces(
    value: string | number,
    decimalPlaces: number,
    asNumber: boolean = true
  ): number | string {
    let inputValue: string = value.toString();
    inputValue = inputValue.length === 0 ? "0" : inputValue;
    if (asNumber) {
      return Number(parseFloat(inputValue).toFixed(decimalPlaces));
    }
    return parseFloat(inputValue).toFixed(decimalPlaces);
  }

  public getAmountAsCurrency(value: number, currency: string): string {
    /*
        Problem with this, we don't know the currency code, but it is way faster than method below.
        new Intl.NumberFormat('en-US', {
            style: "currency",
            currency: "GBP",
        }).format(compEvent.order.wcLineValue)
        */
    return currency + Number(value.toFixed(2)).toLocaleString();
  }

  public ageBetweenDates(
    startDateIso: string,
    endDateIso: string
  ): {
    years: number;
    months: number;
    days: number;
    distance: string;
    message: string;
  } {
    const dateStart = parse(startDateIso);
    const dateEnd = parse(endDateIso);

    const ageYears = differenceInYears(dateEnd, dateStart);
    const ageMonths =
      differenceInMonths(dateEnd, dateStart) -
      (ageYears > 0 ? 12 * ageYears : 0);
    const ageDays =
      differenceInDays(dateEnd, dateStart) - (ageYears > 0 ? 12 * ageYears : 0);
    const distance = distanceInWordsStrict(dateStart, dateEnd);

    const message = ageYears + "y " + ageMonths + "m";

    return {
      years: ageYears,
      months: ageMonths,
      days: ageDays,
      distance,
      message,
    };
  }

  public getNextDateInSequence(dates: string[], dateToMatch?: string): string {
    const dateToMatchFNS = dateToMatch ? parse(dateToMatch) : parse(new Date());
    const dateToMatchDateOnly = format(dateToMatchFNS, "YYYY-MM-DD");
    return dates.reduce((accum, date) => {
      const dateOnly = format(parse(date), "YYYY-MM-DD");

      if (dateOnly === dateToMatchDateOnly) {
        return dateOnly;
      }
      if (accum.length === 0 && dateOnly > dateToMatchDateOnly) {
        return dateOnly;
      }
      return accum;
    }, "");
  }

  public getAllCombinations(arraysToCombine: any[]) {
    const divisors: any = [];
    for (let i = arraysToCombine.length - 1; i >= 0; i--) {
      divisors[i] = divisors[i + 1]
        ? divisors[i + 1] * arraysToCombine[i + 1].length
        : 1;
    }

    function getPermutation(n: any, arrToCombine: any) {
      const result = [];
      let curArray;
      for (let j = 0; j < arrToCombine.length; j++) {
        curArray = arrToCombine[j];
        result.push(curArray[Math.floor(n / divisors[j]) % curArray.length]);
      }
      return result;
    }

    let numPerms = arraysToCombine[0].length;
    for (let i = 1; i < arraysToCombine.length; i++) {
      numPerms *= arraysToCombine[i].length;
    }

    const combinations: any = [];
    for (let i = 0; i < numPerms; i++) {
      combinations.push(getPermutation(i, arraysToCombine));
    }
    return combinations;
  }

  public prop<T, K extends keyof T>(obj: T, key: K) {
    return obj[key];
  }

  // public sortArray<T, K extends keyof T>(prop: K, objs: T[]): T[] {
  //     return R.sortWith([
  //         //  @ts-ignore
  //         R.ascend( R.prop( prop ) as any )
  //     ])(objs) as T[];
  // }

  /**
   * Handles sorting onn strings of numbers
   * @param prop
   * @param someArray
   */
  public sortArray<ObjectType, PropName extends keyof ObjectType>(
    prop: ((t: ObjectType) => string) | PropName,
    someArray: ObjectType[],
    order: "ASC" | "DESC" = "ASC"
  ): ObjectType[] {
    if (!someArray || someArray.length === 0) {
      return someArray;
    }

    return R.clone(someArray).sort((a, b) => {
      const propValueA: unknown =
        typeof prop === "function" ? prop(a) : a[prop];
      const propValueB: unknown =
        typeof prop === "function" ? prop(b) : b[prop];

      if (typeof propValueA === "string" && typeof propValueB === "string") {
        const compA: string = (
          order === "ASC" ? propValueA : propValueB
        ).toUpperCase();
        const compB: string = (
          order === "ASC" ? propValueB : propValueA
        ).toUpperCase();
        // const compB: string = propValueB.toString().toUpperCase();

        if (compA < compB) {
          return -1;
        }
        if (compA > compB) {
          return 1;
        }
        // names must be equal
        return 0;
      }

      if (typeof propValueA === "number" && typeof propValueB === "number") {
        return order === "ASC"
          ? propValueA - propValueB
          : propValueB - propValueA;
      }

      return 0;
    });
  }

  public startArrayFromPosition<ObjectType>(
    someArray: ObjectType[],
    position: number
  ): ObjectType[] {
    if (!someArray || someArray.length === 0) {
      return someArray;
    }

    //  gets everythign from starting position to end.
    const endArray = someArray.slice(position);

    //  Get everything from beginning up to position
    const beginArray = someArray.slice(0, position);

    return [...endArray, ...beginArray];
  }

  public findFirst<T>(pred: (t: T) => boolean, someArray: T[]): T | null {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < someArray.length; i++) {
      const someT = someArray[i];
      const res = pred(someT);
      if (res) {
        return someT;
      }
    }
    return null;
  }

  public isEmpty(someValue: string | number): boolean {
    return someValue.toString().replace(/\s/g, "").length === 0;
  }

  public isOnlyNumbers(someValue: string): boolean {
    return /^\d+$/.test(someValue);
  }

  public getIdAndDescription(base: IBaseConcrete) {
    return "(" + base.id + ") " + base.name;
  }
}
