















import { defineComponent, SetupContext } from "@vue/composition-api";

export default defineComponent({
  name: "comp-content-section-cell-v2",
  components: {},
  props: {
    label: {
      type: String,
      default: () => {
        return "";
      },
    },
    data: {
      type: String,
      default: () => {
        return "";
      },
    },
    isHtml: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  setup(props: { label: string; data: string }, context: SetupContext) {
    return {};
  },
});
