





















import Vue from "vue";
import Component from "vue-class-component";
import {mapState} from "vuex"
import {CONFIG_STORE_CONST, IConfigStoreState} from "../../../config/config-store"
import {IConfigApp} from "../../../config/config-app-models"

@Component({
    name: "common-header",
    computed: {
        ...mapState(CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME, {
            configApp: (state: IConfigStoreState) => state.configApp
        })
    }
})
export default class CommonHeader extends Vue {
    public readonly configApp: IConfigApp;

}
