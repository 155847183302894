































import Vue from "vue";
import Component from "vue-class-component";
import { VUE_MQ_SIZES } from "../index";
import { CONFIG } from "../common/config";
import CloseIcon from "../common/ui/close-icon.vue";
import { handleResponseMessages } from "../common/handle-http-reponse";
import { CartData } from "./cart-data";
import { messageDispatchHelper } from "../user-message/user-message-store";
import { USER_MESSAGE_LEVEL } from "../user-message/user-message-models";
import Axios from "axios";
import { ATH_COMP_SCHED_STORE_CONST } from "../athletecompsched/store/athleteCompSched-store";

@Component({
  name: "cart-mini",
  components: { CloseIcon },
})
export default class CartMini extends Vue {
  public $mq: any;
  public VUE_MQ_SIZES = VUE_MQ_SIZES;
  public CONFIG = CONFIG;
  public PREFIX = Math.random().toString(36).substring(2);
  public eleIdForBubble = this.PREFIX + "cart-mini--content-id";

  public miniCartHtml: string = "";
  public isLoading = false;
  public anItemWasRemoved = false;

  public removeLinks: {
    href: string;
  }[] = [];

  public mounted() {
    this.getCartHandleClick();
  }

  public get getCartUrl() {
    return (
      (process.env.NODE_ENV === "development"
        ? "https://dev.entry4sports.com"
        : "") + "/wp-json/e4s/v5/minicart"
    );
  }

  public loadMiniCartHtml(): Promise<void> {
    this.isLoading = true;
    // this.setRemoveFromCartBinding(false);
    const prom = new CartData().getMiniCart();
    handleResponseMessages(prom);
    return prom
      .then((response) => {
        if (process.env.NODE_ENV === "development") {
          let data =
            "\n" +
            "\n" +
            '\t<ul class="woocommerce-mini-cart cart_list product_list_widget ">\n' +
            '\t\t\t\t\t\t<li class="woocommerce-mini-cart-item mini_cart_item">\n' +
            '\t\t\t\t\t<a href="https://dev.entry4sports.com/my-account/basket/?remove_item=c619d3037eacda6b38dc09ef98903f5e&#038;_wpnonce=e9121023ad" class="remove remove_from_cart_button" aria-label="Remove this item" data-product_id="24221" data-cart_item_key="c619d3037eacda6b38dc09ef98903f5e" data-product_sku="24221">&times;</a>\t\t\t\t\t\t\t\t\t\t\t<img width="300" height="300" \n' +
            '                                src="/resources/ai_cardlogo.png" \n' +
            '                                class="woocommerce-placeholder wp-post-image" \n' +
            '                                alt="Placeholder"\n' +
            '                              >Acme Winter Comp 1st Mar 2021<ul class="wc-item-meta">\n' +
            "                    <li>\n" +
            '                        <strong class="wc-item-meta-label">Athlete:</strong>Lily Calvert\n' +
            "                    </li>\n" +
            "                    <li>\n" +
            '                        <strong class="wc-item-meta-label">Event:</strong>Long Jump\n' +
            "                    </li>\n" +
            '                 </ul>\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t<span class="quantity">1 &times; <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&pound;</span>0.00</span></span>\t\t\t\t</li>\n' +
            '\t\t\t\t\t\t\t\t<li class="woocommerce-mini-cart-item mini_cart_item">\n' +
            '\t\t\t\t\t<a href="https://dev.entry4sports.com/my-account/basket/?remove_item=338ab51c4c2c88328e001aab08fddcd2&#038;_wpnonce=e9121023ad" class="remove remove_from_cart_button" aria-label="Remove this item" data-product_id="24222" data-cart_item_key="338ab51c4c2c88328e001aab08fddcd2" data-product_sku="24222">&times;</a>\t\t\t\t\t\t\t\t\t\t\t<img width="300" height="300" \n' +
            '                                src="/resources/ai_cardlogo.png" \n' +
            '                                class="woocommerce-placeholder wp-post-image" \n' +
            '                                alt="Placeholder"\n' +
            '                              >Acme Winter Comp 1st Mar 2021<ul class="wc-item-meta">\n' +
            "                    <li>\n" +
            '                        <strong class="wc-item-meta-label">Athlete:</strong>Lily Calvert\n' +
            "                    </li>\n" +
            "                    <li>\n" +
            '                        <strong class="wc-item-meta-label">Event:</strong>Triple Jump\n' +
            "                    </li>\n" +
            '                 </ul>\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t<span class="quantity">1 &times; <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&pound;</span>0.00</span></span>\t\t\t\t</li>\n' +
            '\t\t\t\t\t\t\t\t<li class="woocommerce-mini-cart-item mini_cart_item">\n' +
            '\t\t\t\t\t<a href="https://dev.entry4sports.com/my-account/basket/?remove_item=fab33060900bb6ff33680fc93e92c948&#038;_wpnonce=e9121023ad" class="remove remove_from_cart_button" aria-label="Remove this item" data-product_id="23779" data-cart_item_key="fab33060900bb6ff33680fc93e92c948" data-product_sku="">&times;</a>\t\t\t\t\t\t\t\t\t\t\t<img width="300" height="300" \n' +
            '                                src="/entry/v5/css/images/qr.png" \n' +
            '                                class="woocommerce-placeholder wp-post-image" \n' +
            '                                alt="Placeholder"\n' +
            '                              >General Ticket\t\t\t\t\t\t\t\t\t\t<dl class="variation">\n' +
            '\t        <dt class="variation-Athlete"><strong>Athlete</strong>:</dt>\n' +
            '\t\t<dd class="variation-Athlete"><p>Lily Calvert</p>\n' +
            "</dd>\n" +
            "\t</dl>\n" +
            '\t\t\t\t\t<span class="quantity">1 &times; <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&pound;</span>0.00</span></span>\t\t\t\t</li>\n' +
            "\t\t\t\t\t</ul>\n" +
            "\n" +
            '\t<p class="woocommerce-mini-cart__total total">\n' +
            '\t\t<strong>Subtotal:</strong> <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&pound;</span>0.00</span>\t</p>\n' +
            "\n" +
            "\t\n" +
            '\t<p class="woocommerce-mini-cart__buttons buttons"><a href="https://dev.entry4sports.com/my-account/basket/" class="button wc-forward">View basket</a><a href="https://dev.entry4sports.com/checkout/" class="button checkout wc-forward">Checkout</a></p>\n' +
            "\n" +
            "\t\n" +
            "\n";

          data = data.replace(/src="/g, 'src="' + CONFIG.E4S_HOST + "");

          this.miniCartHtml = data;
        } else {
          this.miniCartHtml = (response as any).documentElement.innerHTML;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public getCartHandleClick() {
    // this.setRemoveFromCartBinding(false);
    this.loadMiniCartHtml().then(() => {
      window.setTimeout(() => {
        this.setRemoveFromCartBinding(true);
      }, 100);
    });
  }

  public closeCart() {
    this.$emit("closeCart");
  }

  public get getFrameClass() {
    if (this.$mq === VUE_MQ_SIZES.MOBILE.name) {
      return "cart-mini--iframe-mobile";
    }
    return "cart-mini--iframe-top-right";
  }

  public get getDivClass() {
    if (this.$mq === VUE_MQ_SIZES.MOBILE.name) {
      return "cart-mini--mobile";
    }
    return "cart-mini--top-right";
  }

  public processRemove(event: any, href: string) {
    console.log("processRemove() href: " + href);
    this.isLoading = true;
    Axios.get(href, {
      responseType: "document",
    })
      .then((response: any) => {
        this.anItemWasRemoved = true;
        this.getCartHandleClick();
      })
      .catch((response: any) => {
        messageDispatchHelper(
          "An error occurred.",
          USER_MESSAGE_LEVEL.ERROR.toString()
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public setRemoveFromCartBinding(add: boolean) {
    this.removeLinks = [];

    const eles = document.getElementsByClassName("remove_from_cart_button");
    if (eles.length > 0) {
      for (let i = 0; i < eles.length; i++) {
        const ele = eles[i] as HTMLLinkElement;
        if (add) {
          // ele.addEventListener("click", this.getCartHandleClick);

          if (ele) {
            //  grab
            this.removeLinks.push({
              href: ele.href,
            });

            const href = ele.href;
            ele.addEventListener("click", (event) => {
              this.processRemove(event, href);
            });

            // ele.style.display = "";
            ele.href = "#";
          }
        } else {
          // ele.removeEventListener("click", this.getCartHandleClick);
        }
      }
    }
  }

  public beforeDestroy(): void {
    console.log("CartMini.beforeDestroy()");
    this.setRemoveFromCartBinding(false);
    if (this.anItemWasRemoved) {
      this.$store.dispatch(
        ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME +
          "/" +
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_INITIALISE_EVENTS
      );
      this.$store.dispatch(
        ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME +
          "/" +
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_GET_CART
      );
    }
  }
}
