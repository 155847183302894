// import TestQaz from "../../../test/test-qaz.vue";

export const R4S_ROUTES = {
  R4S_OUTPUT_DISPLAY: "output",
  R4S_OUTPUT_DISPLAY_V2: "output-v2",
  R4S_OUTPUT_DISPLAY_V3: "output-v3",
  PUBLIC_RANKINGS: "public-rankings",
  PUBLIC_SCHEDULE: "public-schedule",
};

export default [
  {
    name: R4S_ROUTES.R4S_OUTPUT_DISPLAY,
    path: R4S_ROUTES.R4S_OUTPUT_DISPLAY + "/:compId/:outputId",
    component: () => {
      return import(
        /* webpackChunkName: "r4s-scoreboard-schedule-output-display" */ "../scoreboard-output/display/scoreboard-output-display-route.vue"
      );
    },
  },
  {
    name: R4S_ROUTES.R4S_OUTPUT_DISPLAY_V2,
    path: R4S_ROUTES.R4S_OUTPUT_DISPLAY_V2 + "/:compId/:outputId",
    component: () => {
      return import(
        /* webpackChunkName: "r4s-scoreboard-schedule-output-v2-display" */ "../scoreboard-output/display/v2/scoreboard-output-display-v2-route.vue"
      );
    },
  },
  {
    name: R4S_ROUTES.R4S_OUTPUT_DISPLAY_V3,
    path: R4S_ROUTES.R4S_OUTPUT_DISPLAY_V3 + "/:compId/:outputId/:showControls",
    component: () => {
      return import(
        /* webpackChunkName: "scoreboard-output-display-route-v3" */ "../scoreboard-output/display/v3/ScoreboardOutputDisplayRouteV3.vue"
      );
    },
  },
  {
    name: R4S_ROUTES.PUBLIC_RANKINGS,
    path: R4S_ROUTES.PUBLIC_RANKINGS + "/:compId/:eventGroupId",
    component: () => {
      return import(
        /* webpackChunkName: "rankings-public-route" */ "./output/public/rankings/rankings-public-route.vue"
      );
    },
  },
  // {
  //     name: R4S_ROUTES.PUBLIC_SCHEDULE,
  //     path: R4S_ROUTES.PUBLIC_SCHEDULE + "/:compId",
  //     component: () => {
  //         return import(/* webpackChunkName: "schedule-public-route" */ "./output/public/schedule/schedule-public-route.vue");
  //     }
  // }
];
