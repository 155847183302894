







































































































import Vue from "vue";
import Component from "vue-class-component";
import { messageDispatchHelper } from "../../user-message/user-message-store";
import { USER_MESSAGE_LEVEL } from "../../user-message/user-message-models";
import { Prop } from "vue-property-decorator";
import { LoginData } from "../login-data";
import { ValidationService } from "../../validation/validation-service";
import FieldValidationLabel from "../../validation/validation-field-lable.vue";
import { ValidationController } from "../../validation/validation-controller";
import {
  IObjectKeyType,
  IServerGenericResponse,
} from "../../common/common-models";
import { IValidationProp } from "../../validation/validation-models";

const validationService: ValidationService = new ValidationService();

@Component({
  name: "register",
  components: {
    FieldValidationLabel,
  },
})
export default class Register extends Vue {
  @Prop({ default: "" }) public readonly redirectedFrom: string;

  public userName: string = "";
  public email: string = "";
  public message: string = "";
  public isLoading: boolean = false;
  public registrationSuccess: boolean = false;

  public validationController: ValidationController =
    new ValidationController();

  public doRegister() {
    this.validate();
    if (!this.validationController.isValid) {
      return;
    }

    const loginData: LoginData = new LoginData();
    this.message = "";
    this.isLoading = true;
    loginData
      .register(this.userName, this.email)
      .then((response: IServerGenericResponse) => {
        if (response.errNo > 0) {
          this.message = response.error;
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }

        this.registrationSuccess = true;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        this.message = "Error occurred during registration.";
        return;
      })
      .finally(() => {
        this.isLoading = false;
        this.$emit("onRegistered");
      });
  }

  public doCancel() {
    this.$emit("onCancel");
  }

  public validate() {
    let errors: IObjectKeyType<IValidationProp> = {};
    if (this.userName.length === 0) {
      errors = validationService.addMessage("userName", "Required", errors);
    }

    if (!validationService.isEmailValid(this.email)) {
      errors = validationService.addMessage(
        "email",
        "Valid email required",
        errors
      );
    }

    this.validationController.setErrors(errors);
  }
}
