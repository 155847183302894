import {
  HtmlString,
  IBase,
  IBaseConcrete,
  IServerGenericPagingResponse,
  IsoDate,
  IsoDateTime,
} from "../common/common-models";
import { ICompShedRuleConfig } from "../athletecompsched/athletecompsched-models";
import { IStatusBase } from "./status/status-models";
import { IBuilderOptions, IBuilderOrgUsers } from "../builder/builder-models";
import { ILocation } from "../location/location-models";
import {
  ICompEventAgeGroupBase,
  ICompEventAgeGroupCoverageModel,
} from "../compevent/compeventschedule/compevent-age-group-coverage/compevent-age-group-coverage-models";
import { IClubCompInfo } from "../entry/v2/schools/clubCompInfo-models";

export type CompetitionStatusDescription = "Active" | "Inactive" | "Closed";
export type CompType = "TICKET" | "SCHOOLS" | "CLUBS" | "ALL";

export interface ICompetitionSummary extends IBaseConcrete {
  organiser: IBaseConcrete;
  location: IBaseConcrete;
  date: IsoDate; //  kind of legacy
  dates: IsoDate[]; //  this is really what we should code against.
}

export interface ITandC {
  link: string;
  description: string;
}

export enum CompetitionType {
  COUNTRY = 4,
  REGION = 3,
  COUNTY = 2,
  CLUB = 1,
  UNRESTRICTED = 0,
}

export const HEAT_ORDER = {
  SLOWEST: {
    label: "Slowest",
    value: "s",
  },
  FASTEST: {
    label: "Fastest",
    value: "f",
  },
};

export interface ICompetitionBase extends IBase {
  compId: number; //  "id" is coming up as "ID" in the json.
  compName: string;
  link: string;
  logo: string;
  clubCompInfo: IClubCompInfo;
  compAgeGroups: ICompEventAgeGroupBase[];
}

export interface ICompetition extends IBase {
  active: boolean;
  options: IBuilderOptions;
}

export interface ICompetitionInfo extends ICompetition, ICompetitionBase {
  areaid: number;
  locationid: number;
  entity: string;
  entityid: CompetitionType;
  name: string;
  tandclink: string;
  yearFactor: string;
  date: string;
  entriesClose: string;
  tandc: ITandC;
  teamid: number;
  indivEvents: boolean;
  teamEvents: boolean;
  compRules: ICompShedRuleConfig[];
  options: IBuilderOptions;
  compOrgId: number;
  information: string;
  hasSecureEvents?: boolean;
}

export interface ICompetitionDropDown extends ICompetitionInfo {
  humanReadableDate: string;
}

export interface ICompetitionLookupResponse {
  systemTime: string;
  clubInfo: string;
  compDates: ICompetitionInfo[];
}

export interface IServerCompetitionLookupResponse
  extends IServerGenericPagingResponse {
  data: ICompetitionLookupResponse;
}

export interface IPublicPermissions {
  builder: boolean;
  check: boolean;
  report: boolean;
}

export interface IWorkFlow extends IBase {
  description: string;
  nextid: number;
  previd: number;
  status: "PAID" | "RAISE" | "PAY";
}

export const STATUS_KEYS = {
  NO_STATUS: "NO_STATUS",
  PAY: "PAY",
  PAID: "PAID",
};

export type StatusKeyType = keyof typeof STATUS_KEYS;

export interface ICompetitionStatus extends IStatusBase {
  code: number;
  description: string;
  status: string;
  nextdescription: string;
  nextid: number;
  prevdescription: string;
  previd: number;
  wfid: number;
  value: number;
}

export interface IContactPublic extends IBase {
  email: string;
  userName: string;
  tel: string;
}

export interface ICompetitionSummaryBase {
  active: boolean;
  club: string;
  compName: string;
  compOrgId: number;
  compId: number;
  closedate: string;
  compAgeGroups: ICompEventAgeGroupBase[];
  opendate: string;
  location: ICompSummaryLocation;
  dates: string[];
  compDate?: string; //  It's coming up on v1 screen.
  areaname: string; //  Available to athletes in.
  saleenddate: string | null;
  access: string; //  User level
  reportAccess: boolean;
  reportId: string; //
  eventTypes: {
    indivEvents: boolean;
    teamEvents: boolean;
    tickets: boolean;
  };
  logo: string;
  options: IBuilderOptions; //  check box   registered, unreg
  organisers: IBuilderOrgUsers[];
}

export interface ICompetitionSummaryPublic
  extends IBase,
    ICompetitionBase,
    ICompetitionSummaryBase {
  clubId: number;
  reportLastChecked: string; //  Way to see if reports running/failing
  clubCompInfo: IClubCompInfo;
  isClosed: boolean;
  location: ILocation;
  compDate?: string; //  It's coming up on v1 screen.
  saleenddate: string;
  entries: {
    athletes: number;
    team: number;
    indiv: number;
    isTeam: boolean;
  };
  options: IBuilderOptions; //  check box   registered, unreg
  permissions: IPublicPermissions;
  status?: ICompetitionStatus;
  information: string; //  html
  termsConditions: string; //  html
  newsFlash: string; //  html
  compRules: ICompShedRuleConfig[];
}

export interface ICompetitionOptionsV2 {
  sockets: boolean; //  ???
  useTeamBibs: boolean;
  scoreboard: {
    image: string;
  };
  seqEventNo: boolean;
  disabled: boolean;
  stripeMandatory: boolean;
  resultsAvailable: boolean;
  paymentCode: string;
  homeInfo: string;
  shortCode: string;
  priority: {
    required: boolean;
    code: string;
    dateTime: IsoDateTime;
    message: string;
  };
  bibNos: 1;
  bibSort1: "surname" | "firstname" | "dob"; //  TODO...type.
  bibSort2: "surname" | "firstname" | "dob";
  bibSort3: "surname" | "firstname" | "dob";
  heatOrder: unknown;
  stadium: string;
  adjustEventNo: unknown;
  pfTargetDirectory: string;
  athleteType: unknown;
  tickets: {
    enabled: boolean;
  };
  contact: {
    email: string;
    id: number;
    tel: string;
    userName: string;
    visible: boolean;
  };
  cardInfo: {
    enabled: boolean;
    availableFrom: string;
  };
  subscription: {
    enabled: boolean;
    timeCloses: string;
    organiserMessage: string;
    e4sMessage: string;
    refunded: unknown;
    process: boolean;
  };
  checkIn: {
    enabled: boolean;
    checkInDateTimeOpens: string;
    defaultFrom: number;
    defaultTo: number;
    qrCode: boolean;
    text: string;
    terms: string;
    useTerms: boolean;
    seedOnEntries: boolean;
  };
  school: boolean;
  cheques: {
    allow: boolean;
    ends: IsoDateTime;
  };
  allowAdd: {
    unregistered: boolean;
    registered: boolean;
  };
  timetable: string;
  helpText: {
    schedule: string;
    teams: string;
    cart: string;
  };
  showTeamAthletes: boolean;
  singleAge: boolean;
  stopReport: boolean;
  showAthleteAgeInEntries: boolean;
  report: {
    summary: boolean;
    athletes: boolean;
    ttathletes: boolean; //  TODO
    ttentries: boolean;
    individual_entries: boolean;
    teams: boolean;
    subscriptions: boolean;
    orders: boolean;
    events: boolean;
  };
  athleteSecurity: {
    areas: unknown[];
    clubs: unknown[];
    onlyClubsUpTo: unknown;
  };
  ui: {
    enterButtonText: string;
    entryDefaultPanel: "SCHEDULE" | string;
    ticketComp: number;
    ticketCompButtonText: string;
  };
  athleteQrData: boolean;
  disabledReason: string;
  laneCount: number;
  compLimits: {
    athletes: number;
    entries: number;
    teams: number;
  };
  cancelEvent: {
    hrsBeforeClose: number;
    refund: {
      allow: boolean;
      type: "E4S_FEES" | string;
    };
    credit: {
      allow: boolean;
    };
  };
  autoEntries: {
    selectedTargetComp: IBaseConcrete;
    targetable: {
      allowedSources: unknown[];
      enabled: boolean;
    };
  };
  level: unknown;
  dates: IsoDate[];
  categoryId: number;
  saleEndDate: IsoDate;
}

export interface ICompetitionV2 extends IBaseConcrete {
  logo: string;
  date: IsoDate;
  closeDate: IsoDateTime;
  openDate: IsoDateTime;
  modified: string; //  weird format
  link: string;
  active: boolean;
  options: IBuilderOptions;
  text: {
    summary: string;
    information: string;
    termsConditions: string; //  html
    newsFlash: string; //  html
  };
}

export interface ICompetitionCountsV2 {
  eventCnt: number;
  indivCnt: number;
  teamCnt: number;
  totalCnt: number;
  uniqueCnt: number;
  waitingEntries: number;
  relayCnt: number;
  relayAthleteCnt: number;
  relayUniqueAthleteCnt: number;
  totalUniqueAthleteCnt: number;
  force: number;
}

export interface ICompetitionLocationV2 {
  id: number;
  name: string;
  website: string;
  address1: string;
  address2: string;
  town: string;
  postcode: string;
  directions: string;
}

export type CompV2StatusType = "PAID" | "RAISE" | "PAY";

export interface ICompetitionSummaryPublicV2 {
  competition: ICompetitionV2;
  organiser: IBaseConcrete;
  organisers: IBuilderOrgUsers[];
  location: ICompetitionLocationV2;
  counts: ICompetitionCountsV2;
  status: ICompetitionStatus;
}

export interface ICompetitionSmallV2 {
  id: number;
  name: string;
  dates: IsoDate[];
  location: IBaseConcrete;
}

export interface ICompetitionMoreInfoV2 {
  ageGroups: Record<string, ICompEventAgeGroupCoverageModel>;
  text: {
    checkinTerms: string;
    checkinText: string;
    e4sNotes: string;
    emailText: string;
    homeInfo: string;
    information: HtmlString;
    newsFlash: string;
    termsConditions: HtmlString;
  };
}

export type ICompSummaryLocation = Omit<ILocation, "contact">;
export type ICompSummaryEntries = {
  eventCount: number;
  teamEventCount: number;
  indivEventCount: number;
  indiv: number;
  waitingCount: number;
  uniqueIndivAthletes: number;
  athletes: number;
};
export interface ICompSummaryStatus {
  id: number;
  compid: number;
  description: string;
  status: StatusKeyType;
  invoicelink: string;
  reference: string;
  notes: string;
  value: number;
  code: number;
  wfid: number;
  previd: number;
  prevdescription: string;
  nextdescription: string;
  nextid: number;
}

export interface ICompSummaryPermissions extends IPublicPermissions {
  adminMenu: boolean;
}

export interface ICompSummary extends ICompetitionSummaryBase {
  clubCompInfo: IClubCompInfo | {};
  location: ICompSummaryLocation;
  dates: string[];
  saleenddate: string | null;
  entries: ICompSummaryEntries;
  options: IBuilderOptions;
  permissions: ICompSummaryPermissions;
  status: ICompSummaryStatus;
  information: "" | null; //  html
  compRules: ICompShedRuleConfig[] | null;

  IndoorOutdoor: string;
  lastentrymod: string;
  waitingrefunded: null;
  resultsAvailable: 0 | 1;
  today: string;
  systemtime: string;
  daysToComp: number;
  daysToClose: number;
  loccontact: string;
  ctcid: number | null;
  r4s: null;
  maxathletes: null;
  maxteams: null;
  maxmale: null;
  maxfemale: null;
  maxagegroup: null;
  uniqueevents: null;
  singleagegroup: null;
  ctc: {
    ctcid: null;
    maxathletes: null;
    maxteams: null;
    maxmale: null;
    maxfemale: null;
    maxagegroup: null;
    uniqueevents: null;
    singleagegroup: null;
  };
  link: string;
  areaid: number;
  entityid: CompetitionType | null;
  yearFactor: string | number;
  teamid: string | number | null;
}
