




















import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import { UiVersion } from "./config-app-models";
import { LAUNCH_ROUTES_PATHS_V2_BASE } from "../launch/v2/launch-routes-v2";
import { useRouter } from "../router/migrateRouterVue3";
import { RawLocation } from "vue-router";
import {LAUNCH_ROUTES_PATHS} from "../launch/launch-routes"

export default defineComponent({
  name: "config-version-switch",
  components: {},
  props: {
    currentUiVersion: {
      type: String as PropType<UiVersion>,
      default: () => {
        const uiVersion: UiVersion = "v1";
        return uiVersion;
      },
    },
  },
  setup(props: { currentUiVersion: UiVersion }, context: SetupContext) {
    const routerInternal = useRouter();

    const v1Path = computed(() => {
      return "/#/" + LAUNCH_ROUTES_PATHS.SHOW_ENTRIES;
    });

    const v2Path = computed(() => {
      return "/" + LAUNCH_ROUTES_PATHS_V2_BASE;
    });

    function goto(uiVersion: UiVersion) {
      let location: RawLocation = {

        path: uiVersion === "v1" ? v1Path.value : v2Path.value,
      };
      routerInternal.push(location);
    }
    return { v1Path, v2Path, goto };
  },
});
