import {IServerPagingResponseList, IServerResponse} from "../common-models";
import https from "../https";
import {Resource} from "./resource";

export interface IListParams {
    startswith: string;
    pagenumber: number;
    pagesize: number;
    sortkey: string;
}

export class ResourceData<T extends Resource> {

    /**
     * @param endpoint      E.g. /v5/org
     */
    constructor(private endpoint: string) {}

    public getEndPoint(): string {
        return this.endpoint;
    }

    public create(item: T): Promise<IServerResponse<T>> {
        return https.post( this.endpoint, item) as any as Promise<IServerResponse<T>>;
    }

    public read(id: number): Promise<IServerResponse<T>> {
        return https.get( this.endpoint + "/" + id) as any as Promise<IServerResponse<T>>;
    }

    public update(item: T): Promise<IServerResponse<T>> {
        return https.put( this.endpoint, item) as any as Promise<IServerResponse<T>>;
    }

    public delete(id: number): Promise<IServerResponse<T>> {
        return https.delete( this.endpoint + "/" + id) as any as Promise<IServerResponse<T>>;
    }

    public list(listParams: IListParams): Promise<IServerPagingResponseList<T>> {
        return https.get( this.endpoint, {
            params: {...listParams}
        }) as any as Promise<IServerPagingResponseList<T>>;
    }

    public getListParamsDefault( searchKey: string ): IListParams {
        return {
            startswith: searchKey,
            pagenumber: 1,
            pagesize: 5,
            sortkey: "name"
        } as IListParams;
    }
}
