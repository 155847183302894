import {IPermission, IPermLevel} from "./permission-models";
import {IPermissionStoreState} from "./permission-store";
import {OrgService} from "../../org/org-service"

const orgService: OrgService = new OrgService();

export class PermissionsService {

    public factoryStoreState(): IPermissionStoreState {
        return {
            rolesLoading: false,
            roles: {},
            permLevelsLoading: false,
            permLevels: []
        } as IPermissionStoreState;
    }

    public factoryPermission(): IPermission {
        return {
            id: 0,
            role: {
                id: -1,
                name: ""
            },
            org: orgService.factory(),
            comp: {
                id: -1,
                name: ""
            },
            permLevels: [] as IPermLevel[]
        } as IPermission ;
    }
}
