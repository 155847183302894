















import { defineComponent, SetupContext } from "@vue/composition-api";

export default defineComponent({
  name: "card-generic-v2",
  components: {},
  props: {
    text: {
      type: String,
      default: () => {
        return "OK";
      },
    },
    buttonType: {
      type: String,
      default: () => {
        return "primary";
      },
      validator: function (value: "primary" | "secondary" | "tertiary") {
        return ["primary", "secondary", "tertiary"].indexOf(value) !== -1;
      },
    },
  },
  setup(props: { text: string }, context: SetupContext) {
    function buttonClicked() {
      console.log("button-generic-v2.buttonClicked()");
      context.emit("click");
    }
    return { buttonClicked };
  },
});
