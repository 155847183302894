//  import {IEventE4S} from "../event/event-models";

import { IAgeGroup, IAgeGroupBase } from "../agegroup/agegroup-models";
import {
  EventGroupIdNumber,
  HtmlString,
  IBase,
  IBaseConcrete,
  IObjectKey,
  IServerBaseResponse,
  IServerGenericResponse,
  PaidType,
} from "../common/common-models";
import { IUniqueEventDisplay } from "../event/event-models";
import { IAthleteSummary } from "../athlete/athlete-models";
import { IClubCompInfo } from "../entry/v2/schools/clubCompInfo-models";
import { IEventDef } from "../eventdef/eventdef-models";

import { IPerfInfo } from "./pb/v3/edit-pb-v3-models";

export interface IRowOptions {
  autoExpandHelpText: boolean;
  showPB: boolean;
  showPrice: boolean;
  showEntryCount: boolean;
  hideOnDisable?: boolean;
}

// export interface IEoptionsEvent {
//     e: number;                         //  This is the event id
// }

export interface ICompUnique {
  ids: number[]; //  TODO split this "16,17,14,15"
  text: string; //  ui description of rule "Only 1 of 800m, 1500m"
  type: string; //  DAY/ COMP
}

export interface ICompShedRuleOptions {
  maxEvents: number; //  Max events in 1 day.
  maxField: number; //  Max number of field events an athlete can do, currently across comp as not had a multi day.
  maxTrack: number; //  Max number of track events the athlete can do ( see maxField note ).
  maxCompEvents: number; //  Max events across competition
  maxCompField: number; //  Max Field events across competition
  maxCompTrack: number; //  Max Track events across competition
  unique: ICompUnique[]; //  Same as event unique, disable any others with id's listed.
  maxExcludedEvents: number; //  INDIV SCHEDULE.  What is the max number of events that a user can enter that are
  //  excluded from "count". @see eoptions\ ceoptions.excludeFromCntRule
  maxTeamEvents: number; //  TEAM EVENTS.  This is how many team events can an athlete enter.
  // type: ""; //  Not used
}

export interface ICompShedRuleConfig {
  id: number;
  agid: number;
  options: ICompShedRuleOptions;
}

export interface IAgeGroupUpScale extends IAgeGroupBase {
  maxCount: number; //  E.g. U11 race, but U10 can enter.  This is max number that can enter.
  minCount: number; //  E.g. U11 race, but U10 can enter.  This is min number that can enter.
  //  Note 1a.  The minActualAgeGroupCount + maxUpScaleCount must be >= min and not exceed max.
  //  ...actually...ALL upscales added up.
}

export type ShowFormType = "DEFAULT" | "CONTACT_SIMPLE" | "SCHOOL" | "LEAGUE";

// teamNameFormatTypes
export const TEAM_NAME_FORMAT_TYPES = {
  ENTITY: "{{entity}}",
  AREA: "{{area}}",
  CLUB: "{{club}}",
  EVENT: "{{eventname}}",
  EVENT_GROUP: "{{eventgroup}}",
  GENDER: "{{gender}}",
  AGE_GROUP: "{{agegroup}}",
};
// UNIQUE: "{{unique}}",

export interface IFormRow {
  position: number; //  The order in which to display
  eventDef: IEventDef; //  Event Definition ID.
  dateTime: string; //  ISO date time
  athlete: IAthleteSummary;
}

export type TEAM_PRICE_TYPE = "" | "ATHLETE";

export interface IEventTeam {
  min: number; //  Minimum athletes for this team
  max: number; //  Max ath...for this team.
  minTargetAgeGroupCount: number; //  Min "target" age Group count, e.g. U11 which allows upscale, but u must
  //  have this number of U11s.
  maxOtherAgeGroupCount: number; //  Max "other" age Group count, e.g. U11 which allows upscale, but u can
  //  only have this number of non target age groups, e.g. u10s, U9s, etc.
  maxTeamsForAthlete: number; //  In a given event, E.g. "Relay Event", how many teams can an athlete enter.
  teamPositionLabel: string;
  teamSubstituteLabel: string;
  maxEventTeams: number; //  Max that can be created.
  currCount: number; //  Used for team event, e.g. 4 x 100m relay.  Don't confuse with Comp Teams.
  singleClub: boolean; //  If true, all athletes in event must be from same club.
  //  TODO int, which form to load        //  E.g. 1 = normal, 2 = Dyn form, 3 = School.
  showForm: boolean; //  If true, don't show athletes, show form.
  formType?: ShowFormType;
  price: TEAM_PRICE_TYPE; //  ATHLETE = price is per athlete, else ignore...is TEAM.
  teamNameFormat: string; //  format for auto team name generation, {{club}} {{area}} {{event}} {{gender}}, etc.
  // dynamicallyAddRows: boolean;        4
  formRows: IFormRow[];
  mustBeIndivEntered: boolean; //  Only allow athletes that have already entered as individuals.
  disableTeamNameEdit?: boolean;
}

//  TODO this model need to be extended for ceoptions...ageGroupUpScale can only exist on CE
//  TODO Check "schedule" but think this is deprecated, as u can upscale anything, so
//  TODO should not be buried in "Teams"
export interface IEventTeamCe extends IEventTeam {
  ageGroupUpScale: IAgeGroupUpScale[]; //
}

export interface ISecondarySpend {
  isParent: boolean;
  parentCeid: number;
  mandatoryGroupId: number;
  alwaysShow: boolean;
}

export interface IOptionsDefault {
  helpText: string; //  Any text to display to user about event.
  warningMessage: string; //  Any warning to display to user about event, e.g. you don't have access etc.
  rowOptions?: IRowOptions;
  unique: IUniqueEventDisplay[]; //  If an event clicked, disable all other ids in this list.
  uniqueEventGroups: IEventGroupSelect[]; //  If an event clicked, disable all other ids in this list.
  eventTeam: IEventTeam;
  excludeFromCntRule: boolean; //
  isTeamEvent: boolean; //  If true, then becomes team event and system will look at IEventTeam.
  maxInHeat: number; //  What is the maximum number of athletes per heat.
  unregisteredAthletes: boolean; //  fine tuning on comp where reg\ unreg allowed.
  registeredAthletes: boolean;
}

export interface IEoptions extends IOptionsDefault {
  min: number; //  Min\ max control PB entry. Simple as that.  @see IAthleteCompSched.split
  max: number; //  IAthleteCompSched.split is for a comp, e.g. -3 means u CAN'T enter PB of more than 3, +3 means u must be more than 3.
  unit: string; //  DEPRECATED
  class: string; //  Ignore.  But this is disability classifications.
  gender: boolean; //  HACK!  do not use this for anything else.
  multiEventOptions: IMultiEventEventDefSelect[];
}

export interface IUnique {
  e: number;
  ce: number;
}

export interface ISecurity {
  clubs?: IBase[];
  counties?: IBase[];
  regions?: IBase[];
}

export interface IEventCheckIn {
  from: number; //  Mins from event time when opens.
  to: number; //  Mins from event time when closes.
}

export interface IHeatInfo {
  useLanes: "A" | "E" | "O"; //  A = All Lanes,  E = Even Lanes, O = Odd Lanes.
  heatDurationMins: number; //   0 - 99 mins, whole number.
}

export interface IEventGroupInfo {
  reportInfo: string; //  Around 500 chars free text.  Org can write some
  //  text that gets printed out on bottom of track card.
  trialInfo: string; //  Around 100 chars. Brief space for explaining how many trials.
}

export interface IEventGroupSelect {
  id: EventGroupIdNumber; //  EGID (Event Group ID)
  name: string;
  eventNo: number;
  isMultiEvent?: boolean; //  E.g. Quad, Pent, Hept, etc.
}

export interface IEventSeed {
  gender: boolean;
  age: boolean;
  type: "O" | "H"; //  O=Open (default), H = Heats.
  qualifyToEg: IEventGroupSelect;
  firstLane: number;
  laneCount: number;
  waiting: boolean; //  Waiting for Seeding.
}

//  <IMultiEvent>
/**
 * This is the child event, e.g. 100m, 200m, 300m, etc. that belongs to a...
 */

//  TODO this is a bit of a mess, as we have "multiEvents" and "multiEventInfo" and "multiEvent" and "multiEventId"
//  ...a parent.  E.g. Pentathlon, Heptathlon, Quad, etc.

export const ScoringSystems = {
  Calculated: -1,
  Table: 1,
};
type ScoringSystemType = typeof ScoringSystems;
export type ScoringSystemName = keyof ScoringSystemType;
export type ScoringSystemValue = ScoringSystemType[keyof ScoringSystemType];

export const ScoringSystemOptionsMap: Record<ScoringSystemName, ScoringSystem> =
  {
    Calculated: {
      id: -1,
      name: "Calculated",
    },
    Table: {
      id: 1,
      name: "Table",
    },
  };

export type ScoringSystem = IBaseConcrete;

// export interface IChildEvent {
//   egId: EventGroupIdNumber | 0; //  EGID (Event Group ID), when "adding" default, set to 0.
//   eventId: number; //  Event ID
//   name: string;
//   gender: GenderType;
//   ceId: number | 0; //  Comp Event ID
// }

export interface IMultiEventEventDefSelect {
  eventDefId: number;
  name: string;
}

export interface IMultiEventEventDef extends IMultiEventEventDefSelect {
  egId: EventGroupIdNumber | 0; //  EGID (Event Group ID), when "adding" default, set to 0.
}

export interface IMultiEventOptions {
  // scoringSystem: ScoringSystem; // How are points calculated for Pent, Hept, Quad etc e.g. -1 = calculated, 1 = table
  childEvents: IMultiEventEventDef[];
}
//  </IMultiEvent>

export interface ICeoptions extends IOptionsDefault {
  xeText: string; //  extra text...for entries list (param query grid)/.
  xbText: string; //  extra text for the basket.`
  xiText: string; //  extra text for the schedule.
  xrText: string; //  extra text for report.
  maxAgeGroupCnt: number; //  E.g. 4 x 200 U13, but also available to U11s, but
  //  must have a min of 2 U13.
  trials: string; //  Purely text for the track\ field report cards.
  cardNotes: string; //
  secondarySpend: ISecondarySpend;
  eventTeam: IEventTeamCe;
  ageGroups: IAgeGroupBase[]; //  Array of Age objects containing just ID.  E.g. 200 U10,  but wew will let these age groups enter.
  //  N.B. do not get confused with IEventTeam.upscale, this just defines, e.g. U10 shot put, i.e. NOT a
  //  team event....just who can enter.  To control upscale for an event, set in IEventTeam.upscale.
  availableFrom: string; //  Only becomes available in "sched" when date reached.
  availableFromStatus: number; //  Status  -1 = Hidden, 0 = Closed
  availableTo: string; //  Removed from "sched" at this date, but if displaying, show expiry date.
  availableToStatus: number; //  Status  -1 = Hidden, 0 = Closed
  hideOnDisable: boolean; //  TODO does for secondary spend, but check if hides when any event disabled by rule.
  security: ISecurity; //  Restrict USERS, e.g. club, county reps
  athleteSecurity: ISecurity; //  Restrict ATHLETES to these clubs, counties...
  isNotAvailable?: boolean; //  Server can override access, E.g. we are displaying "Club" only events to user.
  singleAge: boolean; //  If comp singleAge is set to true, if athlete upscales on an event, means they need to upscale
  //  on every event they enter.  BUT, use this as exception to rule.
  athleteEventCol: string;
  mandatoryPB: boolean;
  checkIn: IEventCheckIn;
  heatInfo: IHeatInfo;
  eventGroupInfo: IEventGroupInfo;
  rowOptions: IRowOptions;

  //  TODO move to IEventGroupInfo Options, which we don't have.
  seed: IEventSeed;
  uniqueEventGroups: IEventGroupSelect[]; //  If an event clicked, disable all other ids in this list.
  entriesFrom: IEventGroupSelect;
  includeEntriesFromEgId: IEventGroupSelect;
  multiEventOptions: IMultiEventOptions;
}

export type EventType = "T" | "F" | "M" | "R" | "X";
export type EventTypeName =
  | "Track"
  | "Field"
  | "Multi"
  | "Road"
  | "Cross Country";
export const EventTypeDictionary: Record<EventType, EventTypeName> = {
  T: "Track",
  F: "Field",
  M: "Multi",
  R: "Road",
  X: "Cross Country",
};

//  Track = anything not field, road race, steeple, XC, whatever.
export type EventTypeNameGeneric = "Track" | "Field";

export enum EVENT_TYPE {
  TRACK = "T",
  FIELD = "F",
  MULTI = "M",
  ROAD = "R",
  CROSS_COUNTRY = "X",
  ALL = "",
}

export enum EVENT_UOM_TYPE {
  TIME = "T",
  DISTANCE = "D",
  HEIGHT = "H",
  POINTS = "P",
}

export type EventUomType = "T" | "D" | "H" | "P";

export interface IEventUom {
  pattern: string;
  text: string;
  short: string;
  uomType: EventUomType; //
}

export interface IPrice {
  id: 0;
  curPrice: number; //  Price calc'd based on early bird date. E.g. before might be £7, and after £10.
  curFee: number; //  the E4S fee
  stdPrice: number; //  E.g. £10
  saleDate: string;
  salePrice: number; //  Early bird price, e.g. £7
  actualPrice: number; //  Current price including discounts. N.B. as stuff gets added to cart may change, so
  //  for ui, slightly irrelevant, else have to recalc entire cart.
}

export interface IUser {
  userId: number;
  userName: string;
  userEmail: string;
}

export interface ICompEventTeamAthlete {
  athlete: string;
  pos: number;
  urn: number;
}

export interface ICompEventEntryCount {
  entryPosition: number; //  Position user is when they "I want to enter the 100m"
  paidCount: number; //
  unpaidCount: number; //  Sitting in carts
  totalCount: number;
  entryCreated: string; //  ISO
}

export interface IOrder {
  creditValue: number; //  what we can credit user
  dateOrdered: string;
  e4sLineValue: number;
  isCredit: boolean; //  Has a credit been done.
  isRefund: boolean; //  Has been refunded.
  orderId: number; //  WC order#
  productId: number; //  we know what this is
  refundValue: number; //  value user can be refunded.
  wcLineValue: number; //  Value they actually paid.
}

/**
 * This will eventually become a top level object.
 */
export interface IEventGroupObject extends IBaseConcrete {
  // id: number;                  //  Table Id
  // eventNo: number;             //  The number for TimeTronics, e.g.  200m is event 1, 400m is event 2, etc.
  // name: string;                //  What is currently call Event Group Name E.g. 200m U17 finals

  isOpen: boolean; //  Shut the whole scheduled event group down. (Also needed on indiv event so
  //  you could shut down just the Girls U15...?)

  maxAthletes: number;
  maxInHeat: number;
  trials: string; //  Free form text for official.  E.g. Athlete allowed 3 trials, 4 - 6 for top 8 athletes, etc.
  reportInfo: string; //  Free text.  any info for track and Field card.
  seed: {
    age: boolean; //  Default = false
    gender: boolean; //  Default = false
    type: "O" | "H"; //  O = Open (default), H = Heats.
  };
}

export interface IAthleteCompSched {
  ceid: number;
  ageGroupId: number;
  ageGroupName: string;
  ageGroup: IAgeGroup;
  athleteid: number;
  description: string;
  startdate: string;
  tf: EVENT_TYPE; // T  F  Track or Field
  saleprice: number; // 7.00
  price: IPrice;
  saledate: string;
  name: string;
  eventid: number;
  multiid: number;
  entryId: number; //  If user has enteed, greater than zero.
  Name: string;
  IsOpen: number; //  If max athletes reached, or event organiser closes event
  split: number; //  For a comp, e.g. -3 means u CAN'T enter PB of more than 3, +3 means u must be more than 3.
  maxgroup: number; //  E.g. "100mu15gfi" find all other events with this ID,
  //  sum all current athletes, reaches max?
  eventGroup: string; //  What is the "event" called, e.g. the Girls and Boys might both be running the "200m"
  maxathletes: number; //  E.g. -1 = a final, u can't enter. 0 = infinity.  25 = only 24

  ceoptions: ICeoptions;

  eventGroupObject: IEventGroupObject;

  eoptions: IEoptions;
  entered: boolean;
  paid: PaidType; //  0 = not paid, 1 = have paid, 2 = cheque, 3 = auto free price.
  pb: null | number; //  Legacy crap
  // pbInfo: {
  //   pb: number;
  //   pbText: string;
  // };
  perfInfo: IPerfInfo;
  uomType: EVENT_UOM_TYPE;
  uomDisplay: string; //  E.g./  mtrs, secs, hrs.  a unit of "PB", display purposes only.
  uomformat: string; //  E.g. ss.SS
  // uom: IEventUom[];
  firstName: string;
  surName: string;
  URN: string;
  user: IUser;
  club: string;
  clubId: number;

  // prodId: number;
  // prodPrice: number;
  teamId: number;
  timeSelected: string;
  ageInfo?: IAgeInfo;
  athletes: ICompEventTeamAthlete[] | undefined;

  userEventAction: IEventAction;

  entrycnt: number; //  Number of paid entries for that event
  entryInfo: ICompEventEntryCount;
  order: IOrder;

  //  Where have these 2 come from. They are in /e4s/v5/admin/user?id=21378  then "orders"
  compName?: string;
  compid?: number;
}

export enum RULE_TYPE {
  EVENT = "EVENT",
  COMP = "COMP",
  // COMP_MAX_RULE = "COMP_MAX_RULE",
  // COMP_MAX_IN_DAY_RULE = "COMP_MAX_IN_DAY_RULE",
  // COMP_MAX_TRACK_RULE = "COMP_MAX_TRACK_RULE",
  // COMP_MAX_FIELD_RULE = "COMP_MAX_FIELD_RULE",
  PB = "PB",
  NONE = "",
}

export interface IAthleteCompSchedRuleEvent extends IAthleteCompSched {
  ruleIsDisabledBy: boolean;
  ruleMessage: string;
  userSelected: boolean;
  ruleType: RULE_TYPE;
  post_date: string; // e.g. "2022-01-22 01:00:45"
}

export interface IPbKey {
  [key: number]: number; // the key is the event id.
}

export interface IAgeInfo {
  ageGroup: string;
  ageGroupId: number;
  currentAge: number;
  vetAgeGroup: string;
  vetAgeGroupId: number;
}

export interface IAthleteCompSchedResponse extends IServerBaseResponse {
  events: IAthleteCompSched[];
  ageInfo: IAgeInfo;
  rules: ICompShedRuleConfig;
  schedInfo: ISchedInfo;
  subscriptionText: string;
}

export const ATHLETE_PB_VALIDATION_MESSAGES = {
  PB_BELOW_MIN: {
    NAME: "PB_BELOW_MIN",
    MESSAGE: "PB is below standard min.",
  },
  PB_ABOVE_MIN: {
    NAME: "PB_ABOVE_MIN",
    MESSAGE: "PB is above standard min.",
  },
  TOO_MANY_SEPARATORS: {
    NAME: "USER_SEPARATOR_COUNT_TOO_HIGH",
    MESSAGE: "Too many separators entered..",
  },
  INVALID_SEPARATOR: {
    NAME: "INVALID_SEPARATOR",
    MESSAGE: "Invalid separators.",
  },
  NOT_A_NUMBER: {
    NAME: "NOT_A_NUMBER",
    MESSAGE: "Not a number.",
  },
  ONLY_NUMBERS: {
    NAME: "ONLY_NUMBERS",
    MESSAGE: "Only enter numbers.",
  },
  ONLY_POSITIVE_NUMBERS: {
    NAME: "ONLY_POSITIVE_NUMBERS",
    MESSAGE: "Only positive numbers.",
  },
  PB_BELOW_SPLIT: {
    NAME: "PB_BELOW_SPLIT",
    MESSAGE: "Below split.",
  },
  PB_ABOVE_SPLIT: {
    NAME: "PB_ABOVE_SPLIT",
    MESSAGE: "Above split.",
  },
};

export interface IOrderSummary {
  athleteIds: IObjectKey;
  athleteNames: IObjectKey;
  athleteCount: number;
  eventCount: number;
  totalPrice: number;
}

export interface IProdIdResponse {
  productId: number;
  productPrice: number;
  entryInfo: ICompEventEntryCount;
}

export interface IProdIdServerResponse extends IServerGenericResponse {
  data: IProdIdResponse;
  meta: {
    clubCompInfo: IClubCompInfo;
  };
}

export interface IPbMultiItem {
  multiid: number;
  eventid: number;
  startdate: string;
  eventname: string;
  uom: IEventUom[];
  eoptions: IEoptions;
  tf: EVENT_TYPE;
  pb: null | number;
  uomType: EVENT_UOM_TYPE;
}

export interface ISchedInfoDetail {
  title: string;
  body: HtmlString;
}

export interface ISchedInfo {
  title: string; //  Comp Athlete details.
  shortDescription: string; //
  autoExpand: boolean | number; //  Functionality changed, if there are some schedInfoDetail(s), then just display
  showLinks: boolean;
  schedInfoDetails: ISchedInfoDetail[];
  rowOptions: IRowOptions;
}

export interface IEventAction {
  id: string;
}

export const IEventActions = {
  NONE: {
    id: "NONE",
  } as IEventAction,
  SWITCH_EVENT: {
    id: "SWITCH_EVENT",
  } as IEventAction,
};
