




























































































































































































import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import { ICompetitionSummaryPublic } from "../../../../competition/competition-models";
import { RawLocation } from "vue-router";
import { LAUNCH_ROUTES_PATHS } from "../../../../launch/launch-routes";
import { useRouter } from "../../../../router/migrateRouterVue3";
import ButtonGenericV2 from "../../../../common/ui/layoutV2/buttons/button-generic-v2.vue";
import CompHeaderLogoCountsV2 from "./comp-header-logo-counts-v2.vue";
import CompHeaderNameLocationV2 from "./comp-header-name-location-v2.vue";
import ButtonGotoCompV2 from "../../../../common/ui/layoutV2/buttons/button-goto-comp-v2.vue";
import ButtonGotoResultsV2 from "../../../../common/ui/layoutV2/buttons/button-goto-results-v2.vue";
import { CompetitionService } from "../../../../competition/competiton-service";
import { getE4sStandardHumanDateTimeOutPut } from "../../../../common/common-service-utils";
import { isAfter, parse } from "date-fns";
import { CompMoreInfoSection } from "./moreinfo/comp-more-info-models";
import InputWithButton from "../../../../common/ui/layoutV2/fields/InputWithButton.vue";
import FieldSelectV2 from "../../../../common/ui/layoutV2/fields/field-select-v2.vue";
import E4sLogoSvg from "../../../../common/ui/svg/E4sLogoSvg.vue";
import * as CompetitonServiceV2 from "../../../../competition/v2/competiton-service-v2";
import { useAuthStore } from "../../../../auth/useAuthStore";
import { LAUNCH_ROUTES_PATHS_V2 } from "../../../../launch/v2/launch-routes-v2";
import { useCompetitionSummaryPublicController } from "../../../../competition/v2/useCompetitionSummaryPublicController";
import CounterV2 from "../../../../common/ui/layoutV2/counter/counter-v2.vue";
import CompetitionEntryCounts from "../../../../competition/v2/CompetitionEntryCounts.vue";
import CompRestrictedV2 from "../../../../competition/restricted/CompRestrictedV2.vue";
import ModalV2 from "../../../../common/ui/layoutV2/modal/modal-v2.vue";
import StatusContainer from "../../../../competition/status/status-container.vue";
import { useCompStoreState } from "../../public-comps-store";
import { VUE_MQ_SIZES } from "../../../../index";
import ButtonGotoGenericV2 from "../../../../common/ui/layoutV2/buttons/button-goto-generic-v2.vue";

export default defineComponent({
  name: "PublicCompCardV2",
  computed: {
    VUE_MQ_SIZES() {
      return VUE_MQ_SIZES;
    },
  },
  components: {
    ButtonGotoGenericV2,
    StatusContainer,
    ModalV2,
    CompRestrictedV2,
    CompetitionEntryCounts,
    CounterV2,
    E4sLogoSvg,
    FieldSelectV2,
    InputWithButton,
    ButtonGotoResultsV2,
    ButtonGotoCompV2,
    CompHeaderNameLocationV2,
    CompHeaderLogoCountsV2,
    ButtonGenericV2,
  },
  props: {
    competitionSummaryPublic: {
      type: Object as PropType<ICompetitionSummaryPublic>,
      required: true,
    },
    showGoToComp: {
      type: Boolean,
      default: true,
    },
    showMoreInfo: {
      type: Boolean,
      default: true,
    },
    isDisplayingMoreInfo: {
      type: Boolean,
      default: false,
    },
    showResultsButton: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  setup(
    props: {
      competitionSummaryPublic: ICompetitionSummaryPublic;
      showGoToComp: boolean;
      showMoreInfo: boolean;
      isDisplayingMoreInfo: boolean;
      showResultsButton: boolean;
      isAdmin: boolean;
    },
    context: SetupContext
  ) {
    const router = useRouter();
    const competitionService = new CompetitionService();
    let authStore = useAuthStore();
    const competitionSummaryPublicController =
      useCompetitionSummaryPublicController(props.competitionSummaryPublic);

    const compStoreState = useCompStoreState();

    function contactOrganiser() {
      context.emit("showContactOrganiser", props.competitionSummaryPublic);
    }

    function goToResults() {
      doShowMoreInfo("SCHEDULE");
    }

    function doShowMoreInfo(compMoreInfoSection: CompMoreInfoSection) {
      context.emit("showMoreInfo", {
        competitionSummaryPublic: props.competitionSummaryPublic,
        compMoreInfoSection: compMoreInfoSection,
      });
    }

    const getCheckinDateTime = computed(() => {
      const checkInDateTimeOpens =
        props.competitionSummaryPublic.options.checkIn.checkInDateTimeOpens;
      if (checkInDateTimeOpens.length === 0) {
        return "";
      }
      return getE4sStandardHumanDateTimeOutPut(checkInDateTimeOpens, false);
    });

    const getEntryCloseDate = computed(() => {
      return getE4sStandardHumanDateTimeOutPut(
        props.competitionSummaryPublic.closedate,
        false
      );
    });

    const getEntryOpenDate = computed(() => {
      return getE4sStandardHumanDateTimeOutPut(
        props.competitionSummaryPublic.opendate,
        false
      );
    });

    const isPastOpenDate = computed(() => {
      return isAfter(
        new Date(),
        parse(props.competitionSummaryPublic.opendate)
      );
    });

    const getLateEntryDate = computed(() => {
      if (competitionService.hasSaleEndDate(props.competitionSummaryPublic)) {
        return competitionService.getSaleEndMessage(
          props.competitionSummaryPublic
        );
      }
      return "";
    });

    const getPublicCardOpenCloseMessage = computed(() => {
      return CompetitonServiceV2.getPublicCardOpenCloseMessage(
        props.competitionSummaryPublic
      );
    });

    const MAX_NEWSFLASH_LENGTH = 150;

    const getNewsFlash = computed(() => {
      //  remove paragraph tags
      const newsFlash = props.competitionSummaryPublic.newsFlash.replace(
        /<\/?p[^>]*>/g,
        ""
      );

      if (newsFlash.length < MAX_NEWSFLASH_LENGTH) {
        return newsFlash;
      }
      return newsFlash.slice(0, MAX_NEWSFLASH_LENGTH) + "...";
    });

    const showMoreNewsFlash = computed(() => {
      return (
        props.competitionSummaryPublic.newsFlash.length > MAX_NEWSFLASH_LENGTH
      );
    });

    function goToComp() {
      if (
        props.competitionSummaryPublic.options.ui.entryDefaultPanel ===
        "SHOP_ONLY"
      ) {
        router.push({
          path:
            "/" +
            LAUNCH_ROUTES_PATHS.SHOP +
            "/" +
            props.competitionSummaryPublic.compId,
        });
        return;
      }

      const compUrl =
        "/v2/" +
        LAUNCH_ROUTES_PATHS_V2.ENTRY_V2 +
        "/" +
        props.competitionSummaryPublic.compId;

      let location: RawLocation;
      if (!authStore.isLoggedIn) {
        location = {
          path: "/v2/" + LAUNCH_ROUTES_PATHS_V2.LOGIN_V2,
          query: {
            redirectFrom: compUrl,
          },
        };
      } else {
        location = {
          path: compUrl,
        };
      }

      router.push(location);
    }

    function doProcessNext() {
      if (props.showMoreInfo) {
        goToComp();
        return;
      }
      doShowMoreInfo("INFO");
    }

    const isCompActive = computed(() => {
      return CompetitonServiceV2.isCompActive(props.competitionSummaryPublic);
    });

    const getCardCss = computed<string[]>(() => {
      const css: string[] = [];

      if (props.isDisplayingMoreInfo) {
        css.push("e4s-card--transparent");
      }
      // else {
      //   css.push("e4s-square--right");
      // }

      if (!isCompActive.value) {
        css.push("entry-public-v2--inactive");
      }
      return css;
    });

    return {
      competitionSummaryPublicController,
      contactOrganiser,
      goToResults,
      doShowMoreInfo,
      isPastOpenDate,
      getEntryOpenDate,
      getCheckinDateTime,
      getEntryCloseDate,
      getLateEntryDate,
      goToComp,
      getPublicCardOpenCloseMessage,
      doProcessNext,
      getNewsFlash,
      MAX_NEWSFLASH_LENGTH,
      showMoreNewsFlash,
      getCardCss,
      isCompActive,
      compStoreState,
    };
  },
});
