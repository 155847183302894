import {
  IAthleteCompSchedRuleEvent,
  IEoptions,
} from "../athletecompsched/athletecompsched-models";
import { GENDER, IValidationResult } from "../common/common-models";
import {
  ATHLETE_PB_VALIDATION_MESSAGES,
  IAgeGroup,
  IAgeInfo,
  IAthlete,
  IAthletePb,
  IAthleteSearch,
  IAthleteSummary,
  ICompEventGroupAthletes,
  IVetAgeGroup,
} from "./athlete-models";
import { ConfigService } from "../config/config-service";
import { IUserInfo } from "../config/config-app-models";
import { CommonService } from "../common/common-service";
import { IValidationProp } from "../validation/validation-models";
import { ValidationService } from "../validation/validation-service";
import { isBefore, parse } from "date-fns";
const configService: ConfigService = new ConfigService();

export class AthleteService {
  public factoryAthleteSummary(): IAthleteSummary {
    return {
      id: 0,
      firstName: "",
      surName: "",
      URN: "",
      dob: "",
      club: "",
      clubname: "",
      clubid: 0,
      club2: "",
      club2id: 0,
      club2Id: 0,
      gender: GENDER.UNKNOWN,
      classification: 0,
      schoolid: 0,
      school: "",
      inTeam: false,
      ageInfo: this.factoryAgeInfo(),
      aocode: "",
      activeEndDate: "",
      userAthletes: [],
      events: [],
      image: "",
      options: {
        noEntryReason: "",
        emergency: {
          name: "",
          tel: "",
          relationship: "",
        },
      },
      pbInfo: [],
      email: "",
      infoText: "",
    };
  }

  public factoryGetAthlete(): IAthlete {
    return {
      ...this.factoryAthleteSummary(),
    };
  }

  public factoryGetAthleteSearch(): IAthleteSearch {
    return {
      athleteid: 0,
      country: "",
      region: "",
      county: "",
      club: "",
      firstname: "",
      surname: "",
      gender: "",
      ageGroupId: 0,
      search: "",
      showAllAthletes: "1",
      ceid: 0,
      urn: "",
      eventId: 0,
    };
  }

  public factoryAgeInfo(): IAgeInfo {
    return {
      ageGroup: this.factoryAgeGroup(),
      ageGroups: [],
      vetAgeGroup: this.factoryVetAgeGroup(),
      currentAge: 0,
      competitionAge: 0,
    };
  }

  public factoryAgeGroup(): IAgeGroup {
    return {
      id: 0,
      Name: "",
      name: "",
      shortName: "",
    };
  }

  public factoryVetAgeGroup(): IVetAgeGroup {
    return {
      Name: "",
      shortName: "",
    };
  }

  public factoryAthletePb(): IAthletePb {
    return {
      eventid: 0,
      eventName: "",
      pb: 0,
      pbText: "",
      uomInfo: {
        id: 0,
        type: "D",
        options: [],
      },
      min: 0,
      max: 0,
      pof10pb: 0,
      sb: 0,
      trackSb: false,
    };
  }

  public factoryCompEventGroupAthletes(): ICompEventGroupAthletes {
    return {
      comp: {
        id: 0,
        name: "",
      },
      eventGroup: {
        id: 0,
        date: "",
        name: "",
        eventNo: 0,
        entries: [],
      },
    };
  }

  public validateAthlete(athlete: IAthlete): Record<string, IValidationProp> {
    const validationService: ValidationService = new ValidationService();
    let validationState: Record<string, IValidationProp> = {};

    if (athlete.firstName.replace(/ /g, "").length === 0) {
      validationState = validationService.addMessage(
        "firstName",
        "First name: Required.",
        validationState
      );
    }
    if (athlete.surName.replace(/ /g, "").length === 0) {
      validationState = validationService.addMessage(
        "surName",
        "Surname: Required.",
        validationState
      );
    }
    if (athlete.gender.length === 0) {
      validationState = validationService.addMessage(
        "gender",
        "Gender: Required.",
        validationState
      );
    }
    if (athlete.dob.length === 0) {
      validationState = validationService.addMessage(
        "dob",
        "Date of birth: Required.",
        validationState
      );
    }
    return validationState;
  }

  public isPbValid(
    event: IAthleteCompSchedRuleEvent,
    pb: null | number
  ): IValidationResult[] {
    const validationResults: IValidationResult[] = [];
    if (pb === 0 || pb === null) {
      return validationResults;
    }

    const eoptions: IEoptions = event.eoptions;
    const pbMin: null | number = eoptions.min ? eoptions.min : null;
    const pbMax: null | number = eoptions.max ? eoptions.max : null;

    if (pbMin !== null && pbMax !== null) {
      if (pb < pbMin) {
        validationResults.push({
          name: ATHLETE_PB_VALIDATION_MESSAGES.PB_BELOW_MIN.NAME,
          message: ATHLETE_PB_VALIDATION_MESSAGES.PB_BELOW_MIN.MESSAGE,
        });
      }

      if (pb > pbMax) {
        validationResults.push({
          name: ATHLETE_PB_VALIDATION_MESSAGES.PB_ABOVE_MIN.NAME,
          message: ATHLETE_PB_VALIDATION_MESSAGES.PB_ABOVE_MIN.MESSAGE,
        });
      }
    }

    return validationResults;
  }

  public validateDobEntry(value: string): boolean {
    // Returns a Boolean or a Promise that resolves to a boolean.
    if (value === undefined || value.length === 0) {
      return false;
    }
    // This pattern is DD/MM/YYYY
    // tslint:disable-next-line:max-line-length
    // const regexPattern = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    const regexPattern =
      /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
    //   This is YYYY-MM-DD
    //  const regexPattern = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    const regexTest = new RegExp(regexPattern);
    const result = regexTest.test(value);
    return result;
  }

  public validateUrn(value: string | number, regexPattern: any) {
    // if (typeof value === "number" ) {
    //     value = value + "";
    // }
    // return (value as string).length === 6;
    if (value === "") {
      return true;
    }
    const re = new RegExp(regexPattern);
    value = value + "";
    return re.test(value);
  }

  public isAthleteSearchFilterEnabled(athleteSearch: IAthleteSearch): boolean {
    if (athleteSearch.ageGroupId > 0) {
      return true;
    }

    // Have to bin below 'cos lose strict typing...?
    return Object.keys(athleteSearch).reduce(
      (accum: boolean, keyName: string) => {
        // @ts-ignore
        const keyValue: any = athleteSearch[keyName];
        if (keyValue && keyValue.length > 0) {
          if (keyName === "showAllAthletes" && keyValue === "1") {
            //   we can skip this, as it is a default.
            return accum;
          }
          accum = true;
        }
        return accum;
      },
      false
    );
  }

  public isClubEditable(athlete: IAthlete): boolean {
    if (athlete.id === 0) {
      return true;
    }
    if (
      athlete.id > 0 &&
      (athlete.clubid === undefined || athlete.clubid === 0)
    ) {
      return true;
    }
    return false;
  }

  public getAthleteUniqueClubId(athlete: IAthlete | IAthleteSummary): string {
    return athlete.id + "-" + athlete.clubid;
  }

  public canDeleteAthleteLink(
    athlete: IAthleteSummary,
    userInfo: IUserInfo
  ): boolean {
    if (athlete.id === 0) {
      return false;
    }
    return configService.getEntitiesFromUserInfo(userInfo).length === 0;
  }

  public getAthletePbForEventId(
    athlete: IAthlete,
    eventId: number
  ): IAthletePb | null {
    return athlete.pbInfo.reduce(
      (accum: IAthletePb | null, athPb: IAthletePb) => {
        if (athPb.eventid === eventId) {
          accum = athPb;
        }
        return accum;
      },
      null
    );
  }

  public arePo10PbsSame(
    athlete: IAthleteSummary,
    athletePo10: IAthleteSummary
  ): boolean {
    if (!(athlete && athlete.pbInfo && athlete.pbInfo.length > 0)) {
      return false;
    }
    if (!(athletePo10 && athletePo10.pbInfo && athletePo10.pbInfo.length > 0)) {
      return false;
    }
    return (
      new CommonService().differenceBetweenTwoArrays(
        athlete.pbInfo,
        athletePo10.pbInfo
      ).length === 0
    );
  }

  public hasRegistration(athlete: IAthleteSummary): boolean {
    if (!(athlete.URN && athlete.URN.toString().length > 0)) {
      return false;
    }
    const activeEndDate = athlete.activeEndDate;
    return activeEndDate && activeEndDate.length > 0 ? true : false;
  }

  public hasRegistrationExpired(athlete: IAthleteSummary): boolean {
    if (!this.hasRegistration(athlete)) {
      return false;
    }
    return isBefore(parse(athlete.activeEndDate), new Date());
  }
}
