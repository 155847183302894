import {
  ISecondaryDef,
  ISecondaryPrice,
  ISecondaryRefObj,
  ISecondaryProduct,
  ISecondaryAttributes,
  ISecondaryAttributeValue,
  ISecondaryVariant,
  ISecondaryProductBase,
  ISecondaryAttributeBase,
  IWooCommerceLineItem,
} from "./secondary-models";
import { ISecondaryStoreState } from "./secondary-store";
import { IValidationProp } from "../validation/validation-models";
import { ValidationService } from "../validation/validation-service";
import * as R from "ramda";
import { IAthlete } from "../athlete/athlete-models";
import { ISecondaryPurchaseRead } from "./cust/secondary-cust-models";
import { ITicketFormBase, ITicketFormData } from "./ticket/ticket-models";
import { format } from "date-fns";
// import {TicketService} from "./ticket/ticket-service";

const validationService: ValidationService = new ValidationService();

// const ticketService: TicketService  = new TicketService();

export class SecondaryService {
  public factorySecondaryRefObj(): ISecondaryRefObj {
    return {
      id: 0,
      objId: 0,
      objName: "",
      objKey: "",
      objType: "SYSTEM",
      compId: 0,
    };
  }

  public factorySecondaryProductBase(): ISecondaryProductBase {
    return {
      id: 0,
      parentId: 0,
      name: "",
      description: "",
      image: "",
      stockQty: 0,
      soldQty: 0,
      price: this.factorySecondaryPrice(),
      download: false,
      ticket: {
        ticketText: "",
        dataReq: false,
      },
    };
  }

  public factorySecondaryProduct(): ISecondaryProduct {
    return {
      ...this.factorySecondaryProductBase(),
      attributes: [],
      variations: [],
    };
  }

  public factorySecondaryDef(): ISecondaryDef {
    return {
      id: 0,

      prod: this.factorySecondaryProduct(),
      maxAllowed: 0,
      refObj: this.factorySecondaryRefObj(),
      perAcc: "",
    };
  }

  public factorySecondaryPrice(): ISecondaryPrice {
    return {
      price: 0,
      salePrice: 0,
      saleEndDate: "",
    };
  }

  public factorySecondaryAttributes(): ISecondaryAttributes {
    return {
      name: "",
      values: "",
    };
  }

  public factorySecondaryAttributeValue(): ISecondaryAttributeValue {
    return {
      name: "",
      value: "",
    };
  }

  public factorySecondaryVariant(): ISecondaryVariant {
    return {
      ...this.factorySecondaryProductBase(),
      menuOrder: 1,
      attributeValues: [],
    };
  }

  public factorySecondaryStoreState(): ISecondaryStoreState {
    return {
      secondaryDefs: [],
      secondariesLoading: false,
      userSecondaryPurchaseRead: [],
      athleteSecondaryPurchaseRead: [],
      athleteSecondaryPurchasesLoading: false,
    };
  }

  public validateSecondary(
    secondaryDef: ISecondaryDef
  ): Record<string, IValidationProp> {
    let validationState: Record<string, IValidationProp> = {};

    if (secondaryDef.prod.name.length === 0) {
      validationState = validationService.addMessage(
        "prod.name",
        "Required.",
        validationState
      );
    }

    if (secondaryDef.maxAllowed < 0) {
      validationState = validationService.addMessage(
        "maxAllowed",
        "Minimum value allowed is 0.",
        validationState
      );
    }

    if (secondaryDef.prod.price.price < 0) {
      validationState = validationService.addMessage(
        "prod.price.price",
        "Minimum value allowed is 0.",
        validationState
      );
    }

    if (secondaryDef.prod.price.salePrice < 0) {
      validationState = validationService.addMessage(
        "prod.price.price",
        "Minimum value allowed is 0.",
        validationState
      );
    }

    if (
      secondaryDef.prod.price.saleEndDate.length > 0 &&
      secondaryDef.prod.price.salePrice < secondaryDef.prod.price.price
    ) {
      validationState = validationService.addMessage(
        "prod.price.salePrice",
        "Sale price should be less than price.",
        validationState
      );
    }

    if (secondaryDef.prod.stockQty < 1) {
      validationState = validationService.addMessage(
        "prod.stockQty",
        "Minimum value allowed is 1.",
        validationState
      );
    }

    return validationState;
  }

  public getAttributesValues(
    secondaryAttributes: ISecondaryAttributes,
    sep: string = "|"
  ): string[] {
    return secondaryAttributes.values.split(sep).map((val) => {
      return val.trim();
    });
  }

  public createVariationFromSecondaryDef(
    secondaryDef: ISecondaryDef
  ): ISecondaryVariant {
    const secondaryVariant = this.factorySecondaryVariant();
    secondaryVariant.name = secondaryDef.prod.name;
    secondaryVariant.price.price = secondaryDef.prod.price.price;
    secondaryVariant.parentId = secondaryDef.prod.id;
    return secondaryVariant;
  }

  public hasAttributes(secondaryDef: ISecondaryDef): boolean {
    return (
      secondaryDef.prod &&
      secondaryDef.prod.attributes &&
      secondaryDef.prod.attributes.length > 0
    );
  }

  public removeAllCrapAttributes(secondaryDef: ISecondaryDef): ISecondaryDef {
    const secondaryDefInt = R.clone(secondaryDef);
    if (secondaryDefInt.prod.attributes) {
      secondaryDefInt.prod.attributes = this.removeCrapAttribute(
        secondaryDefInt.prod.attributes
      ) as ISecondaryAttributes[];
      if (secondaryDefInt.prod.variations) {
        secondaryDefInt.prod.variations = secondaryDefInt.prod.variations.map(
          (variation: ISecondaryVariant) => {
            variation.attributeValues = this.removeCrapAttribute(
              variation.attributeValues
            ) as ISecondaryAttributeValue[];
            return variation;
          }
        );
      }
    }
    return secondaryDefInt;
  }

  public getAttributeNameToIgnore(): string {
    return "_athlete";
  }

  public removeCrapAttribute(
    attributes: ISecondaryAttributeBase[]
  ): ISecondaryAttributeBase[] {
    if (attributes && attributes.length) {
      return attributes.filter((attr: ISecondaryAttributeBase) => {
        return (
          attr.name.toUpperCase() !==
          this.getAttributeNameToIgnore().toUpperCase()
        );
      });
    }
    return attributes;
  }

  public setAthleteAttributeName(
    athlete: IAthlete,
    attributes: ISecondaryAttributeValue[]
  ): ISecondaryAttributeValue[] {
    if (attributes && attributes.length) {
      let foundProp = false;
      const athleteIdName =
        athlete.id + ": " + athlete.firstName + " " + athlete.surName;
      const res = attributes.map((attr: ISecondaryAttributeValue) => {
        if (
          attr.name.toUpperCase() ===
          this.getAttributeNameToIgnore().toUpperCase()
        ) {
          attr.value = athleteIdName;
          foundProp = true;
        }
        return attr;
      });
      if (!foundProp) {
        res.push({
          name: this.getAttributeNameToIgnore(),
          value: athleteIdName,
        });
      }
      return res;
    }
    return attributes;
  }

  public getAthleteAttributeNameFromWoo(
    wooCommerceLineItem: IWooCommerceLineItem
  ): string {
    if (wooCommerceLineItem && wooCommerceLineItem.variation) {
      const keys = Object.keys(wooCommerceLineItem.variation);
      if (keys.length === 0) {
        return "";
      }
      const variation = wooCommerceLineItem.variation;
      return this.getAthleteAttributeName(variation);
    }
    return "";
  }

  public getAthleteAttributeName(variations: Record<string, string>): string {
    if (variations) {
      const keys = Object.keys(variations);
      if (keys.length === 0) {
        return "";
      }

      return keys.reduce((accum, key: string) => {
        const keyName = key.replace("attribute_", "");
        if (
          keyName.toUpperCase() ===
          this.getAttributeNameToIgnore().toUpperCase()
        ) {
          accum = variations[key];
        }
        return accum;
      }, "");
    }
    return "";
  }

  public getAthleteIdFromVariations(
    variations: Record<string, string>
  ): number {
    const idAndName = this.getAthleteAttributeName(variations);
    if (idAndName.length === 0) {
      return 0;
    }
    return Number(idAndName.split(":")[0]);
  }

  public mapAttributeValuesToVariations(
    attributeValues: ISecondaryAttributeValue[]
  ): Record<string, string> {
    return attributeValues.reduce((accum, attributeValue) => {
      accum[attributeValue.name] = attributeValue.value.toString();
      return accum;
    }, {} as Record<string, string>);
  }

  public getSecondaryPurchaseReadForAthleteId(
    athleteId: number,
    secondaryPurchaseReads: ISecondaryPurchaseRead[]
  ): ISecondaryPurchaseRead[] {
    if (athleteId === 0) {
      return [];
    }

    return secondaryPurchaseReads.filter(
      (secondaryPurchaseRead: ISecondaryPurchaseRead) => {
        if (
          secondaryPurchaseRead.orderLine &&
          secondaryPurchaseRead.orderLine._athlete
        ) {
          //  _athlete: "451: Matthew Ashley"
          const attrAthleteId = Number(
            secondaryPurchaseRead.orderLine._athlete.split(":")[0]
          );
          return athleteId === attrAthleteId;
        }
        return false;

        // const variations = this.mapAttributeValuesToVariations(secondaryPurchaseRead.attributeValues);
        // const attrAthleteId = this.getAthleteIdFromVariations(variations);
      }
    );
  }

  public getAthleteVariationName(ticketFormBase: ITicketFormBase): string {
    const attributeMatch = this.getAttributeNameToIgnore();
    return Object.keys(ticketFormBase.variations).reduce((accum, key) => {
      if (key === attributeMatch) {
        accum = ticketFormBase.variations[attributeMatch];
      }
      return accum;
    }, "");
  }

  public getSeatDescription(ticketFormBase: ITicketFormBase) {
    //  Getting some circular issue
    // return ticketService.getSeatDescription(ticketFormBase);
    const seat = ticketFormBase.ticket.seat;
    if (seat && seat.id > 0) {
      return (
        format(seat.date, "Do MMM YY") +
        ", Block: " +
        seat.block +
        ", Row: " +
        seat.row +
        ", Seat: " +
        seat.seatNo
      );
    }
    return "";
  }

  public hasFormData(ticketFormBase: ITicketFormBase): boolean {
    if (!ticketFormBase.data) {
      return false;
    }
    const keys: (keyof ITicketFormData)[] = Object.keys(
      ticketFormBase.data
    ) as (keyof ITicketFormData)[];
    const hasData = keys.reduce<boolean>((accum, key) => {
      if (key !== "id") {
        if (ticketFormBase.data[key].toString()) {
          accum = true;
        }
      }
      return accum;
    }, false);
    return hasData;
  }
}
