






export default {
    props: ["validationController", "propPath"]
};
