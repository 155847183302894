import {IFieldHelpStoreState, IHelpData} from "./field-help-store"

export class FieldHelpService {

    public factoryHelpData(): IHelpData {
        return {
            id: 0,
            key: "",
            title: "",
            type: "T",
            data: "",
            preload: false
        };
    }

    public factoryFieldHelpStoreState(): IFieldHelpStoreState {
        return {
            helpKey: "",
            showHelp: false,
            isLoading: false,
            helpData: this.factoryHelpData(),
            serverCache: {}
        }
    }

}
