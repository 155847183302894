import { ActionContext } from "vuex";
import { AgeGroupData } from "../agegroup/agegroup-data";
import {
  IAgeGroup,
  IAgeGroupCompCoverageModel,
} from "../agegroup/agegroup-models";
import { IRootState } from "../app.store";
import { AreaData } from "../area/area-data";
import {
  GENDER,
  IObjectKey,
  IServerGenericResponse,
  IServerResponse,
  IServerResponseList,
} from "../common/common-models";
import { IListParams } from "../common/resource/resource-service";
import { EventData } from "../event/event-data";
import { LocationData } from "../location/location-data";
import { OrgData } from "../org/org-data";
import { IOrg } from "../org/org-models";
import { USER_MESSAGE_LEVEL } from "../user-message/user-message-models";
import { messageDispatchHelper } from "../user-message/user-message-store";
import { BuilderData } from "./builder-data";
import { IBuilderCompetition } from "./builder-models";
import { IBuilderStoreState } from "./builder-store";
import { BUILDER_STORE_CONST } from "./builder-store-constants";
import { BuilderService } from "./builder-service";
import { EventService } from "../event/event-service";
import { UomData } from "../uom/uom-data";
import { IEventE4S } from "../event/event-models";
import { ICompEvent } from "../compevent/compevent-models";
import { CompEventData } from "../compevent/compevent-data";
import { DiscountData } from "../discount/discount-data";
import { ICompRule } from "../comprule/comprule-models.";
import { CompRuleData } from "../comprule/comprule-data";
import { IDiscount } from "../discount/discount-models";
import { router } from "../index";
import { LAUNCH_ROUTES_PATHS } from "../launch/launch-routes";
import { RawLocation } from "vue-router";
import { PriceData } from "../price/price-data";
import { OrgService } from "../org/org-service";
import { CONFIG_STORE_CONST } from "../config/config-store";
import { ICompEventSchedGrid } from "../compevent/compeventschedule/compeventschedule-models";
// import {SecondaryData} from "../secondary/secondary-data"

const orgData: OrgData = new OrgData();
const builderData: BuilderData = new BuilderData();
const locationData: LocationData = new LocationData();
const areaData: AreaData = new AreaData();
const ageGroupData: AgeGroupData = new AgeGroupData();
const eventData: EventData = new EventData();
const builderService: BuilderService = new BuilderService();
const eventService = new EventService();
const uomData = new UomData();
const compEventData: CompEventData = new CompEventData();
const compRuleData: CompRuleData = new CompRuleData();
const discountData: DiscountData = new DiscountData();
const orgService: OrgService = new OrgService();

export const builderStoreActions = {
  [BUILDER_STORE_CONST.BUILDER_ACTIONS_GET_COMP_ORGS](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: { listParams: IListParams }
  ) {
    context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_ORGS, []);
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_ORGS_LOADING,
      true
    );

    orgData
      .list(payload.listParams)
      .then((response: IServerGenericResponse) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_ORGS,
          response.data
        );
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_ORGS_LOADING,
          false
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_ORGS, []);
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_ORGS_LOADING,
          false
        );
        return;
      });
  },
  [BUILDER_STORE_CONST.BUILDER_ACTIONS_GET_LOCATIONS](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: { listParams: IListParams }
  ) {
    context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_LOCATIONS, []);
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_LOCATIONS_LOADING,
      true
    );
    locationData
      .list(payload.listParams)
      .then((response: IServerGenericResponse) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_LOCATIONS,
          response.data
        );
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_LOCATIONS_LOADING,
          false
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_LOCATIONS, []);
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_LOCATIONS_LOADING,
          false
        );
        return;
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_GET_AREAS](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: { listParams: IListParams }
  ) {
    context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_AREAS, []);
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_AREAS_LOADING,
      true
    );
    areaData
      .list(payload.listParams)
      .then((response: IServerGenericResponse) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_AREAS,
          response.data
        );
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_AREAS_LOADING,
          false
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_AREAS, []);
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_AREAS_LOADING,
          false
        );
        return;
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_GET_BUILDER_BY_ID](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: {
      id: number;
    }
  ) {
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_SUBMIT_BUILDER_LOADING_RESULT,
      ""
    );
    context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_BUILDER_LOADING, true);

    if (payload.id === 0) {
      const configApp =
        context.rootState[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME]
          .configApp;
      const builder = builderService.factoryGetBuilder({
        allowExpiredRegistration:
          configApp.options.defaultAllowExpiredRegistration,
      });

      context.commit(
        BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_BUILDER_FROM_ID,
        builder
      );
      context.commit(
        BUILDER_STORE_CONST.BUILDER_MUTATIONS_BUILDER_LOADING,
        false
      );
      return;
    }

    return builderData
      .read(payload.id)
      .then((response: IServerResponse<IBuilderCompetition>) => {
        if (response.errNo > 0) {
          context.commit(
            BUILDER_STORE_CONST.BUILDER_MUTATIONS_SUBMIT_BUILDER_LOADING_RESULT,
            response.error
          );
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }

        if (
          !response.data.compOrg.options ||
          Object.keys(response.data.compOrg.options).length === 0
        ) {
          response.data.compOrg.options = orgService.factoryOrgOptions();
        }

        if (!response.data.options.autoEntries) {
          response.data.options.autoEntries =
            builderService.factoryAutoEntries();
        }
        if (!response.data.options.level) {
          response.data.options.level = "";
        }
        if (response.data.options.pbMandatory === undefined) {
          response.data.options.pbMandatory = false;
        }
        if (response.data.options.pbMandatory === undefined) {
          response.data.options.pbMandatory = false;
        }
        if (!response.data.options.cloneInfo) {
          response.data.options.cloneInfo = {
            fromId: 0,
            saved: false,
          };
        }

        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_BUILDER_FROM_ID,
          response.data
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        return;
      })
      .finally(() => {
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_BUILDER_LOADING,
          false
        );
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_SUBMIT_BUILDER](
    context: ActionContext<IBuilderStoreState, IRootState>
  ) {
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_SUBMIT_BUILDER_LOADING_RESULT,
      ""
    );
    context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_BUILDER_LOADING, true);
    const builderCompetition = context.state.builderCompetition;
    const prom =
      builderCompetition.id === 0
        ? builderData.create(builderCompetition)
        : builderData.update(builderCompetition);
    return prom
      .then((response: IServerResponse<IBuilderCompetition>) => {
        if (response.errNo > 0) {
          context.commit(
            BUILDER_STORE_CONST.BUILDER_MUTATIONS_SUBMIT_BUILDER_LOADING_RESULT,
            response.error
          );
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        } else {
          messageDispatchHelper(
            "Competition Saved.",
            USER_MESSAGE_LEVEL.INFO.toString()
          );
        }
        //  Route to the created builder
        // context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_BUILDER, response.data);
        const location: RawLocation = {
          name: LAUNCH_ROUTES_PATHS.BUILDER,
          params: {
            id: response.data.id.toString(),
          },
        } as RawLocation;
        router.push(location);
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_BUILDER_LOADING,
          false
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_BUILDER_LOADING,
          false
        );
        return;
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_CREATE_ORG](
    context: ActionContext<IBuilderStoreState, IRootState>,
    org: IOrg
  ) {
    const prom = org.id === 0 ? orgData.create(org) : orgData.update(org);
    return prom
      .then((response: IServerGenericResponse) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        messageDispatchHelper(
          "Organisation " + org.name + " saved.",
          USER_MESSAGE_LEVEL.INFO.toString()
        );
        // context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_BUILDER, response.data);
        // return;
        return response.data;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        return;
      });
  },

  //
  // [BUILDER_STORE_CONST.BUILDER_ACTIONS_CREATE_LOCATION](
  //     context: ActionContext<IBuilderStoreState, IRootState>,
  //     location: ILocation
  // ) {
  //
  //     const prom = location.id === 0 ? locationData.create(location) : locationData.update(location);
  //     return prom
  //         .then( (response: IServerGenericResponse) => {
  //             if (response.errNo > 0) {
  //                 messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
  //                 return;
  //             }
  //             messageDispatchHelper("Organisation " + location.name + " saved.", USER_MESSAGE_LEVEL.INFO.toString());
  //
  //             context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_UPDATE_LOCATION, response.data);
  //
  //             return response.data;
  //         })
  //         .catch((error) => {
  //             messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
  //             return;
  //         });
  // },

  // [BUILDER_STORE_CONST.BUILDER_ACTIONS_GET_AGE_GROUPS_FOR_COMP](
  //     context: ActionContext<IBuilderStoreState, IRootState>
  // ) {
  //     context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP, []);
  //     context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP_LOADING, true);
  //     ageGroupData.getAgeGroupsForComp(context.state.builderCompetition.id)
  //         .then( (response: IServerGenericResponse) => {
  //             if (response.errNo > 0) {
  //                 messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
  //                 return;
  //             }
  //             context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP, response.data);
  //             context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP_LOADING, false);
  //             return;
  //         })
  //         .catch((error) => {
  //             messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
  //             context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP, []);
  //             context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP_LOADING, false);
  //             return;
  //         });
  // },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_GET_AGE_GROUPS_ALL](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: { listParams: IListParams }
  ) {
    context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_ALL, []);
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_ALL_LOADING,
      true
    );
    ageGroupData
      .list(payload.listParams)
      .then((response: IServerGenericResponse) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_ALL,
          response.data
        );
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_ALL_LOADING,
          false
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_ALL,
          []
        );
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_ALL_LOADING,
          false
        );
        return;
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_GET_AGE_GROUPS_DEFAULT](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: {
      aocode: string;
      compId: number;
    }
  ) {
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_DEFAULT_SET,
      []
    );
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_DEFAULT_LOADING,
      true
    );
    ageGroupData
      .getAgeGroupsForComp(payload.aocode, payload.compId)
      .then((response: IServerGenericResponse) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_DEFAULT_SET,
          response.data
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_DEFAULT_SET,
          []
        );
        return;
      })
      .finally(() => {
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_DEFAULT_LOADING,
          false
        );
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_CREATE_AGE_GROUP](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: { ageGroup: IAgeGroup }
  ) {
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_CREATE_GROUP_LOADING,
      true
    );
    const prom =
      payload.ageGroup.id === 0
        ? ageGroupData.create(payload.ageGroup)
        : ageGroupData.update(payload.ageGroup);
    prom
      .then((response: IServerGenericResponse) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        messageDispatchHelper(
          "Age group " + payload.ageGroup.name + " saved.",
          USER_MESSAGE_LEVEL.INFO.toString()
        );
        // context.dispatch(BUILDER_STORE_CONST.BUILDER_ACTIONS_GET_AGE_GROUPS_FOR_COMP);
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP_ADD,
          response.data
        );
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_CREATE_GROUP_LOADING,
          false
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_CREATE_GROUP_LOADING,
          false
        );
        return;
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_LIST_EVENTS](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: {
      listParams: IListParams;
      gender: GENDER;
    }
  ) {
    context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS, []);
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS_LOADING,
      true
    );
    eventData
      .list(payload.listParams)
      .then((response: IServerGenericResponse) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        let eventsByGender;
        if (payload.gender && payload.gender.length > 0) {
          //  TODO Ideally, pass gender to service.
          eventsByGender = eventService.filterEventsByGender(
            payload.gender,
            response.data
          );
        } else {
          eventsByGender = response.data;
        }
        // console.log(BUILDER_STORE_CONST.BUILDER_ACTIONS_LIST_EVENTS + "...", eventsByGender);
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS,
          eventsByGender
        );
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS_LOADING,
          false
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS, []);
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS_LOADING,
          false
        );
        return;
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_UOMS_LIST](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: {
      listParams: IListParams;
    }
  ) {
    context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_UOMS, []);
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_UOMS_LOADING,
      true
    );
    uomData
      .list(payload.listParams)
      .then((response: IServerGenericResponse) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }

        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_UOMS,
          response.data
        );
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_UOMS_LOADING,
          false
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_UOMS, []);
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_UOMS_LOADING,
          false
        );
        return;
      });
  },

  // [BUILDER_STORE_CONST.BUILDER_ACTIONS_UOMS_CREATE](
  //     context: ActionContext<IBuilderStoreState, IRootState>,
  //     payload: {
  //         eventE4s: IEventE4S
  //     }
  // ) {
  //     context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_UOMS_LOADING, true);
  //     eventData.create(payload.eventE4s)
  //         .then( (response: IServerGenericResponse) => {
  //             if (response.errNo > 0) {
  //                 messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
  //                 return;
  //             }
  //             context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_UOMS_LOADING, false);
  //             return;
  //         })
  //         .catch((error) => {
  //             messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
  //             context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_UOMS_LOADING, false);
  //             return;
  //         });
  // }

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_EVENT_CREATE](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: {
      eventE4s: IEventE4S;
    }
  ) {
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS_LOADING,
      true
    );
    return eventData
      .create(payload.eventE4s)
      .then((response: IServerGenericResponse) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        messageDispatchHelper(
          "Event saved.",
          USER_MESSAGE_LEVEL.INFO.toString()
        );
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS_LOADING,
          false
        );
        return response.data;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS_LOADING,
          false
        );
        return;
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_COMP_EVENT_CREATE](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: {
      compEvent: ICompEvent;
    }
  ) {
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_EVENT_LOADING,
      true
    );
    return compEventData
      .create(payload.compEvent)
      .then((response: IServerGenericResponse) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        messageDispatchHelper(
          "Comp Event saved.",
          USER_MESSAGE_LEVEL.INFO.toString()
        );
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_EVENT_LOADING,
          false
        );
        return response.data;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_EVENT_LOADING,
          false
        );
        return {};
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_COMP_RULE_LOAD](
    context: ActionContext<IBuilderStoreState, IRootState>
  ) {
    const params: IListParams = {
      compId: context.state.builderCompetition.id,
    } as any as IListParams;
    compRuleData
      .list(params)
      .then((response: IServerResponseList<ICompRule>) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_COMP_RULE_SET,
          response.data
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        return {};
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_DISCOUNT_LOAD](
    context: ActionContext<IBuilderStoreState, IRootState>
  ) {
    const params: IListParams = {
      compId: context.state.builderCompetition.id,
    } as any as IListParams;
    discountData
      .list(params)
      .then((response: IServerResponseList<IDiscount>) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_DISCOUNT_SET,
          response.data
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        return {};
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_COMPEVENTS_LOAD](
    context: ActionContext<IBuilderStoreState, IRootState>
  ) {
    // const params: IListParams = {
    //   compId: context.state.builderCompetition.id,
    // } as any as IListParams;
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_EVENT_LOADING,
      true
    );

    console.log("BUILDER_STORE_CONST.BUILDER_ACTIONS_COMPEVENTS_LOAD...a");

    return compEventData
      .getScheduleListWithMeta(context.state.builderCompetition.id)
      .then((response) => {
        console.log("BUILDER_STORE_CONST.BUILDER_ACTIONS_COMPEVENTS_LOAD...b");
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_COMPEVENTS_SET,
          response.data
        );

        if (response.meta && response.meta.ageGroups) {
          const ageGroupCompCoverageModels: IAgeGroupCompCoverageModel[] =
            response.meta.ageGroups;
          context.commit(
            BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_COVERAGE_COMP,
            ageGroupCompCoverageModels
          );
        }

        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        return {};
      })
      .finally(() => {
        console.log("BUILDER_STORE_CONST.BUILDER_ACTIONS_COMPEVENTS_LOAD...z");
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_EVENT_LOADING,
          false
        );
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_COMPEVENT_DELETE](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: {
      compEvent: ICompEvent;
    }
  ) {
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_EVENT_LOADING,
      true
    );
    return compEventData
      .delete(payload.compEvent.id)
      .then((response: IServerResponse<ICompEvent>) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        context.dispatch(BUILDER_STORE_CONST.BUILDER_ACTIONS_COMPEVENTS_LOAD);
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        return {};
      })
      .finally(() => {
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_EVENT_LOADING,
          false
        );
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_COMPEVENT_DELETE_MULTIPLE](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: {
      compEvents: ICompEventSchedGrid[];
    }
  ) {
    console.log("BUILDER_ACTIONS_COMPEVENT_DELETE_MULTIPLE...a");
    context.commit(
      BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_EVENT_LOADING,
      true
    );
    const ids: IObjectKey[] = payload.compEvents.map(
      (compEvt: ICompEventSchedGrid) => {
        return {
          id: compEvt.id,
        };
      }
    );

    const eventGroupId: number =
      payload.compEvents[0].compEvent.eventGroupSummary.id;

    const allCompEventsWithSameId = context.state.compEventsSchedule.filter(
      (compEvent) => {
        return compEvent.eventGroupSummary.id === eventGroupId;
      }
    );

    const data = {
      ceids: ids,
    };

    console.log("BUILDER_ACTIONS_COMPEVENT_DELETE_MULTIPLE...b");
    const prom =
      allCompEventsWithSameId.length === ids.length
        ? compEventData.deleteEventGroup(eventGroupId)
        : compEventData.deleteMultiple(data);

    return prom
      .then((response: IServerResponse<any>) => {
        console.log("BUILDER_ACTIONS_COMPEVENT_DELETE_MULTIPLE...c");
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error + (response.message ? " " + response.message : ""),
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        return context.dispatch(
          BUILDER_STORE_CONST.BUILDER_ACTIONS_COMPEVENTS_LOAD
        );
        // return;
      })
      .catch((error) => {
        // messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        return {};
      })
      .finally(() => {
        console.log("BUILDER_ACTIONS_COMPEVENT_DELETE_MULTIPLE...z");
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_EVENT_LOADING,
          false
        );
      });
  },

  [BUILDER_STORE_CONST.BUILDER_ACTIONS_PRICES_GET_FOR_COMP](
    context: ActionContext<IBuilderStoreState, IRootState>,
    payload: {
      compId: number;
    }
  ) {
    context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_PRICES_LOADING, true);
    new PriceData()
      .getPricesForComp(payload.compId)
      .then((response: IServerResponse<any>) => {
        if (response.errNo > 0) {
          messageDispatchHelper(
            response.error,
            USER_MESSAGE_LEVEL.ERROR.toString()
          );
          return;
        }
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_PRICES_SET,
          response.data
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        return {};
      })
      .finally(() => {
        context.commit(
          BUILDER_STORE_CONST.BUILDER_MUTATIONS_PRICES_LOADING,
          false
        );
      });
  },

  // [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SECONDARIES_GET](
  //     context: ActionContext<IBuilderStoreState, IRootState>,
  //     payload: {
  //         compId: number
  //     }
  // ) {
  //     context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SECONDARIES_LOADING, true);
  //     new SecondaryData().getSecondaries(payload.compId)
  //         .then((response) => {
  //             if (response.errNo > 0) {
  //                 messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
  //                 return;
  //             }
  //             context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SECONDARIES_SET, response.data);
  //         })
  //         .catch((error) => {
  //             messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
  //             return;
  //         })
  //         .finally(() => {
  //             context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SECONDARIES_LOADING, false);
  //         });
  // }

  // [BUILDER_STORE_CONST.BUILDER_ACTIONS_LIST_GENERIC](
  //     context: ActionContext<IBuilderStoreState, IRootState>,
  //     payload: {listParams: IListParams, appStoreDispatchHelper: IAppStoreDispatchHelper}
  // ) {
  //     context.commit(payload.appStoreDispatchHelper.dataProp, []);
  //     context.commit(payload.appStoreDispatchHelper.loadingProp, true);
  //     eventData.list(payload.listParams)
  //         .then( (response: IServerGenericResponse) => {
  //             if (response.errNo > 0) {
  //                 messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
  //                 return;
  //             }
  //             context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS, response.data);
  //             context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS_LOADING, false);
  //             return;
  //         })
  //         .catch((error) => {
  //             messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
  //             context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS, []);
  //             context.commit(BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS_LOADING, false);
  //             return;
  //         });
  // }
};
