









































import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import { IAthleteSecurity } from "../../builder/builder-models";
import { IBase } from "../../common/common-models";
import { format, parse } from "date-fns";
import { IAthlete } from "../../athlete/athlete-models";
import { LAUNCH_ROUTES_PATHS } from "../../launch/launch-routes";
import { simpleClone } from "../../common/common-service-utils";

export default defineComponent({
  name: "CompRestrictedV2",
  components: {},
  props: {
    athleteSecurity: {
      type: Object as PropType<IAthleteSecurity>,
      default: () => {
        return {
          areas: [],
          clubs: [],
          onlyClubsUpTo: "",
        };
      },
    },
    athlete: {
      type: Object as PropType<IAthlete>,
      default: () => {
        return {
          id: 0,
          firstName: "",
        };
      },
    },
    showAthleteProfilesLink: {
      type: Boolean,
      default: true,
    },
  },
  setup(
    props: {
      athleteSecurity: IAthleteSecurity;
      athlete: IAthlete;
      showAthleteProfilesLink: boolean;
    },
    context: SetupContext
  ) {
    const restrictedAreas = computed<IBase[]>(() => {
      const areas = simpleClone(props.athleteSecurity.areas);
      return areas.sort((a: IBase, b: IBase) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 1;
      });
    });

    const restrictedClubs = computed<IBase[]>(() => {
      const clubs = simpleClone(props.athleteSecurity.clubs);
      return clubs.sort((a: IBase, b: IBase) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 1;
      });
    });

    const cutOffDateTime = computed(() => {
      if (
        props.athleteSecurity.onlyClubsUpTo &&
        props.athleteSecurity.onlyClubsUpTo.length > 0
      ) {
        const onlyClubsUpTo = format(
          parse(props.athleteSecurity.onlyClubsUpTo),
          "Do MMM HH:mm"
        );
        return " Restriction Ends: " + onlyClubsUpTo;
      }
      //  No date set, so comp completely locked down to these clubs.
      return "";
    });

    const getAthleteProfileLink = computed(() => {
      return "#/" + LAUNCH_ROUTES_PATHS.ATHLETE + "/" + props.athlete.id;
    });

    const getMyAccountAthletes = computed(() => {
      return "#/" + LAUNCH_ROUTES_PATHS.ATHLETES;
    });

    return {
      restrictedAreas,
      restrictedClubs,
      cutOffDateTime,
      getAthleteProfileLink,
      getMyAccountAthletes,
    };
  },
});
