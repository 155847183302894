var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"e4s-flex-row",staticStyle:{"min-height":"50px"}},[_c('div',{staticClass:"e4s-card e4s-card--generic e4s-full-width",class:_vm.getCardCss},[_c('div',{staticClass:"e4s-flex-column e4s-gap--standard"},[_c('h1',{staticClass:"e4s-header--400"},[_c('div',{staticClass:"e4s-flex-row"},[_c('div',{domProps:{"textContent":_vm._s(
              _vm.competitionSummaryPublicController.getFirstCompDate.value +
              ' - ' +
              _vm.competitionSummaryPublic.compName
            )}}),_vm._v(" "),_c('div',{staticClass:"e4s-flex-row--end",domProps:{"textContent":_vm._s('#' + _vm.competitionSummaryPublic.compId)}})])]),_vm._v(" "),_c('hr',{staticClass:"dat-e4s-hr-only"}),_vm._v(" "),_c('div',{staticClass:"e4s-flex-row e4s-full-width e4s-justify-flex-space-between"},[_c('div',{domProps:{"textContent":_vm._s(_vm.competitionSummaryPublic.club)}}),_vm._v(" "),_c('div',{staticClass:"e4s-flex-column e4s-flex-end"},[_c('div',{class:_vm.competitionSummaryPublicController.isNearToClosing.value
                ? 'e4s-info-text--error e4s-header--400'
                : '',domProps:{"textContent":_vm._s(_vm.getPublicCardOpenCloseMessage)}})])]),_vm._v(" "),_c('div',{staticClass:"e4s-flex-row e4s-full-width e4s-justify-flex-space-between"},[_c('div',{staticClass:"e4s-card--competition__logo-container"},[(_vm.competitionSummaryPublicController.getLogo.value.length > 0)?_c('img',{staticClass:"e4s-card--competition__logo-image",attrs:{"src":_vm.competitionSummaryPublicController.getLogo.value}}):_vm._e(),_vm._v(" "),(
              _vm.competitionSummaryPublicController.getLogo.value.length === 0
            )?_c('E4sLogoSvg',{staticStyle:{"height":"68px","padding":"5px"}}):_vm._e()],1),_vm._v(" "),_c('a',{staticClass:"e4s-hyperlink--100 e4s-hyperlink--primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToResults.apply(null, arguments)}}},[_c('div',{staticClass:"\n              e4s-flex-row e4s-flex-nowrap\n              e4s-card-competition__participant-overview-container\n            "},[_c('CompetitionEntryCounts',{attrs:{"competition-summary-public":_vm.competitionSummaryPublic},on:{"click":function($event){$event.preventDefault();return _vm.goToResults.apply(null, arguments)}}})],1)])]),_vm._v(" "),(
          _vm.competitionSummaryPublic.newsFlash.length > 0 &&
          !_vm.competitionSummaryPublic.options.disabled
        )?_c('div',{staticClass:"e4s-flex-column"},[_c('div',{staticClass:"e4s-info-text--error"},[_c('p',{staticClass:"e4s-flex-column e4s-gap--standard-x"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getNewsFlash)}})])])]):_vm._e(),_vm._v(" "),(_vm.competitionSummaryPublic.options.disabled)?_c('div',{staticClass:"e4s-flex-column"},[_c('div',{staticClass:"e4s-info-text--error",domProps:{"innerHTML":_vm._s(_vm.competitionSummaryPublic.options.disabledReason)}})]):_vm._e(),_vm._v(" "),(_vm.competitionSummaryPublicController.hasAthleteSecurity.value)?_c('CompRestrictedV2',{attrs:{"athlete-security":_vm.competitionSummaryPublic.options.athleteSecurity,"show-athlete-profiles-link":false}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"e4s-flex-row"},[(
            !_vm.isDisplayingMoreInfo &&
            _vm.competitionSummaryPublic.options.resultsAvailable &&
            _vm.showResultsButton
          )?_c('ButtonGotoGenericV2',{attrs:{"goto-type":"RESULTS","comp-id":_vm.competitionSummaryPublic.compId}}):_vm._e(),_vm._v(" "),_vm._t("button-more-info",function(){return [(!_vm.isDisplayingMoreInfo)?_c('ButtonGenericV2',{staticClass:"e4s-button--auto e4s-flex-row--end",attrs:{"text":_vm.showMoreInfo
                ? _vm.competitionSummaryPublicController.getEnterButtonText.value
                : _vm.competitionSummaryPublicController
                    .getEnterButtonTextSimpleCard.value},on:{"click":_vm.doProcessNext}}):_vm._e()]})],2),_vm._v(" "),(
          _vm.isAdmin && _vm.competitionSummaryPublicController.isPastCompDate.value
        )?[_c('hr',{staticClass:"dat-e4s-hr-only"}),_vm._v(" "),(_vm.competitionSummaryPublicController.isPastCompDate.value)?_c('div',{staticClass:"e4s-flex-row e4s-justify-flex-space-between"},[_c('div',{staticClass:"e4s-flex-row e4s-gap--standard"},[_vm._v("\n            Status\n            "),_c('span',{domProps:{"textContent":_vm._s(
                _vm.competitionSummaryPublicController.getStatusDescription.value
              )}})]),_vm._v(" "),_c('ButtonGenericV2',{staticClass:"e4s-button--slim",staticStyle:{"width":"50px"},attrs:{"text":"Edit","button-type":"secondary"},on:{"click":function($event){_vm.competitionSummaryPublicController.isEditingStatus.value = true}}}),_vm._v(" "),(_vm.competitionSummaryPublicController.isEditingStatus.value)?_c('ModalV2',{attrs:{"is-full-screen":_vm.$mq === _vm.VUE_MQ_SIZES.MOBILE.name,"always-show-header-blank":true}},[_c('StatusContainer',{attrs:{"slot":"body","competition-summary-public-prop":_vm.competitionSummaryPublic,"work-flows":_vm.compStoreState.workFlows},on:{"onClose":function($event){_vm.competitionSummaryPublicController.isEditingStatus.value = false}},slot:"body"})],1):_vm._e()],1):_vm._e()]:_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }