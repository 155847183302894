import {
    ICheckinAthlete,
    IAthleteEntry,
    ICheckinCompSummary,
    IAthleteCheckinDetails,
    ICheckInStoreState,
    ICheckinStats,
    ICheckInAthleteEventStatus
} from "./checkin-models"
import {GENDER} from "../../common/common-models";
import {CompetitionService} from "../competiton-service";
import {addMinutes, isBefore, parse, isAfter, isEqual, format} from "date-fns"
import {CommonService} from "../../common/common-service";
// import {BIB_COLLECTED_STATUS, IGetCheckinSearchParams} from "./checkin-data";
// import {ConfigService} from "../../config/config-service";

const competitionService: CompetitionService = new CompetitionService();
const commonService: CommonService = new CommonService();
// const configService: ConfigService = new ConfigService();

export class CheckinService {

    public factoryCheckinAthlete(): ICheckinAthlete {
        return {
            id: 0,
            athleteId: 0,
            firstName: "",
            surName: "",
            dob: "",
            urn: "",
            gender: GENDER.UNKNOWN,
            bibNo: 0,
            teamBibNo: "",
            club: "",
            collected: false,
            confirmedTime: "",
            collectedTime: "",
            entries: []
        };
    }

    public factoryAthleteEntry(): IAthleteEntry {
        return {
            id: 0,
            name: "",
            checkedIn: false,
            dateTime: "",
            ageGroup: "",
            checkInFrom: -1,
            checkInTo: -1
        };
    }

    public factoryCheckinStats(): ICheckinStats {
        return {
            expected: 0,
            collected: 0,
            checkInDateTimeOpens: "",
            text: "",
            codes: {},
            useTerms: false,
            terms: "",
            defaultTo: 0,
            defaultFrom: 0
        };
    }

    public factoryCheckinCompSummary(): ICheckinCompSummary {
        return {
            ...competitionService.factoryCompetitionSummary(),
            checkIn: this.factoryCheckinStats(),
            eventGroups: []
        };
    }

    public factoryStoreState(): ICheckInStoreState {
        return {
            isLoading: false,
            message: "",
            athleteCheckin: [] as ICheckinAthlete[],
            checkinSummary: this.factoryCheckinCompSummary(),
            isLoadingSummary: false,
            emailId: "",
            compCode: "",
            nonce: "",
            clearDownIsLoading: false
        };
    }

    // public factoryGetCheckinSearchParams(): IGetCheckinSearchParams {
    //     return {
    //         compId: 0,
    //         isoDate: "",
    //         firstName: "",
    //         lastName: "",
    //         club: "",
    //         entity: configService.factoryEntity(),
    //         urn:"",
    //         eventName: "",
    //         bibNo: "",
    //         pageSize: 20,
    //         pageNumber: 1,
    //         collected: BIB_COLLECTED_STATUS.ALL,
    //         dob: "",
    //         isOrganiser: false
    //     }
    // }

    public getFirstCheckInEvent(athleteCheckin: ICheckinAthlete): IAthleteEntry {
        return this.getCheckInEvent(athleteCheckin, true);
    }

    public getLastCheckInEvent(athleteCheckin: ICheckinAthlete): IAthleteEntry {
        return this.getCheckInEvent(athleteCheckin, false);
    }

    public getCheckInEvent(athleteCheckin: ICheckinAthlete, getFirst: boolean): IAthleteEntry {
        const checkInEvent = athleteCheckin.entries.filter((entry) => {
            return entry.dateTime.length > 0;
        })
            .reduce((accum, entry) => {
                if (accum.id === 0) {
                    accum = entry;
                }
                const propToCheck: string = getFirst ? "checkInFrom" : "checkInTo";

                // @ts-ignore
                if (entry[ propToCheck ] > -1) {
                    // @ts-ignore
                    const adjustedFrom = addMinutes(parse(entry.dateTime), -entry[ propToCheck ]);
                    // @ts-ignore
                    const adjustedFromAccum = addMinutes(parse(accum.dateTime), -accum[ propToCheck ]);
                    if (isBefore(adjustedFrom, adjustedFromAccum)) {
                        accum = entry;
                    }
                } else {

                    if (getFirst) {
                        if (entry.dateTime < accum.dateTime) {
                            accum = entry;
                        }
                    } else {
                        if (entry.dateTime > accum.dateTime) {
                            accum = entry;
                        }
                    }
                }
                return accum;
            }, this.factoryAthleteEntry());
        return checkInEvent;
    }

    public getCheckInTimesIso(athleteEntry: IAthleteEntry): { from: string, to: string } {
        let to = "";
        let from = "";

        const eventDateTime = parse(athleteEntry.dateTime);

        if (athleteEntry.checkInFrom > -1) {
            const adjustedFrom = addMinutes(eventDateTime, -athleteEntry.checkInFrom);
            from = format(adjustedFrom, commonService.getIsoDateTimePattern())
        }
        if (athleteEntry.checkInTo > -1) {
            const adjustedTo = addMinutes(eventDateTime, -athleteEntry.checkInTo);
            to = format(adjustedTo, commonService.getIsoDateTimePattern())
        }

        return {
            from,
            to
        };
    }

    public getCheckInAthleteEvent(athleteEntry: IAthleteEntry, dateTimeNow: Date): ICheckInAthleteEventStatus {
        const checkInTimes = this.getCheckInTimesIso(athleteEntry);
        let isAfterOpenTime = false;
        let isBeforeClosing = false;

        if (checkInTimes.from.length > 0) {
            isAfterOpenTime = isAfter(dateTimeNow, checkInTimes.from) || isEqual(dateTimeNow, checkInTimes.from);
        }
        if (checkInTimes.to.length > 0) {
            isBeforeClosing = isBefore(dateTimeNow, checkInTimes.to) || isEqual(dateTimeNow, checkInTimes.to);
        }
        return {
            isOK: isAfterOpenTime && isBeforeClosing,
            checkInTimes
        }
    }

    // public isCheckInOpenForAthlete(checkinStats: ICheckinStats, athleteCheckin: ICheckinAthlete, dateTimeNow: Date): boolean {
    //     const firstEvent = this.getFirstCheckInEvent(athleteCheckin);
    //     if (!firstEvent) {
    //         return true;
    //     }
    //     if (firstEvent.dateTime.length === 0 ) {
    //         return true;
    //     }
    //
    //     const firstEventDateTime = parse(firstEvent.dateTime);
    //     if (firstEvent.checkInFrom === -1) {
    //         //  All we can do is check the event time...is this even valid?
    //         if (isBefore(dateTimeNow, firstEventDateTime)) {
    //             return true;
    //         }
    //         return false;
    //     }
    //
    //     //  User has to "checkin" within window of the 1st event they are in.
    //     const checkInTimes = this.getCheckInTimesIso(firstEvent);
    //     const adjustedFrom = parse(checkInTimes.from);
    //     const adjustedTo = parse(checkInTimes.to);
    //     const isFromTimeOK = isAfter(dateTimeNow, adjustedFrom) || isEqual(dateTimeNow, adjustedFrom);
    //     const isToTimeOK = isBefore(dateTimeNow, adjustedTo) || isEqual(dateTimeNow, adjustedTo);
    //     return isFromTimeOK && isToTimeOK
    //     // return {
    //     //     dateTimeNow: format(dateTimeNow, commonService.getIsoDateTimePattern()),
    //     //     checkInTimes,
    //     //     isFromTimeOK,
    //     //     isToTimeOK
    //     // }
    // }

    public getAthleteCheckinDetails(
      checkinStats: ICheckinStats,
      athleteCheckin: ICheckinAthlete,
      dateTimeNow: Date,
      dateTimeCheckinOpens: Date | null
    ): IAthleteCheckinDetails {

        const checkinEvent = this.getFirstCheckInEvent(athleteCheckin);

        checkinEvent.checkInFrom = checkinEvent.checkInFrom === -1 ? checkinStats.defaultFrom : checkinEvent.checkInFrom;
        checkinEvent.checkInTo = checkinEvent.checkInTo === -1 ? checkinStats.defaultTo : checkinEvent.checkInTo;

        const details: IAthleteCheckinDetails = {
            checkinEvent,
            isCheckinOpen: true,
            message: "",
            checkInRangeMessage: "",
            status: "NOT_YET_OPEN",
            checkInOpensIso: "",
            checkInClosesIso: "",
            suggestedAthleteOpen: "",
            suggestedAthleteClose: "",
            suggestedAthleteMessage: ""
        };

        if (!checkinEvent) {
            return details;
        }
        if (checkinEvent.dateTime.length === 0 ) {
            return details;
        }

        const firstEventDateTime = parse(checkinEvent.dateTime);
        const checkInTimes = this.getCheckInTimesIso(checkinEvent);

        details.suggestedAthleteOpen = checkInTimes.from;
        details.suggestedAthleteClose = checkInTimes.to;

        let suggestedAthleteMessage = "";
        if (checkInTimes.from.length > 0 && checkInTimes.to.length === 0) {
            suggestedAthleteMessage = "From " + format(parse(checkInTimes.from), "HH:mm");
        }

        if (checkInTimes.from.length === 0 && checkInTimes.to.length > 0) {
            suggestedAthleteMessage = "Until " + format(parse(checkInTimes.to), "HH:mm");
        }

        if (checkInTimes.from.length > 0 && checkInTimes.to.length > 0) {
            suggestedAthleteMessage = "From " + format(parse(checkInTimes.from), "HH:mm")  +
                " until " + format(parse(checkInTimes.to), "HH:mm");
        }

        details.suggestedAthleteMessage = suggestedAthleteMessage;

        let isAfterOpenTime = null;
        let isBeforeClosing = null;
        let adjustedFrom = null;
        let adjustedTo = null;

        const checkInNeverCloses = checkinStats.defaultTo === -1;

        //  Has comp open date...
        if (dateTimeCheckinOpens) {
            adjustedFrom = dateTimeCheckinOpens;
            details.checkInOpensIso = format(adjustedFrom, commonService.getIsoDateTimePattern());
            if (isAfter(dateTimeNow, dateTimeCheckinOpens)){
                details.status = "OPEN";
            }
        } else {
            //  No comp opens datetime so use event.
            if (checkinEvent.checkInFrom === -1) {
                //  All we can do is check the event time...is this even valid?
                if (isBefore(dateTimeNow, firstEventDateTime)) {
                    return details;
                }
                details.isCheckinOpen = false;
                return details;
            }
            //  User has to "checkin" within window of the 1st event they are in.

            details.checkInOpensIso = checkInTimes.from;
            adjustedFrom = parse(checkInTimes.from);
        }
        isAfterOpenTime = isAfter(dateTimeNow, adjustedFrom) || isEqual(dateTimeNow, adjustedFrom);

        if (checkInNeverCloses) {
            details.checkInClosesIso = "";
            isBeforeClosing = true;
        } else {
            details.checkInClosesIso = checkInTimes.to;
            adjustedTo = parse(checkInTimes.to);
            isBeforeClosing = isBefore(dateTimeNow, adjustedTo) || isEqual(dateTimeNow, adjustedTo);
        }

        details.isCheckinOpen = isAfterOpenTime && isBeforeClosing;

        let adjustedFromOutput;
        let adjustedToOutput;
        let message = "";

        adjustedFromOutput = adjustedFrom ? format(adjustedFrom, "Do MMM HH:mm") : "NA"
        adjustedToOutput = adjustedTo ? format(adjustedTo, "Do MMM HH:mm") :
            checkInNeverCloses ? "" : "NA";

        details.checkInRangeMessage = checkInNeverCloses ? "From " + adjustedFromOutput :
            adjustedFromOutput + " to " + adjustedToOutput;

        if (!isBeforeClosing) {
            message = "Closed at " + adjustedToOutput;
            details.status = "CLOSED";
        } else {
            if (isAfterOpenTime) {
                message = checkInNeverCloses ? "Open" : "Closes at " + adjustedToOutput;
                details.status = "OPEN";
            } else {
                message = "Check-in opens: " + adjustedFromOutput + (checkinStats.defaultTo === -1 ? "" : checkInNeverCloses ?  "" : " to " + adjustedToOutput);
                details.status = "NOT_YET_OPEN";
            }
        }
        details.message = message;
        return details;
    }

    public haveAnyEventsBeenSelected(athleteCheckin: ICheckinAthlete): boolean {
        const eventsSelected = athleteCheckin.entries.filter((evt) => {
            return evt.checkedIn;
        });
        return eventsSelected.length > 0;
    }

}
