import { router } from "../index";

// const currentRoute = reactive({
//   ...router.currentRoute
// });
//
// router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
//   Object.keys(to).forEach(key => {
//     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//     // @ts-ignore
//     currentRoute[key] = to[key];
//   });
//   next();
// });

export function useRouter() {
  return router;
}

export function useRoute() {
  return router.currentRoute;
}

