








import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "form-generic-input-error-message-v2",
  components: {},
  inheritAttrs: false,
  props: {
    errorMessage: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
});
