import {ActionContext, Module} from "vuex";
import {IRootState} from "../app.store";
import { IAthlete } from "../athlete/athlete-models";
import { IAthleteCompSchedRuleEvent } from "../athletecompsched/athletecompsched-models";
import { ICartEvent } from "./cart-models";
//  import { CartService } from "./cart-service";

//  const cartService: CartService = new CartService();

export enum CART_STORE_CONST {
    CART_CONST_MODULE_NAME = "CART_CONST_MODULE_NAME",

    // <MUTATIONS>
    CART_MUTATIONS_EVENTS_CART = "CART_MUTATIONS_EVENTS_CART",
    // </MUTATIONS>

    // <ACTIONS>
    CART_ACTIONS_UPDATE_CART_EVENTS = "CART_ACTIONS_UPDATE_CART_EVENTS"
    // </ACTIONS>

}

export interface ICartStoreState {
    eventsCart: ICartEvent[];
}

const mutations = {
    [ CART_STORE_CONST.CART_MUTATIONS_EVENTS_CART ](state: ICartStoreState, eventsCart: ICartEvent[]) {
        state.eventsCart = [...eventsCart];
    }
};

const actions = {

    //  CART_STORE_CONST.CART_ACTIONS_UPDATE_CART_EVENTS
    //  CART_CONST_MODULE_NAME/CART_ACTIONS_UPDATE_CART_EVENTS
    [ "CART_ACTIONS_UPDATE_CART_EVENTS" ](
        context: ActionContext<ICartStoreState, IRootState>,
        payload: {
            athlete: IAthlete,
            eventsSelected: IAthleteCompSchedRuleEvent[]
        }) {

        // const eventsSelected: IAthleteCompSchedRuleEvent[] = context.rootState["sdwsd"];
        // const eventsCart = cartService.factoryEventRulesToCartEvents(eventsSelected)
        // .map((evt) => {
        //     return cartService.addAthleteDetailsToCartEvent(evt, payload.athlete);
        // });
        // context.commit(CART_STORE_CONST.CART_MUTATIONS_EVENTS_CART, eventsCart);
    }
};

export const cartStore: Module<ICartStoreState, IRootState> = {
    namespaced: true,
    state: {
        eventsCart: []
    },
    mutations,
    actions
};
