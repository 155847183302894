import {IServerResponse} from "../../../common-models"
import {Module} from "vuex";
import {IRootState} from "../../../../app.store";
import {ActionContext} from "vuex";
import * as R from "ramda";
import {FieldHelpData} from "./field-help-data"
import {messageDispatchHelper} from "../../../../user-message/user-message-store"
import {USER_MESSAGE_LEVEL} from "../../../../user-message/user-message-models"
import {FieldHelpService} from "./field-help-service"

//  Text | HTML | Url
export type FIELD_HELP_MESSAGE_TYPE = "T" | "H" | "U";

const fieldHelpData: FieldHelpData = new FieldHelpData();
const fieldHelpService: FieldHelpService = new FieldHelpService();

export interface IHelpDataSimple {
    title: string;
    data: string;
    type: FIELD_HELP_MESSAGE_TYPE;
}

export interface IHelpData extends IHelpDataSimple{
    id: number;
    key: string;
    preload: boolean;
}

export interface IFieldHelpStoreState {
    helpKey: string;
    showHelp: boolean;
    helpData: IHelpData;
    isLoading: boolean;
    serverCache: Record<string, IHelpData>;
}

export enum FIELD_HELP_STORE_CONST {

    CONST_MODULE_NAME =  "FIELD_HELP_STORE_CONST",

    // <MUTATIONS>
    MUTATIONS_SET_SHOW_HELP =  "MUTATIONS_SET_SHOW_HELP",
    MUTATIONS_SET_HELP_DATA =  "MUTATIONS_SET_HELP_DATA",
    MUTATIONS_SET_HELP_DATA_SERVER_CACHE =  "MUTATIONS_SET_HELP_DATA_SERVER_CACHE",
    MUTATIONS_SET_HELP_DATA_LOADING =  "MUTATIONS_SET_HELP_DATA_LOADING",
    // </MUTATIONS>

    // <ACTIONS>
    ACTIONS_GET_HELP_FORM_SERVER =  "ACTIONS_GET_HELP_FORM_SERVER",
    ACTIONS_SUBMIT_HELP =  "ACTIONS_SUBMIT_HELP"
    // </ACTIONS>
}

const mutations = {
    [FIELD_HELP_STORE_CONST.MUTATIONS_SET_HELP_DATA_LOADING](state: IFieldHelpStoreState, isLoading: boolean) {
        state.isLoading = isLoading;
    },
    [FIELD_HELP_STORE_CONST.MUTATIONS_SET_SHOW_HELP](state: IFieldHelpStoreState, showHelp: boolean) {
        if (!showHelp) {
            //  Clear out whatever is there.
            state.helpData = fieldHelpService.factoryHelpData();
        }
        state.showHelp = showHelp;
    },
    [FIELD_HELP_STORE_CONST.MUTATIONS_SET_HELP_DATA](state: IFieldHelpStoreState, helpData: IHelpData) {
        if (helpData) {
            state.helpData = R.clone(helpData);
            state.showHelp = true;
        }
    },
    [FIELD_HELP_STORE_CONST.MUTATIONS_SET_HELP_DATA_SERVER_CACHE](state: IFieldHelpStoreState, helpData: IHelpData) {
        if (helpData) {
            state.serverCache[helpData.key] = R.clone(helpData);
        }
    }
};

const getters = {};

const actions = {
    [FIELD_HELP_STORE_CONST.ACTIONS_GET_HELP_FORM_SERVER](
        context: ActionContext<IFieldHelpStoreState, IRootState>,
        payload: {
            key: string
        }
    ) {
        context.commit(FIELD_HELP_STORE_CONST.MUTATIONS_SET_HELP_DATA_LOADING, true);
        context.commit(FIELD_HELP_STORE_CONST.MUTATIONS_SET_SHOW_HELP, true);

        return fieldHelpData.readHelp(payload.key)
            .then( (response: IServerResponse<IHelpData>) => {
                if (response.errNo > 0) {
                    messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
                    return fieldHelpService.factoryHelpData();
                }
                context.commit(FIELD_HELP_STORE_CONST.MUTATIONS_SET_HELP_DATA, response.data);
                context.commit(FIELD_HELP_STORE_CONST.MUTATIONS_SET_HELP_DATA_SERVER_CACHE, response.data);
                return response.data;
            })
            .catch((error) => {
                messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
                return fieldHelpService.factoryHelpData();
            })
            .finally(() => {
                context.commit(FIELD_HELP_STORE_CONST.MUTATIONS_SET_HELP_DATA_LOADING, false);
            })
    },

    [FIELD_HELP_STORE_CONST.ACTIONS_SUBMIT_HELP](
        context: ActionContext<IFieldHelpStoreState, IRootState>,
        payload: {
            helpData: IHelpData
        }
    ): Promise<IHelpData> {
        context.commit(FIELD_HELP_STORE_CONST.MUTATIONS_SET_HELP_DATA_LOADING, true);

        const helpData = payload.helpData;
        let prom: Promise<IServerResponse<IHelpData>>;
        if (helpData.id === 0) {
            prom = fieldHelpData.create(helpData);
        } else {
            prom = fieldHelpData.update(helpData);
        }

        return prom
            .then( (response: IServerResponse<IHelpData>) => {
                if (response.errNo > 0) {
                    messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.ERROR.toString());
                    return fieldHelpService.factoryHelpData();
                }
                context.commit(FIELD_HELP_STORE_CONST.MUTATIONS_SET_HELP_DATA, response.data);
                return response.data;
            })
            .catch((error) => {
                messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
                return fieldHelpService.factoryHelpData();
            })
            .finally(() => {
                context.commit(FIELD_HELP_STORE_CONST.MUTATIONS_SET_HELP_DATA_LOADING, false);
            })
    }
};

export const fieldHelpStore: Module<IFieldHelpStoreState, IRootState> = {
    namespaced: true,
    state: fieldHelpService.factoryFieldHelpStoreState(),
    mutations,
    getters,
    actions
};
