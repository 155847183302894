








import { defineComponent, SetupContext } from "@vue/composition-api";

export default defineComponent({
  name: "input-with-button",
  components: {},
  props: {},
  setup(props: any, context: SetupContext) {
    return {};
  },
});
