





















































import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import { useConfigStore } from "../../config/useConfigStore";
import { useAuthStore } from "../../auth/useAuthStore";
import { USER_ROLES } from "../../config/config-app-models";
import { useStore } from "../../app.store";
import { AUTH_STORE_CONST } from "../../auth/auth-store";
import { ITokenReponse } from "../../auth/auth-models";
import { CONFIG_STORE_CONST } from "../../config/config-store";
import { useRoute } from "../../router/migrateRouterVue3";
import {FieldHelpDataUrlPath} from "../../common/ui/field/field-help/field-help-data"
import {isLocalDevServer} from "../../common/config"

export default defineComponent({
  name: "launch-footer-v2",
  components: {},
  props: {
    entries: {
      type: Array as PropType<unknown[]>,
      default: () => {
        return [];
      },
    },
  },
  setup(props: { entries: unknown[] }, context: SetupContext) {
    const store = useStore();
    const configStore = useConfigStore();
    const authStore = useAuthStore();
    const route = useRoute();

    // const isLocalDevServer = isLocalDevServer;


    function adminToggleRole() {
      const role =
        configStore.configApp.role === USER_ROLES.E4SUSER
          ? USER_ROLES.USER
          : USER_ROLES.E4SUSER;

      store.commit(
        CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME +
          "/" +
          CONFIG_STORE_CONST.CONFIG_MUTATIONS_SET_ROLE,
        role
      );
    }

    function adminToggleLogin() {
      let token: ITokenReponse = {
        token: "",
        user_email: "",
        user_nicename: "",
        user_display_name: "",
      };

      let tokenValue = "";

      if (authStore.tokenReponse.token) {
        tokenValue =
          authStore.tokenReponse.token.length > 0 ? "" : "some_demo_token";
      } else {
        tokenValue = "some_demo_token";
      }

      token.token = tokenValue;

      // const token = !authStore.tokenReponse.token || authStore.tokenReponse.token === "" ? "some_demo_token" : "";

      store.commit(
        AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME +
          "/" +
          AUTH_STORE_CONST.AUTH_MUTATIONS_TOKEN_RESPONSE,
        token
      );
    }

    const getContentWidth = computed(() => {
      if (route.name && route.name === "home-v2") {
        return "e4s-width-controller-entry";
      }
      return "e4s-width-controller";
    });

    const getTermsAndConditionsUrl = computed( () => {
      return FieldHelpDataUrlPath + "/terms-and-conditions"
    })

    return {
      adminToggleRole,
      adminToggleLogin,
      getContentWidth,
      getTermsAndConditionsUrl,
      isLocalDevServer
    };
  },
});
