





























































import {
  defineComponent,
  SetupContext,
  computed,
  PropType,
} from "@vue/composition-api";
import { ILinkParentV2 } from "./launch-link-models";
import LaunchSublinkV2 from "./launch-sublink-v2.vue";

export default defineComponent({
  name: "launch-link-v2",
  components: { LaunchSublinkV2 },
  props: {
    linkParentV2: {
      type: Object as PropType<ILinkParentV2>,
      required: true,
    },
    showChildrenLinkParentV2: {
      type: Object as PropType<ILinkParentV2>,
      required: true,
    },
  },
  setup(
    props: {
      linkParentV2: ILinkParentV2;
      showChildrenLinkParentV2: ILinkParentV2;
    },
    context: SetupContext
  ) {
    // const showChildren = ref(false);

    const showChildren = computed(() => {
      return (
        props.linkParentV2.linkId === props.showChildrenLinkParentV2.linkId
      );
    });

    const hasChildren = computed(() => {
      return props.linkParentV2.children.length > 0;
    });

    function toggleShowChildren() {
      if (!hasChildren.value) {
        return;
      }
      // showChildren.value = !showChildren.value;
      context.emit("showChildren", props.linkParentV2);
    }

    const getParentClasses = computed(() => {
      const classes = [];

      //e4s-navigation-bar-menu--has-submenu e4s-navigation-bar-menu--submenu-closed

      if (hasChildren.value) {
        classes.push("e4s-navigation-bar-menu--has-submenu");

        if (showChildren.value) {
          classes.push("e4s-navigation-bar-menu--submenu-open");
        } else {
          classes.push("e4s-navigation-bar-menu--submenu-closed");
        }
      }

      return classes;
    });

    const getChildClasses = computed(() => {
      const classes = [];

      if (showChildren.value) {
        classes.push("e4s-navigation-dropdown--container-active");
      }

      return classes;
    });

    const getPath = computed(() => {
      return props.linkParentV2.path.length === 0
        ? "#"
        : props.linkParentV2.path;
    });

    function hideIfHasChildren() {
      if (hasChildren.value) {
        context.emit("hideChildren", props.linkParentV2);
      }
    }

    return {
      hasChildren,
      showChildren,
      toggleShowChildren,
      hideIfHasChildren,
      getParentClasses,
      getChildClasses,
      getPath,
    };
  },
});
