






















import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import { ICompetitionSummaryPublic } from "../../../../competition/competition-models";
import { ILocation } from "../../../../location/location-models";
import { useConfigController } from "../../../../config/useConfigStore";
import { format, parse } from "date-fns";
import * as CompetitonServiceV2 from "../../../../competition/v2/competiton-service-v2";

export default defineComponent({
  name: "comp-header-name-location-v2",
  components: {},
  props: {
    competitionSummaryPublic: {
      type: Object as PropType<ICompetitionSummaryPublic>,
      default: () => {
        return [];
      },
    },
    showMapLink: {
      type: Boolean,
      default: true,
    },
  },
  setup(
    props: {
      competitionSummaryPublic: ICompetitionSummaryPublic;
      showMapLink: boolean;
    },
    context: SetupContext
  ) {
    const configController = useConfigController();

    const getFirstCompDate = computed(() => {
      // return format(
      //   parse(props.competitionSummaryPublic.options.dates[0]),
      //   "Do MMM YYYY"
      // );
      // return eventDateDisplay(props.competitionSummaryPublic.options.dates[0]);
      const inSameYear =
        props.competitionSummaryPublic.options.dates[0].slice(0, 4) ===
        new Date().getFullYear().toString();
      return (
        format(
          parse(props.competitionSummaryPublic.options.dates[0]),
          "Do MMM" + (inSameYear ? "" : " YY")
        ) + ":"
      );
    });

    const compNameHtml = computed(() => {
      // Competition Title <span class="e4s-body--200">(123)</span>
      // competitionService.getCompetitionTitle(
      //   props.competitionSummaryPublic
      // )
      return (
        props.competitionSummaryPublic.compId +
        ": " +
        props.competitionSummaryPublic.compName
        // '<span class="e4s-body--200"> (' +
        // props.competitionSummaryPublic.compId +
        // ")</span>"
      );
    });

    const getLocationMapLink = computed(() => {
      return (
        "https://www.google.com/maps?q=" +
        encodeURIComponent(
          getLocationArray(props.competitionSummaryPublic.location).join(",")
        )
      );
    });

    function getLocationArray(location: ILocation): string[] {
      return [
        location.name,
        location.address1,
        location.address2,
        location.town,
        location.postcode,
      ].filter((line) => {
        return line.length > 0;
      });
    }

    const getLocationText = computed(() => {
      return getLocationArray(props.competitionSummaryPublic.location).join(
        ", "
      );
    });

    const getEntryCloseDate = computed(() => {
      return format(
        parse(props.competitionSummaryPublic.closedate),
        "Do MMM YY"
      );
    });

    const getPublicCardOpenCloseMessage = computed(() => {
      return CompetitonServiceV2.getPublicCardOpenCloseMessage(
        props.competitionSummaryPublic
      );
    });

    return {
      compNameHtml,
      getFirstCompDate,
      getLocationMapLink,
      getLocationText,
      configController,
      getEntryCloseDate,
      getPublicCardOpenCloseMessage,
    };
  },
});
