




import { defineComponent, SetupContext } from "@vue/composition-api";
import { RawLocation } from "vue-router";
import { LAUNCH_ROUTES_PATHS } from "../../../../launch/launch-routes";
import { useRouter } from "../../../../router/migrateRouterVue3";
import ButtonGenericV2 from "./button-generic-v2.vue";

export default defineComponent({
  name: "button-goto-contact-organiser-v2",
  components: { ButtonGenericV2 },
  props: {
    compId: {
      type: Number,
      default: 0,
    },
    buttonText: {
      type: String,
      default: () => {
        return "Contact Organiser";
      },
    },
  },
  setup(
    props: {
      compId: number;
      buttonText: string;
    },
    context: SetupContext
  ) {
    const router = useRouter();

    function goTo() {
      let location: RawLocation;
      location = {
        path: "/" + LAUNCH_ROUTES_PATHS.CONTACT_ORGANISER + "/" + props.compId,
      };
      router.push(location);
    }

    return { goTo };
  },
});
