import {IPrice} from "./price-models";
import {IObjectKeyType, IValidationResult} from "../common/common-models";
import {IConfigOptions} from "../config/config-app-models";
import {IValidationProp} from "../validation/validation-models";
import {ValidationService} from "../validation/validation-service";
// import {CommonService} from "../common/common-service";

const validationService: ValidationService = new ValidationService();

export class PriceService {
    public factoryGetPrice(): IPrice {
        return {
            id: 0,
            name: "",
            compId: 0,
            description: "",
            price: 0,
            fee: 0,
            salePrice: 0,
            saleFee: 0,
            saleEndDate: "",
            options: {
                displayFee: false,
                feeIncluded: true,
                freeEntry: false
            }
        };
    }

    public getPriceLabel(price: IPrice) {
        return "[" + price.id + "] " + price.name + " :: " + price.description + " " + price.price + "/" + price.fee + " :: "
            + (price.saleEndDate && price.saleEndDate.length > 0 ? price.saleEndDate : "NA") + " :: " + price.salePrice + "/" + price.saleFee + ", displayFee: " + price.options.displayFee;
    }

    public hasEarlyBird(price: IPrice): boolean {
        if (price.salePrice > 0 && (price.price !== price.salePrice)) {
            return true;
        }
        return false;
    }

    public validateSaleEndDate(price: IPrice): IValidationResult[] {
        const validationResults: IValidationResult[] = [];
        if (price.salePrice === price.price && price.saleFee === price.fee) {
            return validationResults;
        }
        if ((price.salePrice > 0 || price.saleFee > 0) && price.saleEndDate === "") {
            validationResults.push({
                name: "saleEndDate",
                message: "Sale End date required"
            });
        }
        return validationResults;
    }

    public validatePrices(price: IPrice): IValidationResult[] {
        const validationResults: IValidationResult[] = [];
        if (price.fee > price.price ) {
            validationResults.push({
                name: "",
                message: "Fee greater than price"
            });
        }
        return validationResults;
    }

    public validateSalePrices(price: IPrice): IValidationResult[] {
        const validationResults: IValidationResult[] = [];
        if (price.saleFee > price.salePrice ) {
            validationResults.push({
                name: "",
                message: "Sale Fee greater than price"
            });
        }
        if (price.salePrice > price.price ) {
            validationResults.push({
                name: "",
                message: "Sale price greater than price"
            });
        }

        return validationResults;
    }

    public validate(price: IPrice, configOptions: IConfigOptions): IObjectKeyType<IValidationProp> {

        let validationState: IObjectKeyType<IValidationProp> = {};

        if (price.description === "") {
            validationState = validationService.addMessage("description", "Required", validationState);
        }

        if (price.options.freeEntry) {
            return validationState;
        }

        if (price.price.toString().length === 0 || price.price < configOptions.minFee ) {
            validationState = validationService.addMessage("price", "Price must be at least " + configOptions.minFee, validationState);
        }
        this.validatePrices(price)
            .forEach( (validResult) => {
                validationState = validationService.addMessage("price", validResult.message, validationState);
            });

        if (this.hasEarlyBird(price)) {
            this.validateSaleEndDate(price)
                .forEach( (validResult) => {
                    validationState = validationService.addMessage("saleEndDate", validResult.message, validationState);
                });

            this.validateSalePrices(price)
                .forEach( (validResult) => {
                    validationState = validationService.addMessage("salePrice", validResult.message, validationState);
                });
        }
        return validationState;
    }

    public calculateFee(price: number, configOptions: IConfigOptions) {
        let priceResult: number = 0;
        if (price > 0) {
            priceResult =  price > configOptions.minCost ? price * 0.05 : configOptions.minFee;
        }
        return Number(priceResult.toFixed(2));
    }

    public calculateDisplayCosts(price: number, configOptions: IConfigOptions, feeIncluded: boolean, isNational: boolean): {toOrg: string, toE4S: string, costToAthlete: string} {
        let toOrg: number = 0;
        let toE4S: number = 0;
        let costToAthlete: number = 0;

        const addCost = isNational ? configOptions.nationaladdCost : configOptions.addCost;
        // const minCost = isNational ? configOptions.nationalminCost : configOptions.minCost

        // toE4S = price > minCost ? (price * (configOptions.percCharged/100)) + addCost : configOptions.minFee;
        toE4S = (price * (configOptions.percCharged/100)) + addCost;
        if (toE4S < configOptions.minFee) {
            toE4S = configOptions.minFee;
        }
        toE4S = Number(toE4S.toFixed(2));

        if (feeIncluded) {
            costToAthlete = price;
            toOrg = Number((price - toE4S).toFixed(2));
        } else {
            toOrg = price;
            costToAthlete = Number((toOrg + toE4S).toFixed(2));
        }

        //  (Math.round(num * 100) / 100).toFixed(2);

        // const commonService = new CommonService();
        // return {
        //     toOrg: commonService.roundNumberToDecimalPlaces(toOrg, 2, false).toString(),
        //     toE4S: commonService.roundNumberToDecimalPlaces(toE4S, 2, false).toString(),
        //     costToAthlete: commonService.roundNumberToDecimalPlaces(costToAthlete, 2, false).toString()
        // };
        return {
            toOrg: toOrg.toString(),
            toE4S: toE4S.toString(),
            costToAthlete: costToAthlete.toString()
        };
    }
}
