











import Vue from "vue";
import Component from "vue-class-component";
import LaunchHeader from "../../launch/launch-header.vue";

@Component({
    name: "admin-section",
    components: {
        LaunchHeader
    }
})
export default class E4SLayout extends Vue {

}
